import { useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGet, usePost, usePut, useDel } from 'http/useInnovit';
import { rq, rqDel, rqPost } from 'http/apiRoutes';
import { ProductCardEmployeeView } from 'components/ui/ProductCardEmployeeView';
import * as yup from 'yup';
import { FormAccordionSummary, FormBoxElevated } from 'components/form';
import useWindowsDimension from 'functions/useWindowsDimension';
import GetInitials from 'functions/GetInitials';
import { matchIsValidTel, MuiTelInput, MuiTelInputInfo } from 'mui-tel-input';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import {
  Accordion,
  AccordionDetails,
  Avatar,
  Button,
  Divider,
  Grid,
  Box,
  List,
  ListItem,
  Paper,
  Stack,
  Switch,
  Typography,
  IconButton,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import FeedRoundedIcon from '@mui/icons-material/FeedRounded';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import DevicesOtherRoundedIcon from '@mui/icons-material/DevicesOtherRounded';
import ImageUploader from 'components/ui/ImageUploader';
import { ObjectDrawer } from 'pages/Companies/ObjectDrawer';
import {
  IDepartmentViewModel,
  IEmployeeViewModel,
  IPlacement,
} from './ICompaniesConfig';
import ConvertBase64StringToFileModel from 'functions/ConvertBase64StringToFileModel';
import { IAddressViewModel, MissingPhoto } from 'data/globalTypeConfig';
import { IProductViewModel } from 'pages/Billing/BillingConfig';
import { IWorkRoleViewModel } from 'data/atoms/atomConfig';
import { formatPhoneNumber } from 'functions/FormatPhoneNumber';
import { SearchFilter } from 'components/ui/SearchFilter';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { useAtom } from 'jotai';
import ownerAtom from 'data/atoms/ownerAtom';
import {
  ICompanyProductViewModel,
  ICompanyProductAddViewModel,
} from './ICompanyProductConfig';
import GenerateGuid from 'functions/GenerateGuid';
import { IFileModel } from './CompanyConfig';
var _ = require('lodash');

const schema = yup
  .object({
    departmentId: yup.string().required('Department required'),
    name: yup
      .string()
      .matches(/^(|[A-å0-9 ]{2,50})$/, 'Between 2-50 characters')
      .required('Name is required'),
    email: yup.string().email().required('Email is required'),
    address: yup.object().shape({
      street: yup
        .string()
        .matches(/^(|[A-å0-9 ]{2,50})$/, 'Between 2-50 characters')
        .required('Street is required'),
      postalCode: yup
        .string()
        .matches(/^(|[0-9]{4,4})$/, '4 numbers required')
        .required('Postal code is required'),
      city: yup
        .string()
        .matches(/^(|[A-å0-9 ]{2,50})$/, 'Between 2-50 characters')
        .required('City is required'),
    }),
    role: yup.number().positive().integer().required('Role is required'),
    primaryContact: yup.boolean(),
    budget: yup.number().positive().integer(),
    title: yup
      .string()
      .matches(/^(|[A-å0-9 ]{2,50})$/, 'Between 2-50 characters'),
    url: yup.string(),
  })
  .required();

type Action =
  | { type: 'RESET_FORM' }
  | { type: 'SET_STATE'; payload: IEmployeeViewModel }
  | { type: 'UPDATE_FIELD'; field: keyof IEmployeeViewModel; value: any }
  | { type: 'UPDATE_ADDRESS_FIELD'; field: keyof IAddressViewModel; value: any }
  | { type: 'UPDATE_DEPARTMENT'; value: IDepartmentViewModel }
  | { type: 'CHANGE_WORK_ROLE'; payload: IWorkRoleViewModel }
  | { type: 'CHANGE_BUDGET'; payload: number }
  | { type: 'TOGGLE_PRIMARY_CONTACT'; payload: boolean }
  | { type: 'TOGGLE_GLOBAL_ADMINISTRATOR'; payload: boolean }
  | { type: 'ADD_PRODUCT'; value: ICompanyProductViewModel }
  | { type: 'REMOVE_PRODUCT'; value: ICompanyProductViewModel }
  | { type: 'UPDATE_PRODUCT'; value: ICompanyProductViewModel }
  | { type: 'SET_PRODUCTS'; value: ICompanyProductViewModel[] };

const initialState: IEmployeeViewModel = {
  id: 'new',
  name: '',
  phone: '+47',
  email: '',
  departmentId: '',
  department: {} as IDepartmentViewModel,
  address: {} as IAddressViewModel,
  role: 0,
  primaryContact: false,
  globalAdministrator: false,
  budget: 0,
  title: '',
  fileModel: {} as IFileModel,
  companyProducts: [] as ICompanyProductViewModel[],
  workRole: {} as IWorkRoleViewModel,
  url: '',
};

const reducer = (
  state: IEmployeeViewModel,
  action: Action
): IEmployeeViewModel => {
  switch (action.type) {
    case 'UPDATE_FIELD':
      if (action.field === 'phone') {
        return {
          ...state,
          [action.field]: formatPhoneNumber(action.value),
        };
      }
      return { ...state, [action.field]: action.value };

    case 'UPDATE_ADDRESS_FIELD':
      if (state.address === null) {
        // If address is null, create a new address object and update the field
        return {
          ...state,
          address: {
            ...({} as IAddressViewModel),
            [action.field]: action.value,
          },
        };
      } else {
        // If address exists, update the field within the existing address object
        return {
          ...state,
          address: {
            ...state.address,
            [action.field]: action.value,
          },
        };
      }
    case 'UPDATE_DEPARTMENT':
      return {
        ...state,
        department: action.value,
      };
    case 'SET_PRODUCTS':
      return {
        ...state,
        companyProducts: action.value,
      };
    case 'ADD_PRODUCT':
      return {
        ...state,
        companyProducts: [...state.companyProducts, action.value],
      };
    case 'REMOVE_PRODUCT':
      return {
        ...state,
        companyProducts: state.companyProducts.filter(
          (companyProduct) => companyProduct.id !== action.value.id
        ),
      };
    case 'UPDATE_PRODUCT':
      return {
        ...state,
        companyProducts: state.companyProducts.map((companyProduct) =>
          companyProduct.id === action.value.id
            ? { ...companyProduct, ...action.value }
            : companyProduct
        ),
      };
      // If the target product is not found, return the state as is
      return state;
    case 'CHANGE_WORK_ROLE':
      return {
        ...state,
        workRole: action.payload,
      };
    case 'CHANGE_BUDGET':
      return {
        ...state,
        budget: action.payload,
      };
    case 'TOGGLE_PRIMARY_CONTACT':
      return {
        ...state,
        primaryContact: action.payload,
      };
    case 'TOGGLE_GLOBAL_ADMINISTRATOR':
      return {
        ...state,
        globalAdministrator: action.payload,
      };
    case 'RESET_FORM':
      return initialState;
    case 'SET_STATE':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

type Props = {
  employee: IEmployeeViewModel;
  products: IProductViewModel[];
};

export const EmployeeForm = ({ employee, products }: Props) => {
  /* INITIALIZATIONS */

  console.log('employee', employee);
  const { height } = useWindowsDimension();
  const { companyId, employeeId } = useParams() as any | undefined;
  let navigate = useNavigate();
  const [owner] = useAtom(ownerAtom);

  // Other local state variables
  const [department, setDepartment] = useState({} as IDepartmentViewModel);
  const [isProductChanged, setIsProductChanged] = useState(false) as any;
  const [image, setImage] = useState(null) as any;
  const [base64String, setBase64String] = useState('') as any;
  const [workRoles, setWorkRoles] = useState<IWorkRoleViewModel[]>([]);
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...employee,
  });

  // api
  const { data: departments } = useGet(rq.departments(companyId, true), true);
  let isOwner = owner?.id ? true : false;
  const { data: workRoleData } = useGet(
    rq.workRoles(owner.id, isOwner),
    isOwner
  );
  const putEmployee = usePut(
    rqPost.employee(companyId, employeeId, true),
    true
  );
  const postEmployee = usePost(
    rqPost.employee(companyId, employeeId, true),
    true
  );
  const postEmployeeDeactivate = usePost(
    rqPost.employeeDeactivate(companyId, employeeId, true)
  );
  let productId: string = '';
  const deleteCompanyProduct = useDel(
    rqDel.companyProduct(employeeId, productId)
  );
  const addCompanyProducts = usePost(rqPost.companyProducts(employeeId, true));
  const updateCompanyProducts = usePost(
    rqPost.updateCompanyProducts(employeeId, true)
  );

  const [deleteConfirm, setDeleteConfirm] = useState(false);

  /* HANDLSES */

  const handleChangeWorkRole = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    selectedObject: any,
    objectType: string
  ) => {
    switch (objectType) {
      case 'workRoles':
        let object = selectedObject as IWorkRoleViewModel;
        dispatch({ type: 'CHANGE_WORK_ROLE', payload: object });
        break;
      default:
        break;
    }
  };

  const handleConfirm = (event: any) => {
    //console.log("delete");
    postEmployeeDeactivate.mutate(
      { id: employeeId },
      { onSuccess: navigate(`/companies/${companyId}/employees`) }
    );
  };

  const handleRemoveProduct = (companyProduct: ICompanyProductViewModel) => {
    setIsProductChanged(true);
    console.log('handleRemoveProduct', companyProduct);
    companyProduct.id?.startsWith('new')
      ? dispatch({ type: 'REMOVE_PRODUCT', value: companyProduct })
      : companyProduct.ownerId
      ? dispatch({
          type: 'UPDATE_PRODUCT',
          value: {
            ...companyProduct,
            ownerId: null,
            owner: null,
            placement: 0,
          },
        })
      : dispatch({
          type: 'UPDATE_PRODUCT',
          value: {
            ...companyProduct,
            ownerId: employeeId,
            owner: employee,
            placement: 1,
          },
        });

    console.log('product remove: ', companyProduct);
  };

  const handleChange = (
    event: any,
    selectedObject: IProductViewModel,
    objectType: string
  ) => {
    event.preventDefault();
    setIsProductChanged(true);
    const addedProduct: ICompanyProductViewModel = {
      id: 'new-' + GenerateGuid(),
      productId: selectedObject.id,
      resourceName: '',
      companyId: companyId,
      purchaserId: employeeId,
      ownerId: null,
      owner: null,
      comment: '',
      unitPrice: selectedObject.unitPrice,
      retailPrice: selectedObject.retailPrice,
      discount: 0,
      lifetime: 5,
      security: true,
      status: 0,
      productWarning: '',
      product: selectedObject,
      placement: 1 as IPlacement,
      isActive: true,
    };
    dispatch({ type: 'ADD_PRODUCT', value: addedProduct });

    // AddCompanyProduct.mutate(addedProduct);
    //console.log('AddCompanyProduct.mutate', addedProduct);
  };
  interface OHjsxProps {
    onOff: boolean;
    dispatch: React.Dispatch<Action>;
  }
  const PrimaryContactSwitch: React.FC<OHjsxProps> = ({ onOff, dispatch }) => {
    const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({ type: 'TOGGLE_PRIMARY_CONTACT', payload: e.target.checked });
    };
    return (
      <Grid item sx={{ m: 0, p: 0 }}>
        <Stack direction='row'>
          <Typography
            sx={{
              m: 0,
              p: 2.2,
              pr: 0,
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              fontSize: 14,
              color: 'primary.text',
            }}
          >
            {onOff ? 'YES' : 'NO'}
          </Typography>

          <Switch
            color={onOff ? 'secondary' : 'primary'}
            sx={{
              mt: 1,
              mr: 1,
              '& .MuiSwitch-thumb': {
                backgroundColor: onOff ? 'secondary.light' : 'primary.text',
              },
              '& .MuiSwitch-track': {
                backgroundColor: onOff
                  ? 'rgba(0, 0, 0, 0.7)'
                  : 'rgba(0, 0, 255, 0.3)',
              },
            }}
            checked={onOff}
            onChange={handleToggle}
            name='PRIMARY CONTACT'
          />
        </Stack>
      </Grid>
    );
  };
  const GlobalAdministratorSwitch: React.FC<OHjsxProps> = ({
    onOff,
    dispatch,
  }) => {
    const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({
        type: 'TOGGLE_GLOBAL_ADMINISTRATOR',
        payload: e.target.checked,
      });
    };
    return (
      <Grid item sx={{ m: 0, p: 0 }}>
        <Stack direction='row'>
          <Typography
            sx={{
              m: 0,
              p: 2.2,
              pr: 0,
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              fontSize: 14,
              color: 'primary.text',
            }}
          >
            {onOff ? 'YES' : 'NO'}
          </Typography>

          <Switch
            color={onOff ? 'secondary' : 'primary'}
            sx={{
              mt: 1,
              mr: 1,
              '& .MuiSwitch-thumb': {
                backgroundColor: onOff ? 'secondary.light' : 'primary.text',
              },
              '& .MuiSwitch-track': {
                backgroundColor: onOff
                  ? 'rgba(0, 0, 0, 0.7)'
                  : 'rgba(0, 0, 255, 0.3)',
              },
            }}
            checked={onOff}
            onChange={handleToggle}
            name='GLOBAL ADMINISTRATOR'
          />
        </Stack>
      </Grid>
    );
  };

  const setFormDirty = () => {
    console.log('setFormDirty');
  };
  // useEffect

  useEffect(() => {
    if (workRoleData) {
      // console.log('workRoleData', workRoleData);
      setWorkRoles(workRoleData.workRoles as IWorkRoleViewModel[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workRoleData]);

  useEffect(() => {
    if (state.url) {
      // console.log('workRoleData', workRoleData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.url]);

  useEffect(() => {
    if (employee) {
      // console.log('workRoleData', workRoleData);
      dispatch({ type: 'SET_STATE', payload: employee });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee]);

  const onSubmit = (e: any) => {
    e.preventDefault();
    let _employee = { ...state } as IEmployeeViewModel;
    console.log('_employee', _employee.companyProducts);
    if (_employee.department && _employee.email && _employee.phone) {
      const newCompanyProductList: ICompanyProductAddViewModel[] =
        _employee.companyProducts
          .filter((companyProduct) => companyProduct.id?.startsWith('new'))
          .map(({ id, owner, product, ...rest }) => {
            const {
              resourceName,
              productId,
              companyId,
              purchaserId,
              ownerId,
              comment,
              unitPrice,
              retailPrice,
              discount,
              lifetime,
              security,
              status,
              productWarning,
              placement,
            } = rest;

            return {
              resourceName,
              productId,
              companyId,
              purchaserId,
              ownerId,
              comment,
              unitPrice,
              retailPrice,
              discount,
              lifetime,
              security,
              status,
              productWarning,
              placement,
            };
          });

      const removeOwnerFromCompanyProductList = _employee.companyProducts
        .filter(
          (companyProduct) =>
            !companyProduct.id?.startsWith('new') &&
            companyProduct.ownerId === null
        )
        .map(({ owner, ...rest }) => rest);

      const companyProductList: ICompanyProductViewModel[] =
        _employee.companyProducts
          .filter(
            (companyProduct) =>
              !companyProduct.id?.startsWith('new') &&
              companyProduct.ownerId !== null
          )
          .map(({ ...rest }) => rest);
      //   console.log('newCompanyProductList', newCompanyProductList);

      _employee.departmentId = _employee.department.id;

      if (image) {
        _employee.fileModel = ConvertBase64StringToFileModel(
          base64String,
          image.name
        );
      } else {
        _employee.fileModel = null;
      }
      if (_employee.id?.startsWith('new')) {
        const { id, companyProducts, ...employeeWithoutId } = _employee;
        console.log('employeeWithoutId', employeeWithoutId);
        postEmployee.mutate(employeeWithoutId, {
          onSuccess: (res: IEmployeeViewModel) => {
            console.log('onsubmit post', res);
            setImage(null);
            setBase64String(null);
            dispatch({ type: 'RESET_FORM' });
          },
        });
      } else {
        // remove all products
        const { companyProducts, ...employeeWithoutId } = _employee;
        // remove workrole in title = null
        if (employeeWithoutId.workRole?.title === null) {
          employeeWithoutId.workRole = null;
        }
        // remove fileModel if empty
        /*  if (
          employeeWithoutId.fileModel?.content === '' ||
          employeeWithoutId.fileModel?.fileName === ''
        ) {
          employeeWithoutId.fileModel = {} as IFileModel;
        } */

        // updating employee without products
        putEmployee.mutate(employeeWithoutId, {
          onSuccess: (res: any) => {
            console.log(
              'putEmployee onSuccess res: ',
              res,
              newCompanyProductList
            );
            newCompanyProductList.forEach((companyProduct) => {
              companyProduct.ownerId = res.id;
            });
            // add all new products
            console.log(
              'onSuccess put newCompanyProductList',
              newCompanyProductList
            );
            addCompanyProducts.mutate(newCompanyProductList, {
              onSuccess: (res: any) => {
                console.log('AddCompanyProducts onSuccess res :', res);
                dispatch({
                  type: 'SET_PRODUCTS',
                  value: res.companyProducts,
                });
              },
            });

            updateCompanyProducts.mutate(removeOwnerFromCompanyProductList, {
              onSuccess: (res: any) => {
                console.log('updateCompanyProducts onSuccess res:', res);
                dispatch({
                  type: 'SET_PRODUCTS',
                  value: res.companyProducts,
                });
              },
            });
            dispatch({ type: 'RESET_FORM' });
            setImage(null);
            setBase64String('');
          },
        });
      }
    }
  };

  return (
    <>
      <Grid
        container
        sx={{
          m: 0,
          p: 0,
        }}
      >
        {/*   HEADER */}
        <Grid item xs={12}>
          <SearchFilter
            Icon={FeedRoundedIcon}
            Header='Basic Information'
            filterValue={null}
            handleFilterChange={null}
            handleSearch={null}
            handleNew={null}
            selectList={null}
          />
        </Grid>

        <List
          sx={{
            width: '100%',
            height: Math.ceil(height - 298),
            p: 0,
            overflow: 'auto',
          }}
        >
          {/*   Employee Data */}
          <Stack direction='row'>
            <Grid
              item
              sx={{ width: 256, mb: 2, backgroundColor: 'primary.main' }}
            >
              <Stack direction='column'>
                <Typography
                  sx={{
                    m: 0,
                    p: 2,
                    textAlign: 'left',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: 'normal ',
                    fontSize: 14,
                    color: 'primary.text',
                  }}
                >
                  Logo
                </Typography>

                {state &&
                  (console.log('ImageUploader ', state, base64String),
                  (
                    <ImageUploader
                      width={192}
                      height={192}
                      previewImage={state.url}
                      setImage={setImage}
                      setBase64String={(str) => {
                        if (typeof str === 'string' || str === null) {
                          setBase64String(str);
                        }
                      }}
                      base64String={base64String}
                      setFormDirty={setFormDirty}
                    />
                  ))}
                <Grid item xs={12} sx={{ m: 0, p: 0 }}>
                  <Typography
                    sx={{
                      m: 0,
                      p: 2,
                      textAlign: 'left',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      fontWeight: 'normal',
                      fontSize: 14,
                      color: 'primary.text',
                    }}
                  >
                    Work Role
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {state.workRole && state.workRole.id ? (
                    <Grid
                      container
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{
                        width: '100%',
                        height: 60,
                        borderRadius: 2,
                        backgroundColor: 'primary.main',
                      }}
                    >
                      <Grid item sx={{ m: 0, p: 0, flexGrow: 1 }}>
                        <Typography>{state.workRole.title}</Typography>
                        <Typography>
                          {state.workRole.onSiteHourlyRate}
                        </Typography>
                      </Grid>
                      <Grid item sx={{ m: 0, p: 0 }}>
                        <Stack direction='row'>
                          <Box
                            sx={{
                              mr: 1,
                              width: 30,
                              height: 30,
                              backgroundColor: 'primary.light',
                              display: 'flex', // <-- Set this to flex
                              justifyContent: 'center', // <-- This centers children horizontally
                              alignItems: 'center', // <-- This centers children vertically
                              borderRadius: 2,
                              boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
                              '&:hover': {
                                filter: 'brightness(120%)',
                              },
                            }}
                          >
                            <IconButton
                              sx={{ m: 0, p: 0 }}
                              onClick={(e) => {
                                dispatch({
                                  type: 'CHANGE_WORK_ROLE',
                                  payload: {} as IWorkRoleViewModel,
                                });
                              }}
                            >
                              <DeleteForeverRoundedIcon />
                            </IconButton>
                          </Box>
                          <ObjectDrawer
                            buttonContext={'Select WorkRole'}
                            buttonIcon={<ChangeCircleIcon />}
                            objectList={!!workRoles ? workRoles : []}
                            objectType={'workRoles'}
                            handleChange={handleChangeWorkRole}
                            buttonHeight={30}
                            buttonWidth={30}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sx={{ m: 0, p: 0 }}>
                        <Divider sx={{ backgroundColor: 'primary.text' }} />
                      </Grid>
                    </Grid>
                  ) : (
                    workRoles.length > 0 && (
                      <ObjectDrawer
                        buttonContext={'Select WorkRole'}
                        buttonIcon={null}
                        objectList={!!workRoles ? workRoles : []}
                        objectType={'workRoles'}
                        handleChange={handleChangeWorkRole}
                        buttonHeight={60}
                        buttonWidth={'100%'}
                      />
                    )
                  )}
                </Grid>
              </Stack>
            </Grid>
            <Grid
              container
              sx={{
                p: 3,
              }}
              rowSpacing={1}
              columnSpacing={{ xs: 2 }}
            >
              <Grid item xs={7}>
                <TextField
                  sx={{
                    '& .Mui-focused': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  label='Name'
                  value={state.name}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_FIELD',
                      field: 'name',
                      value: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={5}>
                <MuiTelInput
                  sx={{
                    '& .Mui-focused': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  continents={['EU']}
                  preferredCountries={['NO', 'SE', 'DK', 'DE', 'GB']}
                  defaultCountry='NO'
                  forceCallingCode={true}
                  label='Phone'
                  variant='standard'
                  value={state.phone}
                  onChange={(value: string, info: MuiTelInputInfo) => {
                    dispatch({
                      type: 'UPDATE_FIELD',
                      field: 'phone',
                      value: value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={7}>
                <TextField
                  sx={{
                    '& .Mui-focused': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  label='E-Mail'
                  value={state.email}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_FIELD',
                      field: 'email',
                      value: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  sx={{
                    '& .Mui-focused': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  label='title'
                  value={state.title}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_FIELD',
                      field: 'title',
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  sx={{
                    '& .Mui-focused': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  label='budget'
                  value={state.budget}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_FIELD',
                      field: 'budget',
                      value: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    m: 0,
                    p: 0,
                    textAlign: 'left',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: 'normal',
                    fontSize: 14,
                    color: 'primary.text',
                  }}
                >
                  App role
                </Typography>
                <Select
                  sx={{
                    width: '100%',
                    p: 0,
                    mt: -0.5,
                    '& .Mui-focused': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  labelId='role'
                  id='role'
                  variant='standard'
                  value={state.role}
                  label='role'
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_FIELD',
                      field: 'role',
                      value: e.target.value,
                    })
                  }
                >
                  <MenuItem value={0}>Manager</MenuItem>
                  <MenuItem value={1}>Department Manager</MenuItem>
                  <MenuItem value={2}>Employee</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Stack>
          {/*   DEPARTMENT */}
          <Grid
            item
            xs={12}
            sx={{
              mb: 2,
            }}
          >
            <Paper variant='elevation3' sx={{}}>
              <Accordion
                disableGutters
                sx={{
                  boxShadow: 0,
                  backgroundColor: 'transparent',
                }}
              >
                <FormAccordionSummary
                  Icon={HomeRoundedIcon}
                  title='Department'
                />
                <AccordionDetails sx={{}}>
                  <Stack direction='row'>
                    <Box sx={{ mr: 4, display: 'flex', alignItems: 'center' }}>
                      {employee ? (
                        <Avatar
                          variant='rounded'
                          alt={''}
                          src={'./public/img/google.png'}
                          sx={{ width: 96, height: 64 }}
                        />
                      ) : (
                        <Avatar
                          sx={{ width: 96, height: 64 }}
                          src={GetInitials('Google Map Location', 2)}
                        />
                      )}
                    </Box>
                    <Grid
                      container
                      sx={{
                        p: 2,
                      }}
                      rowSpacing={2}
                      direction='row'
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                          <Select
                            sx={{ width: '30%', p: 0, mt: 3 }}
                            labelId='department'
                            id='department'
                            variant='standard'
                            value={state.department?.id || 0}
                            label='department'
                            onChange={(e) => {
                              const selectedDepartmentId = e.target.value;
                              const selectedDepartment =
                                departments.departments.find(
                                  (department: IDepartmentViewModel) =>
                                    department.id === selectedDepartmentId
                                );

                              if (selectedDepartment) {
                                // Dispatch the selected department
                                dispatch({
                                  type: 'UPDATE_DEPARTMENT',
                                  value: selectedDepartment,
                                });
                              } else {
                                // Handle the case when no department is selected
                                // You can choose to handle this as needed (e.g., display an error)
                              }
                            }}
                          >
                            <MenuItem key={0} value={0}>
                              {'Select Department'}
                            </MenuItem>
                            {departments &&
                              departments.departments?.map(
                                (department: IDepartmentViewModel) => (
                                  <MenuItem
                                    key={department.id}
                                    value={department.id}
                                  >
                                    {department.name}
                                  </MenuItem>
                                )
                              )}
                          </Select>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                          <Box sx={{ mt: 3, height: 24 }}>
                            {state.department?.mainAddress?.street}
                          </Box>
                          <Divider sx={{ mt: 0 }} variant='dividerMenu' />
                        </Grid>

                        <Grid item lg={3} xs={12}>
                          <Box sx={{ mt: 3, height: 24 }}>
                            {state.department?.mainAddress?.postalCode}
                          </Box>
                          <Divider sx={{ mt: 0 }} variant='dividerMenu' />
                        </Grid>
                        <Grid item lg={3} xs={12}>
                          <Box sx={{ mt: 3, height: 24 }}>
                            {state.department?.mainAddress?.area}
                          </Box>
                          <Divider sx={{ mt: 0 }} variant='dividerMenu' />
                        </Grid>
                        <Grid item lg={3} xs={12}>
                          <Box sx={{ mt: 3, height: 24 }}>
                            {state.department?.mainAddress?.city}
                          </Box>
                          <Divider sx={{ mt: 0 }} variant='dividerMenu' />
                        </Grid>
                        <Grid item lg={3} xs={12}>
                          <Box sx={{ mt: 3, height: 24 }}>
                            {state.department?.mainAddress?.country}
                          </Box>
                          <Divider sx={{ mt: 0 }} variant='dividerMenu' />
                        </Grid>
                        <Grid item lg={3} xs={12}>
                          <Box sx={{ mt: 3, height: 24 }}>
                            {state.department?.mainAddress?.postBox}
                          </Box>
                          <Divider sx={{ mt: 0 }} variant='dividerMenu' />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Stack>
                </AccordionDetails>
              </Accordion>
            </Paper>
          </Grid>
          {/*   COMPANY PRODUCTS */}
          <Grid
            item
            xs={12}
            sx={{
              mb: 2,
            }}
          >
            <Paper variant='elevation3' sx={{}}>
              <Accordion
                disableGutters
                sx={{
                  boxShadow: 0,
                  backgroundColor: 'transparent',
                }}
              >
                <FormAccordionSummary
                  Icon={DevicesOtherRoundedIcon}
                  title='companyProducts'
                />
                <AccordionDetails>
                  <Box sx={{ display: 'flex', flexGrow: 1 }}>
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <Box sx={{ mb: 1 }}>
                      <ObjectDrawer
                        buttonContext={'Add Product'}
                        buttonIcon={<AddRoundedIcon sx={{ fontSize: 26 }} />}
                        objectList={products ? products : []}
                        objectType={'products'}
                        handleChange={handleChange}
                        buttonHeight={26}
                        buttonWidth={26}
                      />
                    </Box>
                  </Box>
                  <List
                    sx={{
                      width: '100%',
                      p: 2,
                      overflow: 'auto',
                      backgroundColor: 'primary.back',
                      borderRadius: 3,

                      borderLeft: 1,
                      borderLeftColor: 'primary.dark',

                      borderTop: 1,
                      borderTopColor: 'primary.dark',

                      borderRight: 1,
                      borderRightColor: 'primary.light',

                      borderBottom: 1,
                      borderBottomColor: 'primary.light',
                    }}
                  >
                    {state?.companyProducts?.length < 1 && (
                      <div>No companyProducts</div>
                    )}
                    {state?.companyProducts?.map(
                      (
                        companyProduct: ICompanyProductViewModel,
                        index: number
                      ) => {
                        return !!companyProduct ? (
                          <ListItem sx={{ mb: 1, p: 0 }} key={index}>
                            <ProductCardEmployeeView
                              companyProduct={companyProduct}
                              handleRemoveProduct={handleRemoveProduct}
                            />
                          </ListItem>
                        ) : null;
                      }
                    )}
                  </List>
                </AccordionDetails>
              </Accordion>
            </Paper>
          </Grid>
          {/*   HOME OFFICE ADDRESS */}
          <Grid
            item
            xs={12}
            sx={{
              mb: 2,
              border: 0,
              borderRadius: 2,
              boxShadow:
                '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
            }}
          >
            <Accordion
              disableGutters
              sx={{
                backgroundColor: 'transparent',
                border: 0,
              }}
            >
              <FormAccordionSummary
                Icon={HomeRoundedIcon}
                title='Home Office Address'
              />
              <AccordionDetails>
                <TextField
                  sx={{
                    width: '50%',
                    p: 1,
                    m: 0,
                    '& .Mui-focused': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  label='Street'
                  value={state.address?.street || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      field: 'street',
                      value: e.target.value,
                    })
                  }
                />
                <TextField
                  sx={{
                    width: '20%',
                    p: 1,
                    m: 0,
                    '& .Mui-focused': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  label='Postal Code'
                  value={state.address?.postalCode || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      field: 'postalCode',
                      value: e.target.value,
                    })
                  }
                />
                <TextField
                  sx={{
                    width: '30%',
                    p: 1,
                    m: 0,
                    '& .Mui-focused': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  label='Area'
                  value={state.address?.area || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      field: 'area',
                      value: e.target.value,
                    })
                  }
                />
                <TextField
                  sx={{
                    width: '40%',
                    p: 1,
                    m: 0,
                    '& .Mui-focused': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  label='City'
                  value={state.address?.city || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      field: 'city',
                      value: e.target.value,
                    })
                  }
                />

                <Select
                  sx={{
                    width: '30%',
                    p: 0,
                    mt: 3,
                    '& .Mui-focused': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  labelId='country'
                  id='country'
                  variant='standard'
                  value={state.address?.country || 0}
                  label='country'
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      field: 'country',
                      value: e.target.value,
                    })
                  }
                >
                  <MenuItem value={0}>Norway</MenuItem>
                  <MenuItem value={1}>Sweden</MenuItem>
                  <MenuItem value={2}>Denmark</MenuItem>
                  <MenuItem value={3}>Germany</MenuItem>
                  <MenuItem value={4}>England</MenuItem>
                </Select>
                <TextField
                  sx={{
                    width: '30%',
                    p: 1,
                    m: 0,
                    '& .Mui-focused': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  label='PostBox'
                  value={state.address?.postBox || ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ADDRESS_FIELD',
                      field: 'postBox',
                      value: e.target.value,
                    })
                  }
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
          {/*   PRIMARY CONTACT */}
          <Grid item xs={12} sx={{ mb: 2 }}>
            <FormBoxElevated
              Icon={ContactPhoneIcon}
              title='Primary Contact'
              FormJSX={() => (
                <PrimaryContactSwitch
                  onOff={state.primaryContact}
                  dispatch={dispatch}
                />
              )}
            />
          </Grid>
          {/*   GLOBAL ADMINISTRATOR */}
          <Grid item xs={12} sx={{ mb: 2 }}>
            <FormBoxElevated
              Icon={ContactPhoneIcon}
              title='Global Administrator'
              FormJSX={() => (
                <GlobalAdministratorSwitch
                  onOff={state.globalAdministrator}
                  dispatch={dispatch}
                />
              )}
            />
          </Grid>
        </List>
        {/*   Submit Button */}
      </Grid>
      {/*   Submit Button */}
      <Grid item xs={12} sx={{ m: 2, display: 'flex', flexDirection: 'row' }}>
        <Button
          variant='contained'
          onClick={() => setDeleteConfirm(!deleteConfirm ? true : false)}
        >
          {postEmployeeDeactivate.isLoading
            ? 'Deleting Employee...'
            : 'Delete Employee'}
        </Button>
        {!!deleteConfirm === true && (
          <Button variant='contained' color='secondary' onClick={handleConfirm}>
            Confirm Delete
          </Button>
        )}
        <Grid sx={{ flexGrow: 1 }}></Grid>{' '}
        {employeeId !== 'new' && (
          <Button
            onClick={(e) => {
              onSubmit(e);
            }}
            variant='contained'
            //  disabled={isProductChanged ? false : !isDirty ? true : false}
          >
            {putEmployee.isLoading ? 'Updating Employee...' : 'Update Employee'}
          </Button>
        )}
        {employeeId === 'new' && (
          <Button
            onClick={(e) => {
              onSubmit(e);
            }}
            variant='contained'
            // disabled={!isDirty}
          >
            {postEmployee.isLoading
              ? 'Creating Employee...'
              : 'Create Employee'}
          </Button>
        )}
      </Grid>
    </>
  );
};
