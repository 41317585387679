import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { rq } from "http/apiRoutes";
import { useGet } from "http/useInnovit";
import useWindowsDimension from "functions/useWindowsDimension";
import { Grid, Paper } from "@mui/material";
import { StorageList } from "./StorageList";

let _ = require("lodash");

export const StorageView = () => {
  /* INITIALIZATION */
  let navigate = useNavigate();
  const { width, height } = useWindowsDimension();
  
  /* STATES */
  const [products, setProducts] = useState([]);
  const [isProducts, setIsProducts] = useState(true);

   /* API */
  const { data } = useGet(rq.products(isProducts),isProducts);
  const { data: categories } = useGet(rq.categories);

  /* FUNCTIONS */

  /* HANDLES */
  const handleClick = (e:any, product:any) => {
    // e.preventDefault();
    navigate(`/Storage/${product.id}`);
  };

   /* USE EFFECTS */
  useEffect(() => {
    let tmp = _.cloneDeep(data?.products);
    setIsProducts(false);
    //console.log('New Product View',tmp);
    setProducts(tmp);
  }, [data]);

  useEffect(() => {}, [categories]);

  return (
    <Grid container rowSpacing={0}>
      <Grid item xs={6} sx={{ pr: 1, m: 0 }}>
        <StorageList
          categories={categories}
          products={products}
          handleClick={handleClick}
        />
      </Grid>

      <Grid item xs={6} sx={{ p: 0, m: 0 }}>
        <Paper
          variant="elevation3"
          sx={{
            height: Math.ceil(height-20),
          }}
        >
          <Outlet />
        </Paper>
      </Grid>
    </Grid>
  );
};
