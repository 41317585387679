import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { IBillingDetailViewModel, IBillingPostViewModel } from 'pages/Billing/BillingConfig';
import { MissingPhoto } from 'data/globalTypeConfig';
import React from "react";
import { blueGrey } from '@mui/material/colors';
import { pdfStyle } from './PDFStyle';

// Define the data type for the prop
interface Props {
  data: IBillingPostViewModel;
}



// Create Document Component
export const PDFOtherView = ({ data }: Props) => {
  return (
  <>
     <View style={pdfStyle.column50}>
          <Text style={pdfStyle.textLeft}>{'OTHER'}</Text>
        </View>
      <View style={pdfStyle.rowView}>
        <View style={pdfStyle.column15}>
          <Text style={pdfStyle.textLeft}>{'name'}</Text>
        </View>
        <View style={pdfStyle.column35}>
          <Text style={pdfStyle.textLeft}>{'info'}</Text>
        </View>
        <View style={pdfStyle.column10}>
          <Text style={pdfStyle.textLeft}>{'qunatity'}</Text>
        </View>
        <View style={pdfStyle.column15}>
          <Text style={pdfStyle.textRight}>{'retail price'}</Text>
        </View>
        <View style={pdfStyle.column20}>
          <Text style={pdfStyle.textRight}>{'line total'}</Text>
        </View>
      </View>
      {/* Divider */}
      <View style={pdfStyle.divider}></View>

{/* OTHER */}
{data.billingViewModels.map(
  (billingItem: IBillingDetailViewModel, index: number) => {
    // Check if there's a companyProduct in the current billing item
    if (billingItem.companyProduct) {
      const product = billingItem.companyProduct; // For clarity
      return ( 
        <View key={index} style={pdfStyle.containerView}>
          <View key={index} style={pdfStyle.rowView}>
            <View style={pdfStyle.column75}>
              <Text style={pdfStyle.textLeft}>
               
              </Text>
            </View>

            <View style={pdfStyle.column25}>
              <Text style={pdfStyle.textRight}>
             
              </Text>
            </View>
          </View>      
        </View>                                          
      );
    }
    return null;
  } 
  )}
   {/* Divider */}
   <View style={pdfStyle.divider} />
      <View style={pdfStyle.rowView}>
        <View style={pdfStyle.column50}>
          <Text style={pdfStyle.textLeft}>{'OTHER TOTAL'}</Text>
        </View>
        <View style={pdfStyle.column50}>
          <Text style={pdfStyle.textRight}>
            {data.otherInvoiceValue.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Text>
        </View>
      </View>
      <View style={pdfStyle.avsnitt} />
      <View style={pdfStyle.avsnitt} />
    </>
  );
};
