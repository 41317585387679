import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { getFilteredList } from "functions/misc";
import { rq } from "http/apiRoutes";
import { useGet } from "http/useInnovit";

import useWindowsDimension from "functions/useWindowsDimension";

import { Grid, Paper } from "@mui/material";
import { ProductList } from "components/ui/ProductList";

let _ = require("lodash");

export const Products = () => {
  /* INITIALIZATION */
  let navigate = useNavigate();
  const { width, height } = useWindowsDimension();
  const [filterType, setFilterType] = useState([
    { id: 1, name: "All" },
    { id: 2, name: "Active" },
  ]);
  const [isProducts, setIsProducts] = useState(true);
  // api
  const { data } = useGet(rq.products(isProducts),isProducts);

  const { data: categories } = useGet(rq.categories);
  // console.log(data);

  // states
  const [products, setProducts] = useState([]);

  /* FUNCTIONS */

  // handles
  const handleClick = (e, product) => {
    // e.preventDefault();
    // console.log(product);
    navigate(`/products/${product.id}`);
  };

  const handleNew = (e) => {
    // e.preventDefault();
    //console.log("click");
    navigate("/products/new");
  };

  const handleFilterChange = (e) => {
    // setFilterType(e.target.value);
    // ref.current = e.target.value;
  };

  const handleSearch = (e) => {
    setProducts(getFilteredList(data?.products, e.target.value));
  };

  useEffect(() => {
    let tmp = _.cloneDeep(data?.products);
    setIsProducts(false);
    //console.log('New Product View',tmp);
    setProducts(tmp);
  }, [data]);

  useEffect(() => {}, [categories]);

  return (
    <Grid container rowSpacing={0}>
      <Grid item xs={6} sx={{ pr: 1, m: 0 }}>
        <ProductList
          categories={categories}
          products={products}
          handleClick={handleClick}
        />
      </Grid>

      <Grid item xs={6} sx={{ p: 0, m: 0 }}>
        <Paper
          variant="elevation3"
          sx={{
            height: Math.ceil(height-20),
          }}
        >
          <Outlet />
        </Paper>
      </Grid>
    </Grid>
  );
};
