import { useParams } from 'react-router-dom';
import { useGet, usePost } from 'http/useInnovit';

import { CompanyForm } from './CompanyForm';
// @ts-ignore
import SortNameArray from 'functions/SortNameArray';
import { rq, rqPost } from 'http/apiRoutes';

import ContactMailRoundedIcon from '@mui/icons-material/ContactMailRounded';
import { Button, Grid, List, ListItem, Paper, Typography } from '@mui/material';
import useWindowsDimension from 'functions/useWindowsDimension';
import { SingleEmployeeView } from 'components/ui/SingleEmployeeView';
import { SearchFilter } from 'components/ui/SearchFilter';
import { FC, useEffect, useReducer, useState } from 'react';
import { ICompanyViewModel, IFileModel } from './CompanyConfig';
import { IEmployeeViewModel } from 'pages/Tickets/TicketConfig';
import { IAddressViewModel } from 'data/globalTypeConfig';
import { IWorkRoleViewModel } from 'data/atoms/atomConfig';
import {
  IPlacement,
} from './ICompaniesConfig';
import { ProductCardCompanyView } from 'components/ui/ProductCardCompanyView';
import { ObjectDrawer } from './ObjectDrawer';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import GenerateGuid from 'functions/GenerateGuid';
import { IProductViewModel } from 'pages/Billing/BillingConfig';
import { useAtom } from 'jotai';
import userAtom from 'data/atoms/userAtom';
import { ICompanyProductAddViewModel, ICompanyProductViewModel } from './ICompanyProductConfig';

const initialState: ICompanyViewModel = {
  id: null,
  organizationNumber: '',
  name: '',
  mainAddress: {} as IAddressViewModel,
  deliveryAddress: {} as IAddressViewModel,
  invoiceAddress: {} as IAddressViewModel,
  phone: '+47',
  email: '',
  companyType: 0,
  primaryContacts: [] as IEmployeeViewModel[],
  webpage: '',
  url: '',
  productBilling: true,
  isSupport: false,
  onHold: false,
  onHoldNote: '',
  workRole: {} as IWorkRoleViewModel,
  workRoles: [] as IWorkRoleViewModel[],
  fixedHourlyRate: 0,
  priority: 2,
  fileModel: {} as IFileModel,
};

interface IAppState {
  companyProducts: ICompanyProductViewModel[];
  products: IProductViewModel[];
}
type Action =
  | { type: 'SET_NEW_PRODUCTS'; value: IProductViewModel[] }
  | { type: 'SET_PRODUCTS'; value: ICompanyProductViewModel[] }
  | { type: 'ADD_PRODUCT'; value: ICompanyProductViewModel }
  | { type: 'REMOVE_PRODUCT'; value: ICompanyProductViewModel }
  | { type: 'UPDATE_PRODUCT'; value: ICompanyProductViewModel };

const initialCommonProductState: IAppState = {
  companyProducts: [],
  products: [],
};

const reducer = (state: IAppState, action: Action): IAppState => {
  switch (action.type) {
    case 'SET_NEW_PRODUCTS':
      return {
        ...state,
        products: action.value,
      };
    case 'SET_PRODUCTS':
      return {
        ...state,
        companyProducts: action.value,
      };
    case 'ADD_PRODUCT':
      return {
        ...state,
        companyProducts: [...state.companyProducts, action.value],
      };
    case 'REMOVE_PRODUCT':
      return {
        ...state,
        companyProducts: state.companyProducts.filter(
          (companyProduct) => companyProduct.id !== action.value.id
        ),
      };
    case 'UPDATE_PRODUCT':
      return {
        ...state,
        companyProducts: state.companyProducts.map((companyProduct) =>
          companyProduct.id === action.value.id
            ? { ...companyProduct, ...action.value }
            : companyProduct
        ),
      };
    default:
      return state;
  }
};

export const CompanyDetails = () => {
  /* INITIALIZATIONS */
  const { companyId } = useParams<string>();
  const { height } = useWindowsDimension();
  const [user, setUser] = useAtom(userAtom);
  // STATES
  const [company, setCompany] = useState<ICompanyViewModel>(
    initialState as ICompanyViewModel
  );
  const [state, dispatch] = useReducer(reducer, {
    ...initialCommonProductState,
  });
  const [products, setProducts] = useState<IProductViewModel[]>([]);
  const [isProductChanged, setIsProductChanged] = useState(false) as any;
  const [isProducts, setIsProducts] = useState<boolean>(true);

  // API
  let isEnabled = companyId !== 'new' ? true : false;
  const { data: companyData } = useGet(
    rq.company(companyId, isEnabled),
    isEnabled
  );
  let isProductEnabled = company.id ? true : false;
  const { data: companyProductData } = useGet(
    rq.commonCompanyProducts(company.id, isProductEnabled),
    isProductEnabled
  );
  const { data: productData } = useGet(rq.products(isProducts), isProducts);
  const addCompanyProducts = usePost(
    rqPost.commonCompanyProducts(companyId || '', true)
  );
  const updateCompanyProducts = usePost(
    rqPost.commonUpdateCompanyProducts(companyId || '', true)
  );

  // HANDLES
  const handleRemoveProduct = (companyProduct: ICompanyProductViewModel) => {
    setIsProductChanged(true);
    console.log('handleRemoveProduct', companyProduct);
    companyProduct.id?.startsWith('new')
      ? dispatch({ type: 'REMOVE_PRODUCT', value: companyProduct })
      : companyProduct.placement !== 3
      ? dispatch({
          type: 'UPDATE_PRODUCT',
          value: { ...companyProduct, placement: 3 },
        })
      : dispatch({
          type: 'UPDATE_PRODUCT',
          value: { ...companyProduct, placement: 0 },
        });

    console.log('product remove: ', companyProduct);
  };
  const handleChange = (
    event: any,
    selectedObject: IProductViewModel,
    objectType: string
  ) => {
    event.preventDefault();
    if (!companyId?.startsWith('new')) setIsProductChanged(true);
    const addedProduct: ICompanyProductViewModel = {
      id: GenerateGuid(),
      productId: selectedObject.id,
      resourceName: selectedObject.name,
      companyId: companyId ? companyId : '',
      purchaserId: user.id,
      ownerId: null,
      owner: null,
      comment: '',
      unitPrice: selectedObject.unitPrice,
      retailPrice: selectedObject.retailPrice,
      discount: 0,
      lifetime: 5,
      security: true,
      status: 0,
      productWarning: '',
      product: selectedObject,
      placement: 3 as IPlacement,
      isActive: true,
    };
    dispatch({ type: 'ADD_PRODUCT', value: addedProduct });
  };
  // USEEFFECTS
  useEffect(() => {
    if (companyProductData && companyProductData.companyProducts) {
      // Dispatch an action to update the state with the data from `companyProductData`
      setIsProducts(false);
      dispatch({
        type: 'SET_PRODUCTS',
        value: companyProductData.companyProducts,
      });
    }
  }, [companyProductData, dispatch]);
  useEffect(() => {
    if (productData) {
      // setProducts(productData?.products || []); // Make sure 'products' is always an array
      dispatch({
        type: 'SET_NEW_PRODUCTS',
        value: productData?.products,
      });
    }
  }, [productData, dispatch]);

  useEffect(() => {
    if (companyData) {
      //console.log('companyData', companyData);
      let company: ICompanyViewModel = {
        id: companyData.id,
        organizationNumber: companyData.organizationNumber,
        name: companyData.name,
        mainAddress: companyData.mainAddress,
        deliveryAddress: companyData.deliveryAddress,
        invoiceAddress: companyData.invoiceAddress,
        phone: companyData.phone,
        email: companyData.email,
        companyType: companyData.companyType,
        primaryContacts: companyData.primaryContacts,
        webpage: companyData.webpage,
        url: companyData.url,
        productBilling: companyData.productBilling,
        isSupport: companyData.isSupport,
        onHold: companyData.onHold,
        onHoldNote: companyData.onHoldNote,
        workRole: companyData.workRole,
        workRoles: companyData.workRoles,
        fixedHourlyRate: companyData.fixedHourlyRate,
        priority: companyData.priority,
        fileModel: companyData.fileModel,
      };
      setCompany(company);
    }
  }, [companyData]);

  useEffect(() => {
    if (companyId?.startsWith('new')) {
      dispatch({
        type: 'SET_PRODUCTS',
        value: [] as ICompanyProductViewModel[],
      });
    }
  }, [companyId]);

  const onSubmit = (e: any) => {
    e.preventDefault();
    setIsProductChanged(false);
    let _commonProduct = [...state.companyProducts] as ICompanyProductViewModel[];
    //     console.log('_commonProduct', _commonProduct);
    // loop through all common products and make a list with all that will be removed
    const removeCommonProducts = _commonProduct
      .filter(
        (companyProduct) =>
          !companyProduct.id?.startsWith('new') &&
          companyProduct.placement !== (3 as IPlacement)
      )
      .map(({ owner, ...rest }) => rest);

    // loop through all common products and make a list with all new common products
    const addCommonProducts: ICompanyProductAddViewModel[] = _commonProduct
      .filter((companyProduct) => companyProduct.id?.startsWith('new'))
      .map(({ id, owner, product, ...rest }) => {
        const {
          resourceName,
          productId,
          companyId,
          purchaserId,
          ownerId,
          comment,
          unitPrice,
          retailPrice,
          discount,
          lifetime,
          security,
          status,
          productWarning,
          placement,
        } = rest;

        return {
          resourceName,
          productId,
          companyId,
          purchaserId,
          ownerId,
          comment,
          unitPrice,
          retailPrice,
          discount,
          lifetime,
          security,
          status,
          productWarning,
          placement,
        };
      });

    // add all new products
    addCompanyProducts.mutate(addCommonProducts, {
      onSuccess: (res: any) => {},
    });
    updateCompanyProducts.mutate(removeCommonProducts, {
      onSuccess: (res: any) => {},
    });
  };

  /* RENDER */
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
      }}
    >
      <Grid item xs={6} sx={{ pr: 2 }}>
        {companyId === 'new' ? (
          <CompanyForm company={initialState} />
        ) : (
          company && company.id && <CompanyForm company={company} />
        )}
      </Grid>
      <Grid item xs={6}>
        <Grid item xs={12}>
          <SearchFilter
            Icon={ContactMailRoundedIcon}
            Header='common company products'
            filterValue={null}
            handleFilterChange={null}
            handleSearch={null}
            handleNew={null}
            selectList={null}
            gfx={
              <ObjectDrawer
                buttonContext={'Add Product'}
                buttonIcon={<AddRoundedIcon sx={{ fontSize: 26 }} />}
                objectList={state.products || []}
                objectType={'products'}
                handleChange={handleChange}
                buttonHeight={40}
                buttonWidth={60}
              />
            }
          />

          <List
            sx={{
              height: Math.ceil(height - 240 - 174 - 128 - 128),
              width: '100%',
              mb: 0,
              p: 2,
              backgroundColor: 'primary.back',
              overflow: 'auto',
            }}
          >
            {state.companyProducts.length > 0 &&
              state.companyProducts.map(
                (companyProduct: ICompanyProductViewModel, index: number) => {
                  return !!companyProduct ? (
                    <ListItem sx={{ mb: 1, p: 0 }} button key={index}>
                      <ProductCardCompanyView
                        key={companyProduct.id}
                        companyProduct={companyProduct}
                        handleRemoveProduct={handleRemoveProduct}
                      />
                    </ListItem>
                  ) : (
                    <Typography
                      sx={{
                        display: 'flex',
                        letterSpacing: 3,
                        fontSize: 18,
                        textTransform: 'capitalize',
                        fontWeight: 'bold',
                        alignSelf: 'center',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      No common company products
                    </Typography>
                  );
                }
              )}
          </List>
          <Paper
            variant='elevation3'
            sx={{
              p: 2,
              mb: 2,
              height: 64,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            <Grid
              container
              sx={{
                m: 0,
                p: 0,
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                flexGrow: 1,
              }}
            >
              <Grid item></Grid>

              <Grid item>
                {companyId !== 'new' && isProductChanged === true && (
                  <Button onClick={onSubmit} variant='contained'>
                    Update Common Products
                  </Button>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {!!company && (
          <Grid item xs={12}>
            <SearchFilter
              Icon={ContactMailRoundedIcon}
              Header='PrimaryContact'
              filterValue={null}
              handleFilterChange={null}
              handleSearch={null}
              handleNew={null}
              selectList={null}
            />

            <List
              sx={{
                height: 240,
                width: '100%',
                mb: 0,
                p: 2,
                backgroundColor: 'primary.back',
                overflow: 'auto',
              }}
            >
              {company && company.primaryContacts ? (
                <Typography
                  sx={{
                    display: 'flex',
                    letterSpacing: 3,
                    fontSize: 18,
                    textTransform: 'capitalize',
                    fontWeight: 'bold',
                    alignSelf: 'center',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  No Primary Contacts
                </Typography>
              ) : (
                SortNameArray(company.primaryContacts).map(
                  (_employee: IEmployeeViewModel, index: number) => (
                    <SingleEmployeeView key={index} employee={_employee} />
                  )
                )
              )}
            </List>
            <Paper
              variant='elevation3'
              sx={{
                p: 2,
                height: 64,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
            ></Paper>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
