import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import {
  IBillingDetailViewModel,
  IBillingPostViewModel,
} from 'pages/Billing/BillingConfig';
import { MissingPhoto } from 'data/globalTypeConfig';
import React from 'react';
import { pdfStyle } from './PDFStyle';
import { PDFHeaderView } from './PDFHeaderView';
import { PDFFooterView } from './PDFFooterView';
import { PDFInvestmentView } from './PDFInvestmentView';
import { PDFSupportView } from './PDFSupportView';
import { PDFAgreementView } from './PDFAgreementView';
import { PDFOtherView } from './PDFOtherView';
import { PDFTotalView } from './PDFTotalView';
import dayjs from 'dayjs';

// Define the data type for the prop
interface Props {
  data: IBillingPostViewModel;
}

const hasNestedProperties = (obj: any, pathArray: string[]): boolean => {
  return pathArray.every((key) => {
    if (typeof obj !== 'object' || obj === null || !(key in obj)) {
      return false;
    }
    obj = obj[key];
    return true;
  });
};

// Create Document Component
export const PDFFile = ({ data }: Props) => {
 //console.log('PDFFile : ', data);
  // Ensure data structure has nested properties
  const subtotalRetailPrice = data.billingViewModels.reduce(
    (sum: number, item: IBillingDetailViewModel) => {
      if (
        item.agreementProduct &&
        hasNestedProperties(item, [
          'agreementProduct',
          'product',
          'retailPrice',
        ])
      ) {
        const quantity = item.agreementProduct?.quantity || 1; // Default to 1 if not provided
        return sum + (item.agreementProduct?.retailPrice || 0) * quantity;
      }
      return sum;
    },
    0
  );

  // Ensure data structure has nested properties
  const subtotalUnitPrice = data.billingViewModels.reduce(
    (sum: number, item: IBillingDetailViewModel) => {
      if (
        item.agreementProduct &&
        hasNestedProperties(item, [
          'agreementProduct',
          'product',
          'retailPrice',
        ])
      ) {
        const quantity = item.agreementProduct?.quantity || 1; // Default to 1 if not provided
        return sum + (item.agreementProduct?.unitPrice || 0) * quantity;
      }
      return sum;
    },
    0
  );

  data.agreementInvoiceValue = subtotalRetailPrice;
  data.agreementCost = subtotalUnitPrice;  
  return (
   // console.log('PDFFile : ', data),
    <Document>
      <Page style={pdfStyle.body}>
        <PDFHeaderView data={data} />
        <Text style={pdfStyle.subText}>{'OVERVIEW'}</Text>
        <PDFTotalView data={data} />
        <Text style={pdfStyle.subText}>{'DETAILS'}</Text>
        <View style={pdfStyle.divider} />
        <View style={pdfStyle.avsnitt} />

        {data.agreementInvoiceValue > 0 && <PDFAgreementView data={data} />}
        {data.investmentInvoiceValue > 0 && <PDFInvestmentView data={data} />}
        {data.supportInvoiceValue > 0 && <PDFSupportView data={data} />}
        {data.otherInvoiceValue > 0 && <PDFOtherView data={data} />}
        <PDFFooterView data={data} />
      </Page>
    </Document>
  );
};
