import {Grid ,Paper, Avatar, IconButton, Typography, Box } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { MissingPhoto } from "data/globalTypeConfig";

export const ProductCardPackage = (props:any) => {
  const { companyProduct:_cp, handleDecProduct, handleIncProduct } = props;
  
  let companyProduct = _cp.product
  return (
    <Paper
      elevation={3}
      sx={{
        backgroundColor: "primary.main",
        flexGrow: 1,
        borderRadius: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
        }}
      >
        <Box sx={{ p: 0.5, pb: 0.5 }}>
        <Grid
        item
        sx={{
          mx: "auto",
          width: 64,
          height: 64,
          backgroundColor: "primary.light",
        }}
      >
        <img
          src={companyProduct?.url || MissingPhoto.PRODUCT_URL}
          style={{
            width: 64,
            height: 64,
            borderRadius: 3,
            objectFit: "contain",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.50)",
          }}
          alt={"previewUrl"}
        />
      </Grid>
        </Box>
        <Box sx={{ pl: 1, pr: 2, flexGrow: 1 }}>
          <Grid
            container
            spacing={0}
            sx={{
              pl: 0.5,
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
              alignItems: "flex-start",
              alignContent: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                pl: 1,
                pt: 1,
                maxWidth: 250,
                textAlign: "left",
                fontSize: 16,
                letterSpacing: 2,
                fontWeight: "bold",
                textTransform: "uppercase",
                color: "primary.text",
                display: "block",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {companyProduct?.name}
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                pl: 1,
                pt: 1,
                textAlign: "left",
                fontSize: 14,
                letterSpacing: 1,
                fontWeight: "bold",
                textTransform: "capitalism",
                color: "primary.text",
              }}
            >
              {companyProduct?.brand}
            </Box>
          </Grid>
        </Box>
        <Box sx={{ pl: 1, pr: 2 }}>
          <Grid
            container
            spacing={0}
            sx={{
              pl: 0.5,
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
              alignItems: "flex-start",
              alignContent: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <Box
              sx={{
                pt: 1,
                minWidth: 100,
                textAlign: "right",
                whiteSpace: "nowrap",
                fontSize: 16,
                letterSpacing: 2,
                fontWeight: "bold",
                color: "secondary.text",
              }}
            >
              {companyProduct?.retailPrice.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Grid>
        </Box>

        <Box sx={{}}>
          <Grid
            container
            spacing={0}
            sx={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
              alignItems: "flex-center",
              alignContent: "flex-center",
              justifyContent: "flex-center",
            }}
          >
            <IconButton
              aria-label="increase"
              sx={{
                mt: -1,
                p: 0,
                mx: "auto",
                color: "info.text",
              }}
              onClick={() => handleIncProduct(companyProduct)}
            >
              <KeyboardArrowUpIcon sx={{ fontSize: 40 }} />
            </IconButton>
            <Typography
              sx={{
                mt: -1.75,
                mb: -1.75,
                mx: "auto",
                color: "secondary.text",
                fontSize: 18,
              }}
            >
              {_cp?.quantity || 1}
            </Typography>

            <IconButton
              aria-label="reduce"
              sx={{
                m: 0,
                p: 0,
                mx: "auto",
                color: "info.text",
              }}
              onClick={() => handleDecProduct(companyProduct)}
            >
              <KeyboardArrowDownIcon sx={{ fontSize: 40 }} />
            </IconButton>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
};
