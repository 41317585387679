import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  Grid,
  Typography,
  Paper,
  Avatar,
  Stack,
  IconButton,
} from '@mui/material';
import GetPriorityColor from 'functions/GetPriorityColor';
import GetInitials from 'functions/GetInitials';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
import { IOrderDeliveryAddressViewModel } from 'pages/Orders/OrdersConfig';
import postalCodes from 'norway-postal-codes';
import { MissingPhoto } from 'data/globalTypeConfig';

type Props = {
  deliveryAddress?: IOrderDeliveryAddressViewModel | null;
};

export const SingleDeliveryAddressView = ({deliveryAddress } : Props) => {  
  
  return (
    <Paper
      variant='elevation3' 
      sx={{
        mb: 1,
        borderLeft: 8,
        borderColor: GetPriorityColor(3),
        ':hover': {
          cursor: 'pointer',
          filter: 'brightness(120%)',
          transition: 'all 0.2s ease-in-out',
        },
      }}
    >
      <Stack direction={'row'} sx={{height:80}}>
        <Grid item sx={{width:200}}>         
             <img
             src={deliveryAddress?.url ? (deliveryAddress.url) : (MissingPhoto.IMAGE_URL)}
             style={{ width: 158, height: 80, borderRadius: 3, objectFit: 'scale-down', boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)' }}
             alt={'previewUrl'}
             />         
        </Grid>

        <Grid container flexGrow={1} direction={'column'} sx={{}}>
          <Grid item sx={{}}>
            <Stack direction={'row'} flexGrow={1}>
              <Grid flexGrow={1} sx={{}}>
                <Typography
                  sx={{
                    ml: 1,
                    letterSpacing: 1,
                    textAlign: 'left',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                    fontSize: 18,
                  }}
                >
                  {deliveryAddress?.name}
                </Typography>
              </Grid>
              <Typography
                sx={{
                  ml: 1,
                  mt: 0.3,
                  letterSpacing: 1,
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 'normal',
                  fontSize: 16,
                }}
              >
                {/* ( {company?.organizationNumber} ) */}
              </Typography>
              <Grid item sx={{ m: 0, p: 0 }}>
                {/* {props?.detailNav && (
                  <IconButton
                    sx={{ mt: -1, mb: -1 }}
                    onClick={() => {
                      navigate(`/companies/${company.id}`);
                    }}
                  >
                    <LaunchRoundedIcon sx={{ fontSize: 26 }} />
                  </IconButton>
                )} */}
              </Grid>
            </Stack>
          </Grid>

          <Grid container flexGrow={1} direction={'row'} sx={{}}>
            <Grid
              item
              xs={4}
              sx={{
                pl: 1,
              }}
            >
              <Typography
                sx={{
                  m: 0,
                  pr: 0.3,
                  fontWeight: 'normal',
                  fontSize: 14,
                }}
              >
              {deliveryAddress?.address.street}
              </Typography>             
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                pl: 1,
              }}
            >
              <Typography
                sx={{
                  m: 0,
                  pr: 0.3,
                  fontWeight: 'normal',
                  fontSize: 14,
                }}
              >
              {deliveryAddress?.address.postalCode}
              </Typography>             
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                pl: 1,
              }}
            >
              <Typography
                sx={{
                  m: 0,
                  pr: 0.3,
                  fontWeight: 'normal',
                  fontSize: 14,
                }}
              >
              {postalCodes[`${deliveryAddress?.address.postalCode}`]}
              </Typography>             
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                pl: 1,
              }}
            >
              <Typography
                sx={{
                  m: 0,
                  pr: 0.3,
                  fontWeight: 'normal',
                  fontSize: 14,
                }}
              >
              {deliveryAddress?.address.city}
              </Typography>             
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                pl: 1,
              }}
            >
              <Typography
                sx={{
                  m: 0,
                  pr: 0.3,
                  fontWeight: 'normal',
                  fontSize: 14,
                }}
              >
              {deliveryAddress?.address.country}
              </Typography>             
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                pl: 1,
              }}
            >
              <Typography
                sx={{
                  m: 0,
                  pr: 0.3,
                  fontWeight: 'normal',
                  fontSize: 14,
                }}
              >
              {''}
              </Typography>             
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </Paper>
  );
};
