import { Link, Outlet } from "react-router-dom";
import useWindowsDimension from "functions/useWindowsDimension";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
//import { useGet } from "http/useInnovit";
//import { rq } from "http/apiRoutes";

export const Setup = () => {
  const {  width } = useWindowsDimension();
  const drawerWidth = 250;
  let teller = -1;

  //let isEmployee = true;
  //const { data: company } = useGet(rq.appOwner(isEmployee), isEmployee);

  return (
    <Grid container direction="row" spacing={0}>
      <Grid item width={drawerWidth} sx={{}}>
        <Paper
          variant="elevation3"
          sx={{
            width: drawerWidth,
            /* height: "calc(100% - 30px)", */
            borderRadius: 3,
            backgroundColor: "primary.main",
            color: "primary.contrastText",
            position: "fixed",
          }}
        >
          <List sx={{ color: "primary.text" }}>
            <ListItem
              button
              key={teller++}
              component={Link}
              to={`/Setup/mainCompany`}
            >
              <ListItemText primary="Company Details" />
            </ListItem>

            <ListItem
              button
              key={teller++}
              component={Link}
              to={`/Setup/employees`}
            >
              <ListItemText primary="Employee Roles" />
            </ListItem>

            <ListItem
              button
              key={teller++}
              component={Link}
              to={`/Setup/productData`}
            >
              <ListItemText primary="Product Data Setup" />
            </ListItem>
            <ListItem
              button
              key={teller++}
              component={Link}
              to={`/Setup/workRoles`}
            >
              <ListItemText primary="Economy Setup" />
            </ListItem>
            <ListItem
              button
              key={teller++}
              component={Link}
              to={`/Setup/autoAnswers`}
            >
              <ListItemText primary="Auto Answers" />
            </ListItem>
            <ListItem
              button
              key={teller++}
              component={Link}
              to={`/Setup/brand`}
            >
              <ListItemText primary="Miscaleanous" />
            </ListItem>
          </List>
        </Paper>
      </Grid>

      <Grid item width={width - drawerWidth * 2 - 76} sx={{}}>
        <Grid sx={{ ml: 2 }}>
          {/* {!!company && <CompanyHeader company={company} />} */}
        </Grid>
        <Grid sx={{ ml: 2 }}>
          <Outlet />
        </Grid>
      </Grid>
    </Grid>
  );
};
