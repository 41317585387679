import { useState, useReducer, useEffect } from 'react';
import useWindowsDimension from 'functions/useWindowsDimension';
import 'moment-timezone';
import {
  FormTextMultiline,
  FormSwitch,
  FormAccordionSummary,
} from 'components/form';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Paper,
  Stack,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  AccordionDetails,
  Accordion,
  Avatar,
  Switch,
} from '@mui/material';

import { StatusFilter } from 'components/ui/StatusFilter';
import { useParams } from 'react-router';
import { SearchFilter } from 'components/ui/SearchFilter';
import { useRef } from 'react';
import { Hours, Minutes, ticketStatus } from 'data/enum';
import { ticketCommunicationStatus } from 'data/enum';
import { useGet, usePost, usePut } from 'http/useInnovit';
import { rq, rqPost } from 'http/apiRoutes';
import TextsmsRoundedIcon from '@mui/icons-material/TextsmsRounded';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
//@ts-ignore
import DocumentUploader from 'components/ui/DocumentUploader';
//@ts-ignore
import ConvertToAttachment from 'functions/ConvertToAttachment';
import _, { set } from 'lodash';
import { SingleEmployeeView } from 'components/ui/SingleEmployeeView';
import { useAtom } from 'jotai';
import ownerAtom from 'data/atoms/ownerAtom';
import userAtom from 'data/atoms/userAtom';
import { SingleMessage } from 'components/ui/tickets/SingleMessage';
import { ObjectDrawer } from 'pages/Companies/ObjectDrawer';
import { IRateViewModel, IWorkRoleViewModel } from 'data/atoms/atomConfig';
import { ITicketPostViewModel } from './TicketConfig';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import CalculateTotalTime from 'functions/CalculateTotalTime';
import CalculateRateValue from 'functions/CalculateRateValue';
import { IEmailModel, MissingPhoto } from 'data/globalTypeConfig';
import { setegid } from 'process';

const initialState: ITicketPostViewModel = {
  id: null,
  ticketId: null,
  status: 0,
  startTime: new Date(),
  endTime: new Date(new Date().getTime() + 15 * 60000),
  startDeduction: new Date(),
  endDeduction: new Date(),
  billable: true,
  overTime: false,
  internal: false,
  isInHouse: true,
  direction: 1,
  authorId: null,
  author: null,
  receiverId: null,
  receiver: null,
  message: '',
  isBilled: false,
  workRoleId: null,
  workRole: null,
  ticketPostBillingId: null,
  ticketPostBilling: null,
  attachments: [],
};



const setWorkRole = (
  ticketWorkRole: IWorkRoleViewModel | null,
  companyWorkRole: IWorkRoleViewModel | null,
  userWorkRole: IWorkRoleViewModel | null,
  stateWorkRole: IWorkRoleViewModel | null
) => {
  if (stateWorkRole) {
    return stateWorkRole;
  }
  if (ticketWorkRole) {
    return ticketWorkRole;
  }
  if (companyWorkRole) {
    return companyWorkRole;
  }
  return userWorkRole;
};
interface OHjsxProps {
  onHold: boolean;
  setSendEmail: any;
}
export const TicketCommunications = () => {
  /* INITIALIZATIONS */
  const [owner] = useAtom(ownerAtom);
  const [user] = useAtom(userAtom);
  const { ticketId } = useParams<string>();
  const ref: any = useRef(0);
  const { height } = useWindowsDimension();
  const [sendEmail, setSendEmail] = useState<boolean>(false);
  const formReducer = (state: any, action: any) => {
    switch (action.type.toUpperCase()) {
      case 'SET_RATES':
        return { ...state, rates: action.payload };
      case 'SET_TICKET_POST_BILLING':
        return { ...state, ticketPostBilling: action.payload };
      case 'CHANGE_WORK_ROLE':
        return { ...state, workRole: action.payload };
      case 'CHANGE_WORK_ROLE_ID':
        return { ...state, workRoleId: action.payload };
      case 'ASSIGN_POST':
        return {
          ...state,
          ...action.payload,
        };   
      case 'ASSIGN_RECEIVER':
        return { ...state, 
          receiver: action.payload,
          receiverId: action.payload.id };
      case 'CHANGE_STATUS':
        console.log('action', action.payload);
        if (action.payload === 4 || action.payload === 5 || action.payload === 6) {
          setSendEmail(true);
        } else {
          setSendEmail(false);
        }
        return { ...state, status: action.payload };
      case 'CHANGE_START_TIME':
        return { ...state, startTime: dayjs(action.payload) };
      case 'CHANGE_START_DEDUCTION':
        return { ...state, startDeduction: dayjs(action.payload) };
      case 'CHANGE_END_DEDUCTION':
        return { ...state, endDeduction: dayjs(action.payload) };
      case 'CHANGE_END_TIME':
        return { ...state, endTime: dayjs(action.payload) };
      case 'CHANGE_BILLABLE':
        return { ...state, billable: action.payload };
      case 'CHANGE_IN_HOUSE':
        return { ...state, isInHouse: action.payload };
      case 'CHANGE_INTERNAL':
        return { ...state, internal: action.payload };
      case 'CHANGE_MESSAGE':
        return { ...state, message: action.payload };
      case 'CHANGE_OVERTIME':
        return { ...state, overtime: action.payload };
      case 'CHANGE_AUTO_ANSWER':
        return { ...state, message: action.payload };
      case 'CHANGE_ATTACHMENTS':
        return {
          ...state,
          attachments: [
            ...(state.ticketPost?.attachments || []),
            ...action.payload,
          ],
        };
      case 'RESET_FORM':
        return initialState;
      default:
        return state;
    }
  };
  // STATES
  const [state, dispatch] = useReducer(formReducer, initialState);
  const [selectedDocuments, setSelectedDocuments] = useState<any>([]);

  const [totalTime, setTotalTime] = useState<any>('00:00');
  const [totalDeductionTime, setTotalDeductionTime] = useState<any>('00:00');
  const [ticketPosts, setTicketPosts] = useState<ITicketPostViewModel[]>([]);
  const [ticket, setTicket] = useState<any>({});
  const [filterType, setFilterType] = useState<any>('all');
  const [workRoles, setWorkRoles] = useState<IWorkRoleViewModel[]>([]);
 
  // console.log('state', state);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let message = e.target.value;
    dispatch({
      type: 'CHANGE_MESSAGE',
      payload: message,
    });
    //setInputValue(e.target.value);
  };
  const checkForOvertime = (hours: any, minutes: any) => {
    if (
      hours < owner.rates[1].startHour ||
      (hours === owner.rates[1].startHour && minutes < 0)
    ) {
      dispatch({ type: 'CHANGE_OVERTIME', payload: true });
    } else if (
      hours > owner.rates[1].endHour ||
      (hours === owner.rates[1].endHour && minutes > 14)
    ) {
      dispatch({ type: 'CHANGE_OVERTIME', payload: true });
    } else {
      dispatch({ type: 'CHANGE_OVERTIME', payload: false });
    }
  };
  let economy = false;
  // API
  let _ticketId: string = 'new';
  if (ticketId !== null || ticketId !== undefined) {
    _ticketId = ticketId as string;
  }
  let isEnabled = _ticketId !== 'new';

  const { data: ticketData } = useGet(
    rq.ticket(_ticketId, isEnabled, '0'),
    isEnabled
  );
  const postTicketPost = usePost(rqPost.ticketPost(_ticketId, '0'));
  const putTicketPost = usePut(rq.ticketPost(_ticketId, state?.id));
  const postEmail = usePost(rqPost.sendEmail(true));
  const putTicket = usePut(
    rqPost.ticketPut(_ticketId, '0', isEnabled),
    isEnabled
  );
  const { data: ticketPostData } = useGet(rq.ticketPosts(_ticketId));
  isEnabled = owner.id ? true : false;
  const { data: workRoleData } = useGet(
    rq.workRoles(owner.id, isEnabled),
    isEnabled
  );
  const { data: ratesData } = useGet(rq.rates(isEnabled));

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {} as ITicketPostViewModel,
    // resolver: yupResolver(schema),
  });

  // HANDLES
  const handleAssignPost = (ticketPost: ITicketPostViewModel) => {
    dispatch({
      type: 'ASSIGN_POST',
      payload: ticketPost,
    });
    console.log('ticketPost', ticketPost);
    if (ticketPost.workRole) {
      let _workRole = setWorkRole(
        ticket.workRole || null,
        ticketData.workRole || null,
        user.workRole || null,
        ticketPost.workRole
      );
      dispatch({
        type: 'CHANGE_WORK_ROLE',
        payload: _workRole,
      });
    }
  };
  const handleChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'CHANGE_STATUS',
      payload: e,
    });
  };
  const handleChangeStartTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'CHANGE_START_TIME',
      payload: e,
    });

    let startMinutes = new Date().getMinutes();
    let startHours = new Date().getHours();
    //    checkForOvertime(startHours, startMinutes);
  };
  const handleChangeStartDeduction = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch({
      type: 'CHANGE_START_DEDUCTION',
      payload: e,
    });
  };
  const handleChangeEndDeduction = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'CHANGE_END_DEDUCTION',
      payload: e,
    });
  };
  const handleChangeEndTime = (e: any) => {
    dispatch({
      type: 'CHANGE_END_TIME',
      payload: e,
    });
    let _totalTime = CalculateTotalTime(
      state.startTime,
      state.endTime,
      state.startDeduction,
      state.endDeduction
    );
    setTotalTime(_totalTime);
  };
  const handleChangeBillable = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'CHANGE_BILLABLE',
      payload: e,
    });
  };
  const handleChangeOvertime = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'CHANGE_OVERTIME',
      payload: e,
    });
  };
  const handleChangeInHouse = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'CHANGE_IN_HOUSE',
      payload: e,
    });
  };
  const handleChangeInternal = (e: any) => {
    dispatch({
      type: 'CHANGE_INTERNAL',
      payload: e,
    });
  };
  const handleChangeAutoAnswer = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'CHANGE_AUTO_ANSWER',
      payload: e,
    });
  };
  const handleChangeMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
    //console.log('message', e);
    /*   dispatch({
      type: 'CHANGE_MESSAGE',
      payload: inputValue,
    }); */
  };
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
  };
  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    ref.current = Number(e.target.value);
  };
  const handleChangeAttachments = (attachments: any) => {
    dispatch({
      type: 'CHANGE_ATTACHMENTS',
      payload: attachments,
    });
  };
  const handleAssigneMe = () => {
    ticket.responsible = user;
    putTicket.mutate(ticket);
  };
  const handleChange = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    selectedObject: any,
    objectType: string
  ) => {
    switch (objectType) {
      case 'workRoles':
        let object = selectedObject as IWorkRoleViewModel;
        dispatch({ type: 'CHANGE_WORK_ROLE', payload: object });
        dispatch({ type: 'CHANGE_WORK_ROLE_ID', payload: object.id });

        break;
      default:
        break;
    }
  };

  const onSubmit = (data: any, e: any) => {
    e.preventDefault();
    /*  if (user.id !== state.authorId && state.id !== null) {
      return;
    } */
    if (sendEmail) {
      let emailModel :IEmailModel = {
        contactEmail: ticket.contact.email,
        contactName: ticket.contact.name,
      }
      
      console.log('send email', emailModel);
      postEmail.mutate(emailModel);
    }
    const _ticketPost = { ...state };
    // console.log('_ticketPost', _ticketPost);

    if (_ticketPost.internal === true) {
      _ticketPost.billable = false;
      _ticketPost.overtime = false;
    }
    _ticketPost.attachments = ConvertToAttachment(selectedDocuments);
    _ticketPost.authorId = user.id;
    _ticketPost.ticketId = ticket.id;
    _ticketPost.workRoleId = state.workRole?.id;

    if (!_ticketPost.ticketPostBillingId) {
      delete _ticketPost.ticketPostBillingId;
    }
    if (!_ticketPost.ticketPostBilling?.id) {
      delete _ticketPost.ticketPostBilling.id;
    }

    if (!_ticketPost.id) {
      //console.log('POST TICKET POST', _ticketPost);
      postTicketPost.mutate(_ticketPost, {
        onSuccess: () => {
          dispatch({
            type: 'RESET_FORM',
          });
          // setInputValue(null);
          let message = '';
          dispatch({
            type: 'CHANGE_MESSAGE',
            payload: message,
          });
          dispatch({
            type: 'ASSIGN_RECEIVER',
            payload: ticket.contact,
          });
          let workRole: IWorkRoleViewModel = {
            id: user.workRole.id,
            title: user.workRole.title,
            inHouseHourlyRateCost: user.workRole.inHouseHourlyRateCost,
            inHouseHourlyRate: user.workRole.inHouseHourlyRate,
            onSiteHourlyRateCost: user.workRole.onSiteHourlyRateCost,
            onSiteHourlyRate: user.workRole.onSiteHourlyRate,
          };
          let _workRole = setWorkRole(
            ticket.workRole || null,
            ticket.company.workRole || null,
            workRole,
            state.workRole
          );
          dispatch({
            type: 'CHANGE_WORK_ROLE',
            payload: _workRole,
          });

          handleChangeAttachments([]);
        },
      });
    } else {
      putTicketPost.mutate(_ticketPost, {
        onSuccess: () => {
          dispatch({
            type: 'RESET_FORM',
          });
          let message = '';
          dispatch({
            type: 'CHANGE_MESSAGE',
            payload: message,
          });
          dispatch({
            type: 'ASSIGN_RECEIVER',
            payload: ticket.contact,
          });
          let workRole: IWorkRoleViewModel = {
            id: user.workRole.id,
            title: user.workRole.title,
            inHouseHourlyRateCost: user.workRole.inHouseHourlyRateCost,
            inHouseHourlyRate: user.workRole.inHouseHourlyRate,
            onSiteHourlyRateCost: user.workRole.onSiteHourlyRateCost,
            onSiteHourlyRate: user.workRole.onSiteHourlyRate,
          };
          let _workRole = setWorkRole(
            ticket.workRole || null,
            ticket.company.workRole || null,
            workRole,
            state.workRole
          );
          dispatch({
            type: 'CHANGE_WORK_ROLE',
            payload: _workRole,
          });
        },
      });
    }
  };

  // USEEFFECTS
  useEffect(() => {
    if (ticketData) {
      setTicket(ticketData);
      dispatch({
        type: 'ASSIGN_RECEIVER',
        payload: ticketData.contact,
      });
     

      if (ticketData.company.workRole) {
        setWorkRole(null, ticketData.workRole, null, state.workRole);
        dispatch({
          type: 'CHANGE_WORK_ROLE',
          payload: ticketData.company.workRole,
        });
        dispatch({
          type: 'CHANGE_WORK_ROLE:ID',
          payload: ticketData.company.workRole.id,
        });
      }
      //   console.log('ticket data', ticketData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketData]);

  useEffect(() => {
    if (ratesData && ratesData.rates) {
      let rates = [...ratesData.rates];
      // Example: Let's say you want to increase the rate by 10 for each rate
      rates = rates.map((rate: IRateViewModel) => {
        rate.rateValue = 0;
        return rate;
      });
      dispatch({
        type: 'SET_RATES',
        payload: rates,
      });
    }
  }, [ratesData]);

  useEffect(() => {
    handleChangeAttachments(selectedDocuments);
  }, [selectedDocuments]);

  useEffect(() => {
    if (ticketPostData) {
      setTicketPosts(ticketPostData.ticketPosts);
      //console.log('TICKET POSTS DATA', ticketPosts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketPostData]);

  useEffect(() => {
    if (workRoleData) {
      setWorkRoles(workRoleData.workRoles as IWorkRoleViewModel[]);
    }
  }, [workRoleData]);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      console.log('user', user);
      if (user.workRole?.id != null) {
        let workRole = {
          id: user.workRole.id,
          title: user.workRole.title,
          inHouseHourlyRateCost: user.workRole.inHouseHourlyRateCost,
          inHouseHourlyRate: user.workRole.inHouseHourlyRate,
          onSiteHourlyRateCost: user.workRole.onSiteHourlyRateCost,
          onSiteHourlyRate: user.workRole.onSiteHourlyRate,
        };
        let _workRole = setWorkRole(
          ticket?.workRole,
          ticket?.company?.workRole,
          workRole,
          state.workRole
        );
        dispatch({ type: 'CHANGE_WORK_ROLE', payload: _workRole });
        dispatch({ type: 'CHANGE_WORK_ROLE_ID', payload: _workRole?.id });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    setTotalTime(
      CalculateTotalTime(
        state.startTime,
        state.endTime,
        state.startDeduction,
        state.endDeduction
      )
    );
    
    if (state.rates && state.rates.length > 0 && state.workRole) {
      let _ticketPostBilling = CalculateRateValue({
        rates: state.rates,
        startTime: state.startTime,
        endTime: state.endTime,
        startDeduction: state.startDeduction,
        endDeduction: state.endDeduction,
        isInHouse: state.isInHouse,
        workRole: state.workRole,
      });
      _ticketPostBilling.ticketPostId = state.id;
      _ticketPostBilling.isBillable = state.billable;
      // console.log('_ticketPostBilling', _ticketPostBilling);
      dispatch({
        type: 'SET_TICKET_POST_BILLING',
        payload: _ticketPostBilling,
      });
      //  console.log('totalRateValue', _ticketPostBilling);
    }
  }, [
    state.id,
    state.rates,
    state.startTime,
    state.endTime,
    state.startDeduction,
    state.endDeduction,
    state.isInHouse,
    state.workRole,
    state.overtime,
    state.billable,
  ]);

  useEffect(() => {
    setTotalDeductionTime(
      CalculateTotalTime(null, null, state.startDeduction, state.endDeduction)
    );
  }, [state.startDeduction, state.endDeduction]);

  const OHjsx: React.FC<OHjsxProps> = ({ onHold: sendEmail, setSendEmail }) => {
    const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSendEmail(e.target.checked);
    };
    return (
      <Grid item sx={{ m: 0, p: 0 }}>
        <Stack direction='row'>
          <Typography
            sx={{
              m: 0,
              p: 2.2,
              pr: 0,
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              fontSize: 14,
              color: 'primary.text',
            }}
          >
            {sendEmail ? 'YES' : 'NO'}
          </Typography>

          <Switch
            color={sendEmail ? 'secondary' : 'primary'}
            sx={{
              mt: 1,
              mr: 1,
              '& .MuiSwitch-thumb': {
                backgroundColor: sendEmail ? 'secondary.light' : 'primary.text', // Use any color you like for 'onHold' state
              },
              '& .MuiSwitch-track': {
                backgroundColor: sendEmail
                  ? 'rgba(0, 0, 0, 0.7)'
                  : 'rgba(0, 0, 255, 0.3)', // This gives a lighter red shade for the track. Adjust as needed.
              },
            }}
            checked={sendEmail}
            onChange={handleToggle}
            name='sendEmail'
          />
        </Stack>
      </Grid>
    );
  };

  return (
    <>
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
        }}
      >
        <Grid item xs={6} sx={{ pr: 1, m: 0 }}>
          <SearchFilter
            Icon={TextsmsRoundedIcon}
            Header='Communication'
            filterValue={filterType}
            handleFilterChange={handleFilterChange}
            handleSearch={handleSearch}
            selectList={ticketStatus}
          />
          <List
            sx={{
              pl: 1,
              pr: 1,
              height: Math.ceil(height - 272),
              backgroundColor: 'primary.back',
              overflow: 'auto',
            }}
          >
            {!!ticketPosts &&
              ticketPosts?.map(
                (ticketPost: ITicketPostViewModel, index: number) => (
                  <Grid
                    sx={{
                      backgroundColor: 'transparent',
                      border: 0,
                      boxShadow: 'none',
                    }}
                    key={index}
                    onClick={() => {
                      handleAssignPost(ticketPost);
                    }}
                  >
                    <SingleMessage ticketPost={ticketPost} />
                  </Grid>
                )
              )}
          </List>

          <Paper
            variant='elevation3'
            sx={{
              p: 2,
              height: 64,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          ></Paper>
        </Grid>
        <Grid item xs={6} sx={{ pl: 1, m: 0 }}>
         
            <form
              name='ticketCommunicationForm'
              onSubmit={handleSubmit(onSubmit)}
            >
              {/* NEW COMMUNICATION*/}
              <StatusFilter
                Icon={TextsmsRoundedIcon}
                Header={!state.id ? 'New Communication' : 'Edit Communication'}
                name='status'
                control={control}
                label='Status'
                list={ticketCommunicationStatus}
                statusValue={state.status}
                handleChangeStatus={handleChangeStatus}
              />
          <List
          sx={{
            height: Math.ceil(height - 272),
            width: '100%',
            mb: 0,
            p: 2,
            backgroundColor: 'primary.main',
            overflow: 'auto',
          }}
        >
              <Stack direction={'column'} flex={1} sx={{ mx: 'auto' }}>
                {/* SWITCHES */}
                <Grid
                  container
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    height: 70,
                    mb: 1,
                  }}
                >
                  <Grid item xs={2} sx={{ m: 0, p: 0 }}>
                    <Box
                      sx={{
                        m: 1,
                        border: 1,
                        borderRadius: 2,
                        borderColor: 'primary.dark',
                        backgroundColor: 'primary.back',
                        display: 'flex',
                      }}
                    >
                      <Stack direction='column' spacing={0} alignSelf='center'>
                        <Typography
                          sx={{
                            m: 0,
                            p: 0,
                            width: '100%',
                            fontSize: 14,
                            letterSpacing: 1,
                            textAlign: 'center', // Add this line
                            alignSelf: 'center',
                            alignContent: 'center',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            textTransform: 'capitalize',
                          }}
                        >
                          Billable
                        </Typography>
                        <Box
                          sx={{
                            ml: 2,
                          }}
                        >
                          <FormSwitch
                            name='billable'
                            control={control}
                            onSwitchChange={handleChangeBillable}
                            value={state.billable || false}
                          />
                        </Box>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sx={{ m: 0, p: 0 }}>
                    <Box
                      sx={{
                        m: 1,
                        border: 1,
                        borderRadius: 2,
                        borderColor: 'primary.dark',
                        backgroundColor: 'primary.back',
                        display: 'flex',
                      }}
                    >
                      <Stack direction='column' spacing={0} alignSelf='center'>
                        <Typography
                          sx={{
                            m: 0,
                            p: 0,
                            width: '100%',
                            fontSize: 14,
                            letterSpacing: 1,
                            textAlign: 'center', // Add this line
                            alignSelf: 'center',
                            alignContent: 'center',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            textTransform: 'capitalize',
                          }}
                        >
                          overtime
                        </Typography>
                        <Box
                          sx={{
                            ml: 2,
                          }}
                        >
                          <FormSwitch
                            name='overtime'
                            control={control}
                            onSwitchChange={handleChangeOvertime}
                            value={state.overtime || false}
                          />
                        </Box>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sx={{ m: 0, p: 0 }}>
                    <Box
                      sx={{
                        m: 1,
                        border: 1,
                        borderRadius: 2,
                        borderColor: 'primary.dark',
                        backgroundColor: 'primary.back',
                        display: 'flex',
                      }}
                    >
                      <Stack direction='column' spacing={0} alignSelf='center'>
                        <Typography
                          sx={{
                            m: 0,
                            p: 0,
                            width: '100%',
                            fontSize: 14,
                            letterSpacing: 1,
                            textAlign: 'center', // Add this line
                            alignSelf: 'center',
                            alignContent: 'center',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            textTransform: 'capitalize',
                          }}
                        >
                          In House
                        </Typography>
                        <Box
                          sx={{
                            ml: 2,
                          }}
                        >
                          <FormSwitch
                            name='isInHouse'
                            control={control}
                            onSwitchChange={handleChangeInHouse}
                            value={state.isInHouse || false}
                          />
                        </Box>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={3} sx={{ m: 0, p: 0 }}>
                    {ticket?.responsible ? (
                      <Box
                        sx={{
                          m: 1,
                          height: 60,
                          border: 1,
                          borderRadius: 2,
                          borderColor: 'primary.dark',
                          backgroundColor: 'primary.back',
                          display: 'flex',
                        }}
                      >
                        <Avatar
                          variant='rounded'
                          alt={ticket?.responsible.name}
                          src={
                            ticket?.responsible.url || MissingPhoto.EMPLOYEE_URL
                          }
                          sx={{ width: 64, height: 64 }}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          m: 1,
                          height: 60,
                          border: 1,
                          borderColor: 'primary.back',
                          borderRadius: 2,
                          backgroundColor: 'primary.back',
                          display: 'flex',
                        }}
                      >
                        <Button
                          sx={{
                            mx: 'auto',
                            width: '100%',
                            height: '100%',
                            borderRadius: 3,
                          }}
                          variant='contained'
                          onClick={(e) => {
                            handleAssigneMe();
                          }}
                        >
                          <Typography
                            sx={{
                              textTransform: 'uppercase',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              fontWeight: 'bold',
                              fontSize: 14,
                              letterSpacing: 2,
                            }}
                          >
                            Assign me
                          </Typography>
                        </Button>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={3} sx={{ m: 0, p: 0 }}>
                    <Grid item sx={{ m: 0, p: 1 }}>
                      {state.workRole ? (
                        <Box
                          sx={{
                            height: 60,
                            border: 1,
                            borderRadius: 2,
                            borderColor: 'primary.dark',
                            backgroundColor: 'primary.back',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Typography>{state.workRole.title}</Typography>

                          {state.isInHouse ? (
                            <Typography>
                              {state.workRole.inHouseHourlyRate}
                            </Typography>
                          ) : (
                            <Typography>
                              {state.workRole.onSiteHourlyRate}
                            </Typography>
                          )}
                          <ObjectDrawer
                            buttonContext={'Select WorkRole'}
                            buttonIcon={<ChangeCircleIcon />}
                            objectList={!!workRoles ? workRoles : []}
                            objectType={'workRoles'}
                            handleChange={handleChange}
                            buttonHeight={30}
                            buttonWidth={30}
                          />
                        </Box>
                      ) : (
                        <ObjectDrawer
                          buttonContext={'Select WorkRole'}
                          buttonIcon={null}
                          objectList={!!workRoles ? workRoles : []}
                          objectType={'workRoles'}
                          handleChange={handleChange}
                          buttonHeight={60}
                          buttonWidth={'100%'}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                {/* TIME */}
                <Grid sx={{ m: 1 }}>
                  <Paper
                    variant='accordion'
                    sx={{
                      backgroundColor: 'primary.main',
                      border: 1,
                      borderColor: 'primary.back',
                      m: 0,
                      p: 0,
                    }}
                  >
                    <Grid
                      container
                      spacing={0}
                      flexDirection={'row'}
                      sx={{ m: 0, p: 0 }}
                    >
                      <Grid item xs={0.5} sx={{ m: 0, p: 0 }}>
                        <ScheduleRoundedIcon
                          sx={{ fontSize: 32, m: 1, pt: 0.5 }}
                        />
                      </Grid>
                      <Grid item xs={7.5} sx={{ m: 0, p: 0 }}>
                        <Typography
                          sx={{
                            mt: 2,
                            flexGrow: 1,
                            textTransform: 'uppercase',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontWeight: 'bold',
                            fontSize: 16,
                            letterSpacing: 3,
                          }}
                        >
                          Date / Time {totalTime}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sx={{ m: 0, pr: 2, pt: 1 }}>
                        <Stack direction={'row'} sx={{}}>
                          <Typography
                            sx={{
                              pt: 0.7,
                              width: 100,
                              letterSpacing: 1,
                              textAlign: 'center',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              fontWeight: 'bold',
                              textTransform: 'capitalize',
                            }}
                          >
                            Start:
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDateTimePicker
                              value={
                                dayjs(state.startTime)
                                  ? dayjs(new Date(state.startTime))
                                  : dayjs(new Date())
                              }
                              onChange={(e) => {
                                handleChangeStartTime(
                                  e as unknown as React.ChangeEvent<HTMLInputElement>
                                );
                              }}
                              onClose={() => {
                                //console.log('close endtime', e.target.value);
                                CalculateTotalTime(
                                  state.startTime,
                                  state.endTime,
                                  state.startDeduction,
                                  state.endDeduction
                                );
                              }}
                              ampm={false}
                              views={[
                                'year',
                                'month',
                                'day',
                                'hours',
                                'minutes',
                              ]}
                            />
                          </LocalizationProvider>
                        </Stack>
                      </Grid>
                      <Grid item xs={4} sx={{ m: 0, p: 0 }}>
                        <Accordion
                          disableGutters
                          sx={{
                            backgroundColor: 'primary.main',
                            border: 1,
                            borderColor: 'primary.back',
                            m: 0,
                            p: 0,
                          }}
                        >
                          <FormAccordionSummary
                            Icon={HistoryToggleOffIcon}
                            title={`deduction time ${totalDeductionTime}`}
                          />

                          <Grid sx={{ ml: 2, mr: 2, mt: 1 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DesktopDateTimePicker
                                value={
                                  dayjs(state.startDeduction)
                                    ? dayjs(new Date(state.startDeduction))
                                    : null
                                }
                                onChange={(e) => {
                                  handleChangeStartDeduction(
                                    e as unknown as React.ChangeEvent<HTMLInputElement>
                                  );
                                }}
                                ampm={false}
                                views={[
                                  'year',
                                  'month',
                                  'day',
                                  'hours',
                                  'minutes',
                                ]}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid sx={{ ml: 2, mr: 2, mt: 1 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DesktopDateTimePicker
                                value={
                                  dayjs(state.endDeduction)
                                    ? dayjs(new Date(state.endDeduction))
                                    : null
                                }
                                onChange={(e) => {
                                  handleChangeEndDeduction(
                                    e as unknown as React.ChangeEvent<HTMLInputElement>
                                  );
                                }}
                                ampm={false}
                                views={[
                                  'year',
                                  'month',
                                  'day',
                                  'hours',
                                  'minutes',
                                ]}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </Accordion>
                      </Grid>
                      <Grid item xs={4} sx={{ m: 0, p: 0 }}></Grid>
                      <Grid item xs={4} sx={{ m: 0, pr: 2, pt: 1 }}>
                        <Stack direction={'row'} sx={{}}>
                          <Typography
                            sx={{
                              pt: 0.7,
                              width: 100,
                              letterSpacing: 1,
                              textAlign: 'center',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              fontWeight: 'bold',
                              textTransform: 'capitalize',
                            }}
                          >
                            End:
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDateTimePicker
                              value={
                                dayjs(state.endTime)
                                  ? dayjs(new Date(state.endTime))
                                  : null
                              }
                              onChange={(e) => {
                                handleChangeEndTime(
                                  e as unknown as React.ChangeEvent<HTMLInputElement>
                                );
                              }}
                              ampm={false}
                              views={[
                                'year',
                                'month',
                                'day',
                                'hours',
                                'minutes',
                              ]}
                            />
                          </LocalizationProvider>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              
     
                {/* ECONOMY INFO */}
                {economy && <Grid item xs={12} sx={{ m: 2, p: 0 }}>
                  <Grid
                    container
                    spacing={0}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      backgroundColor: 'primary.back',
                      borderRadius: 2,
                      p: 1,
                    }}
                  >
                    {state.ticketPostBilling &&
                      Object.entries(state.ticketPostBilling).map(
                        ([key, value]) => (
                          <Grid
                            item
                            xs={
                              key === 'id'
                                ? 12
                                : key === 'ticketPostId'
                                ? 12
                                : 4
                            }
                            key={key}
                          >
                            <Typography>
                              {key}: {String(value)}
                            </Typography>
                          </Grid>
                        )
                      )}
                  </Grid>
                </Grid>
                }
                {/* MESSAGE & AUTOANSVER */}
                <Grid
                  container
                  spacing={0}
                  flexDirection={'row'}
                  sx={{ m: 0, p: 1 }}
                >
                  <Grid item xs={7} sx={{ m: 0, pr: 2 }}>
                    <Grid
                      sx={{
                        flexGrow: 1,
                        backgroundColor: 'primary.back',
                        borderRadius: 2,
                      }}
                    >
                      <Stack direction='row' spacing={0} flexGrow={1}>
                        <Typography
                          sx={{
                            m: 0,
                            p: 0,
                            pl: 2,
                            letterSpacing: 1,
                            alignSelf: 'center',
                            alignContent: 'center',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            textTransform: 'capitalize',
                            flexGrow: 1,
                          }}
                        >
                          internal Message
                        </Typography>
                        <FormSwitch
                          name='internal'
                          control={control}
                          onSwitchChange={handleChangeInternal}
                          value={state.internal || false}
                        />
                      </Stack>
                      <Grid
                        sx={{
                          m: 0,
                          pb: 1,
                          flexGrow: 1,
                          backgroundColor: 'primary.back',
                          borderRadius: 2,
                        }}
                      >
                        <FormTextMultiline
                          control={control}
                          label='message'
                          value={state.message != null ? state.message : ''}
                          minRows={9}
                          multiline={true}
                          name='message'
                          onChange={handleInputChange}
                          onBlur={handleChangeMessage}
                          //state={state}
                        />
                      </Grid>
                      <Stack direction='row' spacing={0} flexGrow={1}>
                        <Typography
                          sx={{
                            m: 0,
                            p: 0,
                            pl: 2,
                            letterSpacing: 1,
                            alignSelf: 'center',
                            alignContent: 'center',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            textTransform: 'capitalize',
                            flexGrow: 1,
                          }}
                        >
                          send email
                        </Typography>
                        <OHjsx onHold={sendEmail} setSendEmail={setSendEmail} />                       
                      </Stack>
                 
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    sx={{
                      m: 0,
                      p: 0,
                      backgroundColor: 'primary.back',
                      borderRadius: 2,
                    }}
                  >
                    <Grid
                      sx={{
                        flexGrow: 1,
                        backgroundColor: 'primary.back',
                        borderRadius: 2,
                      }}
                    >
                      <Stack direction={'row'} sx={{}}>
                        <Typography
                          sx={{
                            flexGrow: 1,
                            letterSpacing: 1,
                            textAlign: 'center',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontWeight: 'normal',
                            textTransform: 'capitalize',
                          }}
                        >
                          Auto Answers
                        </Typography>
                        {/* <FormSwitch
                          name='autoAnswer'
                          control={control}
                          onSwitchChange={handleChangeAutoAnswer}
                        /> */}
                      </Stack>
                    </Grid>

                    <List
                      sx={{
                        m: 1,
                        pl: 1,
                        pr: 1,
                        height: 263,
                        backgroundColor: 'primary.main',
                        overflow: 'auto',
                        borderRadius: 2,
                      }}
                    >
                      <ListItem
                        sx={{
                          m: 0,
                          p: 0,
                          mb: 0.5,
                          width: '100%',
                          backgroundColor: 'primary.main',
                          boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
                          ':hover': {
                            cursor: 'pointer',
                            filter: 'brightness(120%)',
                            transition: 'all 0.2s ease-in-out',
                          },
                        }}
                      >
                        <ListItemText
                          primary='I am on vacation'
                          sx={{
                            m: 0,
                            p: 0,
                            color: 'primary.text',
                            textAlign: 'center',
                          }}
                        />
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
                <Accordion
                        disableGutters
                        sx={{
                          backgroundColor: 'transparent',
                          border: 0,
                          m:1,
                        }}
                      >
                        <FormAccordionSummary
                          Icon={AttachFileIcon}
                          title='Attachments'
                        />
                        <AccordionDetails>
                          <DocumentUploader
                            setDocuments={(files: any) =>
                              setSelectedDocuments((prevDocs: any) => [
                                ...prevDocs,
                                ...files,
                              ])
                            }
                          />
                          <List>
                            {state.attachments?.map(
                              (document: any, index: number) => {
                                //console.log('document:', document);
                                return (
                                  <ListItem key={index}>
                                    {document.url ? (
                                      <a
                                        rel='noopener noreferrer'
                                        target='_blank'
                                        href={document.url}
                                        style={{ color: 'white' }}
                                      >
                                        {document.fileName}
                                      </a>
                                    ) : (
                                      document.file.name
                                    )}
                                  </ListItem>
                                );
                              }
                            )}
                          </List>
                        </AccordionDetails>
                      </Accordion>
           
              </Stack>
          </List>
          {/* FOTTER */}
          <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
     {/* SUBMIT*/}
     <Stack direction={'column'}>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'right',
                    }}
                  >
                    {state.id !== null && (
                      <Button
                        sx={{
                         
                          width: '25%',
                          backgroundColor: 'primary.back',
                        }}
                        onClick={() => {
                          dispatch({
                            type: 'RESET_FORM',
                          });
                          let message = '';
                          dispatch({
                            type: 'CHANGE_MESSAGE',
                            payload: message,
                          });
                          dispatch({
                            type: 'ASSIGN_RECEIVER',
                            payload: ticket.contact,
                          });
                          let workRole: IWorkRoleViewModel = {
                            id: user.workRole.id,
                            title: user.workRole.title,
                            inHouseHourlyRateCost:
                              user.workRole.inHouseHourlyRateCost,
                            inHouseHourlyRate: user.workRole.inHouseHourlyRate,
                            onSiteHourlyRateCost:
                              user.workRole.onSiteHourlyRateCost,
                            onSiteHourlyRate: user.workRole.onSiteHourlyRate,
                          };
                          let _workRole = setWorkRole(
                            ticket.workRole || null,
                            ticket.company.workRole || null,
                            workRole,
                            null
                          );
                          dispatch({
                            type: 'CHANGE_WORK_ROLE',
                            payload: _workRole,
                          });
                          //setInputValue(null);
                        }}
                        variant='contained'
                      >
                        Cancel
                      </Button>
                    )}
                    <Button
                      disabled={state.direction !== 1 ? true : false}
                      sx={{
                      
                        width: '25%',
                        // backgroundColor: 'secondary.main',
                      }}
                      type='submit'
                      variant='contained'
                    >
                      {!state.id ? 'Submit' : 'Confirm Edit'}
                    </Button>
                  </Box>
                </Stack>
        </Paper>
            </form>
        </Grid>
      </Grid>
    </>
  );
};
