import { memo, useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router';
// @ts-ignore
import { useDel, useGet, usePost, usePut } from 'http/useInnovit';
import { rq, rqDel, rqPost } from 'http/apiRoutes';
import { ObjectDrawer } from 'pages/Companies/ObjectDrawer';
// @ts-ignore

import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import * as yup from 'yup';
import { FormAccordionSummary, FormBoxElevated } from 'components/form';
import ArtTrackRoundedIcon from '@mui/icons-material/ArtTrackRounded';
import {
  Accordion,
  AccordionDetails,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  IconButton,
  List,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import FeedRoundedIcon from '@mui/icons-material/FeedRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import useWindowsDimension from 'functions/useWindowsDimension';
import ImageUploader from 'components/ui/ImageUploader';
import ConvertBase64StringToFileModel from 'functions/ConvertBase64StringToFileModel';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import GetPriorityColor from 'functions/GetPriorityColor';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import { useAtom } from 'jotai';
import ownerAtom from 'data/atoms/ownerAtom';
import { IWorkRoleViewModel } from 'data/atoms/atomConfig';
import { ICompanyViewModel, IFileModel } from './CompanyConfig';
import { IEmployeeViewModel } from 'pages/Tickets/TicketConfig';
import { IAddressViewModel } from 'data/globalTypeConfig';
import { SearchFilter } from 'components/ui/SearchFilter';
import { formatPhoneNumber } from 'functions/FormatPhoneNumber';
import { blueGrey } from '@mui/material/colors';
import { MuiTelInput, MuiTelInputInfo } from 'mui-tel-input';
let _ = require('lodash');

const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .matches(/^(|[A-å0-9 ]{2,50})$/, 'Between 2-50 characters')
      .required('Name is required'),
    organizationNumber: yup
      .string()
      .matches(/^(|[0-9]{9,9})$/, '9 numbers required')
      .required('Organization number is required'),
    fixedHourlyRate: yup
      .string()
      .matches(
        /^(|[0-9]{1,9})$/,
        'a number between 0 and 999999999 is required'
      )
      .required('Fixed hourly price is required'),
    email: yup.string().email(),
    // phone: yup.string().matches(/^(|[0-9]{8,16})$/, "Between 8-16 characters"),
    webpage: yup.string(),
    // companyType: yup.number().integer(),
    mainAddress: yup.object().shape({
      street: yup
        .string()
        .matches(/^(|[A-å0-9 ]{2,50})$/, 'Between 2-50 characters')
        .required('Street is required'),
      postalCode: yup
        .string()
        .matches(/^(|[0-9]{4,4})$/, '4 numbers required')
        .required('Postal code is required'),
      city: yup
        .string()
        .matches(/^(|[A-å0-9 ]{2,50})$/, 'Between 2-50 characters')
        .required('City is required'),
    }),
    deliveryAddress: yup.object().shape({
      street: yup
        .string()
        .matches(/^(|[A-å0-9 ]{2,50})$/, 'Between 2-50 characters'),
      postalCode: yup.string().matches(/^(|[0-9]{4,4})$/, '4 numbers required'),
      city: yup
        .string()
        .matches(/^(|[A-å0-9 ]{2,50})$/, 'Between 2-50 characters'),
    }),
    invoiceAddress: yup.object().shape({
      street: yup
        .string()
        .matches(/^(|[A-å0-9 ]{2,50})$/, 'Between 2-50 characters'),
      postalCode: yup.string().matches(/^(|[0-9]{4,4})$/, '4 numbers required'),
      city: yup
        .string()
        .matches(/^(|[A-å0-9 ]{2,50})$/, 'Between 2-50 characters'),
    }),
  })
  .required();

type State = ICompanyViewModel;

const initialState: ICompanyViewModel = {
  id: null,
  organizationNumber: '',
  name: '',
  mainAddress: {} as IAddressViewModel,
  deliveryAddress: {} as IAddressViewModel,
  invoiceAddress: {} as IAddressViewModel,
  phone: '+47',
  email: '',
  companyType: 0,
  primaryContacts: [] as IEmployeeViewModel[],
  webpage: '',
  url: '',
  productBilling: true,
  isSupport: false,
  onHold: false,
  onHoldNote: '',
  workRole: {} as IWorkRoleViewModel,
  workRoles: [] as IWorkRoleViewModel[],
  fixedHourlyRate: 0,
  priority: 2,
  fileModel: {} as IFileModel,
};
interface OHjsxProps {
  onHold: boolean;
  dispatch: React.Dispatch<Action>;
}

type Action =
  | { type: 'UPDATE_FIELD'; field: keyof ICompanyViewModel; value: any }
  | {
      type: 'UPDATE_ADDRESS_FIELD';
      addressType: 'mainAddress' | 'deliveryAddress' | 'invoiceAddress';
      field: keyof IAddressViewModel;
      value: any;
    }
  | { type: 'UPDATE_COMPANY_TYPE'; value: number }
  | { type: 'CHANGE_WORK_ROLE'; payload: IWorkRoleViewModel }
  | { type: 'CHANGE_PRIORITY'; payload: number }
  | { type: 'TOGGLE_ON_HOLD'; payload: boolean };

function stateReducer(
  state: ICompanyViewModel,
  action: Action
): ICompanyViewModel {
  switch (action.type) {
    case 'UPDATE_FIELD':
      if (action.field === 'phone') {
        return {
          ...state,
          [action.field]: formatPhoneNumber(action.value),
        };
      }
      return { ...state, [action.field]: action.value };

    case 'UPDATE_ADDRESS_FIELD':
      return {
        ...state,
        [action.addressType]: {
          ...state[action.addressType],
          [action.field]: action.value,
        },
      };
    case 'UPDATE_COMPANY_TYPE':
      return {
        ...state,
        companyType: action.value,
      };
    case 'CHANGE_WORK_ROLE':
      return {
        ...state,
        workRole: action.payload,
      };
    case 'CHANGE_PRIORITY':
      return {
        ...state,
        priority: action.payload,
      };
    case 'TOGGLE_ON_HOLD':
      //  console.log('TOGGLE_ON_HOLD', action.payload);
      return {
        ...state,
        onHold: action.payload,
      };
    default:
      return state;
  }
}

type Props = {
  company: ICompanyViewModel;
};

export const CompanyForm = ({ company }: Props) => {
  /* INITIALIZATIONS */
  const { companyId } = useParams<string>();
  const [owner] = useAtom(ownerAtom);
  const { height } = useWindowsDimension();
  
  // STATES
  const [state, dispatch] = useReducer(stateReducer, {
    ...initialState, // Start with the initial state defined above
    ...company, // Merge in the company data from props
  });

  const [workRoles, setWorkRoles] = useState<IWorkRoleViewModel[]>([]);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [base64String, setBase64String] = useState('') as any;
  const [image, setImage] = useState(null) as any;
  // API CALLS
  let isEnabled1 = owner ? true : false;
  const { data: workRoleData } = useGet(
    rq.workRoles(owner.id, isEnabled1),
    isEnabled1
  );
  let isEnabled = companyId !== 'new' ? true : false;
  const postCompany = usePost(
    rqPost.company(companyId || '', String(state.companyType), isEnabled),
    isEnabled
  );
  const putCompany = usePut(
    rqPost.company(companyId || '', String(state.companyType), isEnabled),
    isEnabled
  );
  const delCompany = useDel(
    rqDel.company(companyId || '', String(state.companyType), isEnabled),
    isEnabled
  );

  // HANDLERS
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // XXX true need to be changed to isDirty
    if (true) {
      let _company = _.cloneDeep(state);
      console.log('onsubmit', state, _company);

      // remove unused fields
      delete _company.primaryContacts;
      delete _company.workRoles;
      delete _company.url;

      _company.workRoleId = _company.workRole?.id || null;
      delete _company.workRole;
      _company.priority = state.priority;
      _company.fixedHourlyRate = +_company.fixedHourlyRate;

      if (image) {
        console.log("there's an image");
        _company.fileModel = ConvertBase64StringToFileModel(
          base64String,
          image.name
        );
        delete _company.image;
      } else {
        delete _company.fileModel;
      }
      // if (company.onHoldNote !== undefined) {
      //   _company.onHoldNote = company.onHoldNote;
      // } else {
      //   _company.onHoldNote = '';
      // }

      if (_company?.id === 'new') {
        delete _company.id;
      }

      if (
        _company.invoiceAddress.street === '' ||
        _company.invoiceAddress.postalCode === '' ||
        _company.invoiceAddress.area === ''
      ) {
        delete _company.invoiceAddress;
      }

      if (
        _company.deliveryAddress.street === '' ||
        _company.deliveryAddress.postalCode === '' ||
        _company.deliveryAddress.area === ''
      ) {
        delete _company.deliveryAddress;
      }

      _company.productBilling = true;
      _company.SupportEmail = _company.email;

      // check if it is a new company
      if (!_company?.id) {
        // post new company
        console.log('ONSUBMIT _company : ', _company);
        postCompany.mutate(_company, {
          onSuccess: (res: any) => {},
        });
      } else {
        // update existing company
        _company.active = true;
        console.log('ONSUBMIT PUT : ', _company);
        putCompany.mutate(_company, {
          onSuccess: (res: any) => {},
        });
      }
    }
  };
  const handlePrioritySubmit = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    priority: number
  ) => {
    dispatch({ type: 'CHANGE_PRIORITY', payload: priority });
  };
  const handleConfirm = async (e: any) => {
    e.preventDefault();
    const del = await delCompany.mutateAsync();
    setDeleteConfirm(false);
    //console.log('del', del);
  };
  const handleChangeWorkRole = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    selectedObject: any,
    objectType: string
  ) => {
    switch (objectType) {
      case 'workRoles':
        let object = selectedObject as IWorkRoleViewModel;
        dispatch({ type: 'CHANGE_WORK_ROLE', payload: object });
        break;
      default:
        break;
    }
  };

  const setFormDirty = () => {
    console.log('setFormDirty');
  };

  // USSEFFECTS
  useEffect(() => {
    if (workRoleData) {
      // console.log('workRoleData', workRoleData);
      setWorkRoles(workRoleData.workRoles as IWorkRoleViewModel[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workRoleData]);

  const GoogleJSX = memo(() => {
    return (
      <></>

      /*  <TravelExploreRoundedIcon
        sx={{ fontSize: 40 }}
        onClick={(e) =>
          openInNewTab(
            e,
            'https://www.google.com/maps/place/' +
              state.mainAddress?.street +
              ',' +
              state.mainAddress?.postalCode +
              state.mainAddress?.city +
              '/'
          )
        }
      /> */
    );
  });
  const priorityJSX = memo(() => {
    return (
      <>
        <ButtonGroup
          variant='contained'
          aria-label='outlined primary button group'
          size='small'
          sx={{ m: 2 }}
        >
          <Button
            key='LOW'
            sx={{
              width: 100,
              backgroundColor:
                state.priority === 1
                  ? GetPriorityColor(state.priority)
                  : 'primary.main',
            }}
            onClick={(e) => {
              handlePrioritySubmit(e, 1);
            }}
          >
            Low
          </Button>
          <Button
            key='NORMAL'
            sx={{
              width: 100,
              backgroundColor:
                state.priority === 2
                  ? GetPriorityColor(state.priority)
                  : 'primary.main',
            }}
            onClick={(e) => {
              handlePrioritySubmit(e, 2);
            }}
          >
            Normal
          </Button>
          <Button
            key='HIGH'
            sx={{
              width: 100,
              backgroundColor:
                state.priority === 3
                  ? GetPriorityColor(state.priority)
                  : 'primary.main',
            }}
            onClick={(e) => {
              handlePrioritySubmit(e, 3);
            }}
          >
            High
          </Button>
        </ButtonGroup>
      </>
    );
  });
  const OHjsx: React.FC<OHjsxProps> = ({ onHold, dispatch }) => {
    const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({ type: 'TOGGLE_ON_HOLD', payload: e.target.checked });
    };

    return (
      <Grid item sx={{ m: 0, p: 0 }}>
        <Stack direction='row'>
          <Typography
            sx={{
              m: 0,
              p: 2.2,
              pr: 0,
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              fontSize: 14,
              color: 'primary.text',
            }}
          >
            {onHold ? 'YES' : 'NO'}
          </Typography>

          <Switch
            color={onHold ? 'secondary' : 'primary'}
            sx={{
              mt: 1,
              mr: 1,
              '& .MuiSwitch-thumb': {
                backgroundColor: onHold ? 'secondary.light' : 'primary.text', // Use any color you like for 'onHold' state
              },
              '& .MuiSwitch-track': {
                backgroundColor: onHold
                  ? 'rgba(0, 0, 0, 0.7)'
                  : 'rgba(0, 0, 255, 0.3)', // This gives a lighter red shade for the track. Adjust as needed.
              },
            }}
            checked={onHold}
            onChange={handleToggle}
            name='onHold'
          />
        </Stack>
      </Grid>
    );
  };

  return (
    <Grid
      container
      sx={{
        m: 0,
        p: 0,
      }}
    >
      <Grid item xs={12}>
        <SearchFilter
          Icon={FeedRoundedIcon}
          Header='Basic Information'
          filterValue={null}
          handleFilterChange={null}
          handleSearch={null}
          handleNew={null}
          selectList={null}
        />
      </Grid>

      <List
        sx={{
          width: '100%',
          height: Math.ceil(height - 286),
          p: 2,
          overflow: 'auto',
          backgroundColor: 'primary.main',
        }}
      >
        <Stack direction='row'>
          <Grid item sx={{ width: 384, }}>
            <Stack direction='column'>
              <Typography
                sx={{
                  m: 0,
                  p: 2,
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 'normal ',
                  fontSize: 14,
                  color: 'primary.text',
                }}
              >
                Logo
              </Typography>

              {company && (
              <ImageUploader
              width={256}
              height={128}
              setImage={setImage}
              previewImage={state.url}
              setBase64String={(str) => {
                if (typeof str === 'string' || str === null) {
                  setBase64String(str);
                }
              }}
              setFormDirty={setFormDirty}
              base64String={base64String}
            />
             
              )}
              <Grid item xs={12} sx={{ m: 0, p: 0 }}>
                <Typography
                  sx={{
                    m: 0,
                    p: 2,
                    textAlign: 'left',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: 'normal',
                    fontSize: 14,
                    color: 'primary.text',
                  }}
                >
                  Work Role
                </Typography>
              </Grid>

              {state.workRole && state.workRole.id ? (
                <Grid
                  container
                  display={'flex'}
                  flexDirection={'row'}
                  sx={{
                    width: '100%',
                    height: 60,
                    borderRadius: 2,
                    backgroundColor: 'primary.main',
                  }}
                >
                  <Grid item sx={{ m: 0, p: 0, flexGrow: 1 }}>
                    <Typography>{state.workRole.title}</Typography>
                    <Typography>{state.workRole.onSiteHourlyRate}</Typography>
                  </Grid>
                  <Grid item sx={{ m: 0, p: 0 }}>
                    <Stack direction='row'>
                      <Box
                        sx={{
                          mr: 1,
                          width: 30,
                          height: 30,
                          backgroundColor: 'primary.light',
                          display: 'flex', // <-- Set this to flex
                          justifyContent: 'center', // <-- This centers children horizontally
                          alignItems: 'center', // <-- This centers children vertically
                          borderRadius: 2,
                          boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
                          '&:hover': {
                            filter: 'brightness(120%)',
                          },
                        }}
                      >
                        <IconButton
                          sx={{ m: 0, p: 0 }}
                          onClick={(e) => {
                            dispatch({
                              type: 'CHANGE_WORK_ROLE',
                              payload: {} as IWorkRoleViewModel,
                            });
                          }}
                        >
                          <DeleteForeverRoundedIcon />
                        </IconButton>
                      </Box>
                      <ObjectDrawer
                        buttonContext={'Select WorkRole'}
                        buttonIcon={<ChangeCircleIcon />}
                        objectList={!!workRoles ? workRoles : []}
                        objectType={'workRoles'}
                        handleChange={handleChangeWorkRole}
                        buttonHeight={30}
                        buttonWidth={30}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sx={{ m: 0, p: 0 }}>
                    <Divider sx={{ backgroundColor: 'primary.text' }} />
                  </Grid>
                </Grid>
              ) : (
                workRoles.length > 0 &&
                (
                  <ObjectDrawer
                    buttonContext={'Select WorkRole'}
                    buttonIcon={null}
                    objectList={!!workRoles ? workRoles : []}
                    objectType={'workRoles'}
                    handleChange={handleChangeWorkRole}
                    buttonHeight={60}
                    buttonWidth={'100%'}
                  />
                )
              )}
            </Stack>
          </Grid>
          <Grid
            container
            sx={{
              p: 3,
            }}
            rowSpacing={1}
            columnSpacing={{ xs: 2 }}
          >
            <Grid item xs={8}>
              <TextField
               sx = {{'& .Mui-focused':{
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
              }, }}
                label='Name'
                value={state.name}
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_FIELD',
                    field: 'name',
                    value: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
               sx = {{'& .Mui-focused':{
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
              }, }}
                label='Org. No.'
                value={state.organizationNumber}
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_FIELD',
                    field: 'organizationNumber',
                    value: e.target.value.replace(/\s+/g, ''),
                  })
                }
              />
            </Grid>

            <Grid item xs={7}>
              <TextField
               sx = {{'& .Mui-focused':{
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
              }, }}
                label='E-Mail'
                value={state.email}
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_FIELD',
                    field: 'email',
                    value: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={5}>
              <MuiTelInput
                sx = {{'& .Mui-focused':{
                  backgroundColor: 'rgba(0, 0, 0, 0.2)',
                }, }}
                continents={['EU']}
                preferredCountries={['NO', 'SE', 'DK', 'DE', 'GB']}
                defaultCountry='NO'
                forceCallingCode={true}
                label='Phone'
                variant='standard'
                value={state.phone}
                onChange={(value: string, info: MuiTelInputInfo) => {
                  dispatch({
                    type: 'UPDATE_FIELD',
                    field: 'phone',
                    value: value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={8}>
              <TextField
               sx = {{'& .Mui-focused':{
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
              }, }}
                label='Webpage'
                value={state.webpage}
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_FIELD',
                    field: 'webpage',
                    value: e.target.value,
                  })
                }
              />
            </Grid>
            {company.companyType !== 5 && (
              <Grid item xs={4}>
                <Typography
                  sx={{
                    m: 0,
                    p: 0,
                    textAlign: 'left',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: 'normal',
                    fontSize: 14,
                    color: 'primary.text',
                  }}
                >
                  Company Type
                </Typography>
                <Select
                  sx={{ width: '100%', p: 0, mt: -0.5 }}
                  labelId='companyType'
                  id='companyType'
                  variant='standard'
                  value={state.companyType}
                  label='companyType'
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_COMPANY_TYPE',
                      value: e.target.value as number,
                    })
                  }
                >
                  <MenuItem value={0}>Prospect</MenuItem>
                  <MenuItem value={1}>FormerClient</MenuItem>
                  <MenuItem value={2}>Client</MenuItem>
                  <MenuItem value={3}>Lost</MenuItem>
                  <MenuItem value={4}>Vendor</MenuItem>
                </Select>
              </Grid>
            )}
            <Grid item xs={6}>
              <TextField
                sx = {{'& .Mui-focused':{
                  backgroundColor: 'rgba(0, 0, 0, 0.2)',
                }, }}
                label='Fixed Hourly Rate'
                value={state.fixedHourlyRate}
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_FIELD',
                    field: 'fixedHourlyRate',
                    value: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Stack>

        <Grid item xs={12} sx={{ mb: 2 }}>
          <FormBoxElevated
            Icon={ArtTrackRoundedIcon}
            title='priority'
            FormJSX={priorityJSX}
          />
        </Grid>

        {/*     Main Address */}

        <Grid
          item
          xs={12}
          sx={{
            mb: 2,
            border: 0,
            borderRadius: 2,
            boxShadow:
              '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
          }}
        >
          <Accordion
            disableGutters
            sx={{
              backgroundColor: 'transparent',
              border: 0,
            }}
          >
            <FormAccordionSummary Icon={HomeRoundedIcon} title='Main Address' />
            <AccordionDetails>
              <TextField
                sx={{ width: '50%', p: 1, m: 0 }}
                label='Street'
                value={state.mainAddress.street || ''}
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_ADDRESS_FIELD',
                    addressType: 'mainAddress',
                    field: 'street',
                    value: e.target.value,
                  })
                }
              />
              <TextField
                sx={{ width: '20%', p: 1, m: 0 }}
                label='Postal Code'
                value={state.mainAddress.postalCode || ''}
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_ADDRESS_FIELD',
                    addressType: 'mainAddress',
                    field: 'postalCode',
                    value: e.target.value,
                  })
                }
              />
              <TextField
                sx={{ width: '30%', p: 1, m: 0 }}
                label='Area'
                value={state.mainAddress.area || ''}
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_ADDRESS_FIELD',
                    addressType: 'mainAddress',
                    field: 'area',
                    value: e.target.value,
                  })
                }
              />
              <TextField
                sx={{ width: '40%', p: 1, m: 0 }}
                label='City'
                value={state.mainAddress.city || ''}
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_ADDRESS_FIELD',
                    addressType: 'mainAddress',
                    field: 'city',
                    value: e.target.value,
                  })
                }
              />

              <Select
                sx={{ width: '30%', p: 0, mt: 3 }}
                labelId='country'
                id='country'
                variant='standard'
                value={state.mainAddress.country || 0}
                label='country'
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_ADDRESS_FIELD',
                    addressType: 'mainAddress',
                    field: 'country',
                    value: e.target.value,
                  })
                }
              >
                <MenuItem value={0}>Norway</MenuItem>
                <MenuItem value={1}>Sweden</MenuItem>
                <MenuItem value={2}>Denmark</MenuItem>
                <MenuItem value={3}>Germany</MenuItem>
                <MenuItem value={4}>England</MenuItem>
              </Select>
              <TextField
                sx={{ width: '30%', p: 1, m: 0 }}
                label='PostBox'
                value={state.mainAddress.postBox || ''} 
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_ADDRESS_FIELD',
                    addressType: 'mainAddress',
                    field: 'postBox',
                    value: e.target.value,
                  })
                }
              />
            </AccordionDetails>
          </Accordion>
        </Grid>

        {/*     Delivery Address */}

        <Grid
          item
          xs={12}
          sx={{
            mb: 2,
            border: 0,
            borderRadius: 2,
            boxShadow:
              '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
          }}
        >
          <Accordion
            disableGutters
            sx={{
              backgroundColor: 'transparent',
              border: 0,
            }}
          >
            <FormAccordionSummary
              Icon={HomeRoundedIcon}
              title='Delivery Address'
            />
            <AccordionDetails>
              <TextField
                sx={{ width: '50%', p: 1, m: 0 }}
                label='Street'
                value={state.deliveryAddress.street || ''}
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_ADDRESS_FIELD',
                    addressType: 'deliveryAddress',
                    field: 'street',
                    value: e.target.value,
                  })
                }
              />
              <TextField
                sx={{ width: '20%', p: 1, m: 0 }}
                label='Postal Code'
                value={state.deliveryAddress.postalCode || ''}
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_ADDRESS_FIELD',
                    addressType: 'deliveryAddress',
                    field: 'postalCode',
                    value: e.target.value,
                  })
                }
              />
              <TextField
                sx={{ width: '30%', p: 1, m: 0 }}
                label='Area'
                value={state.deliveryAddress.area || ''}
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_ADDRESS_FIELD',
                    addressType: 'deliveryAddress',
                    field: 'area',
                    value: e.target.value,
                  })
                }
              />
              <TextField
                sx={{ width: '40%', p: 1, m: 0 }}
                label='City'
                value={state.deliveryAddress.city || ''}
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_ADDRESS_FIELD',
                    addressType: 'deliveryAddress',
                    field: 'city',
                    value: e.target.value,
                  })
                }
              />

              <Select
                sx={{ width: '30%', p: 0, mt: 3 }}
                labelId='country'
                id='country'
                variant='standard'
                value={state.deliveryAddress.country || 0}
                label='country'
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_ADDRESS_FIELD',
                    addressType: 'deliveryAddress',
                    field: 'country',
                    value: e.target.value,
                  })
                }
              >
                <MenuItem value={0}>Norway</MenuItem>
                <MenuItem value={1}>Sweden</MenuItem>
                <MenuItem value={2}>Denmark</MenuItem>
                <MenuItem value={3}>Germany</MenuItem>
                <MenuItem value={4}>England</MenuItem>
              </Select>
              <TextField
                sx={{ width: '30%', p: 1, m: 0 }}
                label='PostBox'
                value={state.deliveryAddress.postBox || ''}
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_ADDRESS_FIELD',
                    addressType: 'deliveryAddress',
                    field: 'postBox',
                    value: e.target.value,
                  })
                }
              />
            </AccordionDetails>
          </Accordion>
        </Grid>

        {/*     Invoice Address */}

        <Grid
          item
          xs={12}
          sx={{
            mb: 2,
            border: 0,
            borderRadius: 2,
            boxShadow:
              '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
          }}
        >
          <Accordion
            disableGutters
            sx={{
              backgroundColor: 'transparent',
              border: 0,
            }}
          >
            <FormAccordionSummary
              Icon={HomeRoundedIcon}
              title='Invoice Address'
            />
            <AccordionDetails>
              <TextField
                sx={{ width: '50%', p: 1, m: 0 }}
                label='Street'
                value={state.invoiceAddress.street || ''}
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_ADDRESS_FIELD',
                    addressType: 'invoiceAddress',
                    field: 'street',
                    value: e.target.value,
                  })
                }
              />
              <TextField
                sx={{ width: '20%', p: 1, m: 0 }}
                label='Postal Code'
                value={state.invoiceAddress.postalCode || ''}
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_ADDRESS_FIELD',
                    addressType: 'invoiceAddress',
                    field: 'postalCode',
                    value: e.target.value,
                  })
                }
              />
              <TextField
                sx={{ width: '30%', p: 1, m: 0 }}
                label='Area'
                value={state.invoiceAddress.area || ''}
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_ADDRESS_FIELD',
                    addressType: 'invoiceAddress',
                    field: 'area',
                    value: e.target.value,
                  })
                }
              />
              <TextField
                sx={{ width: '40%', p: 1, m: 0 }}
                label='City'
                value={state.invoiceAddress.city || ''}
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_ADDRESS_FIELD',
                    addressType: 'invoiceAddress',
                    field: 'city',
                    value: e.target.value,
                  })
                }
              />

              <Select
                sx={{ width: '30%', p: 0, mt: 3 }}
                labelId='country'
                id='country'
                variant='standard'
                value={state.invoiceAddress.country || 0}
                label='country'
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_ADDRESS_FIELD',
                    addressType: 'invoiceAddress',
                    field: 'country',
                    value: e.target.value,
                  })
                }
              >
                <MenuItem value={0}>Norway</MenuItem>
                <MenuItem value={1}>Sweden</MenuItem>
                <MenuItem value={2}>Denmark</MenuItem>
                <MenuItem value={3}>Germany</MenuItem>
                <MenuItem value={4}>England</MenuItem>
              </Select>
              <TextField
                sx={{ width: '30%', p: 1, m: 0 }}
                label='PostBox'
                value={state.invoiceAddress.postBox || ''}
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_ADDRESS_FIELD',
                    addressType: 'invoiceAddress',
                    field: 'postBox',
                    value: e.target.value,
                  })
                }
              />
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12} sx={{ mb: 2 }}>
          <FormBoxElevated
            Icon={ReportProblemRoundedIcon}
            title='On Hold'
            FormJSX={() => <OHjsx onHold={state.onHold} dispatch={dispatch} />} // Pass it as a component
          />
        </Grid>
        {state.onHold && (
          <TextField
            name='onHoldeNote'
            className='subvariant-hovered'
            label='On Holde Note'
            variant='filled'
            multiline={true}
            onChange={(e) =>
              dispatch({
                type: 'UPDATE_FIELD',
                field: 'onHoldNote',
                value: e.target.value,
              })
            }
          />
        )}
      </List>

      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Paper
            variant='elevation3'
            sx={{
              width: '100%',
              p: 0,
              height: 64,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              backgroundColor: 'primary.main',
            }}
          >
            <Grid
              container
              sx={{
                m: 0,
                p: 2,
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                flexGrow: 1,
              }}
            >
              <Grid item>
                <Button
                  variant='contained'
                  onClick={() => setDeleteConfirm(!deleteConfirm)}
                >
                  {delCompany.isLoading
                    ? 'Deleting Company...'
                    : 'Delete Company'}
                </Button>
                {deleteConfirm && (
                  <Button
                    variant='contained'
                    color='secondary'
                    onClick={handleConfirm}
                  >
                    Confirm Delete
                  </Button>
                )}
              </Grid>

              <Grid item>
                {companyId !== 'new' && (
                  <Button type='submit' variant='contained'>
                    {putCompany.isLoading
                      ? 'Updating Company...'
                      : 'Update Company'}
                  </Button>
                )}
                {companyId === 'new' && (
                  <Button type='submit' variant='contained'>
                    {postCompany.isLoading
                      ? 'Creating Company...'
                      : 'Create Company'}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Paper>
        </form>
      </Grid>
    </Grid>
  );
};
