import { Grid, List, Paper } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import useWindowsDimension from 'functions/useWindowsDimension';
import { SearchFilter } from 'components/ui/SearchFilter';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import { rq } from 'http/apiRoutes';
import { useGet } from 'http/useInnovit';
import { useEffect, useState } from 'react';
import { SingleTicketView } from 'components/ui/tickets/SingleTicketView';

export const CompanyTickets = () => {
  /* INITIALIZATION */
  const { width, height } = useWindowsDimension();
  const { companyId } = useParams() as any;
  const navigate = useNavigate();
  const [tickets, setTickets] = useState<any[]>([]);

  // API
  let isEnabled = companyId ? true : false;
  const { data: ticketsData } = useGet(
    rq.CompanyTickets(companyId, isEnabled),
    isEnabled
  );
  console.log(ticketsData);
  // FUNCTIONS

  // HANDLES

  // USE EFFECTS
  useEffect(() => {
    if (ticketsData) {
      setTickets(ticketsData.tickets);
    }
  }, [ticketsData]);

  return (
    <Grid container>
      <Grid
        item
        xs={6}
        sx={{
          display: 'flex',
          flexDirection: 'row',

          borderRadius: 3,
          pr: 1,
        }}
      >
        <Grid item xs={12} sx={{ pr: 1, m: 0 }}>
   
            <SearchFilter
              Icon={SupportAgentRoundedIcon}
              Header='Company Tickets'
              //filterValue={filterType}
              //handleFilterChange={handleFilterChange}
              //handleSearch={handleSearch}
              //handleNew={handleNew}
              //selectList={companyTypes}
            />
       

          <List
            sx={{
              height: Math.ceil(height - 286),
              width: '100%',
              mb: 0,
              p: 2,
              backgroundColor: 'primary.back',
              overflow: 'auto',
            }}
          >
            {tickets?.length === 0 && <div>No data</div>}
            {tickets?.map((ticket: any, index: any) => (
              <SingleTicketView
                key={index}
                index={index}
                ticket={ticket}
                //setCompany={setCompany}
              />
            ))}
          </List>
          <Paper
            variant='elevation3'
            sx={{
              p: 2,
              height: 64,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          ></Paper>
        </Grid>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          display: 'flex',
          flexDirection: 'row',

          borderRadius: 3,
          pl: 1,
        }}
      >
        <Grid
          container
          sx={{
            height: `${height - 158}px`, // assuming height is defined
            backgroundColor: 'primary.main',
            borderRadius: 3,
            p: 1,
          }}
        >
          selected ticket view
        </Grid>
      </Grid>
    </Grid>
  );
};
