import { useEffect, useReducer, useState } from 'react';
import { useGet, usePost } from 'http/useInnovit';
import { rq, rqPost } from 'http/apiRoutes';
import GetPriorityColor from 'functions/GetPriorityColor';

import {
  Avatar,
  ButtonGroup,
  Grid,
  List,
  Box,
  Paper,
  Stack,
  Typography,
  Select,
  FormControl,
  IconButton,
  SelectChangeEvent,
  Button,
  TextField,
  MenuItem,
} from '@mui/material';

import { ticketType } from 'data/enum';

import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import PersonRemoveRoundedIcon from '@mui/icons-material/PersonRemoveRounded';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import SourceRoundedIcon from '@mui/icons-material/SourceRounded';
import ArtTrackRoundedIcon from '@mui/icons-material/ArtTrackRounded';

import useWindowsDimension from 'functions/useWindowsDimension';

import { SingleEmployeeView } from 'components/ui/SingleEmployeeView';
import { SearchFilter } from 'components/ui/SearchFilter';

//@ts-ignore"
import { EmployeeDrawer } from 'pages/Companies/EmployeeDrawer';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';

import { memo } from 'react';
import { CompanyDrawer } from 'pages/Companies/CompanyDrawer';
import { useAtom } from 'jotai';
import ownerAtom from 'data/atoms/ownerAtom';
import { ObjectDrawer } from 'pages/Companies/ObjectDrawer';
import { IEmployeeViewModel, ITicketAddViewModel } from './TicketConfig';
import { ICompanyViewModel } from 'pages/Companies/CompanyConfig';
import { IMobileAnswersViewModel } from 'data/globalTypeConfig';
import { SingleCompanyView } from 'components/ui/SingleCompanyView';
import { ICompanyProductViewModel } from 'pages/Companies/ICompanyProductConfig';

let _ = require('lodash');

function sleep(ms: any) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const ResponsibleGfx = memo((props: any) => {
  return (
    <IconButton
      sx={{ fontSize: 26, p: 0, m: 0 }}
      onClick={(e) => props.handleRemoveResponsible(e)}
    >
      <DeleteForeverIcon />
    </IconButton>
  );
});

const ContactGfx = memo((props: any) => {
  return (
    <IconButton
      sx={{ fontSize: 26, p: 0, m: 0 }}
      onClick={(e) => props.handleRemoveContact(e)}
    >
      <DeleteForeverIcon />
    </IconButton>
  );
});

const TechnicianGfx = memo((props: any) => {
  return (
    <IconButton
      sx={{ fontSize: 26, p: 0, m: 0 }}
      onClick={(e) => props.handleRemoveTechnician(e, props.technician)}
    >
      <DeleteForeverIcon />
    </IconButton>
  );
});

const Header = (props: any) => {
  const { icon: IH, action: AH, headerText: HT } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        p: 1,
      }}
    >
      <Box sx={{ pt: 1 }}>
        <IH sx={{ fontSize: 40 }} />
      </Box>
      <Box sx={{ pl: 2, pt: 2, flexGrow: 1 }}>
        <Typography
          noWrap
          sx={{
            textTransform: 'capitalize',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontWeight: 'bold',
            fontSize: 18,
            letterSpacing: 4,
          }}
        >
          {HT} {props.children}
        </Typography>
      </Box>
      <Box sx={{}}>{AH && <AH sx={{ fontSize: 40 }} />}</Box>
    </Box>
  );
};

const initialState: any = {
  ticket: {
    ticketNo: null,
    board: 1,
    priority: 2,
    status: 0,
    companyId: '',
    contactId: '',
    responsibleId: null,
    techniciansId: [],
    autoAnswerSelection: '"601f90d5-6ab0-4fbc-8d0c-cc01cc000005"',
    comment: '',
    productBilling:true,
    billing: 0,
    title: '',
  } as ITicketAddViewModel,
  company: {} as ICompanyViewModel,
  contact: {} as IEmployeeViewModel,
  responsible: {} as IEmployeeViewModel,
  technicians: [] as IEmployeeViewModel[],
  companyProducts: [] as ICompanyProductViewModel[],
  mobileAnswers: [] as IMobileAnswersViewModel[],
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'SET_AUTO_ANSWERS': {
      return {
        ...state,
        mobileAnswers: action.payload,
      };
    }
    case 'SET_AUTO_ANSWER': {
      return {
        ...state,
        ticket: {
          ...state.ticket,
          autoAnswerSelection: action.payload,
        },
      };
    }
    case 'SET_BOARD': {
      return {
        ...state,
        ticket: {
          ...state.ticket,
          board: action.payload,
        },
      };
    }

    case 'EDIT_COMMENT': {
      return {
        ...state,
        ticket: {
          ...state.ticket,
          comment: action.payload,
        },
      };
    }
    case 'SET_COMPANY': {
      let companyId = action.payload.id;
      return {
        ...state,
        company: action.payload,
        ticket: {
          ...state.ticket,
          companyId: companyId,
        },
      };
    }
    case 'SET_COMPANY_PRODUCTS': {
      return {
        ...state,
        companyProducts: action.companyProducts,
      };
    }
    case 'SET_CONTACT': {
      let contactId = action.payload.id;
      return {
        ...state,
        contact: action.payload,
        ticket: {
          ...state.ticket,
          contactId: contactId,
        },
      };
    }
    case 'SET_RESPONSIBLE': {
      let responsibleId = action.payload.id;
      return {
        ...state,
        responsible: action.payload,
        ticket: {
          ...state.ticket,
          responsibleId: responsibleId,
        },
      };
    }
    case 'SET_TECHNICIANS': {
      return {
        ...state,
        technicians: [...state.technicians, action.technicians],
      };
    }
    case 'SET_PRIORITY': {
      return {
        ...state,
        ticket: {
          ...state.ticket,
          priority: action.payload,
        },
      };
    }
    case 'SET_COMMENT': {
      return {
        ...state,
        ticket: {
          ...state.ticket,
          comment: action.payload,
        },
      };
    }
    case 'REMOVE_RESPONSIBLE': {
      return {
        ...state,
        responsible: {} as IEmployeeViewModel,
        ticket: {
          ...state.ticket,
          responsibleId: '',
        },
      };
    }
    case 'REMOVE_CONTACT': {
      console.log('remove contact', action.contact);
      return {
        ...state,
        contactId: null,
        contact: null,
      };
    }
    case 'REMOVE_TECHNICIAN': {
      const updatedTechnicians = state.technicians.filter(
        (tech: any) => tech.id !== action.technician.id
      );

      // Return the new state with the updated technicians array
      return { ...state, technicians: updatedTechnicians };
    }
    default:
      return initialState;
  }
};

export const NewTicket = () => {
  /* INITIALIZE */
  // let navigate = useNavigate();
  const { height } = useWindowsDimension();
  const [owner, setOwner] = useAtom(ownerAtom);
  const [state, dispatch] = useReducer(reducer, initialState);

  // states
  const [options, setOptions] = useState<any>([]);
  // console.log("dispatch", state.company?.id);
  const loading = options.length === 0;

  // api
  const { data: mobileAutoAnswersData } = useGet(
    rq.mobileAutoAnswers('CCC00CCC-6C0C-4C0C-8C0C-CCC001CCC001', true),
    true
  );
  const { data: dCompanies } = useGet(rq.companies('2', true), true);

  let isOwner = owner.id ? true : false;
  const { data: ccs } = useGet(rq.employees(owner.id, isOwner), isOwner);

  let isProduct =
    state.ticket.companyId && state.ticket.contactId ? true : false;
  const { data: employeeData } = useGet(
    rq.employeeWithProducts(
      state.ticket.companyId,
      state.ticket.contactId,
      isProduct
    ),
    isProduct
  );
  const postTicket = usePost(
    rqPost.ticket(state.ticket.companyId, 1, true),
    true
  );

  //console.log('state', state);

  /* FUNCTIONS */

  // HANDLES
  const handleChangeCompanyProduct = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    selectedObject: any,
    objectType: string
  ) => {
    switch (objectType) {
      case 'workRoles':
        /*    let object = selectedObject as IWorkRoleViewModel;
        dispatch({ type: 'CHANGE_WORK_ROLE', payload: object }); */
        break;
      default:
        break;
    }
  };
  const handlePriorityChange = (e: any, priority: any) => {
    e.preventDefault();
    dispatch({ type: 'SET_PRIORITY', payload: priority });
  };

  const handleCompanyChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    company: any
  ) => {
    dispatch({ type: 'SET_COMPANY', payload: company });
    let priority = company.priority;
    dispatch({ type: 'SET_PRIORITY', payload: priority });
  };
  const handleContactChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    contact: any
  ) => {
    dispatch({ type: 'SET_CONTACT', payload: contact });
  };
  const handleResponsibleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    responsible: any
  ) => {
    console.log(' handleResponsibleChange', responsible);
    dispatch({ type: 'SET_RESPONSIBLE', payload: responsible });
  };
  const handleTechniciansChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    technicians: any
  ) => {
    dispatch({ type: 'SET_TECHNICIANS', payload: technicians });
  };

  const handleRemoveResponsible = (
    event: React.ChangeEvent<HTMLInputElement>,
    responsible: any
  ) => {
    dispatch({ type: 'REMOVE_RESPONSIBLE', payload: responsible });
  };
  const handleRemoveContact = (
    event: React.ChangeEvent<HTMLInputElement>,
    contact: any
  ) => {
    dispatch({ type: 'REMOVE_CONTACT', payload: contact });
  };
  const handleRemoveTechnician = (
    event: React.ChangeEvent<HTMLInputElement>,
    technician: any
  ) => {
    dispatch({ type: 'REMOVE_TECHNICIAN', payload: technician });
  };
  const onSubmit = () => {
    let _ticket = _.cloneDeep(state.ticket);
    console.log('state submit', _ticket);
    postTicket.mutate(_ticket, {
      onSuccess: (state: any) => {
        // navigate(`/tickets/${data.id}`);
      },
    });
  };
  const handleBlur = (e: any) => {
    e.preventDefault();
    console.log('blur', e.target.value);
    let comment = e.target.value;
    dispatch({ type: 'EDIT_COMMENT', payload: comment });
  };
  // USE EFFECTS
  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(3e3); // For demo purposes.

      if (active) {
        setOptions([...ticketType]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);
  useEffect(() => {
    if (employeeData) {
      console.log('employeeData', employeeData);
      dispatch({
        type: 'SET_COMPANY_PRODUCTS',
        companyProducts: employeeData.companyProducts,
      });
    }
  }, [employeeData]);
  useEffect(() => {
    if (mobileAutoAnswersData) {
      console.log('mobileAutoAnswersData', mobileAutoAnswersData);
      let autoAnswers = mobileAutoAnswersData.mobileAutoAnswers;
      dispatch({ type: 'SET_AUTO_ANSWERS', payload: autoAnswers });
      dispatch({ type: 'SET_AUTO_ANSWER', payload: autoAnswers[5].id });
    }
  }, [mobileAutoAnswersData]);
  // RENDER
  return (
    <>
      <Grid container sx={{ flexGrow: 1, m: 0 }}>
        <Grid
          item
          xs={12}
          sx={{ mb: 2, borderRadius: 2, backgroundColor: 'primary.main' }}
        >
          <Header icon={SourceRoundedIcon} headerText='New Ticket' />
        </Grid>
        <Grid item xs={6} sx={{}}>
          <Grid item xs={12} sx={{}}>
            <SearchFilter
              Icon={GroupsRoundedIcon}
              Header='Company'
              gfx={
                <CompanyDrawer
                  buttonIcon={<ChangeCircleIcon sx={{ fontSize: 40 }} />}
                  companies={!!dCompanies ? dCompanies.companies : []}
                  handleCompanySubmit={handleCompanyChange}
                />
              }
            />
          </Grid>
          <List
            sx={{
              height: 88,
              overflow: 'auto',
              backgroundColor: 'primary.back',
              width: '100%',
              pl: 1,
              pr: 1,
              pt: 1,
              m: 0,
            }}
          >
            {state?.company?.id && (
              <SingleCompanyView company={state.company} />
            )}
          </List>
          <Paper
            variant='elevation3'
            sx={{
              p: 2,
              width: '100%',
              height: 64,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              mb: 2,
            }}
          ></Paper>

          <Paper
            variant='elevation3'
            sx={{
              mb: 2,
              p: 1,
              backgroundColor: 'primary.main',
            }}
          >
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <Grid item xs={12}>
                <Stack display={'flex'} flexGrow={1} direction={'row'}>
                  <Box
                    sx={{
                      flexGrow: 1,
                    }}
                  >
                    <Header icon={ArtTrackRoundedIcon} headerText='priority' />
                  </Box>
                </Stack>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ m: 1, display: 'flex', justifyContent: 'flex-end' }}
              >
                <ButtonGroup
                  variant='contained'
                  aria-label='outlined primary button group'
                  size='small'
                >
                  <Button
                    key='NONE'
                    sx={{
                      width: 100,
                      backgroundColor:
                        state.ticket.priority === 0
                          ? GetPriorityColor(state.priority)
                          : 'primary.main',
                      '&:hover': {
                        backgroundColor: GetPriorityColor(0),
                      },
                    }}
                    onClick={(e) => {
                      handlePriorityChange(e, 0);
                    }}
                  >
                    None
                  </Button>
                  <Button
                    key='LOW'
                    sx={{
                      width: 100,
                      backgroundColor:
                        state.ticket.priority === 1
                          ? GetPriorityColor(state.ticket.priority)
                          : 'primary.main',
                      '&:hover': {
                        backgroundColor: GetPriorityColor(1),
                      },
                    }}
                    onClick={(e) => {
                      handlePriorityChange(e, 1);
                    }}
                  >
                    Low
                  </Button>
                  <Button
                    key='NORMAL'
                    sx={{
                      width: 100,
                      backgroundColor:
                        state.ticket.priority === 2
                          ? GetPriorityColor(state.ticket.priority)
                          : 'primary.main',
                      '&:hover': {
                        backgroundColor: GetPriorityColor(2),
                      },
                    }}
                    onClick={(e) => {
                      handlePriorityChange(e, 2);
                    }}
                  >
                    Normal
                  </Button>
                  <Button
                    key='HIGH'
                    sx={{
                      width: 100,
                      backgroundColor:
                        state.ticket.priority === 3
                          ? GetPriorityColor(state.ticket.priority)
                          : 'primary.main',
                      '&:hover': {
                        backgroundColor: GetPriorityColor(3),
                      },
                    }}
                    onClick={(e) => {
                      handlePriorityChange(e, 3);
                    }}
                  >
                    High
                  </Button>

                  <Button
                    key='CRITICAL'
                    sx={{
                      width: 100,
                      backgroundColor:
                        state.ticket.priority === 4
                          ? GetPriorityColor(state.ticket.priority)
                          : 'primary.main',
                      '&:hover': {
                        backgroundColor: GetPriorityColor(4),
                      },
                    }}
                    onClick={(e) => {
                      handlePriorityChange(e, 4);
                    }}
                  >
                    Critical
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Paper>

          <Paper
            variant='elevation3'
            sx={{
              mb: 2,
              p: 1,
              backgroundColor: 'primary.main',
            }}
          >
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <Grid item xs={8}>
                <Header icon={SourceRoundedIcon} headerText='board'></Header>
              </Grid>
              <Grid item xs={4} sx={{ pr: 2 }}>
                <Select
                  sx={{ width: '100%', p: 0, mt: -0.5 }}
                  labelId='Board'
                  id='Board'
                  variant='standard'
                  value={state.ticket.board}
                  label='Board'
                  onChange={(e) => {
                    dispatch({ type: 'SET_BOARD', payload: e.target.value });
                  }}
                >
                  <MenuItem value={0}>Sale</MenuItem>
                  <MenuItem value={1}>Support</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Paper>

          <Paper
            variant='elevation3'
            sx={{
              mb: 2,
              p: 1,
              backgroundColor: 'primary.main',
            }}
          >
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <Grid item xs={8}>
                <Header
                  icon={SourceRoundedIcon}
                  headerText='support type'
                ></Header>
              </Grid>
              <Grid item xs={4} sx={{ pr: 2 }}>
                {state.mobileAnswers.length > 0 ? (                             
                    <Select
                      sx={{ width: '100%', p: 0, mt: -0.5 }}
                     // labelId='support type'
                     // id='support type'
                      variant='standard'
                      value={state.ticket?.autoAnswerSelection}
                     // label='support type'
                      onChange={(e) => {
                        dispatch({
                          type: 'SET_AUTO_ANSWER',
                          payload: e.target.value,
                        });
                      }}
                    >
                      {state.mobileAnswers.map((answer: any) => (               
                        <MenuItem key={answer.id} value={answer.id}>
                          {answer.local}
                        </MenuItem>
                      ))}
                    </Select>                
                ) : null}
              </Grid>
            </Grid>
          </Paper>

          <Paper
            variant='elevation3'
            sx={{
              mb: 2,
              backgroundColor: 'primary.main',
            }}
          >
            <Header icon={ReportProblemRoundedIcon} headerText='Issue' />
            <Grid
              container
              display={'flex'}
              flexDirection={'row'}
              sx={{ pl: 2, pt: 2, height: Math.ceil(height - 846) }}
            >
              <Grid item xs={12} sx={{ height: 88, mt: -2 }}>
                {state.companyProducts.length > 0 && (
                  <ObjectDrawer
                    buttonContext={'Select Company Product'}
                    buttonIcon={null}
                    objectList={
                      !!state.companyProducts ? state.companyProducts : []
                    }
                    objectType={'products'}
                    handleChange={handleChangeCompanyProduct}
                    buttonHeight={'100%'}
                    buttonWidth={'100%'}
                  />
                )}
              </Grid>
              <Grid item xs={12} sx={{ m: 0, p: 0 }}>
                <TextField
                  sx={{ m: 0, p: 0 }}
                  //value={state.comment}
                  defaultValue={state.comment}
                  label={'edit issus here'}
                  variant='filled'
                  multiline={true}
                  minRows={height > 1080 ? 11 : 4}
                  onChange={(e) => {
                    dispatch({ type: 'EDIT_COMMENT', payload: e.target.value })}}
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Grid sx={{ ml: 2, mb: 2 }}>
            <SearchFilter
              Icon={GroupsRoundedIcon}
              Header='contacts'
              gfx={
                state?.company && (
                  <EmployeeDrawer
                    buttonIcon={<ChangeCircleIcon sx={{ fontSize: 40 }} />}
                    companyId={state?.company.id}
                    handleEmployeeSubmit={handleContactChange}
                  />
                )
              }
            />

            <List
              sx={{
                height: 190,
                overflow: 'auto',
                backgroundColor: 'primary.back',
                p: 1,
              }}
            >
              {state?.contact?.id && (
                <SingleEmployeeView
                  employee={state.contact}
                  action={PersonRemoveRoundedIcon}
                  gfx={<ContactGfx handleRemoveContact={handleRemoveContact} />}
                />
              )}
            </List>
            <Paper
              variant='elevation3'
              sx={{
                p: 2,
                height: 64,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
            ></Paper>
          </Grid>
          {/* RESPONSIBLE */}
          <Grid sx={{ ml: 2, mb: 2 }}>
            <SearchFilter
              Icon={PersonOutlineRoundedIcon}
              Header='responsible'
              gfx={
                ccs &&
                state?.companyId && (
                  <EmployeeDrawer
                    buttonIcon={<ChangeCircleIcon sx={{ fontSize: 40 }} />}
                    companyId={owner.id}
                    handleEmployeeSubmit={handleResponsibleChange}
                  />
                )
              }
            />

            <List
              sx={{
                height: 100,
                overflow: 'auto',
                backgroundColor: 'primary.back',
                p: 1,
              }}
            >
              {state?.responsible?.id && (
                <SingleEmployeeView
                  employee={state.responsible}
                  action={PersonRemoveRoundedIcon}
                  gfx={
                    <ResponsibleGfx
                      handleRemoveResponsible={handleRemoveResponsible}
                    />
                  }
                />
              )}
            </List>
            <Paper
              variant='elevation3'
              sx={{
                p: 2,
                height: 64,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
            ></Paper>
          </Grid>
          {/* TECHNICIANS */}
          <Grid sx={{ ml: 2, mb: 2 }}>
            <SearchFilter
              Icon={EngineeringIcon}
              Header='technicians'
              gfx={
                ccs &&
                state?.companyId && (
                  <EmployeeDrawer
                    buttonIcon={<ChangeCircleIcon sx={{ fontSize: 40 }} />}
                    companyId={owner.id}
                    handleEmployeeSubmit={handleTechniciansChange}
                  />
                )
              }
              /*               
                filterValue={filterType}
                handleFilterChange={handleFilterChange}
                handleSearch={handleSearch}
                handleNew={handleNew}
                selectList={[]}
                */
            />

            <List
              sx={{
                height: Math.ceil(height - 894),
                overflow: 'auto',
                backgroundColor: 'primary.back',
                p: 2,
              }}
            >
              {state?.technicians.length > 0 &&
                state.technicians?.map((technician: any, index: number) => (
                  <SingleEmployeeView
                    key={index}
                    employee={technician}
                    action={PersonRemoveRoundedIcon}
                    gfx={
                      <TechnicianGfx
                        handleRemoveTechnician={handleRemoveTechnician}
                        technician={technician}
                      />
                    }
                  />
                ))}
            </List>
            <Paper
              variant='elevation3'
              sx={{
                p: 2,
                height: 64,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
            ></Paper>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            m: 0,
            pt: 1.5,
            pr: 2,
            height: 64,
            borderRadius: 2,
            backgroundColor: 'primary.main',
            display: 'flex',
            flexDirection: 'row-reverse',
          }}
        >
          <Button
            sx={{ width: 150 }}
            variant='contained'
            //type='submit'
            onClick={onSubmit}
            disabled={postTicket.isLoading}
          >
            Save Ticket
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
