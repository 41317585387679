import { useMemo, useReducer } from 'react';

import { Avatar, Grid, List, Paper } from '@mui/material';

import useWindowsDimension from 'functions/useWindowsDimension';
import { useGet, usePut } from 'http/useInnovit';
import { rq, rqPost } from 'http/apiRoutes';
import { useState, useEffect } from 'react';
import { SingleTicketView } from 'components/ui/tickets/SingleTicketView';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import userAtom from 'data/atoms/userAtom';
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
  closestCorners,
} from '@dnd-kit/core';
import { DroppableTicketList } from '../../components/ui/DroppableTicketList';
import { createPortal } from 'react-dom';

/* FUNCTIONS */

export const TicketsList = () => {
  // INITIALIZATION

  let navigate = useNavigate();
  const { height } = useWindowsDimension();
  const [filterType, setFilterType] = useState(0);
  const [user] = useAtom(userAtom);

  const [ticket, setTicket] = useState(null);
  const [activeTicket, setActiveTicket] = useState(null);
  const [activeTicketId, setActiveTicketId] = useState(null);
  const [sortedTickets, setSortedTickets] = useState([]);
  const [sortedUserTickets, setSortedUserTickets] = useState([]);
  const columns = ['newTickets', 'userTickets'];

  // API
  let isEnabled = user && Object.keys(user).length > 0 ? true : false;

  const { data: userTicketsData } = useGet(
    rq.userTickets(1, user.id, isEnabled),
    isEnabled
  );
  const { data: newTicketsData } = useGet(
    rq.tickets(1, filterType, isEnabled),
    isEnabled
  );

  let isPutEnabled = ticket?.id !== undefined ? true : false;
  // console.log('ticket id', ticket?.id);
  const putTicket = usePut(
    rqPost.ticketPut(activeTicketId, '0', isPutEnabled),
    isPutEnabled
  );
  // USEFFECTS
  useEffect(() => {
    if (newTicketsData) {
      console.log('useEffect - newTicketsData', newTicketsData);
      let sortedTickets = [...newTicketsData.tickets].sort(
        (a, b) => b.priority - a.priority
      );
      setSortedTickets(sortedTickets);
    }
  }, [newTicketsData]);

  useEffect(() => {
    if (userTicketsData) {
      // console.log('useEffect - newTicketsData', userTicketsData);
      let sortedTickets = [...userTicketsData.tickets].sort(
        (a, b) => b.priority - a.priority
      );

      setSortedUserTickets(sortedTickets);
    }
  }, [userTicketsData]);

  useEffect(() => {
    console.log('useEffect - sortedTickets', sortedTickets, sortedUserTickets);
  }, [sortedTickets, sortedUserTickets]);

  useEffect(() => {
    console.log('useEffect - activeTicket', activeTicket);
  }, [activeTicket]);

  // HANDLES
  const handleAssigneMe = (ticket) => {
    console.log('handleAssigneMe', ticket);
    ticket.responsible = user;
    if (ticket.technicians === null) {
      ticket.technicians = [];
    }
    putTicket.mutate(ticket);
  };

  const handleRemoveAssign = (ticket) => {
    console.log('handleRemoveAssigneMe', ticket);
    ticket.responsible = null;
    if (ticket.technicians === null) {
      ticket.technicians = [];
    }
    putTicket.mutate(ticket);
  };

  const handleDragStart = (e) => {
    setActiveTicketId(e.active.id);
    console.log('handleDragStart', e.active);
    setActiveTicket(e.active.data.current?.ticket);
  };

  const handleDragEnd = (e) => {
    const { active, over } = e;
    setActiveTicketId(null);
    setActiveTicket(null);

    const getColumnNameForId = (id) => {
      if (sortedTickets.find((ticket) => ticket.id === id)) {
        return 'newTickets';
      } else if (sortedUserTickets.find((ticket) => ticket.id === id)) {
        return 'userTickets';
      }
      return null;
    };

    const activeColumn = getColumnNameForId(active.id);
    console.log('active column: ' + activeColumn);
    const overColumn = over ? over.id : null;

    if (overColumn && activeColumn !== overColumn) {
      console.log('Item was moved to a different column!', active.id);
      if (activeColumn === 'newTickets') {
        handleAssigneMe(
          sortedTickets.find((ticket) => ticket.id === active.id)
        );
      } else if (activeColumn === 'userTickets') {
        handleRemoveAssign(
          sortedUserTickets.find((ticket) => ticket.id === active.id)
        );
      }
    } else {
      console.log(
        'Item stayed in the same column or was not placed over any.',
        active.id
      );
      return;
    }

    if (activeColumn === 'newTickets' && overColumn === 'userTickets') {
      const ticketToMove = sortedTickets.find(
        (ticket) => ticket.id === active.id
      );
      if (ticketToMove) {
        setSortedTickets((prevState) =>
          prevState.filter((ticket) => ticket.id !== active.id)
        );
        setSortedUserTickets((prevState) => [ticketToMove, ...prevState]);
      }
    } else if (activeColumn === 'userTickets' && overColumn === 'newTickets') {
      const ticketToMove = sortedUserTickets.find(
        (ticket) => ticket.id === active.id
      );

      if (ticketToMove) {
        setSortedUserTickets((prevState) =>
          prevState.filter((ticket) => ticket.id !== active.id)
        );
        setSortedTickets((prevState) => [ticketToMove, ...prevState]);
      }
    }
  };

  // dnd sortable

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          // overflowX: 'hidden',
        }}
      >
        {columns.map((column) => {
          switch (column) {
            case 'newTickets':
              return (
                <DroppableTicketList
                  key={column}
                  id={column}
                  tickets={sortedTickets}
                  setActiveTicket={setActiveTicket}
                />
              );
            case 'userTickets':
              return (
                <DroppableTicketList
                  key={column}
                  id={column}
                  tickets={sortedUserTickets}
                  setActiveTicket={setActiveTicket}
                />
              );
            default:
              return null;
          }
        })}
      </Grid>
      {createPortal(
        <DragOverlay>
          {activeTicket && <SingleTicketView ticket={activeTicket} />}
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  );
};
