import { Paper, Grid, IconButton, Box, Typography } from '@mui/material';
import { MissingPhoto } from 'data/globalTypeConfig';
import PersonOffRoundedIcon from '@mui/icons-material/PersonOffRounded';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import GetColorByName from 'functions/GetColorByName';
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import { ICompanyProductViewModel } from 'pages/Companies/ICompanyProductConfig';
import { IPlacement } from 'pages/Companies/ICompaniesConfig';
type ProductCardEmployeeViewProps = {
  companyProduct: ICompanyProductViewModel; // Replace with the actual type
  handleRemoveProduct: (companyProduct: ICompanyProductViewModel) => void; // Adjust the argument type as needed
};

export const ProductCardCompanyView: React.FC<
  ProductCardEmployeeViewProps
> = ({ companyProduct, handleRemoveProduct }) => {
 // console.log('companyProduct', companyProduct);
  return (
    <Paper
      elevation={3}
      sx={{
        width: '100%',
        borderLeft: 8,
        borderRight: 3,
        borderRadius: 3,
        borderColor: GetColorByName(companyProduct.product?.productType?.name),
        backgroundColor: 'primary.main',
        height: 64,
        /* ':hover': {
          cursor: 'pointer',
          filter: 'brightness(120%)',
          transition: 'all 0.2s ease-in-out',
        }, */
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            width: 64,
            height: 64,
            backgroundColor: 'primary.light',
          }}
        >
          <img
            src={companyProduct.product?.url || MissingPhoto.PRODUCT_URL}
            alt='previewUrl'
            style={{
              width: 64,
              height: 64,
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
              objectFit: 'scale-down',
              backgroundColor: 'primary.light',
            }}
          />
        </Box>
        <Box sx={{ pl: 1, pr: 1, flexGrow: 1 }}>
          <Grid
            container
            spacing={0}
            sx={{
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'column',
              alignItems: 'flex-start',
              alignContent: 'flex-start',
              justifyContent: 'flex-start',
            }}
          >
            <Typography
              sx={{ 
                textAlign: 'left',
                fontSize: 14,
                letterSpacing: 2,
                fontWeight: 'bold',
                textTransform: 'uppercase',
                color: 'primary.text',
                display: 'block',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {companyProduct.product?.name || ''}
            </Typography>
           
            <Typography
              sx={{ 
                textAlign: 'left',
                fontSize: 12,
                letterSpacing: 1,
                fontWeight: 'normal',
                textTransform: 'uppercase',
                color: 'primary.text',
                display: 'block',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {companyProduct.product?.productType?.name || ''}
              </Typography>
              <Typography
              sx={{ 
                textAlign: 'left',
                fontSize: 12,
                letterSpacing: 1,
                fontWeight: 'normal',
                textTransform: 'uppercase',
                color: 'primary.text',
                display: 'block',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {companyProduct.product?.brand}
              </Typography>
          </Grid>
        </Box>
        <Box sx={{ pr: 2 }}>
          <Grid
            container
            spacing={0}
            sx={{
              pl: 0.5,
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'column',
              alignItems: 'flex-start',
              alignContent: 'flex-start',
              justifyContent: 'flex-start',
            }}
          >
            <Box
              sx={{
                pt: 1,
                minWidth: 100,
                textAlign: 'right',
                whiteSpace: 'nowrap',
                fontSize: 16,
                letterSpacing: 2,
                fontWeight: 'bold',
                color: 'secondary.text',
              }}
            >
              {companyProduct?.retailPrice.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Grid>
        </Box>

        <Box sx={{}}>
          <Grid
            container
            spacing={0}
            sx={{
              pr: 1,
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'column',
              alignItems: 'flex-center',
              alignContent: 'flex-center',
              justifyContent: 'flex-center',
            }}
          >
            <IconButton
              aria-label='delete-forever'
              sx={{
                mt: 1,
                p: 0,
                elevation: 3,
                width: 40,
                height: 40,
                mx: 'auto',
                // color: "info.text",
                backgroundColor: 'primary.main',
                ':hover': {
                  cursor: 'pointer',
                  filter: 'brightness(120%)',
                  transition: 'all 0.2s ease-in-out',
                },
                boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.50)',
              }}
              onClick={() => handleRemoveProduct(companyProduct)}
            >
              {
                companyProduct.id?.startsWith('new') ? (
                  <DeleteForeverIcon sx={{ fontSize: 32, color: 'warning.light' }} />
                ) : (
                  companyProduct.placement !== 3 as IPlacement ? (
                    <DomainAddIcon  sx={{ fontSize: 32, color: 'info.light' }} />
                  ) : (
                    <DomainDisabledIcon sx={{ fontSize: 32, color: 'secondary.light' }} />
                  )
                )
              }

            </IconButton>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
};
