// @ts-ignore
import { openInNewTab } from 'functions/misc';
import postalCodes from 'norway-postal-codes';
import { FormAccordionSummary } from 'components/form';
import { useNavigate } from 'react-router';

import {
  Accordion,
  AccordionDetails,
  Grid,
  Stack,
  TextField,
  IconButton,
  Box,
  Typography,
} from '@mui/material';
import FeedRoundedIcon from '@mui/icons-material/FeedRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import TravelExploreRoundedIcon from '@mui/icons-material/TravelExploreRounded';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
import { MissingPhoto } from 'data/globalTypeConfig';
import GetPriorityColor from 'functions/GetPriorityColor';

function CompanyView(props:any) {
  /* INITIALIZATIONS */
  let navigate = useNavigate();
  const { company } = props;
  /* FUNCTIONS */
//console.log('CompanyView', company);
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
      }}
    >
      {/* HEADING */}
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Box sx={{ pt: 1 }}>
            <FeedRoundedIcon sx={{ fontSize: 40 }} />
          </Box>
          <Box sx={{ pl: 2, pt: 2, flexGrow: 1 }}>
            <Typography
              sx={{
                textTransform: 'capitalize',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: 'bold',
                fontSize: 18,
                letterSpacing: 4,
              }}
            >
              Company Info
            </Typography>
          </Box>
          {company.id !== 'new' && company.name > '' && (
            <Box>
              <IconButton
                sx={{ mt: 0 }}
                onClick={(e) => {
                  openInNewTab(
                    e,
                    'https://www.google.com/maps/place/' +
                      company.mainAddress?.street +
                      ',' +
                      company.mainAddress?.postalCode +
                      ',' +
                      company.mainAddress?.city +
                      '/'
                  );
                }}
              >
                <TravelExploreRoundedIcon sx={{ fontSize: 32 }} />
              </IconButton>
              <IconButton
                sx={{ mt: -1, mb: -1 }}
                onClick={() => {
                  navigate(`/companies/${company.id}`);
                }}
              >
                <LaunchRoundedIcon sx={{ fontSize: 32 }} />
              </IconButton>
            </Box>
          )}
        </Box>
      </Grid>

      <Stack direction='row'>
      <Grid
          item
          sx={{
            width: 160,
            height: 80,
            borderRadius: 3,
            backgroundColor: 'primary.light', 
            display: 'flex',
          }}
        >
        <img
             src={company?.url || MissingPhoto.COMPANY_URL}
             style={{ width: 160, height: 80, borderRadius: 3, objectFit: 'scale-down', boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)', }}
             alt={'previewUrl'}
            />
        </Grid>
        <Grid
          container
          sx={{
            p: 2,
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={12} lg={8}>
            <TextField
              id='name'
              label='Company Name'
              variant='standard'
              value={company?.name}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <TextField
              id='orgnr'
              label='Org nr'
              variant='standard'
              value={company?.organizationNumber}
            />
          </Grid>

          <Grid item xs={12} lg={8}>
            <TextField
              id='email'
              label='Email'
              variant='standard'
              value={company?.email}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <TextField
              id='phone'
              label='Phone'
              variant='standard'
              value={company?.phone}
            />
          </Grid>

          <Grid item xs={12} lg={8}>
            <TextField
              id='webpage'
              label='Webpage'
              variant='standard'
              value={company?.webpage}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <TextField
              id='companyType'
              label='Company Type'
              variant='standard'
              value={company?.companyType}
            />
          </Grid>
        </Grid>
      </Stack>

      {/*     Main Address */}
      <Grid
        item
        xs={12}
        sx={{
          mb: 2,
          border: 0,
          borderRadius: 2,
          boxShadow:
            '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
        }}
      >
        <Accordion
          disableGutters
          sx={{
            backgroundColor: 'transparent',
            border: 0,
          }}
        >
          <FormAccordionSummary Icon={HomeRoundedIcon} title='Main Address' />
          <AccordionDetails>
            <Grid container>
              <Grid
                item
                xs={6}
                lg={6}
                sx={{
                  pl: 1,
                  pr: 1,
                }}
              >
                <TextField
                  id='mainAddress.street'
                  label='Street'
                  variant='standard'
                  value={company.mainAddress?.street || ' '}
                />
              </Grid>

              <Grid
                item
                xs={2}
                lg={2}
                sx={{
                  pl: 1,
                  pr: 1,
                }}
              >
                <TextField
                  id='mainAddress.postalCode'
                  label='Code'
                  variant='standard'
                  value={company.mainAddress?.postalCode || ' '}
                />
              </Grid>

              <Grid
                item
                xs={4}
                lg={4}
                sx={{
                  pl: 1,
                  pr: 1,
                }}
              >
                <TextField
                  id='mainAddress.postalArea'
                  label='Area'
                  variant='standard'
                  value={
                    postalCodes[`${company.mainAddress?.postalCode}`] || ' '
                  }
                />
              </Grid>

              <Grid
                item
                xs={4}
                lg={4}
                sx={{
                  pl: 1,
                  pr: 1,
                }}
              >
                <TextField
                  id='mainAddress.city'
                  label='City'
                  variant='standard'
                  value={company.mainAddress?.city || ' '}
                />
              </Grid>

              <Grid
                item
                xs={12}
                lg={4}
                sx={{
                  pl: 1,
                  pr: 1,
                }}
              >
                <TextField
                  id='mainAddress.country'
                  label='Country'
                  variant='standard'
                  value={company.mainAddress?.country || ' '}
                />
              </Grid>

              <Grid
                item
                xs={12}
                lg={4}
                sx={{
                  pl: 1,
                  pr: 1,
                }}
              >
                <TextField
                  id='mainAddress.postbox'
                  label='Postbox'
                  variant='standard'
                  value={company.mainAddress?.postBox || ' '}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/*     Invoice Address */}
      <Grid
        item
        xs={12}
        sx={{
          mb: 2,
          border: 0,
          borderRadius: 2,
          boxShadow:
            '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
        }}
      >
        <Accordion
          disableGutters
          sx={{
            backgroundColor: 'transparent',
            border: 0,
          }}
        >
          <FormAccordionSummary
            Icon={HomeRoundedIcon}
            title='Delivery Address'
          />
          <AccordionDetails>
            <Grid container>
              <Grid
                item
                xs={6}
                lg={6}
                sx={{
                  pl: 1,
                  pr: 1,
                }}
              >
                <TextField
                  id='deliveryAddress.street'
                  label='Street'
                  variant='standard'
                  value={company.deliveryAddress?.street || ' '}
                />
              </Grid>

              <Grid
                item
                xs={2}
                lg={2}
                sx={{
                  pl: 1,
                  pr: 1,
                }}
              >
                <TextField
                  id='deliveryAddress.postalCode'
                  label='Code'
                  variant='standard'
                  value={company.deliveryAddress?.postalCode || ' '}
                />
              </Grid>

              <Grid
                item
                xs={4}
                lg={4}
                sx={{
                  pl: 1,
                  pr: 1,
                }}
              >
                <TextField
                  id='deliveryAddress.postalArea'
                  label='Area'
                  variant='standard'
                  value={
                    postalCodes[`${company.deliveryAddress?.postalCode}`] || ' '
                  }
                />
              </Grid>

              <Grid
                item
                xs={4}
                lg={4}
                sx={{
                  pl: 1,
                  pr: 1,
                }}
              >
                <TextField
                  id='deliveryAddress.city'
                  label='City'
                  variant='standard'
                  value={company.deliveryAddress?.city || ' '}
                />
              </Grid>

              <Grid
                item
                xs={12}
                lg={4}
                sx={{
                  pl: 1,
                  pr: 1,
                }}
              >
                <TextField
                  id='deliveryAddress.country'
                  label='Country'
                  variant='standard'
                  value={company.deliveryAddress?.country || ' '}
                />
              </Grid>

              <Grid
                item
                xs={12}
                lg={4}
                sx={{
                  pl: 1,
                  pr: 1,
                }}
              >
                <TextField
                  id='deliveryAddress.postbox'
                  label='Postbox'
                  variant='standard'
                  value={company.deliveryAddress?.postBox || ' '}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/*     Delivery Address */}
      <Grid
        item
        xs={12}
        sx={{
          mb: 2,
          border: 0,
          borderRadius: 2,
          boxShadow:
            '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
        }}
      >
        <Accordion
          disableGutters
          sx={{
            backgroundColor: 'transparent',
            border: 0,
          }}
        >
          <FormAccordionSummary
            Icon={HomeRoundedIcon}
            title='Billing Address'
          />
          <AccordionDetails>
            <Grid container>
              <Grid
                item
                xs={6}
                lg={6}
                sx={{
                  pl: 1,
                  pr: 1,
                }}
              >
                <TextField
                  id='invoiceAddress.street'
                  label='Street'
                  variant='standard'
                  value={company.invoiceAddress?.street || ' '}
                />
              </Grid>

              <Grid
                item
                xs={2}
                lg={2}
                sx={{
                  pl: 1,
                  pr: 1,
                }}
              >
                <TextField
                  id='invoiceAddress.postalCode'
                  label='Code'
                  variant='standard'
                  value={company.invoiceAddress?.postalCode || ' '}
                />
              </Grid>

              <Grid
                item
                xs={4}
                lg={4}
                sx={{
                  pl: 1,
                  pr: 1,
                }}
              >
                <TextField
                  id='invoiceAddress.postalArea'
                  label='Area'
                  variant='standard'
                  value={
                    postalCodes[`${company.invoiceAddress?.postalCode}`] || ' '
                  }
                />
              </Grid>

              <Grid
                item
                xs={4}
                lg={4}
                sx={{
                  pl: 1,
                  pr: 1,
                }}
              >
                <TextField
                  id='invoiceAddress.city'
                  label='City'
                  variant='standard'
                  value={company.invoiceAddress?.city || ' '}
                />
              </Grid>

              <Grid
                item
                xs={12}
                lg={4}
                sx={{
                  pl: 1,
                  pr: 1,
                }}
              >
                <TextField
                  id='invoiceAddress.country'
                  label='Country'
                  variant='standard'
                  value={company.invoiceAddress?.country || ' '}
                />
              </Grid>

              <Grid
                item
                xs={12}
                lg={4}
                sx={{
                  pl: 1,
                  pr: 1,
                }}
              >
                <TextField
                  id='invoiceAddress.postbox'
                  label='Postbox'
                  variant='standard'
                  value={company.invoiceAddress?.postBox || ' '}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
}

export default CompanyView;
