import {
  Avatar,
  Badge,
  Box,
  Button,
  Grid,
  List,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IOrderViewModel } from 'pages/Orders/OrdersConfig';
import GetColorByName from 'functions/GetColorByName';
import getInitials from 'functions/GetInitials';
import { MissingPhoto } from 'data/globalTypeConfig';
import GetPriorityColor from 'functions/GetPriorityColor';

type Props = {
  unmanagedObject: any;
  handleClick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    unmanagedObject: any
  ) => void;
};

const SingleUnmanagedObjectView = ({ unmanagedObject, handleClick }: Props) => {
  console.log('SingleUnmanagedObjectView', unmanagedObject);
  // INITIALIZATION

  // API

  // HANDLES

  // EFFECTS

  if (!unmanagedObject) {
    return (
      <Paper
        // ref={setNodeRef}
        variant='elevation3'
        sx={{
          mb: 1,
          borderLeft: 8,
          borderRight: 1,
          borderColor: GetPriorityColor(Math.random() * 4),
        }}
        // style={draggableStyle}
      >
        <Stack direction={'row'}>
          <Grid container direction={'row'}></Grid>
        </Stack>
      </Paper>
    );
  }

  return (
    <Grid
      container
      onClick={(event) => handleClick(event, unmanagedObject)}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        height: 84,
        mb: 2,
        borderLeft: 10,
        borderRadius: 2,
        zIndex: 9999,

        borderColor: GetColorByName(
          unmanagedObject?.product?.brand || 'no brand'
        ),
        boxShadow:
          '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
      }}
    >
      <Grid item xs={6} sx={{ m: 0, height: 84 }}>
        <Grid
          container
          sx={{
            display: 'flex',
            flexDirection: 'row',
            m: 0,
            p: 1,
            height: 84,
          }}
        >
          <Grid item sx={{ m: 0, p: 0, height: 'inherit' }}>
            <Badge
              color='secondary'
              badgeContent={
                <Typography
                  sx={{
                    fontSize: 16,
                    fontWeight: 'bold',
                  }}
                >
                  {unmanagedObject?.product?.quantity || 0}
                </Typography>
              }
              showZero
              sx={{
                fontSize: 12,
                fontWeight: 'normal',
              }}
            >
              <Box
                sx={{
                  width: 64,
                  height: 64,
                  backgroundColor: 'primary.light',
                }}
              >
                {/*  XXX fix image */}
                <img
                  src={MissingPhoto.PRODUCT_URL}
                  alt='Url'
                  style={{
                    width: 64,
                    height: 64,
                    borderRadius: 2,
                    objectFit: 'scale-down',
                    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
                  }}
                />
              </Box>
            </Badge>
          </Grid>
          <Grid item sx={{ m: 0, p: 0, flexGrow: 1 }}>
            <Typography
              sx={{
                pl: 1,
                width: '100%',
                fontSize: 14,
                letterSpacing: 0,
                fontWeight: 'normal',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {unmanagedObject?.product?.name || 'no product'}
            </Typography>
            <Typography
              sx={{
                pl: 1,
                width: '100%',
                fontSize: 14,
                letterSpacing: 0,
                fontWeight: 'normal',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {unmanagedObject?.product?.productType || 'no product'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        flexGrow={1}
        sx={{
          m: 0,
          p: 1,
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <img
          src={
            unmanagedObject?.ticket?.company?.url || MissingPhoto.COMPANY_URL
          }
          alt='previewUrl'
          style={{
            width: 128,
            height: 64,
            borderRadius: 2,
            objectFit: 'scale-down',
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
            marginRight: 20,
          }}
        />

        <img
          src={
            unmanagedObject?.ticket?.contact?.url || MissingPhoto.EMPLOYEE_URL
          }
          alt='previewUrl'
          style={{
            width: 64,
            height: 64,
            borderRadius: 32,
            objectFit: 'scale-down',
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
          }}
        />
      </Grid>
      <Grid
        item
        sx={{
          m: 0,
          p: 1,
          display: 'flex',
          flexDirection: 'row-reverse',
          width: 128,
        }}
      >
        {unmanagedObject?.vendor ? (
          <img
            src={unmanagedObject?.vendor?.url || MissingPhoto.COMPANY_URL}
            alt='previewUrl'
            style={{
              width: '100%',
              height: 64,
              borderRadius: 2,
              objectFit: 'scale-down',
              boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
            }}
          />
        ) : (
          <Button
            variant='contained'
            sx={{
              width: 128,
              height: 64,
              borderRadius: 2,
              // objectFit: 'scale-down',
              boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
            }}
          >
            Add vendor
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default SingleUnmanagedObjectView;
