import React from 'react';
import { PackageForm } from './PackageForm';
import { useParams } from 'react-router';
import { useGet } from 'http/useInnovit';
import { rq } from 'http/apiRoutes';
import { emptyPackage } from 'data/templates';
import { IPackageViewModel } from './IPackageConfig';

type Params = Record<string, string | undefined>;

export const Package: React.FC = () => {
  const { companyId, packageId } = useParams<Params>();
  // api
  let isEnabled = packageId !== 'new';

  const { data: packageData } = useGet(
    rq.package(companyId || '', packageId || '', isEnabled),
    isEnabled
  );

  if (packageId === 'new') {
    return <PackageForm packageData={{} as IPackageViewModel} />;
  } else {
    return !!packageData && <PackageForm packageData={packageData as IPackageViewModel} />;
  }
};
