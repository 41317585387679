import React from 'react';
import { Grid, Typography, Paper } from '@mui/material';
import { SingleProductView } from './SingleProductView';
import GetColorByName from 'functions/GetColorByName';

// Define the interface for the agreement object
interface Agreement {
  product: {
    name: string;
    productType:{
      name: string;
    }

    // Add other properties as needed
  };
  quantity: number;
  retailPrice: number,
  totalPrice: number;
  nextPayment: string;
  bindingPeriod: string;
  url: string;
  // Add other properties as needed
}

interface SingleAgreementViewProps {
  agreement: Agreement;
}

const missingPhoto = {
    PHOTO_URL: process.env.PUBLIC_URL + '/img/noCompany.png',
  };

  const SingleAgreementView: React.FC<SingleAgreementViewProps> = ({ agreement }) => {
    console.log('agreement', agreement);
    let name = agreement.product.productType.name;
    return (
      <Paper
        sx={{
          p: 0,
          pr:1,
          mb: 2,
          borderLeft: 8,
          borderRight: 3,
          borderColor: GetColorByName(`${name}`),
          backgroundColor: 'primary.main',
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center', // Center vertically
        }}
      >
        {/* Display Image on the far left */}
        <img
          src={agreement?.url || missingPhoto.PHOTO_URL}
          style={{
            width: 80,
            height: 80,
            borderRadius: 3,
            objectFit: 'scale-down',
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
          }}
          alt={'previewUrl'}
        />
        {/* Display Agreement Name or Product Name */}
        <div style={{ marginLeft: '16px', flex: 1 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            {agreement.product?.name}
          </Typography>
          {/* Lines beneath product name */}
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            {/* Display Quantity */}
            <Typography variant="body2">x {agreement.quantity}</Typography>
  
            {/* Display Retail Price */}
            <Typography variant="body2">Retail Price: {agreement.retailPrice.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}</Typography>
  
            {/* Display Total Price */}
            <Typography variant="body2" sx={{ color: 'secondary.text' }}>Total Price: {agreement.totalPrice.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}</Typography>
          </div>
  
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            {/* Display Next Payment */}
            <Typography variant="body2" sx={{ color: 'primary.text' }}>Next Payment: {agreement.nextPayment}</Typography>
  
            {/* Display Binding Period */}
            <Typography variant="body2" sx={{ color: 'primary.text' }}>
              Binding Period: {agreement.bindingPeriod}
            </Typography>
          </div>
        </div>
      </Paper>
    );
  };
  
  export default SingleAgreementView;
