import GetColorByName from 'functions/GetColorByName';


export const TicketData = (props: any) => {
  const data = { ...props };
  let rows;
  data.map((ticket:any) => {
    ticket.company.logo
      ? (ticket.company.color = 'primary.text')
      : (ticket.company.color = GetColorByName(ticket.company.name));
    ticket.contact.logo
      ? (ticket.contact.color = 'avatar.main')
      : (ticket.contact.color = GetColorByName(ticket.contact.name));
    ticket.owner.logo
      ? (ticket.owner.color = 'avatar.main')
      : (ticket.owner.color = GetColorByName(ticket.owner.name));

    rows.push({
      id: ticket.id,
      board: ticket.board,
      status: ticket.status,
      date: ticket.date,
      age: ticket.age,
      priority: ticket.priority,
      time: ticket.timer,

      logo: ticket.company.logo,
      company: ticket.company,
      contact: ticket.contact,
      contactName: ticket.contact.name,
      step1: ticket.step1,
      step2: ticket.step2,
      step3: ticket.step3,
      step4: ticket.step4,
      comment: ticket.comment,

      hoursTotal: ticket.hoursTotal,
      owner: ticket.owner,
    });
  });

  return rows;
};
export const brand = [
  {
    id: '2c963f66afa6',
    description: 'Lenovo',
    featureName: 'Lenovo',
  },
  {
    id: '3fa85f64',
    description: 'string',
    featureName: 'string',
  },
];

export const newAddress = {
  street: '',
  postalCode: '',
  city: '',
  postBox: '',
  country: '',
};

export const emptyAddress = {
  street: ' ',
  postalCode: ' ',
  city: ' ',
  postBox: ' ',
  country: ' ',
};

export const emptyCompany = {
  id: 'new',
  organizationNumber: '',
  name: '',
  mainAddress: newAddress,
  invoiceAddress: newAddress,
  deliveryAddress: newAddress,
  email: '',
  phone: '',
  companyType: 2,
  primaryContacts: [],
  webpage: '',
  logo: [],
  productBilling: false,
  onHold: false,
  onHoldNote: '',
  workRoles: [],
  fixedHourlyRate: 1290.0,
  priority: 0,
};

export const emptyDepartment = {
  companyId: '',
  name: '',
  phone: '',
  email: '',
  mainAddress: newAddress,
  // deliveryAddress: newAddress,
  // default: false,
  active: true,
};

export const emptyEmployee = {
  id: 'new',
  departmentId: '',
  name: '',
  phone: '',
  email: '',
  address: {
    street: '',
    postalCode: '',
    city: '',
    postBox: '',
    country: '',
  },
  role: 2,
  primaryContact: false,
  budget: 0,
  title: '',
  // birthday: null,
  // active: true,
  url: {
    fileName: '',
    content: '',
  },
  companyProducts: [],
};

export const newProduct = {
  name: '',
  comment: '',
  serialNumber: '',
  unitPrice: 0,
  retailPrice: 0,
  security: 0,
  fileModel: {
    fileName: '',
    content: '',
  },
  productTypeId: '',
  productFeatureDataIds: [],
  ean: '',
  brand: '',
  paymentCycle: 0,
  bindingPeriod: 0,
  quantity:1,
};

export const emptyProduct = {
  id: 'new',
  productType: {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    name: 'Laptop',
  },
  features: [
    {
      id: '3fa85f64-5717-4562-b3fc-2c963f66afa2',
      description: 'Intel Core i9',
      featureName: 'CPU',
    },
    {
      id: '3fa85f64-5717-4562-b3fc-2c963f66afa1',
      description: '1024GB',
      featureName: 'Storage',
    },
  ],
  name: ' ',
  comment: ' ',
  serialNumber: ' ',
  unitPrice: 0,
  retailPrice: 0,
  security: 0,
  ean: 0,
  brand: ' ',
  active: true,
  url: 'Lenovo L14',
  monthlyPaymentCycle: 0,
  bindingPeriod: 0,
};

export const emptyPackage = {
  companyId: '',
  defaultDepartment: {
    id: '',
    name: '',
  },
  url: '',
  investment: 0,
  monthly: 0,
  quarterly: 0,
  yearly: 0,
  description: '',
  name: '',
  agreementIncreases: [
    {
      id: '',
      name: '',
      info: '',
      value: 0,
      paymentCycle: 0,
      bindingPeriod: 0,
    },
  ],
  products: [],
  defaultPackage: false,
  active: true,
};

export const newPackage = {
  companyId: '',
  defaultDepartment: { id: '', name: '' },
  name: '',
  description: '',
  imageNr: 0,
  products: [],
  productIds: [],
  totalCost: 0,
  active: true,
  default: false,
  investment: 0,
  paymentCycle: 0,
};

export const newBoard = [
  { id: '1', name: 'Support' },
  { id: '2', name: 'Sales' },
  { id: '3', name: 'Project' },
];

export const newStatus = [
  { id: '1', name: 'open' },
  { id: '2', name: 'in Progress' },
  { id: '3', name: 'Closed' },
];

export const newPriority = [
  { id: '1', name: 'High' },
  { id: '2', name: 'Medium' },
  { id: '3', name: 'Low' },
  { id: '3', name: 'None' },
];

export const countries = [
  { code: 'DK', label: 'Denmark', phone: '45' },
  { code: 'NO', label: 'Norway', phone: '47' },
  { code: 'SE', label: 'Sweden', phone: '46' },
];
