import { Avatar, Grid, Typography } from "@mui/material";
import React, { useState, useEffect, DragEvent, FC } from "react";

interface ImageUploaderProps {
  width: number;
  height: number;
  setImage: (file: File) => void;
  previewImage: string;
  setBase64String: (str: string | ArrayBuffer | null) => void;
  setFormDirty: () => void;
  base64String: string | null; // Add this line
}


const ImageUploader: FC<ImageUploaderProps> = ({
  width,
  height,
  setImage,
  previewImage,
  setBase64String,
  setFormDirty,
}) => {
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  useEffect(() => {
    setPreviewUrl(previewImage);
  }, [previewImage]);

  const handleOndragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleOndrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setFormDirty();
    handleFile(event);
  };

  const handleFile = (e: DragEvent<HTMLDivElement>) => {
    const file = e.dataTransfer.files[0];
    setImage(file);

    const fileReader = new FileReader();

    fileReader.onload = () => {
      setBase64String(fileReader.result);
      setPreviewUrl(fileReader.result as string);
    };
    fileReader.readAsDataURL(file);
  };

 // ... (the other import statements and function definition remains the same)

 return (
  <Grid
    item
    sx={{
      m: 0,
      p: 0,
      width: width,
      height: height,
      backgroundColor: "primary.light",
      border: 1,
      borderColor: "primary.light",
      borderRadius: 3,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      
    }}
    onDragOver={handleOndragOver}
    onDrop={handleOndrop}
  >
    {previewUrl ? (
      <img
        src={previewUrl}
        style={{
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          borderRadius: 3,
          objectFit: "scale-down",
        }}
        alt="preview"
      />
    ) : (
      <Typography
        sx={{
          mt: 2,
          p: 2,
          textAlign: "center",
          alignContent: "center",
          alignSelf: "center",
          alignItems: "center",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          fontWeight: "bold",
          fontSize: 14,
          color: "primary.text",
        }}
      >
        upload image
      </Typography>
    )}
  </Grid>
);
};

export default ImageUploader;

