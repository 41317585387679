import * as React from "react";
import { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Avatar,
  Stack,
} from "@mui/material";

import GetAvatarInitials from "functions/GetAvatarInitials";
import GetColorByName from "functions/GetColorByName";

const variables = {
  PHOTO_URL: process.env.PUBLIC_URL + "/img/",
};

export const SingleDepartmentView = (props) => {
  const department = props.department;
  
  return (
    <Grid
      container
      direction={"row"}
      sx={{
        mb: 1,
        borderLeft: 8,
        borderRight: 3,
        borderRadius: 3,
        borderColor: GetColorByName(`${department?.name}` || "default"),
        ':hover': {
          cursor: 'pointer',
          filter: 'brightness(120%)',
          transition: 'all 0.2s ease-in-out',
        },
      }}
    >
      <Grid item sx={{ ml: -0.1 }}>
        {!!department?.logo ? (
          <Avatar
            variant="rounded"
            alt={department.name}
            src={variables.PHOTO_URL + department?.logo + ".png"}
            sx={{
              width: 80,
              height: 80,
            }}
          />
        ) : (
          <Avatar {...GetAvatarInitials(department.name, 80, 80, 0)} />
        )}
      </Grid>

      <Grid
        item
        flexGrow={1}
        sx={{
          pl: 1,
        }}
      >
        <Stack direction={"column"}>
          <Typography
            sx={{
              letterSpacing: 2,
              textAlign: "left",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              fontWeight: "bold",
              fontSize: 18,
            }}
          >
            {department?.name}
          </Typography>
          <Typography
            sx={{
              height: 24,
              letterSpacing: 3,
              textAlign: "left",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              fontWeight: "normal",
              fontSize: 16,
            }}
          >
            {department?.phone}
          </Typography>
          <Typography
            sx={{
              height: 24,
              letterSpacing: 3,
              textAlign: "left",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              fontWeight: "normal",
              fontSize: 16,
            }}
          >
            {department?.email}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};
