import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import { innovit } from 'http/innovitConfig';
import getBearer from 'http/getBearer';
import { useSnackbar } from 'notistack';
import { newProduct } from 'data/templates';

/* COMPANY */

export function useCategories() {
  const queryClient = useQueryClient();

  return useQuery('productCategories', () => get('/ProductCategory'), {
    staleTime: 1000 * 20,
    cacheTime: 1000 * 60 * 60,
    initialData: () => {
      let cacheData = queryClient.getQueryData('productCategories');
      return cacheData;
    },
  });
}

// 1.9 GET /api/Company/{id}/departments
export function useDepartments(companyId, isEnabled) {
  const queryClient = useQueryClient();

  return useQuery(
    ['departments', companyId],
    () => get(`/Company/${companyId}/departments`),
    {
      enabled: isEnabled,
      staleTime: 1000 * 20,
      initialData: () => {
        let cacheData = queryClient.getQueryData(['departments', companyId]);
        if (cacheData) {
          return cacheData.departments;
        }
      },
    }
  );
}

// 3.4 POST /api/Employee
export const usePostEmployee = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((formData) => post(`/Employee`, formData), {
    onSuccess: () => {
      queryClient.invalidateQueries(['employees']);
      enqueueSnackbar('Employee created', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: () => {
      enqueueSnackbar('Could not create Employee', {
        variant: 'error',
      });
    },
  });
};

// 3.3 PUT /api/Employee
export const usePutEmployee = (employeeId) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (formData) => {
      put(`/Employee`, formData);
    },
    {
      /*
       * @param d: data from put
       * @param v: data from form
       */
      onSuccess: (d, v) => {
        //console.log('onsuccess setquerydata', d, v)
        queryClient.setQueryData(['employee', employeeId], v);
        enqueueSnackbar('Employee updated', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      },
      onError: () => {
        enqueueSnackbar('Could not update Employee', {
          variant: 'error',
        });
      },
    }
  );
};

//  GET /api/ProductCategory
export function useProductCategory() {
  const queryClient = useQueryClient();

  return useQuery(['productCategories'], () => get(`/ProductCategory`), {
    staleTime: 1000 * 20,
    initialData: () => {
      let cacheData = queryClient.getQueryData(['c']);
      if (cacheData) {
        return cacheData;
      }
    },
  });
}

// POST /api/ProductCategory
export const usePostProductCategory = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((formData) => post(`/ProductCategory`, formData), {
    onSuccess: (newProduct) => {
      queryClient.invalidateQueries(['productCategories']);

      enqueueSnackbar('Product Category created', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: () => {
      enqueueSnackbar('Could not create Product Category', {
        variant: 'error',
      });
    },
  });
};

// POST /api/ProductCategory
export const usePostProductType = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((formData) => post(`/ProductType`, formData), {
    onSuccess: (newProduct) => {
      queryClient.invalidateQueries(['productCategories']);

      enqueueSnackbar('Product Type created', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: () => {
      enqueueSnackbar('Could not create Product Type', {
        variant: 'error',
      });
    },
  });
};

// POST api/ProductType/{id}/ProductFeature
export const usePostProductTypeFeature = (productTypeId) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (formData) =>
      post(`/ProductType/${productTypeId}/ProductFeature`, formData),
    {
      enabled: !!productTypeId,
      onSuccess: (newProductTypeFeature) => {
        queryClient.invalidateQueries(['productType', productTypeId]);

        enqueueSnackbar('ProductType Feature created', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      },
      onError: () => {
        enqueueSnackbar('Could not create ProductType Feature', {
          variant: 'error',
        });
      },
    }
  );
};

// POST /api/ProductFeatureData
export const usePostProductFeatureData = (featureId, typeId) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((formData) => post('/ProductFeatureData', formData), {
    enabled: !!featureId,
    onSuccess: (res) => {
      queryClient.invalidateQueries(['productType', typeId]);

      enqueueSnackbar('ProductType FeatureData created', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: () => {
      enqueueSnackbar('Could not create ProductType FeatureData', {
        variant: 'error',
      });
    },
  });
};

// PUT MODAL
export const usePutModalProductData = (props) => {
  const modalData = props.modalData;
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((formData) => put(modalData.url, formData), {
    onSuccess: (res) => {
      queryClient.invalidateQueries([modalData.rqCache]);

      enqueueSnackbar(`${modalData.title} updated`, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: () => {
      enqueueSnackbar(`Could not update ${modalData.title}`, {
        variant: 'error',
      });
    },
  });
};

// DELETE MODAL
export const useDeleteModalProductData = (props) => {
  const modalData = props.modalData;
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  // console.log('props', props)
  return useMutation((formData) => del(modalData.delUrl, formData), {
    onSuccess: (res) => {
      queryClient.invalidateQueries([modalData.rqCache]);
      enqueueSnackbar(`${modalData.title} deleted`, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: () => {
      enqueueSnackbar(`Could not delete ${modalData.title}`, {
        variant: 'error',
      });
    },
  });
};

export const useDel = (props) => {
  const { name, keys, url } = props;
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
//  console.log('UseDel',props)
  return useMutation((formData) => del(url, formData), {
    onSuccess: (res) => {
      queryClient.invalidateQueries(keys);
      enqueueSnackbar(`Succesfully removed `, {
        variant: 'success',
        autoHideDuration: 5000,
      });
    },
    onError: () => {
      enqueueSnackbar(`Could not delete ${name}`, {
        variant: 'error',
      });
    },
  });
};

export const useGet = (props) => {
  const { url, keys, id, cacheQuery, staleTime, enabled } = props;
  //console.log("USEGET :", enabled, id, url);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useQuery(keys, () => get(url), {
    enabled: enabled,
    // staleTime: !!staleTime || 1000 * 20,
    initialData: () => {
      let cacheData = queryClient.getQueryData(cacheQuery.keys);
      //  console.log("cacheData", cacheQuery.keys, cacheData);
      switch (keys[0] || '') {
        case 'company':
          return cacheData?.companies.find((c) => c.id === cacheQuery.id);
        case 'department':
          return cacheData?.departments.find((d) => d.id === cacheQuery.id);
        case 'employee':
          return cacheData?.employees.find((e) => e.id === cacheQuery.id);
        /* case "employeeByEmail":
          return cacheData?.employees.find((e) => e.email === cacheQuery.email); */
        case 'package':
          return cacheData?.productPackages.find((p) => p.id === cacheQuery.id);
        case 'product':
          // console.log("cacheData product", cacheData, cacheData.products?.find((p) => p.id === cacheQuery.id));
          return cacheData?.products?.find((p) => p.id === cacheQuery.id);
        case 'ticket':
          return cacheData?.tickets?.find((t) => t.id === cacheQuery.id);
        case 'ticketPost':
          return cacheData?.ticketPosts?.find((t) => t.id === cacheQuery.id);

        default:
          if (cacheData) {
            return cacheData;
          } else {
            // console.log("useGet: default", cacheData, id, key);
          }
      }
    },
    onError: () => {
      enqueueSnackbar(`Could not retrieve ${keys[0]}`, {
        variant: 'error',
      });
    },
  });
};

export const usePost = (props) => {
  const { url, name, keys } = props;
  // console.log("usePost - > ", url, name, keys);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((formData) => post(url, formData), {
    onSuccess: (res) => {
      queryClient.invalidateQueries(keys.all);

      enqueueSnackbar(`${name} success`, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: (error) => {
      enqueueSnackbar(`Could not perform request: ${error.response?.data}`, {
        variant: 'error',
        autoHideDuration: 5000,
      });
    },
  });
};

export const usePut = (props) => {
  const { url, name, keys } = props;
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  //console.log("usePut - > ", url, name, keys);

  return useMutation((formData) => put(url, formData), {
    /*
     * @param data: data from server
     * @param value: data from form
     */
    onSuccess: (data, value) => {
      queryClient.invalidateQueries(keys.all);
      queryClient.setQueryData(keys.single, data);
      enqueueSnackbar(`${name} updated`, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: (error) => {
      enqueueSnackbar(`Could not update ${name}. ${error.response?.data}`, {
        variant: 'error',
        autoHideDuration: 5000,
      });
    },
  });
};

/* CRUD */

const del = async (url, formData) => {
  const bearer = await getBearer();
  innovit.defaults.headers['Authorization'] = bearer;
  //console.log('bearer', bearer);
  url = url.replace('undefined', formData.id);

  if (bearer) {
    const { data } = await innovit.delete(url);
    return data;
  } else {
    return null;
  }
};

const get = async (url) => {
  const bearer = await getBearer();
  innovit.defaults.headers['Authorization'] = bearer;
  //console.log("bearer", bearer); // currently idtoken

  if (bearer) {
    const { data } = await innovit.get(url);
    return data;
  } else {
    return null;
  }
};

const put = async (url, formData) => {
  // console.log('put api', url, formData);
  const bearer = await getBearer();
  innovit.defaults.headers['Authorization'] = bearer;
  //console.log('bearer', bearer)
  if (bearer) {
    const { data } = await innovit.put(url, formData);
    return data;
  } else {
    return null;
  }
};

const post = async (url, formData) => {
  const bearer = await getBearer();
  innovit.defaults.headers['Authorization'] = bearer;
  //console.log('bearer', bearer)

  if (bearer) {
    const { data } = await innovit.post(url, formData);
    // console.log("post api", data); PS: happens AFTER onSuccess
    return data;
  } else {
    return null;
  }
};
