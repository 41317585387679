import { memo, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { usePost, usePut } from 'http/useInnovit';
import { rqPost } from 'http/apiRoutes';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { countries } from 'data/templates';
import { openInNewTab } from 'functions/misc';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import * as yup from 'yup';
import { FormSwitch, FormText } from 'components/form';
import {
  FormAccordionSummary,
  FormUIAddress,
  FormBoxElevated,
  FormBox,
} from 'components/form';

import {
  Button,
  Accordion,
  AccordionDetails,
  Grid,
  List,
  IconButton,
  Paper,
} from '@mui/material';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import FeedRoundedIcon from '@mui/icons-material/FeedRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import TravelExploreRoundedIcon from '@mui/icons-material/TravelExploreRounded';
import useWindowsDimension from 'functions/useWindowsDimension';

const postalCodes = require('norway-postal-codes');
let _ = require('lodash');

const schema = yup
  .object({
    companyId: yup.string().required(),
    name: yup
      .string()
      .matches(/^(|[A-å0-9 ]{2,50})$/, 'Between 2-50 characters')
      .required('Name is required'),
    email: yup.string().email(),
    mainAddress: yup.object().shape({
      street: yup
        .string()
        .matches(/^(|[A-å0-9 ]{2,50})$/, 'Between 2-50 characters')
        .required('Street is required'),
      postalCode: yup
        .string()
        .matches(/^(|[0-9]{4,4})$/, '4 numbers required')
        .required('Postal code is required'),
      city: yup
        .string()
        .matches(/^(|[A-å0-9 ]{2,50})$/, 'Between 2-50 characters')
        .required('City is required'),
    }),
    /* deliveryAddress: yup.object().shape({
      street: yup
        .string()
        .matches(/^(|[A-å0-9 ]{2,50})$/, "Between 2-50 characters")
        .required("Street is required"),
      postalCode: yup
        .string()
        .matches(/^(|[0-9]{4,4})$/, "4 numbers required")
        .required("Postal code is required"),
      city: yup
        .string()
        .matches(/^(|[A-å0-9 ]{2,50})$/, "Between 2-50 characters")
        .required("City is required"),
    }), */
    active: yup.boolean(),
  })
  .required();

export const DepartmentForm = (props) => {
  /* INITIALIZATIONS */
  const { department } = props;
  const { height } = useWindowsDimension();
  const { companyId, departmentId } = useParams();
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  let navigate = useNavigate();
  // console.log("DepartmentForm", department);

  // states
  const [addressPostalArea, setAddressPostalArea] = useState(
    postalCodes[department.mainAddress.postalCode]
  );
  const [deliveryArea, setDeliveryArea] = useState(
    postalCodes[department.deliveryAddress?.postalCode]
  );

  // api
  const postDepartment = usePost(rqPost.department(companyId));
  const putDepartment = usePut(rqPost.department(companyId));
  const departmentDeactivate = usePost(
    rqPost.departmentDeactivate(companyId, departmentId)
  );

  /* FUNCTIONS */

  // handles

  const handleConfirm = (event) => {
    //console.log("delete");
    departmentDeactivate.mutate(
      { id: departmentId },
      { onSuccess: navigate(`/companies/${companyId}/departments`) }
    );
  };

  // form
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: department,
    // resolver: yupResolver(schema),
  });

  /* FUNCTIONS */

  // handles
  const handleBlur = (e) => {
    e.preventDefault();
    if (e.target.name === 'mainAddress.postalCode') {
      setAddressPostalArea(postalCodes[`${e.target.value}`]);
    }
    if (e.target.name === 'deliveryAddress.postalCode') {
      setDeliveryArea(postalCodes[`${e.target.value}`]);
    }
  };

  // form
  const onSubmit = (data, e) => {
    e.preventDefault();
    //console.log("onSubmit", data);
    let payload = _.cloneDeep(data);
    payload.companyId = companyId;
    if (!payload.deliveryAddress.street) {
      delete payload.deliveryAddress;
    }
    // XXX: this is a hack to get the form to work
    delete payload.default;

    if (isDirty) {
      if (departmentId === 'new') {
        postDepartment.mutate(payload, {
          onSuccess: (data) => {
            //console.log("onSuccess", data);
            navigate(`/companies/${companyId}/departments/${data.id}`);
          },
        });
      } else {
        putDepartment.mutate(payload);
        // console.log("onsubmit put", payload);
      }
    }
  };

  const DefaultJSX = memo(() => {
    return <FormSwitch name='default' control={control} />;
  });
  const GoogleJSX = memo(() => {
    return (
      <IconButton
        onClick={(e) =>
          openInNewTab(
            e,
            'https://www.google.com/maps/place/' +
              department.mainAddress?.street +
              ',' +
              department.mainAddress?.postalCode +
              department.mainAddress?.city +
              '/'
          )
        }
      >
        <TravelExploreRoundedIcon sx={{ mt: -1, fontSize: 32 }} />
      </IconButton>
    );
  });

  useEffect(() => {
    reset(department);
  }, [departmentId, department?.id, reset, department]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} name='departmentForm'>
        <Grid
          container
          sx={{
            height: Math.ceil(height - 154),
          }}
        >
          <Grid item xs={12}>
            <FormBox
              Icon={FeedRoundedIcon}
              title='Basic Information'
              FormJSX={GoogleJSX}
            />
          </Grid>
          <List
            sx={{
              width: '100%',
              height: Math.ceil(height - 330),
              p: 2,
            }}
          >
            <Grid
              container
              sx={{
                pt: 2,
              }}
            >
              
              <Grid item xs={5} sx={{ pr: 2, mb: 2 }}>
                <FormText  sx = {{'& .Mui-focused':{
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    }, }} name='name' control={control} label='Name' />
              </Grid>
              <Grid item xs={4} sx={{ pr: 2 }}>
                <FormText  sx = {{'& .Mui-focused':{
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    }, }} name='email' control={control} label='e-Mail' />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name='phone'
                  control={control}
                  rules={{ validate: matchIsValidTel }}
                  render={({ field, fieldState }) => (
                    <MuiTelInput
                    sx = {{'& .Mui-focused':{
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    }, }}
                      {...field}
                      continents={['EU']}
                      defaultCountry='NO'
                      error={fieldState.invalid}
                      forceCallingCode={true}
                      helperText={fieldState.invalid ? 'Phone is invalid' : ''}
                      label='Phone'
                      variant='standard'
                    />
                  )}
                />
              </Grid>
            </Grid>
            {/* Department Address */}
            <Grid
              item
              xs={12}
              sx={{
                mb: 2,
              }}
            >
              <Paper variant='elevation3' sx={{}}>
                <Accordion
                  disableGutters
                  sx={{
                    boxShadow: 0,
                    backgroundColor: 'transparent',
                  }}
                >
                  <FormAccordionSummary
                    Icon={HomeRoundedIcon}
                    title='Department Address'
                  />
                  <AccordionDetails>
                    <FormUIAddress
                      address='mainAddress'
                      addressPostalArea={addressPostalArea}
                      control={control}
                      countries={countries}
                      handleBlur={handleBlur}
                    />
                  </AccordionDetails>
                </Accordion>
              </Paper>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                mb: 2,
              }}
            >
              <Paper variant='elevation3' sx={{}}>
                <Accordion
                  disableGutters
                  sx={{
                    boxShadow: 0,
                    backgroundColor: 'transparent',
                  }}
                >
                  <FormAccordionSummary
                    Icon={HomeRoundedIcon}
                    title='Delivery Address'
                  />
                  <AccordionDetails>
                    <FormUIAddress
                      address='deliveryAddress'
                      addressPostalArea={deliveryArea}
                      control={control}
                      countries={countries}
                      handleBlur={handleBlur}
                    />
                  </AccordionDetails>
                </Accordion>
              </Paper>
            </Grid>

            {/*     Active/Inactive */}
            <Grid
              item
              xs={12}
              sx={{
                mt: 2,
              }}
            >
              <FormBoxElevated
                Icon={StarRoundedIcon}
                title='Default Department'
                FormJSX={DefaultJSX}
              />
            </Grid>
            {/*     Active/Inactive */}
            {/* <Grid
              item
              xs={12}
              sx={{
                mt: 2,
              }}
            >
              <FormBoxElevated
                Icon={DomainDisabledRoundedIcon}
                title="Active"
                FormJSX={ActiveJSX}
              />
            </Grid> */}
          </List>
          <Grid
            item
            xs={12}
            sx={{ m: 2, display: 'flex', flexDirection: 'row' }}
          >
            <Button
              sx={{ m: 2 }}
              variant='contained'
              onClick={() => setDeleteConfirm(!deleteConfirm ? true : false)}
            >
              {/*  {postEmployeeDeactivate.isLoading
                ? "Deleting Company..."
                : "Delete Company"} */}
              DELETE
            </Button>
            {!!deleteConfirm === true && (
              <Button
                sx={{ m: 2 }}
                variant='contained'
                color='secondary'
                onClick={handleConfirm}
              >
                Confirm Delete
              </Button>
            )}
            <Grid sx={{ flexGrow: 1 }}></Grid>{' '}
            {departmentId !== 'new' && (
              <Button
                sx={{ m: 2 }}
                type='submit'
                variant='contained'
                disabled={!isDirty}
              >
                {putDepartment.isLoading
                  ? 'Updating Department...'
                  : 'Update Department'}
              </Button>
            )}
            {departmentId === 'new' && (
              <Button
                sx={{ m: 2 }}
                type='submit'
                variant='contained'
                disabled={!isDirty}
              >
                {postDepartment.isLoading
                  ? 'Creating Department...'
                  : 'Create Department'}
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    </>
  );
};
