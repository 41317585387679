import { Outlet } from "react-router";
import { PackagesList } from "./PackagesList";
import useWindowsDimension from "functions/useWindowsDimension";
import { Grid, Paper } from "@mui/material";


export const Packages = () => {
  
  const { height } = useWindowsDimension();

  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
      }}
    >
      <Grid item xs={6} sx={{ pr: 1, m: 0, width: "100%" }}>       
          <PackagesList />       
      </Grid>
      <Grid item xs={6}>
        <Paper
          variant="elevation3"
          sx={{           
            height: Math.ceil(height - 158),
          }}
        >
          <Outlet />
        </Paper>
      </Grid>
    </Grid>
  );
};
