import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import { useGet } from 'http/useInnovit';
import { rq } from 'http/apiRoutes';
import useWindowsDimension from 'functions/useWindowsDimension';
import { variables } from 'data/variables';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {
  Avatar,
  ListItemAvatar,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import GetAvatarInitials from 'functions/GetAvatarInitials';

export const Company = () => {
  /* INITIALIZATIONS */
  const { width } = useWindowsDimension();
  let navigate = useNavigate();
  const { companyId } = useParams(); // check if companyId is valid?

  // api
  let isEnabled = false;
  const { data: company } = useGet(rq.company(companyId, isEnabled), isEnabled);
  //const { data: departments } = useGet(rq.departments(companyId, isEnabled), isEnabled);
  //const { data: employees } = useGet(rq.employees(companyId, isEnabled), isEnabled);

  const missingPhoto = {
    PHOTO_URL: process.env.PUBLIC_URL + '/img/noCompany.png',
  };

  const CompanyHeaderLinks = [
    {
      name: 'Details',
      component: { Link },
      to: `/companies/${companyId}`,
    },

    {
      name: 'Departments',
      component: { Link },
      to: `/companies/${companyId}/departments`,
    },
    {
      name: 'Resources',
      component: { Link },
      to: `/companies/${companyId}/departments`,
    },
    {
      name: 'Employee',
      component: { Link },
      to: `/companies/${companyId}/employees`,
    },
    {
      name: 'Packages',
      component: { Link },
      to: `/companies/${companyId}/packages`,
    },

    {
      name: 'Agreements',
      component: { Link },
      to: `/companies/${companyId}/agreements`,
    },

    {
      name: 'Products',
      component: { Link },
      to: `/companies/${companyId}/products`,
    },

    {
      name: 'Tickets',
      component: { Link },
      to: `/companies/${companyId}/tickets`,
    },

    {
      name: 'Finance',
      component: { Link },
      to: `/companies/${companyId}/finance`,
    },
  ];

  /* FUNCTIONS */

  return (
    <Grid container direction='row' spacing={0}>
      <Grid item xs={12} sx={{}}>
        <Paper
          variant='elevation3'
          sx={{
            p: 0,
            mb: 2,
          }}
        >
          <ListItem>
            <ListItemAvatar>
              <img
                src={company?.url || missingPhoto.PHOTO_URL}
                alt='previewUrl'
                style={{
                  width: 128,
                  height: 64,
                  borderRadius: 3,
                  objectFit: 'scale-down',
                  //boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
                }}
              />
            </ListItemAvatar>
            <ListItemText
              sx={{ ml: 2 }}
              primary={
                <Typography variant='subHeader'>{company?.name}</Typography>
              }
              secondary={company?.email}
            />
          </ListItem>

          <Grid item xs={12} sx={{ p: 0.5 }}>
            <ToggleButtonGroup
              sx={{
                width: Math.ceil(width - 350),
                ml: 0.5,
                mb: 0.2,
                p: 0,
              }}
              color='secondary'
              size='small'
              orientation='horizontal'
              // value={currentView}
              exclusive
              aria-label='text alignment'
            >
              {CompanyHeaderLinks.map((obj, index) => {
                //console.log('headerlink object', obj);
                return (
                  <ToggleButton
                    key={index}
                    name={obj.name}
                    onClick={() => navigate(obj.to)}
                    // Link={obj.to}
                    sx={{
                      m: 0,
                      p: 0,
                      width: 200,
                      borderRadius: 2,
                      borderLeft: 2,
                      borderRight: 2,
                      borderLeftColor: 'primary.light',
                      borderRightColor: 'primary.light',
                    }}
                    value={index}
                    aria-label='left aligned'
                  >
                    {obj.name}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} sx={{}}>
        <Outlet />
      </Grid>
    </Grid>
  );
};
