import { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useGet } from "http/useInnovit";
import { rq } from "http/apiRoutes";
import useWindowsDimension from "functions/useWindowsDimension";
import { getFilteredList } from "functions/misc";
import { SearchFilter } from "components/ui/SearchFilter";

import { Grid, Paper, List } from "@mui/material";
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";
import { SingleDepartmentView } from "components/ui/SingleDepartmentView";

let _ = require("lodash");

export const Departments = () => {
  /* INITIALIZATIONS */
  let navigate = useNavigate();
  const { companyId } = useParams();
  const { height } = useWindowsDimension();

  // api
  const { data } = useGet(rq.departments(companyId));

  // states
  const [departments, setDepartments] = useState([]);

  /* FUNCTIONS */

  // handles
  const handleNew = (e) => {
    e.preventDefault();
    navigate(`/companies/${companyId}/departments/new`);
  };

  const handleSearch = (e) => {
    setDepartments(getFilteredList(data?.departments, e.target.value));
  };

  useEffect(() => {
    let tmp = _.cloneDeep(data?.departments);
    setDepartments(tmp);
  }, [data]);

  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
      }}
    >
      <Grid item xs={6} sx={{ pr: 1, m: 0, }}>
  
        <SearchFilter
          Icon={PeopleAltTwoToneIcon}
          Header="Department"
          handleSearch={handleSearch}
          handleNew={handleNew}
        />
        <List
          sx={{
            height: Math.ceil(height - 286),
            width: "100%",
            mb: 0,
            p: 2,
            backgroundColor: "primary.back",
            overflow: "auto",
          }}
        >
          {departments?.map((dep, index) => (
            <Paper
              elevation={1}
              sx={{ mb: 1 }}
              key={index}
              onClick={() => {
                navigate("/companies/" + companyId + "/departments/" + dep.id);
              }}
            >
              {
                <SingleDepartmentView
                  key={index}
                  index={index}
                  department={dep}
                />
              }
            </Paper>
          ))}
        </List>

        <Paper
          variant="elevation3"
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        ></Paper>
      </Grid>

      <Grid item xs={6} sx={{ m: 0, p: 0 }}>
        <Paper
          variant="elevation3"
          sx={{
            p: 2,
            height: Math.ceil(height - 158),
          }}
        >
          <Outlet />
        </Paper>
      </Grid>
    </Grid>
  );
};
