import { Paper, Typography, Box } from "@mui/material";

export const FormBoxElevated = (props: any) => {
  const { Icon, title, FormJSX } = props;

  return (
    <>
      <Paper
        elevation={1}
        sx={{
          backgroundColor: "primary.main",
          borderRadius: 2,
          boxShadow:
            "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 25%), 0px 1px 8px 0px rgb(0 0 0 / 10%)",
        }}
      >
        <Box sx={{ alignSelf: "center", display: "flex", }}>
          {!!Icon && <Icon sx={{ fontSize: 26, m: 2 }} />}
          <Typography
            sx={{
              m: 2,
              ml: -1,
              pt: 0.5,
              flexGrow: 1,
              textTransform: "uppercase",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontWeight: "bold",
              fontSize: 14,
              letterSpacing: 2,
            }}
          >
            {title}
          </Typography>

          {!!FormJSX && <FormJSX />}
        </Box>
      </Paper>
    </>
  );
};
