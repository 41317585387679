import { useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router';
// import { useForm } from "react-hook-form";
//@ts-ignore
import GetAvatarInitials from 'functions/GetAvatarInitials';
import GetPriorityColor from 'functions/GetPriorityColor';
import { emptyProduct } from 'data/templates';
import useWindowsDimension from 'functions/useWindowsDimension';
// import { FormSelect } from "components/form";
import { SingleEmployeeView } from 'components/ui/SingleEmployeeView';
import { SearchFilter } from 'components/ui/SearchFilter';

import {
  Avatar,
  Button,
  ButtonGroup,
  Grid,
  List,
  Box,
  Paper,
  Stack,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  IconButton,
} from '@mui/material';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import PersonRemoveRoundedIcon from '@mui/icons-material/PersonRemoveRounded';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
// import DevicesOtherRoundedIcon from "@mui/icons-material/DevicesOtherRounded";
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import SourceRoundedIcon from '@mui/icons-material/SourceRounded';
import ArtTrackRoundedIcon from '@mui/icons-material/ArtTrackRounded';
import { rq, rqPost } from 'http/apiRoutes';
import { useGet, usePut } from 'http/useInnovit';
//@ts-ignore
import { EmployeeDrawer } from 'pages/Companies/EmployeeDrawer';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
// import { SingleContactView } from "components/ui/SingleContactView";
import { memo } from 'react';
import _ from 'lodash';
import ownerAtom from 'data/atoms/ownerAtom';
import { useAtom } from 'jotai';
import { SingleCompanyView } from 'components/ui/SingleCompanyView';

const variables = {
  PHOTO_URL: process.env.PUBLIC_URL + '/img/',
};

const Issues = (props: any) => {
  const { step1, step2, step3, comment, product } = props;

  return (
    <Grid container direction={'row'} sx={{ flexGrow: 1, m: 0, p: 1 }}>
      {/*COMMUNICATION */}

      <Stack direction={'row'} sx={{ pl: 1, pr: 1 }}>
        <Grid item sx={{ m: 0, pr: 1 }}>
          {!!product[0]?.url ? (
            <Avatar
              variant='rounded'
              alt={product[0]?.name || ''}
              src={variables.PHOTO_URL + product[0].url + '.png'}
              sx={{ p: 0, width: 72, height: 72, borderRadius: 3 }}
            />
          ) : (
            <Avatar
              {...GetAvatarInitials(
                product[0]?.name || 'empty sender',
                72,
                72,
                3
              )}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <Typography
            sx={{
              m: 0,
              pl: 1,
              letterSpacing: 3,
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              fontSize: 14,
            }}
          >
            {step1 || 'none'}
          </Typography>
          <Typography
            sx={{
              m: 0,
              pl: 1,
              letterSpacing: 3,
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              fontSize: 14,
            }}
          >
            {step2 || 'none'}
          </Typography>
          <Typography
            sx={{
              m: 0,
              pl: 1,
              letterSpacing: 3,
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              fontSize: 14,
            }}
          >
            {step3 || 'none'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            sx={{
              m: 0,
              pl: 1,
              letterSpacing: 3,
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'wrap',
              fontWeight: 'bold',
              fontSize: 14,
            }}
          >
            {comment}
          </Typography>
        </Grid>
      </Stack>
    </Grid>
  );
};

const Header = (props: any) => {
  const { icon: IH, action: AH, headerText: HT } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        p: 1,
      }}
    >
      <Box sx={{ pt: 1 }}>
        <IH sx={{ fontSize: 40 }} />
      </Box>
      <Box sx={{ pl: 2, pt: 2, flexGrow: 1 }}>
        <Typography noWrap >
          {HT} {props.children}
        </Typography>
      </Box>
      <Box sx={{}}>{AH && <AH sx={{ fontSize: 40 }} />}</Box>
    </Box>
  );
};

export const TicketDetails = () => {
  const { height } = useWindowsDimension();
  const { ticketId } = useParams<string>();
  const [ticket, setTicket] = useState<any>({});
  const [owner, setOwner] = useAtom(ownerAtom)

  // const owner = JSON.parse(window.localStorage.getItem('OWNER'));

  const reducer = (state:any, action:any) => {
    console.log('ticket state', state);
    switch (action.type) {
      case 'RESET_STATE':
        return initialState;

      case 'CHANGE_PRIORITY':
        return {
          ...state,
          priority: action.priority,
        };
      case 'CHANGE_BOARD':
        return {
          ...state,
          board: action.board,
        };
      case 'CHANGE_CONTACT':
        return {
          ...state,
          contact: action.contact,
        };
      case 'CHANGE_RESPONSIBLE':
        return {
          ...state,
          responsible: action.responsible,
        };
      case 'REMOVE_RESPONSIBLE':
        return {
          ...state,
          responsible: action.responsible,
        };
      case 'SET_INITIAL_TECHNICIANS':
        return {
          ...state,
          technicians: action.technicians,
        };
      case 'ADD_TECHNICIANS':
        console.log('ADD_TECHNICIANS', [
          ...state.technicians,
          action.technicians,
        ]);
        return {
          ...state,
          technicians: [...state.technicians, action.technicians],
        };
      case 'CHANGE_COMPANY':
        return {
          ...state,
          company: action.company,
        };
      case 'CHANGE_APP_COMPANY_ID':
        return {
          ...state,
          appCompanyId: action.appCompanyId,
        };
      default:
        return null;
    }
  };

  let initialState = {
    priority: ticket?.priority || 1,
    board: ticket?.board || 2,
    contact: ticket?.contact || null,
    responsibleId: ticket?.responsibleId || null,
    responsible: ticket?.responsible || null,
    technicians: ticket?.technicians || [],
    company: ticket?.company || null,
    appCompanyId: ticket?.appCompanyId || null,
  };
  // states
  const [state, actions] = useReducer(reducer, initialState);

  // api
  let isEnabled =
    ticketId !== 'new' ? (state.appCompanyId === null ? true : false) : false;
  let _ticketId ='new'
  if(ticketId !== undefined){
    _ticketId = ticketId.toString();
  }
  const { data } = useGet(rq.ticket(_ticketId, isEnabled,'0'), isEnabled);
  const putTicket = usePut(rqPost.ticketPut(_ticketId,'0',isEnabled),isEnabled);

  // useEffect set initial ticket state
  useEffect(() => {
    if (data) {
      console.log('initial ticket data', data);
      setTicket(data);
      actions({ type: 'CHANGE_CONTACT', contact: data.contact });
      actions({ type: 'CHANGE_RESPONSIBLE', responsible: data.responsible });
      actions({ type: 'CHANGE_PRIORITY', priority: data.priority });
      actions({ type: 'CHANGE_BOARD', board: data.board });
      actions({
        type: 'SET_INITIAL_TECHNICIANS',
        technicians: data.technicians,
      });
      actions({ type: 'CHANGE_COMPANY', company: data.company });
      actions({
        type: 'CHANGE_APP_COMPANY_ID',
        appCompanyId: data.appCompanyId,
      });
    }

  }, [data, ticket]);

  

  /* FUNCTIONS */

  // handles

  const handlePrioritySubmit = (e: React.ChangeEvent<any>, priority:number) => {
    e.preventDefault();
    actions({ type: 'CHANGE_PRIORITY', priority: priority });
    ticket.priority = priority;
    putTicket.mutate(ticket);
  };

  const handleBoardSubmit = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    let board = e.target.value;
    actions({ type: 'CHANGE_BOARD', board });
    ticket.board = board;
    putTicket.mutate(ticket);
  };

  const handleResponsibleSubmit = (e: React.ChangeEvent<any>, responsible: any) => {
    e.preventDefault();
    ticket.responsible = responsible;
    ticket.responsibleId = responsible.id;
    actions({ type: 'CHANGE_RESPONSIBLE', responsible });
    let _ticket = _.cloneDeep(ticket);

    console.log('_ticket', _ticket);
    delete _ticket.appCompanyId;
    putTicket.mutate(_ticket);
  };

  const handleContactSubmit = (e: React.ChangeEvent<any>, contact: any) => {
    e.preventDefault();
    actions({ type: 'CHANGE_CONTACT', contact });
    ticket.contact = contact;
    putTicket.mutate(ticket);
  };

  const handleTechniciansSubmit = (event: React.ChangeEvent<any>, technicians: any) => {
    actions({ type: 'ADD_TECHNICIANS', technicians });
    ticket.technicians = [...state.technicians, technicians];
    putTicket.mutate(ticket);
  };

  const handleRemoveResponsibleSubmit = (e: React.ChangeEvent<any>, responsible: any) => {
    e.preventDefault();
    actions({ type: 'REMOVE_RESPONSIBLE', responsible });
    ticket.responsible = null;
    putTicket.mutate(ticket);
  };

  const ResponsibleGfx = memo((props: any) => {
    return (
      <IconButton
        sx={{ fontSize: 26, p: 0, m: 0 }}
        onClick={(e) => props.handleRemoveResponsibleSubmit(e)}
      >
        <DeleteForeverIcon />
      </IconButton>
    );
  });

  return (
    <div>
      <Grid container>
        <Grid item xs={6}>
          <Grid sx={{}}>
            {state?.company && <SingleCompanyView company={state.company} />}
          </Grid>
          <Paper
            variant='elevation3'
            sx={{
              mb: 2,
              p: 1,
              backgroundColor: 'primary.main',
            }}
          >
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <Grid item xs={12}>
                <Stack display={'flex'} flexGrow={1} direction={'row'}>
                  <Box
                    sx={{
                      flexGrow: 1,
                    }}
                  >
                    <Header icon={ArtTrackRoundedIcon} headerText='priority' />
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12} sx={{ m: 1, display: 'flex' }}>
                <ButtonGroup
                  variant='contained'
                  aria-label='outlined primary button group'
                  size='small'
                >
                  <Button
                    key='NONE'
                    sx={{
                      width: 100,
                      backgroundColor:
                        state.priority === 0
                          ? GetPriorityColor(state.priority)
                          : 'primary.main',
                    }}
                    onClick={(e) => {
                      handlePrioritySubmit(e, 0);
                    }}
                  >
                    None
                  </Button>
                  <Button
                    key='LOW'
                    sx={{
                      width: 100,
                      backgroundColor:
                        state.priority === 1
                          ? GetPriorityColor(state.priority)
                          : 'primary.main',
                    }}
                    onClick={(e) => {
                      handlePrioritySubmit(e, 1);
                    }}
                  >
                    Low
                  </Button>
                  <Button
                    key='NORMAL'
                    sx={{
                      width: 100,
                      backgroundColor:
                        state.priority === 2
                          ? GetPriorityColor(state.priority)
                          : 'primary.main',
                    }}
                    onClick={(e) => {
                      handlePrioritySubmit(e, 2);
                    }}
                  >
                    Normal
                  </Button>
                  <Button
                    key='HIGH'
                    sx={{
                      width: 100,
                      backgroundColor:
                        state.priority === 3
                          ? GetPriorityColor(state.priority)
                          : 'primary.main',
                    }}
                    onClick={(e) => {
                      handlePrioritySubmit(e, 3);
                    }}
                  >
                    High
                  </Button>

                  <Button
                    key='CRITICAL'
                    sx={{
                      width: 100,
                      backgroundColor:
                        state.priority === 4
                          ? GetPriorityColor(state.priority)
                          : 'primary.main',
                    }}
                    onClick={(e) => {
                      handlePrioritySubmit(e, 4);
                    }}
                  >
                    Critical
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Paper>
          <form
            /* onSubmit={handleSubmit(onSubmit)}  */
            name='boardForm'
          >
            <Paper
              variant='elevation3'
              sx={{
                mb: 2,
                p: 1,
                backgroundColor: 'primary.main',
              }}
            >
              <Grid
                container
                direction='row'
                justifyContent='space-between'
                alignItems='center'
              >
                <Grid item xs={8}>
                  <Header icon={SourceRoundedIcon} headerText='board'></Header>
                </Grid>
                <Grid item xs={4} sx={{ pr: 2 }}>
                  <FormControl variant='standard' fullWidth>
                    <InputLabel id='demo-simple-select-label'>Board</InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={state.board}
                      label='Board'
                      onChange={(e: any) => {
                        handleBoardSubmit(e);
                      }}
                    >
                      <MenuItem value={0}>Sale</MenuItem>
                      <MenuItem value={1}>Support</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>
          </form>
          <Paper
            variant='elevation3'
            sx={{
              mb: 2,
              backgroundColor: 'primary.main',
            }}
          >
            <Header icon={ReportProblemRoundedIcon} headerText='Issue' />
            <Typography
              sx={{
                m: 0,
                ml: 2,
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
                letterSpacing: 2,
                fontSize: 14,
                color: 'secondary.text',
              }}
            >
              #{ticket?.id}
            </Typography>
            <Issues
              product={ticket?.product || emptyProduct}
              step1={ticket?.areaSelection || 'none'}
              step2={ticket?.companyProductSelection || 'none'}
              step3={ticket?.autoAnswerSelection || 'none'}
              comment={ticket?.comment}
            />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Grid sx={{ ml: 2, mb: 2 }}>
          
              <SearchFilter
                Icon={GroupsRoundedIcon}
                Header='contacts'
                gfx={
                  state?.company && (
                    <EmployeeDrawer
                      buttonIcon={<ChangeCircleIcon sx={{ fontSize: 40 }} />}
                      companyId={state?.company.id}
                      handleEmployeeSubmit={handleContactSubmit}
                    />
                  )
                }
              />
           
            <List
              sx={{
                height: 190,
                overflow: 'auto',
                backgroundColor: 'primary.back',
                p: 1,
              }}
            >
              {ticket?.contact && (
                <SingleEmployeeView
                  employee={ticket.contact}
                  action={PersonRemoveRoundedIcon}
                />
              )}
            </List>
            <Paper
              variant='elevation3'
              sx={{
                p: 2,
                height: 64,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
            ></Paper>
          </Grid>

          <Grid sx={{ ml: 2, mb: 2 }}>
          
              <SearchFilter
                Icon={PersonOutlineRoundedIcon}
                Header='responsible'
                gfx={
                  state?.appCompanyId && (
                    <EmployeeDrawer
                      buttonIcon={<ChangeCircleIcon sx={{ fontSize: 40 }} />}
                      companyId={owner.id}
                      handleEmployeeSubmit={handleResponsibleSubmit}
                    />
                  )
                }
              />
          

            <List
              sx={{
                height: 100,
                overflow: 'auto',
                backgroundColor: 'primary.back',
                p: 1,
              }}
            >
              {state?.responsible && (
                <SingleEmployeeView
                  employee={state.responsible}
                  action={PersonRemoveRoundedIcon}
                  gfx={
                    <ResponsibleGfx
                      handleRemoveResponsibleSubmit={
                        handleRemoveResponsibleSubmit
                      }
                    />
                  }
                />
              )}
            </List>
            <Paper
              variant='elevation3'
              sx={{
                p: 2,
                height: 64,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
            ></Paper>
          </Grid>

          <Grid sx={{ ml: 2, mb: 2 }}>
         
              <SearchFilter
                Icon={EngineeringIcon}
                Header='technicians'
                gfx={
                  state?.appCompanyId && (
                    <EmployeeDrawer
                      buttonIcon={<ChangeCircleIcon sx={{ fontSize: 40 }} />}
                      companyId={owner.id}
                      handleEmployeeSubmit={handleTechniciansSubmit}
                    />
                  )
                }
                /*               
                filterValue={filterType}
                handleFilterChange={handleFilterChange}
                handleSearch={handleSearch}
                handleNew={handleNew}
                selectList={[]}
                */
              />
           
            <List
              sx={{
                height: Math.ceil(height - 834),
                overflow: 'auto',
                backgroundColor: 'primary.back',
                p: 2,
              }}
            >
              {state.technicians?.length > 0 &&
                state.technicians?.map((technician:any, index:number) => (
                  // console.log('technicians in the ticket', technician),
                  <SingleEmployeeView
                    key={index}
                    employee={technician}
                    action={PersonRemoveRoundedIcon}
                  />
                ))}
            </List>
            <Paper
              variant='elevation3'
              sx={{
                p: 2,
                height: 64,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
            ></Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
