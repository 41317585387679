
import Divider from "@mui/material/Divider";

export const variables: any = {
  PHOTO_URL: process.env.PUBLIC_URL + "/img/",
};

export const mainAddress: any = {
  street: "Kanalveien 52C",
  postalCode: "5068",
  city: "Bergen",
  postBox: "",
  country: "Norway",
};

export const DummyDefaultDepartment: any = {
  id: "55379ea3-2a9a-4534-a558-c0d6715bebe6",
  companyid: "95f58beb-95b5-453b-bf26-9dcd58d17358",
  name: "Administrasjon",
  phone: 94274236,
  email: "administrasjon@areo.no",
  address: mainAddress,
  default: true,
};

export const mainCompany: any = {
  logo: "",
  name: "CC Solution AS",
  email: "suport@ccolution.no",
  webpage: "www.ccsolution.no",
  phone: "55910250",
  organizationNumber: "99968574",
  mainAddress: mainAddress,
  invoiceAddress: mainAddress,
  deliveryAddress: mainAddress,
};

export const Hardware: any = [
  {
    id: 1,
    name: "Desktop",
  },
  {
    id: 2,
    name: "Laptop",
  },
  {
    id: 3,
    name: "Mobile",
  },
  {
    id: 4,
    name: "Monitor",
  },
];

export const parent: any = {
  department: "department",
  mainaddress: "mainaddress",
  billingaddress: "billingaddress",
  deliveryaddress: "deliveryaddress",
  homeoffice: "homeoffice",
  employee: "employee",
};

export const emptyDepartment: any = {
  companyId: "",
  name: "",
  phone: "",
  email: "",
  mainAddress: {
    street: "",
    postalCode: "",
    city: "",
    postBox: "",
    country: "",
  },
};

export const dummyProductData: any = [
  {
    id: "HL-1",
    logo: null,
    category: "Hardware",
    type: "Laptop",
    brand: "Lenovo",
    name: "Lenovo L14 i5 8gb 256gb single",
    description: "mega bra maskien kjøp denne",
    unitCost: 6000,
    retailPrice: 8790,
    display: "2560 x 1440",
    os: "Windows 10",
    processor: "Intel Core I5",
    graphic: "Intel Iris Xe",
    memory: "32GB ram",
    storage: "2048GB",
    mobile4G: "3G",
    interfaces: "HDMI",
    warranty: "3 Year pickup deliver",
  },
  {
    id: "SW-1",
    logo: null,
    category: "Software",
    type: "Laptop",
    brand: "Lenovo",
    name: "Lenovo L14 i5 8gb 256gb single",
    description: "mega bra maskien kjøp denne",
    unitCost: 6000,
    retailPrice: 8790,
    display: "2560 x 1440",
    os: "Windows 10",
    processor: "Intel Core I5",
    graphic: "Intel Iris Xe",
    memory: "32GB ram",
    storage: "2048GB",
    mobile4G: "3G",
    interfaces: "HDMI",
    warranty: "3 Year pickup deliver",
  },
];

export const ProductData: any = {
  id: "New",
  logo: "logo",
  name: "",
  email: "",
  webpage: "",
  phone: null,
  organizationNumber: null,
  mainAddress: "",
  billingaddressid: "",
  deliveryAddress: "",
};

export const newProduct: any = {
  id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  name: "Lenovo L14",
  comment: "nydelig pc vakkert design",
  serialNumber: "123456",
  unitPrice: 5678,
  retailPrice: 12345,
  security: 0,
  ean: 12345678,
  brand: "Lenovo",
  quantity: 1,
  paymentCycle: 1,
  bindingPeriod: 1,
  productType: {
    id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    name: "Laptop",
  },
  features: [
    {
      id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      description: "Intel Core i9",
      featureName: "CPU",
    },
    {
      id: "3fa85f64-5717-4562-b3fc-2c963f66afa2",
      description: "Intel Core i9",
      featureName: "CPU",
    },
    {
      id: "3fa85f64-5717-4562-b3fc-2c963f66afa3",
      description: "Intel Core i9",
      featureName: "CPU",
    },
    {
      id: "3fa85f64-5717-4562-b3fc-2c963f66afa4",
      description: "Intel Core i9",
      featureName: "CPU",
    },
    {
      id: "3fa85f64-5717-4562-b3fc-2c963f66afa5",
      description: "Intel Core i9",
      featureName: "CPU",
    },
    {
      id: "3fa85f64-5717-4562-b3fc-2c963f66afa7",
      description: "Intel Core i9",
      featureName: "CPU",
    },
    {
      id: "3fa85f64-5717-4562-b3fc-2c963f66afa8",
      description: "Intel Core i9",
      featureName: "CPU",
    },
    {
      id: "3fa85f64-5717-4562-b3fc-2c963f66afa9",
      description: "Intel Core i9",
      featureName: "CPU",
    },
    {
      id: "3fa85f64-5717-4562-b3fc-2c963f66afa0",
      description: "Intel Core i9",
      featureName: "CPU",
    },
    {
      id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      description: "Intel Core i9",
      featureName: "CPU",
    },
  ],
  active: true,
  url: "Lenovo L14",
};

export const newProduct1: any = {
  id: "3fa85f64-5717-4562-b3fc-2c963f66afa61",
  name: "Samsung Galaxy Tab",
  comment: "nydelig pc vakkert design",
  serialNumber: "123456",
  unitPrice: 5678,
  retailPrice: 12345,
  security: 0,
  ean: 12345678,
  brand: "Lenovo",
  quantity: 1,
  paymentCycle: 1,
  bindingPeriod: 1,
  productType: {
    id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    name: "Pad",
  },
  features: [
    {
      id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      description: "Intel Core i3",
      featureName: "CPU",
    },
  ],
  active: true,
  url: "samsung galaxy tab",
 
};

export const newProduct2: any = {
  id: "3fa85f64-5717-4562-b3fc-2c963f66afa61",
  name: "Fortigate P60 - E",
  comment: "nydelig pc vakkert design",
  serialNumber: "123456",
  unitPrice: 5678,
  retailPrice: 52540,
  security: 0,
  ean: 12345678,
  brand: "Lenovo",
  quantity: 1,
  paymentCycle: 1,
  bindingPeriod: 1,
  productType: {
    id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    name: "Pad",
  },
  features: [
    {
      id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      description: "Intel Core i3",
      featureName: "CPU",
    },
  ],
  active: true,
  url: "Fortigate",
 
};

export const newProduct3: any = {
  id: "3fa85f64-5717-4562-b3fc-2c9631f66afa61",
  name: "Galaxy Z flip 4",
  comment: "nydelig pc vakkert design",
  serialNumber: "123456",
  unitPrice: 5678,
  retailPrice: 22190,
  security: 0,
  ean: 12345678,
  brand: "Lenovo",
  quantity: 1,
  paymentCycle: 1,
  bindingPeriod: 1,
  productType: {
    id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    name: "Pad",
  },
  features: [
    {
      id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      description: "Intel Core i3",
      featureName: "CPU",
    },
  ],
  active: true,
  url: "galaxyz",
  
};

export const CompanyData: any = {
  id: "New",
  logo: null,
  name: null,
  email: null,
  webpage: null,
  phone: null,
  organizationNumber: null,
  mainAddress: [],
  invoiceAddress: [],
  deliveryAddress: [],
  primaryContact: [],
};

export const dummyAddress_1: any = {
  id: "New",
  companyid: null,
  street: "Nordlia 36",
  postalCode: 5223,
  city: "Bergen",
  country: "Norway",
  postBox: "",
};

export const dummyDepartment_1: any = {
  id: "95f58beb-95b5-453b-bf26-9dcd58d17358",
  companyid: null,
  name: "Administration",
  phone: 93202809,
  email: "admin@ccsolution.no",
  address: dummyAddress_1,
};
export const dummyDepartment_2: any = {
  id: "2f631e98-167e-4623-834e-a2d8512af562",
  companyid: null,
  name: "Sale",
  phone: 93202809,
  email: "admin@ccsolution.no",
  address: dummyAddress_1,
};
export const dummyDepartment_3: any = {
  id: "95f58beb-95b5-453b-bf26-9dcd58d17352",
  companyid: null,
  name: "Marked",
  phone: 93202809,
  email: "admin@ccsolution.no",
  address: dummyAddress_1,
};
export const dummyDepartment_4: any = {
  id: "95f58beb-95b5-453b-bf26-9dcd58d17352",
  companyid: null,
  name: "Ecomomy",
  phone: 93202809,
  email: "admin@ccsolution.no",
  address: dummyAddress_1,
};

export const newAgreement: any = {
  id: "",
  companyid: "",
  name: "",
  products: [],
  startDate: "15/02 2022",
  endDate: "15/02 2025",
};

export const EmployeeData: any = {
  id: "New",
  companyid: null,
  name: null,
  phone: null,
  email: null,
  address: null,
  role: null,
  title: null,
  budget: null,
  departmentid: null,
  primarycontact: false,
};

export const AgreementData: any = {
  id: "New",
  name: "",
  start: "",
  end: "",
  interval: "",
  cost: 0,
  value: 0,
  companyid: "",
};

export const CountryData: any = [
  {
    id: 1,
    name: "Denmark",
  },
  {
    id: 2,
    name: "Norway",
  },
  {
    id: 3,
    name: "Sweden",
  },
];

export const ErrorData: any = {
  id: "New",
  text: "location",
};

export const toastData: any = {
  isShow: false,
  header: "",
};

export const enumApi: any = {
  get: "get",
  post: "post",
  put: "put",
  delete: "delete",
};

export const newApiData: any = {
  method: null,
  url: null,
  data: null,
  setObject: null,
};
export const step1: any = [
  {
    id: 0,
    step: "Select from the dropdown",
    nextstep: "step2",
  },
  {
    id: 1,
    step: "Lenovo ThinkCentre M710q",
    nextstep: "step11",
  },
  {
    id: 2,
    step: "Lenovo L14s",
    nextstep: "step11",
  },
  {
    id: 3,
    step: "Samsun Galaxy Tab 8",
    nextstep: "step2",
  },
  {
    id: 4,
    step: "iPhone 11 Max",
    nextstep: "step2",
  },
];
export const step2: any = [
  {
    id: 0,
    step: "Select from the dropdown",
    nextstep: "step2",
  },
  {
    id: 1,
    step: "Department Computer",
    nextstep: "step2",
  },
  {
    id: 2,
    step: "Home office Computer",
    nextstep: "step2",
  },
  {
    id: 3,
    step: "Server",
    nextstep: "step2",
  },
  {
    id: 4,
    step: "Mobile phone",
    nextstep: "step2",
  },
];
export const step3: any = [
  {
    id: 0,
    step: "Select from the dropdown",
    nextstep: "step2",
  },
  {
    id: 1,
    step: "Department Computer",
    nextstep: "step2",
  },
  {
    id: 2,
    step: "Home office Computer",
    nextstep: "step2",
  },
  {
    id: 3,
    step: "Server",
    nextstep: "step2",
  },
  {
    id: 4,
    step: "Mobile phone",
    nextstep: "step2",
  },
];
export const step11: any = [
  {
    id: 0,
    step: "Select from the dropdown",
    nextstep: "step3",
  },
  {
    id: 1,
    step: "Cant save to disk",
    nextstep: "step21",
  },
  {
    id: 2,
    step: "Making noise",
    nextstep: "step3",
  },
  {
    id: 3,
    step: "Cant find it",
    nextstep: "step3",
  },
];
export const step21: any = [
  {
    id: 0,
    step: "Select from the dropdown",
    nextstep: "step3",
  },
  {
    id: 1,
    step: "Cant save to disk",
    nextstep: "step21",
  },
  {
    id: 2,
    step: "Making noise",
    nextstep: "step3",
  },
  {
    id: 3,
    step: "Cant find it",
    nextstep: "step3",
  },
];



export const ChartDataColor: any = [
  "#4dabf5", // blue
  "#6fbf73", // green
  "#ed4b82", // pink
  "#ffac33", // orange
  "#ffef62", // yellow

  "#af52bf", // purple
  "#6573c3", // indigo
  "#33ab9f", // teal
  "#ff784e", // deep orange
  "#ffcd38", // amber

  "#8561c5", // deep purple
  "#f6685e", // red
  "#35baf6", // light blue
  "#a2cf6e", // light green
  "#d7e360", // lime
  "#33c9dc", // cyan
];

export const appAccess: any = [
  {
    name: "company",
    access: 2,
  },
  {
    name: "tickets",
    access: 2,
  },
  {
    name: "sales",
    access: 2,
  },
  {
    name: "orders",
    access: 1,
  },
  {
    name: "projects",
    access: 1,
  },
  {
    name: "products",
    access: 2,
  },
  {
    name: "marketing",
    access: 0,
  },
  {
    name: "finance",
    access: 0,
  },
  {
    name: "setup",
    access: 0,
  },
];

export const dummyEmployee_1: any = {
  id: "5b629a58-960f-4be6-b1cb-3786ebddfcec",
  companyid: "95f58beb-95b5-453b-bf26-9dcd58d17358",
  url: "e-0",
  name: "Ove Ronny Hauge",
  phone: 93202809,
  email: "ove@ccsolution.no",
  birthday: "15/02-1967",
  title: "kam",
  budget: 100000,
  departmentId: "2f631e98-167e-4623-834e-a2d8512af562",
  department: dummyDepartment_2,
  address: dummyAddress_1,
  access: appAccess,
  primaryContact: true,
  workRole: 0,
};

export const dummyEmployee_2: any = {
  id: "bce9b094-272b-4f17-8777-380ea819d7c4",
  companyid: "95f58beb-95b5-453b-bf26-9dcd58d17358",
  url: "e-2",
  name: "Christopher Namtvedt",
  phone: 93202809,
  email: "ove@ccsolution.no",
  birthday: "25/06-1955",
  title: "kam",
  budget: 100000,
  departmentId: "2f631e98-167e-4623-834e-a2d8512af562",
  department: dummyDepartment_2,
  address: dummyAddress_1,
  access: appAccess,
  primaryContact: false,

  workRole: 0,
};

export const dummyEmployee_3: any = {
  id: "98bb6953-fc5a-4a76-8b63-38134c035a5f",
  companyid: "95f58beb-95b5-453b-bf26-9dcd58d17358",
  url: "e-1",
  name: "Ole Erik Skaare",
  phone: 98252842,
  email: "oeskare@ccsolution.no",
  birthday: "05/06-1976",
  title: "avdelingleder",
  budget: 100000,
  departmentId: "2f631e98-167e-4623-834e-a2d8512af562",
  department: dummyDepartment_4,
  address: dummyAddress_1,
  access: appAccess,
  primaryContact: false,

  workRole: 0,
};

export const dummyEmployee_4: any = {
  id: "5280a559-9ac9-4d86-ab38-3b7187210267",
  companyid: "95f58beb-95b5-453b-bf26-9dcd58d17358",
  url: null,
  name: "Øyvind Listou",
  phone: 98252842,
  email: "oeskare@ccsolution.no",
  birthday: "05/06-1976",
  title: "eier",
  budget: 100000,
  departmentId: "2f631e98-167e-4623-834e-a2d8512af562",
  department: dummyDepartment_1,
  address: dummyAddress_1,
  access: appAccess,
  primaryContact: false,

  workRole: 0,
};

export const dummyCompanyData_1: any = {
  id: "95f58beb-95b5-453b-bf26-9dcd58d17358",
  logo: "ccsolution 128x64",
  name: "C C Solution AS",
  email: "support@ccsolution.no",
  webpage: "www.ccsolution.no",
  phone: 943743873,
  organizationNumber: 234534,
  mainAddress: "a-0",
  invoiceAddress: "a-1",
  deliveryAddress: "a-2",
  primaryContact: [],
};

export const dummyCompanyData_2: any = {
  id: "c2efdbff-2a11-4b1a-88db-3a9d2039d24c",
  logo: "Area 128x64",
  name: "Area Bergen",
  email: "support@ccsolution.no",
  webpage: "www.ccsolution.no",
  phone: 943743873,
  organizationNumber: 234534,
  mainAddress: "a-0",
  invoiceAddress: "a-1",
  deliveryAddress: "a-2",
  primaryContact: [],
};

export const dummyCompanyData_3: any  = {
  id: "c2efdbff-2a11-4b1a-88db-3a9d2039d24c",
  logo: "Flex 128x64",
  name: "Flex Reklame AS",
  email: "support@ccsolution.no",
  webpage: "www.ccsolution.no",
  phone: 943743873,
  organizationNumber: 234534,
  mainAddress: "a-0",
  invoiceAddress: "a-1",
  deliveryAddress: "a-2",
  primaryContact: [],
};

export const dummyCompanyData_4: any = {
  id: "c2efdbff-2a11-4b1a-88db-3a9d2039d24c",
  logo: "design 128x64",
  name: "Design  Reklame AS",
  email: "support@ccsolution.no",
  webpage: "www.ccsolution.no",
  phone: 943743873,
  organizationNumber: 234534,
  mainAddress: "a-0",
  invoiceAddress: "a-1",
  deliveryAddress: "a-2",
  primaryContact: [],
};
