import { useDroppable } from '@dnd-kit/core';
import { SortableSingleTicket } from './tickets/SortabeSingleTicket';
import useWindowsDimension from 'functions/useWindowsDimension';
import { Grid, List, Paper } from '@mui/material';
import { SearchFilter } from './SearchFilter';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';

export function DroppableTicketList(props: any) {
  const { setNodeRef } = useDroppable({
    id: props.id,
    data: props.id,
  });

  const { id: columnId, setActiveTicket } = props;

  let navigate = useNavigate();
  const [filterType, setFilterType] = useState(0);

  const { tickets } = props;
  const { height } = useWindowsDimension();

  const handleNew = () => {
    navigate('/tickets/new');
  };

  return (
    <>
      <Grid
        ref={setNodeRef}
        item
        xs={6}
        sx={{ pr: 1, m: 0, overflowX: 'hidden' }}
      >
        <SearchFilter
          Icon={SupportAgentRoundedIcon}
          Header={
            props.id === 'newTickets' ? 'Unassigned Tickets' : 'Your Tickets'
          }
          filterValue={filterType}
          //handleFilterChange={handleFilterChange}
          //handleSearch={handleSearch}
          handleNew={handleNew}
          //selectList={companyTypes}
        />

        <List
          sx={{
            height: Math.ceil(height - 148),
            width: '100%',
            mb: 0,
            p: 2,
            backgroundColor: 'primary.back',
          }}
        >
          {tickets?.length === 0 && <div>No data</div>}
          {tickets?.map((ticket: any, index: number) => {
            return (
              <SortableSingleTicket
                key={ticket.id}
                id={ticket.id}
                ticket={ticket}
                ticketIndex={index}
                setActiveTicket={setActiveTicket}
                columnId={columnId}
              />
            );
          })}
        </List>

        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        ></Paper>
      </Grid>
    </>
  );
}
