import {
  FormAddressCity,
  FormText,
  FormAutocompleteCountry,
} from 'components/form';
import { Box, Divider, Grid, IconButton } from '@mui/material';
import TravelExploreRoundedIcon from '@mui/icons-material/TravelExploreRounded';
export const FormUIAddress = (props:any) => {
  const {
    address,
    addressPostalArea,
    control,
    countries,
    handleBlur,
    googleAddress,
  } = props;
  const goToGoogle = (e:any, address:any) => {
    e.preventDefault();
    let newWindow = null;

    newWindow = window.open(
      'https://www.google.com/maps/place/' +
        googleAddress?.street +
        ',' +
        googleAddress?.postalCode +
        googleAddress?.city +
        '/',
      '_blank',
      'noopener,noreferrer'
    );
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Grid
      container
      sx={{
        p: 0,
      }}
      rowSpacing={2}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    >
      <Grid
        item
        xs={12}
        lg={6}
        sx={{
          m: 0,
          p: 0,
          textAlign: 'left',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        <FormText
         sx = {{'& .Mui-focused':{
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
        }, }}
          name={`${address}.street`}
          control={control}
          label='Street name'
          endAdornment={
            <IconButton name={address} onClick={(e) => goToGoogle(e, address)}>
              <TravelExploreRoundedIcon />
            </IconButton>
          }
        />
      </Grid>
      <Grid
        item
        xs={12}
        lg={3}
        sx={{
          m: 0,
          p: 0,
          textAlign: 'left',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        <FormAddressCity        
          name={`${address}.postalCode`}
          control={control}
          label='Postal Code'
          handleBlur={handleBlur}
        />
      </Grid>
      <Grid
        item
        xs={12}
        lg={3}
        sx={{
          m: 0,
          p: 0,
          textAlign: 'left',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
         <FormAddressCity          
          name={`${address}.area`}
          control={control}
          label='Area'
          handleBlur={handleBlur}
        />
        {/* <Box sx={{ mt: 3 }}>{addressPostalArea}</Box> */}
        <Divider sx={{ mt: 0 }} variant='dividerMenu' />
      </Grid>
      <Grid
        item
        xs={12}
        lg={5}
        sx={{
          m: 0,
          p: 0,
          textAlign: 'left',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        <FormText name={`${address}.city`} control={control} label='City' />
      </Grid>
      <Grid
        item
        xs={12}
        lg={4}
        sx={{
          m: 0,
          p: 0,
          textAlign: 'left',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        <FormAutocompleteCountry
 sx = {{'& .Mui-focused':{
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
}, }}
control={control}
          label='Country'
          name={`${address}.country`}
          list={countries}
        />
      </Grid>
      <Grid
        item
        xs={12}
        lg={3}
        sx={{
          m: 0,
          p: 0,
          textAlign: 'left',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        <FormText
         sx = {{'& .Mui-focused':{
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
        }, }}
          name={`${address}.postBox`}
          control={control}
          label='Post Box'
        />
      </Grid>
    </Grid>
  );
};
