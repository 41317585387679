import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { EmployeeForm } from './EmployeeForm'; // Import the EmployeeForm component
import { useGet } from 'http/useInnovit';
import { rq } from 'http/apiRoutes';
import { IEmployeeViewModel, IDepartmentViewModel } from './ICompaniesConfig';
import { IAddressViewModel } from "data/globalTypeConfig";
import { IWorkRoleViewModel } from 'data/atoms/atomConfig';
import { IFileModel } from './CompanyConfig';
import { IProductViewModel } from 'pages/Billing/BillingConfig';
import { ICompanyProductViewModel } from './ICompanyProductConfig';

const initialState: IEmployeeViewModel = {
  id: 'new',
  name: '',
  phone: '+47',
  email: '',
  departmentId: '',
  department: {} as IDepartmentViewModel,
  address: {} as IAddressViewModel,
  role: 0,
  primaryContact: false,
  globalAdministrator: false,
  budget: 0,
  title: '',
  fileModel: {
    content: '',
    fileName: '',
  } as IFileModel,
  companyProducts: [] as ICompanyProductViewModel[],
  workRole: {} as IWorkRoleViewModel,  
  url: '',
};


export const Employee: React.FC = () => {
  const { companyId, employeeId } = useParams<{ companyId: string; employeeId: string }>();
  const [products, setProducts] = useState<IProductViewModel[]>([]); 
  const [isProducts, setIsProducts] = useState<boolean>(true);
  // api
  let isEnabled = employeeId !== "new" && companyId !== undefined ? true : false;
  const { data: employeeData } = useGet(
    rq.employeeWithProducts(companyId || '', employeeId || '', isEnabled),
    isEnabled
  );
  const { data: productData } = useGet(rq.products(isProducts),isProducts);
  useEffect(() => {
    setProducts(productData?.products || []); // Make sure 'products' is always an array
    setIsProducts(false);
  }, [productData]);

  if (employeeId === "new" && products.length > 0) {
    return <EmployeeForm employee={initialState} products={products} />;
  }

  if (employeeId !== "new" && products.length > 0) {
    return (
      <>
        {employeeData && <EmployeeForm employee={employeeData as IEmployeeViewModel} products={products} />}
      </>
    );
  }

  // You should return something meaningful here for the cases where conditions aren't met.
  return null;
};
