import React, { memo, useEffect, useState } from 'react';
// @ts-ignore
import { msalInstance } from 'index';
import {
  useMsal,
  useIsAuthenticated,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
// @ts-ignore
import { itsRequest } from 'http/authConfig';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
// @ts-ignore
import GetAvatarInitials from 'functions/GetAvatarInitials';
import { useNavigate } from 'react-router-dom';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';

type Props = {
  url: string | null | undefined;
  setEmail: (email: string) => void;
};

const avatarMenuLogoutLists: any = [
  {
    index: 100,
    name: 'Divider',
    icon: <Divider />,
  },
  {
    index: 2,
    name: 'My Account',
    icon: <ManageAccountsRoundedIcon />,
  },
  {
    index: 3,
    name: 'Logout',
    icon: <LogoutRoundedIcon />,
  },
  {
    index: 200,
    name: 'Divider',
    icon: <Divider />,
  },
];

const avatarMenuLoginLists: any = [
  {
    index: 100,
    name: 'Divider',
    icon: <Divider />,
  },
  {
    index: 1,
    name: 'Login',
    icon: <LoginRoundedIcon />,
  },
  {
    index: 200,
    name: 'Divider',
    icon: <Divider />,
  },
];

export const AccountMenu = memo(({ url, setEmail }: Props) => {
  /* INITIALIZATIONS */
  const navigate = useNavigate();
  const [accountName, setAccountName] = useState('Login');
  const [expanded, setExpanded] = useState(false);
  const [image, setImage] = useState<string | null>(null);
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // handle functions
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (event.currentTarget.id === '1') {
      handleLogin();
    } else if (event.currentTarget.id === '2') {
      handleMyAccount();
    } else if (event.currentTarget.id === '3') {
      handleLogout();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogin = () => {
    instance.loginPopup(itsRequest);
    setExpanded(false);
  };

  const handleMyAccount = () => {
    console.log('handleMyAccount');
    navigate(`/accountPage/`);
  };

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  const handleAccordionChange =
    (panel: any) => (event: any, isExpanded: any) => {
      setExpanded(isExpanded ? panel : false);
    };

  // useEffect
  useEffect(() => {
    if (isAuthenticated) {
      let account = msalInstance.getActiveAccount();
      if (account) {
        console.log('account', account);
        console.log('account token role', account.idTokenClaims.roles);
        setEmail(account.username);
        setAccountName(account.name);
      }
    } else {
      setAccountName('Login');
    }
    //console.log("isauth", isAuthenticated);
  }, [isAuthenticated]);

  // useEffect
  useEffect(() => {
    if (url !== null && url !== undefined) {
      setImage(url);
      // console.log("url :::: ", url);
    }
  }, [url]);

  return (
    <>
      <Accordion
        disableGutters
        elevation={0}
        sx={{ backgroundColor: 'transparent', boxShadow: 0, border: 0 }}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Grid sx={{ mb: 0, mx: 'auto' }}>
            <Box
              component={Avatar}
              sx={{
                mb: 2,
                mx: 'auto',
                justifyContent: 'center',
                width: 64,
                height: 64,
              }}
            >
              {image ? (
                <Avatar
                  alt={accountName}
                  src={image}
                  sx={{
                    m: 0,
                    p: 0,
                    borderRadius: 2,
                    width: 64,
                    height: 64,
                  }}
                />
              ) : (
                <Avatar {...GetAvatarInitials(accountName, 64, 64, 8)} />
              )}
            </Box>

            <Box
              component='div'
              width={200}
              sx={{
                textAlign: 'center',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
                fontSize: 18,
              }}
            >
              {accountName}
            </Box>
          </Grid>
        </AccordionSummary>
        <UnauthenticatedTemplate>
          <AccordionDetails>
            <List>
              {avatarMenuLoginLists.map((avatarMenuLoginList: any) =>
                avatarMenuLoginList.index < 99 ? (
                  <ListItem
                    key={avatarMenuLoginList.index}
                    onClick={handleClick}
                    id={avatarMenuLoginList.index}
                    button
                  >
                    <ListItemIcon>{avatarMenuLoginList.icon}</ListItemIcon>
                    <ListItemText primary={avatarMenuLoginList.name} />
                  </ListItem>
                ) : (
                  <Divider
                    variant='fullWidth'
                    key={avatarMenuLoginList.index}
                  />
                )
              )}
            </List>
          </AccordionDetails>
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          <AccordionDetails>
            <List>
              {avatarMenuLogoutLists.map((avatarMenuLogoutList: any) =>
                avatarMenuLogoutList.index < 99 ? (
                  <ListItem
                    key={avatarMenuLogoutList.index}
                    onClick={handleClick}
                    id={avatarMenuLogoutList.index}
                    button
                  >
                    <ListItemIcon>{avatarMenuLogoutList.icon}</ListItemIcon>
                    <ListItemText primary={avatarMenuLogoutList.name} />
                  </ListItem>
                ) : (
                  <Divider
                    variant='fullWidth'
                    key={avatarMenuLogoutList.index}
                  />
                )
              )}
            </List>
          </AccordionDetails>
        </AuthenticatedTemplate>
      </Accordion>
    </>
  );
});
