import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import {
  IBillingDetailViewModel,
  IBillingPostViewModel,
} from 'pages/Billing/BillingConfig';
import { MissingPhoto } from 'data/globalTypeConfig';
import React from 'react';
import { blueGrey } from '@mui/material/colors';
import { pdfStyle } from './PDFStyle';

// Define the data type for the prop
interface Props {
  data: IBillingPostViewModel;
}

// Create Document Component
export const PDFFooterView = ({ data }: Props) => {
  return (
    <>
     <View style={pdfStyle.containerView}>
        <View style={pdfStyle.column100}>
            <Text style={pdfStyle.textLeft}>{'DEFINITIONS'}</Text>
          </View>
        <View style={pdfStyle.divider}></View>
        <View style={pdfStyle.avsnitt} />
        <View style={pdfStyle.rowView}>
        <View style={pdfStyle.column20}>
            <Text style={pdfStyle.textCenter}>{'TIME'}</Text>
          </View>
          <View style={pdfStyle.column20}>
            <Text style={pdfStyle.textLeft}>{'n: nighttime'}</Text>
          </View>
          <View style={pdfStyle.column20}>
            <Text style={pdfStyle.textLeft}>{'w: worktime'}</Text>
          </View>
          <View style={pdfStyle.column20}>
            <Text style={pdfStyle.textLeft}>{'m: midday'}</Text>
          </View>
          <View style={pdfStyle.column20}>
            <Text style={pdfStyle.textLeft}>{'e: evening'}</Text>
          </View>
        </View>
      </View>
    </>
  );
};
