import { Outlet } from "react-router-dom";

import Grid from "@mui/material/Grid";

export const Companies = () => {

  return (
    <>
      <Grid container rowSpacing={0}>
        
        <Outlet />
      </Grid>
    </>
  );
};
