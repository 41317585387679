import * as React from "react";
import { useEffect, useState } from "react";
import StarTwoToneIcon from "@mui/icons-material/StarTwoTone";
import { Grid, Avatar, Stack, Typography } from "@mui/material";
import GetColorByName from "functions/GetColorByName";
import { MissingPhoto } from "data/globalTypeConfig";

export const SingleEmployeeView = (props: any) => {
  const { employee, gfx } = props;
  return (
    <Grid
      container
      direction={"row"}
      sx={{
        mb: 1,
        borderLeft: 8,
        borderRight: 3,
        borderRadius: 3,
        borderColor: GetColorByName(`${employee?.department}`),
        backgroundColor: "primary.main",
        height: "100%",
        maxHeight: 80,
        ':hover': {
          cursor: 'pointer',
          filter: 'brightness(120%)',
          transition: 'all 0.2s ease-in-out',
        },
      }}
    >
      <Grid
        item
        sx={{
          mx: "auto",
          width: 80,
          height: 80,
          backgroundColor: "primary.light",
        }}
      >
        <img
          src={employee?.url || MissingPhoto.EMPLOYEE_URL}
          style={{
            width: 80,
            height: 80,
            borderRadius: 3,
            objectFit: "contain",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.50)",
          }}
          alt={"previewUrl"}
        />
      </Grid>

      <Grid
        item
        flexGrow={1}
        sx={{
          pl: 1,
        }}
      >
        <Stack direction={"column"}>
          <Typography
            sx={{
              letterSpacing: 2,
              textAlign: "left",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              fontWeight: "bold",
              fontSize: 18,
            }}
          >
            {employee?.name}
          </Typography>
          <Typography
            sx={{
              height: 24,
              letterSpacing: 3,
              textAlign: "left",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              fontWeight: "normal",
              fontSize: 16,
            }}
          >
            {employee?.phone}
          </Typography>
          <Typography
            sx={{
              height: 24,
              letterSpacing: 3,
              textAlign: "left",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              fontWeight: "normal",
              fontSize: 16,
            }}
          >
            {employee?.email}
          </Typography>
        </Stack>
      </Grid>
      <Grid
        item
        xs={3}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          p: 0,
          mb: 1,
        }}
      >
        <Stack direction={"column"} sx={{ p: 0, mt: 0 }}>
          <Stack direction={"row-reverse"} sx={{ p: 0, pt: 1 }}>
            {!!gfx ? gfx : <></>}
            <Typography
              sx={{
                pr: 1,
                letterSpacing: 2,
                textAlign: "right",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                fontWeight: "normal",
                fontSize: 16,
                color: "secondary.text",
              }}
            >
              {employee?.primaryContact ? <StarTwoToneIcon /> : ""}
            </Typography>
          </Stack>
          <Typography
            sx={{
              pr: 1,
              mt: -1,
              height: 24,
              letterSpacing: 3,
              textAlign: "right",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              fontWeight: "normal",
              fontSize: 16,
            }}
          >
            Budget
          </Typography>
          <Typography
            sx={{
              pr: 1,
              height: 24,
              letterSpacing: 2,
              textAlign: "right",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              fontWeight: "normal",
              fontSize: 16,
              color: "secondary.text",
            }}
          >
            {employee?.budget?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};
