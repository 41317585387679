import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import {
  IBillingDetailViewModel,
  IBillingPostViewModel,
} from 'pages/Billing/BillingConfig';
import { MissingPhoto } from 'data/globalTypeConfig';
import React from 'react';
import { blueGrey } from '@mui/material/colors';
import { pdfStyle } from './PDFStyle';
import dayjs from 'dayjs';

// Define the data type for the prop
interface Props {
  data: IBillingPostViewModel;
}

// Create Document Component
export const PDFHeaderView = ({ data }: Props) => (
  //console.log('PDFHeaderView : ', data),
  <>
    <View style={pdfStyle.headerView}>
     {/*  <Image style={pdfStyle.headerImage} src={ data.company?.url ||  MissingPhoto.COMPANY_URL} /> */}
      {/*Left Header */}
      <View style={pdfStyle.headerLeftView}>
        <Text style={pdfStyle.headerText}>{data.company?.name}</Text>
        <Text style={pdfStyle.subText}>
          Org.No : {data.company?.organizationNumber}
        </Text>
      </View>

      {/* Right Header */}
      <View style={pdfStyle.headerRightView}>
        <Text style={pdfStyle.text}>{data.BillingNo}</Text>
        <Text style={pdfStyle.text}>{dayjs().format('MMMM DD, YYYY')}</Text>
      </View>
    </View>
    <View style={pdfStyle.avsnitt} />
  </>
);
