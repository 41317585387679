import { useEffect, useState } from 'react';
import { useGet } from 'http/useInnovit';
import { rq } from 'http/apiRoutes';
import useWindowsDimension from 'functions/useWindowsDimension';
// @ts-ignore
import { CompanyList } from 'components/ui/CompanyList';

import { Button, Drawer, Box } from '@mui/material';

export const CompanyDrawer = (props: any) => {
  const {
    companies,
    buttonIcon,
    handleCompanySubmit,
    handleRemoveCompanySubmit,
  } = props;
  const { width } = useWindowsDimension();
  const [open, setOpen] = useState(false);
  //   const [employees, setEmployees] = useState([]);

  //console.log('companyDrawer ', companies);
  // api

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          width: '100%',
          height: '100%',
        }}
      >
        <Button
          sx={{ p: 0, m: 0 }}
          size='small'
          //type='open'
          variant='contained'
          onClick={() => setOpen(!open)}
        >
          {buttonIcon}
        </Button>
      </Box>
      <Drawer anchor={'left'} open={open} onClose={() => setOpen(!open)}>
        <Box sx={{ width: width / 2, backgroundColor: 'primary.main' }}>
          {companies.length > 0 && (
            <CompanyList
              companies={companies}
              setOpen={setOpen}
              handleCompanySubmit={handleCompanySubmit}
              handleRemoveCompanySubmit={handleRemoveCompanySubmit}
            />
          )}
        </Box>
      </Drawer>
    </>
  );
};
