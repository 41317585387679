/**
 * Get the initials from a name.
 *
 * @param name - The full name.
 * @param maxInitials - The maximum number of initials to return.
 * @returns The initials of the name, up to maxInitials characters.
 */
function getInitials(name: string, maxInitials: number): string {
  // Logging the input parameters for debugging
  console.log('getInitials', typeof name, maxInitials);
  
  // If no name is provided, return 'x' as default
  if (!name) return 'x';

  // Split the name into individual words
  const names = name.split(' ');

  // Extract the first letter from each word to form the initials
  let initials = '';
  names.forEach((n) => {
    initials += n.slice(0, 1);
  });

  // Return only the specified number of initials
  return initials.slice(0, maxInitials);
}

export default getInitials;
