import axios from 'axios';

export const innovit = axios.create({
  headers: {
    'content-type': 'application/json', // override instance defaults
    'Access-Control-Allow-Origin': '*',
    //Authorization: null,
  },
  timeout: 5000,
  // DEV
  // baseURL: 'https://bluu-admin-api-dev.azurewebsites.net/api',
  // TEST
  //baseURL: "https://bluu-admin-api-test.azurewebsites.net/api",
  // TEST
  baseURL: "https://bluu-admin-api-demo.azurewebsites.net/api",
  // PROD
  //baseURL: 'https://bluu-admin-api-prod.azurewebsites.net/api',
  // LOCAL
  //baseURL: process.env.API_URL || "https://localhost:44387/api",
});
