
import { Button, Grid, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { ChartSingleView } from 'components/ui/ChartSingleView';
import { FormDate } from 'components/form';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { rq } from 'http/apiRoutes';
import { useGet, usePut } from 'http/useInnovit';

export const Test = () => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  let isEnabled = true;
  const { data: billingPostData } = useGet(rq.billing( isEnabled), isEnabled);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

useEffect (() => {
  console.log('billingPostData', billingPostData);
}, [billingPostData])

  return (
    <>
      <Grid item xs={3} sx={{ flexGrow: 1, mb:1, }}>       
        <ChartSingleView
          header={'total tickets (2023)'}
          oldValue={750000000}
          value={1000000000}
          extendedData={{
            header: 'overview (2023)',
            lines: [
              {
                name: 'Q1 (2023)',
                oldValue: 750000000,
                value: 1000000000,
              },
              {
                name: 'Q2 (2023)',
                oldValue: 1000000000,
                value: 1000000000,
              },
              {
                name: 'Q3 (2023)',
                oldValue: 950000000,
                value: 940000000,
              },
              {
                name: 'Q4 (2023)',
                oldValue: 750000000,
                value: 900000000,
              },
            ],
          }}

          />
           <ChartSingleView
          header={'total tickets (2023)'}
          oldValue={750000000}
          value={1000000000}
          extendedData={null}
          />
      </Grid>
      <Grid item xs={3} sx={{ 
          
      }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>          
        <DesktopDateTimePicker 
          ampm={false}
          ampmInClock={false}
          displayWeekNumber={true}
          views={['year', 'month', 'day', 'hours', 'minutes']}   
          formatDensity="spacious"
          minutesStep={1}
          orientation="landscape"
          reduceAnimations={true}
          showDaysOutsideCurrentMonth={true}
          label="Basic date picker" />         
    </LocalizationProvider>


   

        <IconButton
          sx={{
            p: 0,
            m: 0,           
          }}        
        >
          <ChangeCircleIcon sx={{m:0,p:0,fontSize:26}} />
        </IconButton>
      </Grid>
    </>
  );
};
