import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import {
  IBillingDetailViewModel,
  IBillingPostViewModel,
} from 'pages/Billing/BillingConfig';
import { MissingPhoto } from 'data/globalTypeConfig';
import React from 'react';
import { blueGrey } from '@mui/material/colors';
import { pdfStyle } from './PDFStyle';
import dayjs from 'dayjs';

// Define the data type for the prop
interface Props {
  data: IBillingPostViewModel;
}

// Create Document Component
export const PDFSupportView = ({ data }: Props) => {
  //console.log('PDFSupportView : ', data);
  return (
    <>
       <View style={pdfStyle.column100}>
          <Text style={pdfStyle.textLeft}>{'SUPPORT'}</Text>
        </View>
      <View style={pdfStyle.rowView}>
        <View style={pdfStyle.column15}>
          <Text style={pdfStyle.textLeft}>{'date'}</Text>
        </View>
        <View style={pdfStyle.column50}>
          <Text style={pdfStyle.textLeft}>{'Info'}</Text>
        </View>
        <View style={pdfStyle.column10}>
          <Text style={pdfStyle.textRight}>{'time'}</Text>
        </View>
        <View style={pdfStyle.column10}>
          <Text style={pdfStyle.textRight}>{'cost'}</Text>
        </View>
        <View style={pdfStyle.column20}>
          <Text style={pdfStyle.textRight}>{'line total'}</Text>
        </View>
      </View>
      {/* Divider */}
      <View style={pdfStyle.divider}></View>
      {/* Investment */}
      {data.billingViewModels.map(
        (item: IBillingDetailViewModel, index: number) => {
          // Check if there's a companyProduct in the current billing item
          if (item.support) {
            return (
              <View key={index} style={pdfStyle.containerView}>
                <View key={index} style={pdfStyle.rowView}>
                  {/* DATO */}
                  <View style={{...pdfStyle.column15,display:'flex', height:50, }}>
                    <Text style={pdfStyle.textInfo}>
                      {dayjs(item.ticketPost.startTime).format('MMMM DD')}
                    </Text>
                  </View>

                  {/* TEKNIKKER */}
                  <View style={{...pdfStyle.column50,display:'flex', height:50, }}>
                    <Text style={{...pdfStyle.textLeftBold, }}>
                      {item.ticketPost.author?.name} 
                    </Text>
                    <Text style={{...pdfStyle.textInfo, maxLines:3,}}>
                      {item.ticketPost.message}
                    </Text>
                  </View>

                  {/* ARBEID */}

                  <View style={{...pdfStyle.column10,display:'flex', height:50, }}>
                    <Text style={pdfStyle.textInfoRight}>
                      n: {item.support.nightHours.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Text>
                    <Text style={pdfStyle.textInfoRight}>
                      w: {item.support.workHours.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Text>
                    <Text style={pdfStyle.textInfoRight}>
                      m: {item.support.midDayHours.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Text>
                    <Text style={pdfStyle.textInfoRight}>
                      e: {item.support.eveningHours.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Text>
                    
                    </View>

                    <View style={{...pdfStyle.column10,display:'flex', height:50, }}>
                    <Text style={pdfStyle.textInfoRight}>
                      {item.support.nightBillableValue.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Text>
                    <Text style={pdfStyle.textInfoRight}>
                      {item.support.workBillableValue.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Text>
                    <Text style={pdfStyle.textInfoRight}>
                      {item.support.midDayBillableValue.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Text>
                    <Text style={pdfStyle.textInfoRight}>
                      {item.support.eveningBillableValue.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Text>
                  </View>

                  {/* TOTAL */}
                  <View style={{...pdfStyle.column20,display:'flex', height:50, }}>
                    <Text style={pdfStyle.textRight}>
                      {(item.support.nightBillableValue + item.support.workBillableValue + item.support.midDayBillableValue + item.support.eveningBillableValue).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyle.avsnitt} />
                </View>          
            );
          }
          return <View key={index}> </View>;
        }
      )}
      {/* Divider */}
      <View style={pdfStyle.divider} />
      <View style={pdfStyle.rowView}>
        <View style={pdfStyle.column50}>
          <Text style={pdfStyle.textLeft}>{'SUPPORT TOTAL'}</Text>
        </View>
        <View style={pdfStyle.column50}>
          <Text style={pdfStyle.textRight}>
            {data.supportInvoiceValue.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Text>
        </View>
      </View>
      <View style={pdfStyle.avsnitt} />
      <View style={pdfStyle.avsnitt} />
    </>
  );
};
