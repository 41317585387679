import { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFilteredList } from 'functions/misc';
import { SearchFilter } from './SearchFilter';
import { SingleEmployeeView } from 'components/ui/SingleEmployeeView';
import useWindowsDimension from 'functions/useWindowsDimension';
import _ from 'lodash';
import { Grid, List, Paper } from '@mui/material';
import ImportantDevicesRoundedIcon from '@mui/icons-material/ImportantDevicesRounded';

export const EmployeeList = memo((props) => {
  /* INITIALIZATION */
  const { employees, handleEmployeeSubmit, setOpen } = props;
  // console.log(employees);
  const list = _.cloneDeep(employees);
  /*   list?.map((employee) => {
    employee.typeId = employee.employeeType.id;
  }); */

  const { height } = useWindowsDimension();
  let navigate = useNavigate();

  // states
  const [search, setSearch] = useState('');
  const [filterType, setFilterType] = useState('all');
  const [filteredEmployees, setFilteredEmployees] = useState(employees);
  const [employeeTypes, setEmployeeTypes] = useState([]);

  /* FUNCTIONS */

  // handles
  const handleNew = (e) => {
    navigate('/employees/new');
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    let filterValue = filterType === 'all' ? '' : filterType;
    let filteredRows = getFilteredList(list, filterValue);
    let searchRows = getFilteredList(filteredRows, e.target.value);

    setFilteredEmployees(searchRows);
  };

  const handleFilterChange = (e) => {
    setFilterType(e.target.value);
    let filterValue = e.target.value === 'all' ? '' : e.target.value;
    let filteredRows = getFilteredList(list, filterValue);
    let searchRows = getFilteredList(filteredRows, search);

    setFilteredEmployees(searchRows);
  };

  useEffect(() => {
    setFilteredEmployees(employees);
  }, [employees]);

  // console.log("categories", categories, );
  if (!employees) return null;

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
      }}
    >
      <Grid item xs={12} sx={{ pr: 1, m: 0 }}>
       
          <SearchFilter
            Icon={ImportantDevicesRoundedIcon}
            Header='employees'
            filterValue={filterType}
            handleFilterChange={handleFilterChange}
            handleSearch={handleSearch}
            handleNew={handleNew}
            selectList={employeeTypes}
          />
       

        <List
          sx={{
            height: Math.ceil(height - 148),
            width: '100%',
            mb: 0,
            p: 2,
            backgroundColor: 'primary.dark',
            overflow: 'auto',
          }}
        >
          {filteredEmployees?.length === 0 && <div>No employees found</div>}
          {filteredEmployees?.map((employee, index) => (
            <Paper
              variant='elevation3'
              key={index}
              onClick={(e) => {
                handleEmployeeSubmit(e, employee);
                setOpen(false);
              }}
            >
              {/* XXX */}
              <Grid item key={index} xs={12} sx={{ pr: 0, m: 0 }}>
                <SingleEmployeeView
                  employee={employee}
                  handleRemoveEmployeeSubmit={null}
                />
              </Grid>
            </Paper>
          ))}
        </List>
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        ></Paper>
      </Grid>
    </Grid>
  );
});
