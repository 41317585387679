import { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useGet } from "http/useInnovit";
import { rq } from "http/apiRoutes";
import useWindowsDimension from "functions/useWindowsDimension";
import { getFilteredList } from "functions/misc";
import { SingleEmployeeView } from "components/ui/SingleEmployeeView";
import { SearchFilter } from "components/ui/SearchFilter";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { List } from "@mui/material";
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";

let _ = require("lodash");

export const MainEmployees = () => {
  /* INITIALIZATIONS */
  let navigate = useNavigate();
  const { width, height } = useWindowsDimension();
  let isEmployee = true;
  const { data: company } = useGet(rq.appOwner(isEmployee), isEmployee);
  const [companyId, setCompanyId] = useState("");

  let isCompany = companyId !== "" ? true : false;
  //console.log("isCompany", isCompany, companyId);
  // api
  const { data: eData } = useGet(
    rq.appEmployees(companyId, isCompany),
    isCompany
  );
  const { data: dData } = useGet(
    rq.appDepartments(companyId, isCompany),
    isCompany
  );

  // states
  const [search, setSearch] = useState("");
  const [filterType, setFilterType] = useState("all");
  const [employees, setEmployees] = useState([]);
  console.log("employees", employees);
  /* FUNCTIONS */

  // handles
  const handleNew = (e) => {
    e.preventDefault();
    //console.log("click");
    navigate(`/companies/${companyId}/employees/new`);
  };

  const handleFilter = (e) => {
    setFilterType(e.target.value);
    let filterValue = e.target.value === "all" ? "" : e.target.value;
    let filteredRows = getFilteredList(eData?.employees, filterValue);
    let searchRows = getFilteredList(filteredRows, search);

    setEmployees(searchRows);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    let filterValue = filterType === "all" ? "" : filterType;
    let filteredRows = getFilteredList(eData?.employees, filterValue);
    let searchRows = getFilteredList(filteredRows, e.target.value);

    setEmployees(searchRows);
  };

  useEffect(() => {
    if (eData) {
      let tmp = _.cloneDeep(eData?.employees);
      setEmployees(tmp);
      console.log("useEffect - eData", eData);
    }

    if (company) {
      setCompanyId(company.id);
    }
  }, [eData, company]);

  // console.log("employees - ", employeeList);

  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
      }}
    >
      <Grid item xs={6} sx={{ pr: 1, m: 0 }}>
        
          <SearchFilter
            Icon={PeopleAltTwoToneIcon}
            Header="Employees"
            filterValue={filterType}
            handleFilterChange={handleFilter}
            handleSearch={handleSearch}
            handleNew={handleNew}
            selectList={dData?.departments}
          />
     

        <List
          sx={{
            height: Math.ceil(height - 280),
            width: "100%",
            mb: 0,
            p: 2,
            backgroundColor: "primary.back",
            overflow: "auto",
          }}
        >
          {employees?.map((_employee, index) => (
            <Paper
              variant="elevation3"
              sx={{ mb: 1 }}
              key={index}
              onClick={() => {
                //setEmployee(_employee);
                navigate(
                  "/companies/" + companyId + "/employees/" + _employee.id
                );
              }}
            >
              <SingleEmployeeView
                key={index}
                index={index}
                employee={_employee}
              />
            </Paper>
          ))}
        </List>
        <Paper
          variant="elevation3"
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          PAGINATION
        </Paper>
      </Grid>

      <Grid item xs={6} sx={{ m: 0, p: 0 }}>
        <Paper
          variant="elevation3"
          sx={{
            p: 2,
            height: Math.ceil(height - 154),
          }}
        >
          <Outlet />
        </Paper>
      </Grid>
    </Grid>
  );
};
