import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AccountMenu } from 'components/ui/AccountMenu';

import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';

import {
  Badge,
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  Business,
  Dashboard as DashboardIcon,
  DevicesOther,
  ShoppingCart,
  SupportAgent,
} from '@mui/icons-material';
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded';
import AutoGraphRoundedIcon from '@mui/icons-material/AutoGraphRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import FeedbackIcon from '@mui/icons-material/Feedback';
import WarehouseRoundedIcon from '@mui/icons-material/WarehouseRounded';
import ContentPasteRoundedIcon from '@mui/icons-material/ContentPasteRounded';
import PriceChangeRoundedIcon from '@mui/icons-material/PriceChangeRounded';
import { useGet } from 'http/useInnovit';
import { rq } from 'http/apiRoutes';
import ownerAtom from 'data/atoms/ownerAtom';
import { useAtom } from 'jotai';
import userAtom from 'data/atoms/userAtom';
import ratesAtom from 'data/atoms/ratesAtom';

const variables = {
  PHOTO_URL: process.env.PUBLIC_URL + '/img/',
};
export const Sidebar = () => {
  const drawerWidth = 260;
  const [email, setEmail] = useState('');
  const [companyQty, setCompanyQty] = useState(0);
  const [ticketsQty, setTicketsQty] = useState(0);
  const [salesQty, setSalesQty] = useState(0);
  const [projectsQty, setProjectsQty] = useState(0);
  const [ordersQty, setOrdersQty] = useState(0);
  const [owner, setOwner] = useAtom(ownerAtom);
  const [user, setUser] = useAtom(userAtom);
  const [rates, setRates] = useAtom(ratesAtom);

  // API
  let isEnabled = email !== '' ? true : false;
  const { data: userData } = useGet(
    rq.employeeByEmail(email, isEnabled),
    isEnabled
  );
  let IsUser = userData?.id ? true : false; // xxx check if user company = owner company
  const { data: ownerData } = useGet(rq.appOwner(IsUser), IsUser);
  const { data: ratesData } = useGet(rq.rates(IsUser), IsUser);

  useEffect(() => {
    if (ratesData) {
      // console.log('ratesData', ratesData.rates);
      setRates(ratesData.rates);
    }
  }, [ratesData, setRates]);

  useEffect(() => {
    if (userData) {
      console.log('userData', userData);
      setUser(userData);
    }
  }, [userData, setUser]);

  useEffect(() => {
    if (ownerData) {
      setOwner(ownerData);
    }
  }, [ownerData, setOwner]);

  return (
    <Paper
      variant='elevation3'
      sx={{
        width: drawerWidth,
        height: 'calc(100% - 20px)',
        p: 1,
        ml: 2,
        position: 'fixed',
        alignItems: 'center',
        alignContent: 'center',
      }}
    >
      <Link to='/'>
        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img height='80' src={variables.PHOTO_URL + 'iBluu.png'} alt='' />
        </Grid>
      </Link>

      <AccountMenu setEmail={setEmail} url={user?.url ?? null} />

      <Divider sx={{ backgroundColor: 'primary.lighter' }} />
      <List>
        <ListItem
          component={Link}
          to={'/dashboard'}
          sx={{ color: 'primary.text' }}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={'Dashboard'} />
          <Badge>{''}</Badge>
        </ListItem>
        <Divider sx={{ backgroundColor: 'primary.lighter' }} />

        <ListItem
          component={Link}
          to={'/companies'}
          sx={{ color: 'primary.text' }}
        >
          <ListItemIcon>
            <Business />
          </ListItemIcon>
          <ListItemText primary={'Companies'} />
          <Badge>{companyQty}</Badge>
        </ListItem>
        <Divider sx={{ backgroundColor: 'primary.lighter' }} />

        <ListItem
          component={Link}
          to={'/tickets'}
          sx={{ color: 'primary.text' }}
        >
          <ListItemIcon>
            <SupportAgent />
          </ListItemIcon>
          <ListItemText primary={'Tickets'} />
          <Badge>{ticketsQty}</Badge>
        </ListItem>
        {/* 
        <ListItem
          component={Link}
          to={'/sales'}
          sx={{ color: 'primary.text' }}
        >
          <ListItemIcon>
            <ShoppingCart />
          </ListItemIcon>
          <ListItemText primary={'Sales'} />
          <Badge>{salesQty}</Badge>
        </ListItem>
 */}
        {/*  <ListItem
          component={Link}
          to={'/projects'}
          sx={{ color: 'primary.text' }}
        >
          <ListItemIcon>
            <ContentPasteRoundedIcon />
          </ListItemIcon>
          <ListItemText primary={'Projects'} />
          <Badge>{projectsQty}</Badge>
        </ListItem> */}

    {/* <Divider sx={{ backgroundColor: 'primary.lighter' }} /> */}

        <ListItem
          component={Link}
          to={'/orders'}
          sx={{ color: 'primary.text' }}
        >
          <ListItemIcon>
            <ReceiptRoundedIcon />
          </ListItemIcon>
          <ListItemText primary={'Orders'} />
          <Badge>{ordersQty}</Badge>
        </ListItem>

        <Divider sx={{ backgroundColor: 'primary.lighter' }} />

        <ListItem
          component={Link}
          to={'/products'}
          sx={{ color: 'primary.text' }}
        >
          <ListItemIcon>
            <DevicesOther />
          </ListItemIcon>
          <ListItemText primary={'Products'} />
          <Badge>{''}</Badge>
        </ListItem>
       {/*  <Divider sx={{ backgroundColor: 'primary.lighter' }} />
 */}
        <ListItem
          component={Link}
          to={'/storage'}
          sx={{ color: 'primary.text' }}
        >
          <ListItemIcon>
            <WarehouseRoundedIcon />
          </ListItemIcon>
          <ListItemText primary={'Storage'} />
          <Badge>{''}</Badge>
        </ListItem>
        <Divider sx={{ backgroundColor: 'primary.lighter' }} />

        {/* <ListItem          
          component={Link}
          to={'/marketing'}
          sx={{ color: 'primary.text' }}
        >
          <ListItemIcon>
            <EventNoteRoundedIcon />
          </ListItemIcon>
          <ListItemText primary={'Marketing'} />
          <Badge>{''}</Badge>
        </ListItem>
       
        <Divider sx={{ backgroundColor: 'primary.lighter' }} /> */}

        <ListItem
          component={Link}
          to={'/billingOverView'}
          sx={{ color: 'primary.text' }}
        >
          <ListItemIcon>
            <PriceChangeRoundedIcon />
          </ListItemIcon>
          <ListItemText primary={'Billing'} />
          <Badge>{''}</Badge>
        </ListItem>

        <ListItem
          component={Link}
          to={'/finance'}
          sx={{ color: 'primary.text' }}
        >
          <ListItemIcon>
            <AutoGraphRoundedIcon />
          </ListItemIcon>
          <ListItemText primary={'Finance'} />
          <Badge>{''}</Badge>
        </ListItem>


       

        <Divider sx={{ backgroundColor: 'primary.lighter' }} />

        <ListItem
          component={Link}
          to={'/feedback'}
          sx={{ color: 'primary.text' }}
        >
          <ListItemIcon>
            <FeedbackIcon />
          </ListItemIcon>
          <ListItemText primary={'Feedback'} />
          <Badge>{'0'}</Badge>
        </ListItem>

        <Divider sx={{ backgroundColor: 'primary.lighter' }} />

        <ListItem component={Link} to={'/setup'} sx={{ color: 'primary.text' }}>
          <ListItemIcon>
            <SettingsRoundedIcon />
          </ListItemIcon>
          <ListItemText primary={'Setup'} />
          <Badge>{''}</Badge>
        </ListItem>

        <Divider sx={{ backgroundColor: 'primary.lighter' }} />

        <ListItem component={Link} to={'/test'} sx={{ color: 'primary.text' }}>
          <ListItemIcon>
            <EventNoteRoundedIcon />
          </ListItemIcon>
          <ListItemText primary={'Test'} />
          <Badge>{''}</Badge>
        </ListItem>

        <Divider sx={{ backgroundColor: 'primary.lighter' }} />
      </List>
    </Paper>
  );
};
