import { useDroppable } from '@dnd-kit/core';
import useWindowsDimension from 'functions/useWindowsDimension';
import { Grid, List, Paper } from '@mui/material';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { IOrderViewModel } from './OrdersConfig';
import SingleUnmanagedObjectView from 'components/ui/Orders/UnManaged/SingleUnmanagedObjectView';
import { SortableSingleOrder } from './SortableSingleOrder';

export function DroppableOrdersList(props: any) {
  const { setNodeRef } = useDroppable({
    id: props.id,
    data: props.id,
  });

  const { id: columnId, setActiveOrder, orders, handleClick } = props;
  console.log('DroppableOrdersList', columnId, orders);

  let navigate = useNavigate();

  const { height } = useWindowsDimension();

  const handleNew = () => {
    navigate('/tickets/new');
  };

  return (
    <>
      <List
        ref={setNodeRef}
        sx={{
          height: 'calc(100% - 128px)',
          width: '100%',
          mb: 0,
          p: 2,
          backgroundColor: 'primary.back',
          overflow: 'auto',
          overflowX: 'hidden',
        }}
      >
        {orders &&
          orders.map((order: IOrderViewModel, index: number) => {
            // console.log('order map', order);
            return (
              <SortableSingleOrder
                key={order.id}
                id={order.id}
                unmanagedObject={order}
                orderIndes={index}
                setActiveOrder={setActiveOrder}
                columnId={columnId}
                handleClick={handleClick}
              />
            );
          })}
      </List>
    </>
  );
}
