// Language: javascript
// Path: enum.js

export const companyTypes = [
  { id: 0, name: 'Prospect' },
  { id: 1, name: 'FormerClient' },
  { id: 2, name: 'Client' },
  { id: 3, name: 'Lost' },
  { id: 4, name: 'Vendor' },
];

export const companyPriority = [
  { id: 1, name: 'low' },
  { id: 2, name: 'normal' },
  { id: 3, name: 'high' },
];

export const employeeRole = [
  { id: 0, name: 'Manager' },
  { id: 1, name: 'DepartmentManager' },
  { id: 2, name: 'Employee' },
];

export const ticketType = [
  { id: 0, name: 'Sale' },
  { id: 1, name: 'Support' },
];

export const direction = [
  { id: 0, name: 'out' },
  { id: 1, name: 'in' },
];

export const ticketStatus = [
  { id: 0, name: 'NEW' },
  { id: 1, name: 'SCHEDULE' },
  { id: 2, name: 'IN PROGRESS' },
  { id: 3, name: 'ON HOLD' },
  { id: 4, name: 'AWAITING PARTS' },
  { id: 5, name: 'AWAITING RESPONSE' },
  { id: 6, name: 'RESOLVED' },
  { id: 7, name: 'REOPEN' },
  { id: 8, name: 'INVOICED' },
  { id: 9, name: 'INTERNAL' },
];

export const ticketCommunicationStatus = [
  { id: 0, name: 'NEW' },
  { id: 1, name: 'SCHEDULE' },
  { id: 2, name: 'IN PROGRESS' },
  { id: 3, name: 'ON HOLD' },
  { id: 4, name: 'AWAITING PARTS' },
  { id: 5, name: 'AWAITING RESPONSE' },
  { id: 6, name: 'RESOLVED' },
  { id: 7, name: 'REOPEN' },
  { id: 9, name: 'INTERNAL' },
];

export const ticketPriority = [
  { id: 0, name: 'none' },
  { id: 1, name: 'low' },
  { id: 2, name: 'normal' },
  { id: 3, name: 'high' },
  { id: 4, name: 'critical' },
];

export const billing = [
  { id: 0, name: 'systemPeriod' },
  { id: 1, name: 'companyPeriode' },
  { id: 2, name: 'solved' },
];

export const workRoles = [
  { id: 0, name: 'senior technician', value: 1290 },
  { id: 1, name: 'junior technician', value: 990 },
  { id: 2, name: 'senior developer', value: 1590 },
  { id: 3, name: 'junior developer', value: 1190 },
  { id: 4, name: 'manger', value: 1290 },
];

export const Hours = [
  { id: 0, name: '00' },
  { id: 1, name: '01' },
  { id: 2, name: '02' },
  { id: 3, name: '03' },
  { id: 4, name: '04' },
  { id: 5, name: '05' },
  { id: 6, name: '06' },
  { id: 7, name: '07' },
  { id: 8, name: '08' },
  { id: 9, name: '09' },
  { id: 10, name: '10' },
  { id: 11, name: '11' },
  { id: 12, name: '12' },
  { id: 13, name: '13' },
  { id: 14, name: '14' },
  { id: 15, name: '15' },
  { id: 16, name: '16' },
  { id: 17, name: '17' },
  { id: 18, name: '18' },
  { id: 19, name: '19' },
  { id: 20, name: '20' },
  { id: 21, name: '21' },
  { id: 22, name: '22' },
  { id: 23, name: '23' },
];

export const Minutes = [
  { id: 0, name: '00' },
  { id: 1, name: '01' },
  { id: 2, name: '02' },
  { id: 3, name: '03' },
  { id: 4, name: '04' },
  { id: 5, name: '05' },
  { id: 6, name: '06' },
  { id: 7, name: '07' },
  { id: 8, name: '08' },
  { id: 9, name: '09' },
  { id: 10, name: '10' },
  { id: 11, name: '11' },
  { id: 12, name: '12' },
  { id: 13, name: '13' },
  { id: 14, name: '14' },
  { id: 15, name: '15' },
  { id: 16, name: '16' },
  { id: 17, name: '17' },
  { id: 18, name: '18' },
  { id: 19, name: '19' },
  { id: 20, name: '20' },
  { id: 21, name: '21' },
  { id: 22, name: '22' },
  { id: 23, name: '23' },
  { id: 24, name: '24' },
  { id: 25, name: '25' },
  { id: 26, name: '26' },
  { id: 27, name: '27' },
  { id: 28, name: '28' },
  { id: 29, name: '29' },
  { id: 30, name: '30' },
  { id: 31, name: '31' },
  { id: 32, name: '32' },
  { id: 33, name: '33' },
  { id: 34, name: '34' },
  { id: 35, name: '35' },
  { id: 36, name: '36' },
  { id: 37, name: '37' },
  { id: 38, name: '38' },
  { id: 39, name: '39' },
  { id: 40, name: '40' },
  { id: 41, name: '41' },
  { id: 42, name: '42' },
  { id: 43, name: '43' },
  { id: 44, name: '44' },
  { id: 45, name: '45' },
  { id: 46, name: '46' },
  { id: 47, name: '47' },
  { id: 48, name: '48' },
  { id: 49, name: '49' },
  { id: 50, name: '50' },
  { id: 51, name: '51' },
  { id: 52, name: '52' },
  { id: 53, name: '53' },
  { id: 54, name: '54' },
  { id: 55, name: '55' },
  { id: 56, name: '56' },
  { id: 57, name: '57' },
  { id: 58, name: '58' },
  { id: 59, name: '59' },
];
