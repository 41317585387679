import { memo, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFilteredList } from 'functions/misc';
import { SearchFilter } from './SearchFilter';
import { Grid, List, Paper } from '@mui/material';
import useWindowsDimension from 'functions/useWindowsDimension';
import { companyTypes } from 'data/enum';
import { SingleCompanyView } from './SingleCompanyView';
import Business from '@mui/icons-material/Business';
import { emptyCompany } from 'data/templates';
import _ from 'lodash';

export const CompanyList = memo((props) => {
  /* INITIALIZATION */
  const { companies, handleCompanySubmit, setOpen } = props;
  const ref = useRef(2);
  // console.log(employees);
  //   const list = _.cloneDeep(employees);
  /*   list?.map((employee) => {
    employee.typeId = employee.employeeType.id;
  }); */

  const { height } = useWindowsDimension();
  let navigate = useNavigate();

  // states
  const [search, setSearch] = useState('');
  const [company, setCompany] = useState(emptyCompany);
  const [filterType, setFilterType] = useState('all');
  const [filteredCompanies, setFilteredCompanies] = useState(companies);
  const [companiesTypes, setCompaniesTypes] = useState([]);

  /* FUNCTIONS */

  // handles
  const handleNew = (e) => {
    navigate('/companies/new');
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    let filterValue = filterType === 'all' ? '' : filterType;
    let filteredRows = getFilteredList(companies, filterValue);
    let searchRows = getFilteredList(filteredRows, e.target.value);

    setFilteredCompanies(searchRows);
  };

  const handleFilterChange = (e) => {
    setFilterType(e);

    console.log('filter value', e);
    setFilterType(e);
    ref.current = e;
    // let filterValue = e === 'all' ? '' : e;
    // let filteredRows = getFilteredList(companies, filterValue);
    // let searchRows = getFilteredList(filteredRows, search);

    // setFilteredCompanies(searchRows);
  };

  useEffect(() => {
    setFilteredCompanies(companies);
  }, [companies]);

  // console.log("categories", categories, );
  //   if (!employees) return null;

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
      }}
    >
      <Grid item xs={12} sx={{ pr: 1, m: 0 }}>
       
          <SearchFilter
            Icon={Business}
            Header='Companies'
            filterValue={filterType}
            handleFilterChange={handleFilterChange}
            handleSearch={handleSearch}
            handleNew={handleNew}
            selectList={companyTypes}
          />
      

        <List
          sx={{
            height: Math.ceil(height - 148),
            width: '100%',
            mb: 0,
            p: 2,
            backgroundColor: 'primary.back',
            overflow: 'auto',
          }}
        >
          {companies?.length === 0 && <div>No data</div>}
          {companies?.map((company, index) => (
            <Paper
              key={index}
              onClick={(e) => {
                handleCompanySubmit(e, company);
                setOpen(false);
              //  console.log('company select, ', company);
              }}
              sx={{               
                ':hover': {
                  cursor: 'pointer',
                  filter: 'brightness(120%)',
                  transition: 'all 0.2s ease-in-out',
                },
              }}
            >
              <SingleCompanyView                
                index={index}
                company={company}
                setCompany={setCompany}
                detailNav={setOpen ? false : true}
              />
            </Paper>
          ))}
        </List>
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        ></Paper>
      </Grid>
    </Grid>
  );
});
