import { StyleSheet } from '@react-pdf/renderer';
import { blueGrey } from "@mui/material/colors";

export const pdfStyle = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    headerView: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 5,
      backgroundColor: blueGrey[400],
      color: 'white',
      padding: 5,
    },
    headerLeftView: {
      width: '75%',
    },
    headerRightView: {
      width: '25%',
      textAlign: 'right',
      justifyContent: 'flex-end',
    },
    headerImage: {
        width:64,
        height:32,
        paddingRight: 5,
    },
    containerView: {
        flexDirection: 'column',
    },
    rowView: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    divider: {
      backgroundColor: blueGrey[400],
      marginVertical: 3,
      padding: 1,
      width: '100%',
    },
    headerText: {
      fontSize: 18,
      fontWeight: 'bold',
      textAlign: 'left',
    },
    subText: {
      fontSize: 14,
      marginBottom: 0,
      fontWeight: 'bold',
      textAlign: 'left',
    },
    text: {
      fontSize: 14,
      textAlign: 'right',
    },
    textLeft: {
        fontSize: 12,
        textAlign: 'left',
      },
      textLeftBold: {
        fontSize: 12,
        textAlign: 'left',
        fontWeight:'bold',        
        paddingRight:10,
      },
      textInfo: {
        fontSize: 10,
        textAlign: 'left',
      },
      textInfoBlock: {
        fontSize: 10,
        textAlign: 'left',
        overflow: 'hidden',
        paddingRight:10,      
      },
      textInfoRight: {
        fontSize: 10,
        textAlign: 'right',
      },
      textRight: {
      fontSize: 12,
      textAlign: 'right',
    },
    textCenter: {
      fontSize: 12,
      textAlign: 'center',
    },
  
    pageNumber: {
      position: 'absolute',
      fontSize: 10,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    column10: {
        width: '10%',
    //    backgroundColor: blueGrey[100],
      },
    column15: {
        width: '15%',
    //    backgroundColor: blueGrey[200],
      },
    column20: {
        width: '20%',
    //    backgroundColor: blueGrey[300],
      },
    column25: {
      width: '25%',
    //  backgroundColor: blueGrey[400],
    },
    column35: {
        width: '35%',
     //   backgroundColor: blueGrey[500],
      },
    column50: {
      width: '50%',
    //  backgroundColor: blueGrey[600],
    },
    column75: {
      width: '75%',
    },
    column100: {
      width: '100%',
    },
    avsnitt: {
        marginBottom: 10,
    },
  });