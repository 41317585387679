import * as React from 'react';

import { useEffect, useState } from 'react';
//@ts-ignore
import GetAvatarInitials from 'functions/GetAvatarInitials';
import {
  Grid,
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';

import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import MoreTimeRoundedIcon from '@mui/icons-material/MoreTimeRounded';
//@ts-ignore
import GetChatColor from 'functions/GetChatColor';
import { ITicketPostViewModel } from 'pages/Tickets/TicketConfig';
const variables = {
  PHOTO_URL: process.env.PUBLIC_URL + '/img/',
};

type props = {
  ticketPost: ITicketPostViewModel;
}

export const SingleMessage = ({ticketPost}:props) => {
  
  const direction = ticketPost.direction === 1 ? 'row' : 'row-reverse';
  
  return (
    <Grid 
      container 
      display={'flex'} 
      flexDirection={direction} 
      >
      <Grid item sx={{flexGrow:1,}}>
        <ListItem
          alignItems='flex-start'          
          sx={{
            mb: 1,
            borderRadius: 3,
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
            pb: 1,
            ':hover': {
              cursor: 'pointer',
              filter: 'brightness(120%)',
              transition: 'all 0.2s ease-in-out',
            },
          }}
        >     
          <ListItemText
            primary={
              <Grid
                container
                sx={{
                  pl: 1,
                  pr: 1,
                  borderRadius: 2,
                  width: '100%',
                  backgroundColor:
                  ticketPost.internal === false
                      ? GetChatColor(ticketPost.direction) + '.dark'
                      : GetChatColor(6) + '.main',
                }}
              >
                <Typography
                  sx={{ display: 'inline', pt: 0.3 }}
                  component='span'
                  variant='body2'
                  color='text.secondary'
                >
                  {ticketPost.startTime?.toString() || new Date().toString()}
                </Typography>

                <Typography sx={{ flexGrow: 1 }}></Typography>
                <Typography
                  sx={{ display: 'inline', pt: 0.1 }}
                  component='span'
                  variant='body1'
                  color='text.primary'
                >
                  ( {ticketPost.status} )
                </Typography>
                {ticketPost.overTime && (
                  <IconButton aria-label='overtime' size='small' sx={{}}>
                    <MoreTimeRoundedIcon sx={{ fontSize: 16 }} />
                  </IconButton>
                )}
                {ticketPost.billable && (
                  <IconButton aria-label='billable' size='small' sx={{}}>
                    <AttachMoneyRoundedIcon sx={{ fontSize: 16 }} />
                  </IconButton>
                )}
                {ticketPost?.attachments && (
                  <IconButton aria-label='attachment' size='small' sx={{}}>
                    <AttachFileRoundedIcon sx={{ fontSize: 16 }} />
                  </IconButton>
                )}
              </Grid>
            }
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ 
                    mr:1,
                  display: 'inline', 
                  fontWeight: 'bold' 
                }}
                  component='span'
                  variant='body1'
                  color='text.primary'
                >
                  {ticketPost.author?.name ? ticketPost.author.name : ''}
                </Typography>
                <Typography
                  sx={{ display: 'inline' }}
                  component='span'
                  variant='body2'
                  color='text.primary'
                >
                  {ticketPost.message}
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      </Grid>
      <Grid item sx={{  pb: 1, width: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
     
            {!!ticketPost.author?.url ? (
              <Avatar
                //variant='rounded'
                alt={ticketPost.author.name}
                src={variables.PHOTO_URL + ticketPost.author.url + '.png'}
                sx={{                 
                  borderRadius: 3,
                  width: 64,
                  height: 64,
                }}
              />
            ) : (
              <Avatar {...GetAvatarInitials(ticketPost.author?.name || '', 64, 64, 3)} />
            )}
        
      </Grid>
    </Grid>
  );
};
