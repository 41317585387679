
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import useWindowsDimension from 'functions/useWindowsDimension';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FormBox } from 'components/form';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';

export const Ticket = () => {
  /* INITIALIZATIONS */
  const { ticketId } = useParams();
  const { width } = useWindowsDimension();
  let navigate = useNavigate();

  // api
  const HeaderLinks = [
    {
      name: 'Details',
      component: { Link },
      to: `/tickets/${ticketId}`,
    },

    {
      name: 'Communications',
      component: { Link },
      to: `/tickets/${ticketId}/communications`,
    },

    {
      name: 'Economy',
      component: { Link },
      to: `/tickets/${ticketId}/economy`,
    },
    {
      name: 'Products',
      component: { Link },
      to: `/tickets/${ticketId}/products`,
    },

    {
      name: 'Products history',
      component: { Link },
      to: `/tickets/${ticketId}/productshistory`,
    },

    {
      name: 'Resolution',
      component: { Link },
      to: `/tickets/${ticketId}/resolution`,
    },

    {
      name: 'Attachments',
      component: { Link },
      to: `/tickets/${ticketId}/attachments`,
    },
  ];

  return (
    <Grid container direction='row' spacing={0}>
      <Grid item xs={12} sx={{}}>
        <Paper
          variant='elevation3'
          sx={{
            pl: 2,
            pt: 2,
            pr: 2,
            mb: 2,
          }}
        >
          <FormBox
            Icon={SupportAgentRoundedIcon}
            title='Ticket'
            /*  FormJSX={Googlejsx} */
          />

          <Grid item xs={12} sx={{ p: 0.5 }}>
            <ToggleButtonGroup
              sx={{
                width: Math.ceil(width - 350),

                mb: 0.2,
                p: 0,
                borderWidth: 2,
                borderColor: 'red',
              }}
              color='primary'
              size='small'
              orientation='horizontal'
              // value={currentView}
              exclusive
              aria-label='text alignment'
            >
              {HeaderLinks.map((obj, index) => {
                return (
                  <ToggleButton
                    key={index}
                    name={obj.name}
                    onClick={() => navigate(obj.to)}
                    // Link={obj.to}
                    sx={{
                      m: 0,
                      p: 0,
                      width: 200,
                      borderRadius: 2,
                      borderLeft: 2,
                      borderRight: 2,
                      borderLeftColor: 'primary.light',
                      borderRightColor: 'primary.light',
                    }}
                    value={index}
                    aria-label='left aligned'
                  >
                    {obj.name}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} sx={{}}>
        <Outlet />
      </Grid>
    </Grid>
  );
};
