import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGet } from 'http/useInnovit';
import { rq } from 'http/apiRoutes';
import useWindowsDimension from 'functions/useWindowsDimension';
// @ts-ignore
import { getFilteredList } from 'functions/misc';
import { SingleEmployeeView } from 'components/ui/SingleEmployeeView';
import { SearchFilter } from 'components/ui/SearchFilter';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { List } from '@mui/material';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import { IEmployeeViewModel } from './ICompaniesConfig';
let _ = require('lodash');

export const Employees = () => {
  /* INITIALIZATIONS */
  let navigate = useNavigate();
  const { width, height } = useWindowsDimension();
  const { companyId } = useParams();
  const [isEnabled, setIsEnabled] = useState(true);
  const location = useLocation();

  // api
  const { data: eData } = useGet(rq.employees(companyId as string, isEnabled), isEnabled);
  const { data: dData } = useGet(rq.departments(companyId as string, isEnabled), isEnabled);
  //console.log("employee list", eData);

  // states
  const [search, setSearch] = useState('');
  const [filterType, setFilterType] = useState('all');
  const [employees, setEmployees] = useState<IEmployeeViewModel[]>([]);


  /* FUNCTIONS */
  // handles
  const handleNew = (e:any) => {
    e.preventDefault();
    console.log("click");
    navigate(`/companies/${companyId}/employees/new`);
  };

  const handleFilter = (e:any) => {
    setFilterType(e);
    let filterValue = e === 'all' ? '' : e;
    let filteredRows = getFilteredList(eData?.employees, filterValue);
    let searchRows = getFilteredList(filteredRows, search);

    setEmployees(searchRows);
  };

  const handleSearch = (e:any) => {
    setSearch(e.target.value);
    let filterValue = filterType === 'all' ? '' : filterType;
    let filteredRows = getFilteredList(eData?.employees, filterValue);
    let searchRows = getFilteredList(filteredRows, e.target.value);

    setEmployees(searchRows);
  };
  
  useEffect(() => {
    let tmp = _.cloneDeep(eData?.employees);
    setEmployees(tmp);
  }, [eData]);


  //console.log("employees - ", employees);

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
      }}
    >
      <Grid item xs={6} sx={{ pr: 1, m: 0 }}>
   
          <SearchFilter
            Icon={PeopleAltTwoToneIcon}
            Header='Employees'
            filterValue={filterType}
            handleFilterChange={handleFilter}
            handleSearch={handleSearch}
            handleNew={handleNew}
            selectList={dData?.departments}
          />

        <List
          sx={{
            height: Math.ceil(height - 286),
            width: '100%',
            mb: 0,
            p: 2,
            backgroundColor: 'primary.back',
            overflow: 'auto',
          }}
        >
          {employees?.map((employee: IEmployeeViewModel , index: number) => (
            <Paper
              variant='elevation3'
              sx={{ mb: 1 }}
              key={index}
              onClick={() => {
                //setEmployee(_employee);
                navigate(
                  '/companies/' + companyId + '/employees/' + employee.id
                );
              }}
            >
              <SingleEmployeeView
                key={index}
                index={index}
                employee={employee}
              />
            </Paper>
          ))}
        </List>
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          PAGINATION
        </Paper>
      </Grid>

      <Grid item xs={6} sx={{ m: 0, p: 0 }}>
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: Math.ceil(height - 158),
          }}
        >
          <Outlet />
        </Paper>
      </Grid>
    </Grid>
  );
};
