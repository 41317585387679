import { useState } from 'react';
import { Grid, IconButton, List, Paper, Typography } from '@mui/material';
import useWindowsDimension from 'functions/useWindowsDimension';
import { SingleDeliveryAddressView } from 'components/ui/SingleDeliveryAddressView';
import { SingleCompanyView } from 'components/ui/SingleCompanyView';
import { SingleProductView } from 'components/ui/SingleProductView';
import { SingleEmployeeView } from 'components/ui/SingleEmployeeView';
import { Button, Drawer, Box } from '@mui/material';
import { SearchFilter } from 'components/ui/SearchFilter';
import Business from '@mui/icons-material/Business';
import { SingleWorkRoleView } from 'components/ui/SingleWorkRoleView';

type Props = {
  objectList: any;
  objectType: string;
  buttonContext: string;
  buttonIcon?: any | null;
  handleChange: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    selectedObject: any,
    objectType: string
  ) => void;
  buttonHeight: any;
  buttonWidth: any;
};

export const ObjectDrawer = ({
  objectList,
  objectType,
  buttonContext,
  buttonIcon,
  handleChange,
  buttonHeight,
  buttonWidth,
}: Props) => {
  const [open, setOpen] = useState(false);
  const { width, height } = useWindowsDimension();
  return (
    <>
      {buttonIcon === null ? (
        <Button
          variant='contained'
          sx={{
            border: 1,
            borderRadius: 2,
            borderColor: 'primary.dark',         
            width: buttonWidth || '100%',
            height: buttonHeight || '100%',
          }}
          onClick={() => setOpen(!open)}
        >
          <Typography
            sx={{
              textTransform: 'uppercase',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontWeight: 'bold',
              fontSize: 14,
              letterSpacing: 2,
            }}
          >
              {buttonContext}
          </Typography>
        
        </Button>
      ) : (

      
        <Box 
        sx={{ 
          width: buttonWidth || 20, 
          height: buttonHeight || 20, 
          backgroundColor: 'primary.light',
          display: 'flex', // <-- Set this to flex
          justifyContent: 'center', // <-- This centers children horizontally
          alignItems: 'center', // <-- This centers children vertically
          borderRadius: 2,  
          boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',    
          '&:hover': {
            filter: 'brightness(120%)'
          }   
        }}
        >
          <IconButton
            sx={{
              p: 0,
              m: 0, 
              width: buttonWidth-5 || 18,
              height: buttonHeight-5 || 18,   
              //color: 'secondary.light',        
             /*  '&:hover': {
                color: 'secondary.light'
              } */
            }}
            onClick={() => setOpen(!open)}
          >
            {buttonIcon}
          </IconButton>
        </Box>
      )}

      <Drawer anchor={'left'} open={open} onClose={() => setOpen(!open)}>
        <Box sx={{ width: width / 2, backgroundColor: 'primary.main' }}>
          {objectList && (
            <Grid
              container
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexGrow: 1,
              }}
            >
              <Grid item xs={12} sx={{ pr: 1, m: 0 }}>
          
                  <SearchFilter
                    Icon={Business}
                    Header={buttonContext}
                    // filterValue={filterType}
                    // handleFilterChange={handleFilterChange}
                    // handleSearch={handleSearch}
                    //handleNew={handleNew}
                    //selectList={companyTypes}
                  />

                <List
                  sx={{
                    height: Math.ceil(height - 148),
                    width: '100%',
                    mb: 0,
                    p: 2,
                    backgroundColor: 'primary.back',
                    overflow: 'auto',
                  }}
                >
                  {objectList?.map((item: any, index: number) => (
                    <Paper
                      key={index}
                      onClick={(e) => {
                        handleChange(e, item, objectType);
                        setOpen(false);
                      }}
                      sx={{
                        ':hover': {
                          cursor: 'pointer',
                          filter: 'brightness(120%)',
                          transition: 'all 0.2s ease-in-out',
                        },
                      }}
                    >
                      {objectType === 'vendors' && (
                        <SingleCompanyView
                          index={index}
                          company={item}
                          //setCompany={handleChange}
                          //detailNav={setOpen(false)}
                        />
                      )}
                      {objectType === 'companies' && (
                        <SingleCompanyView
                          index={index}
                          company={item}
                          // setCompany={handleChange}
                          //  detailNav={setOpen(false)}
                        />
                      )}
                      {objectType === 'products' && (
                        <SingleProductView
                          index={index}
                          product={item}
                          //setCompany={handleChange}
                          //  detailNav={setOpen(false)}
                        />
                      )}
                      {objectType === 'deliveryAddress' && (
                        <SingleDeliveryAddressView
                          deliveryAddress={item}
                          //handleChange={handleChange}
                          //  detailNav={setOpen(false)}
                        />
                      )}
                      {objectType === 'employees' && (
                        <SingleEmployeeView
                          employee={item}
                          //handleChange={handleChange}
                          //  detailNav={setOpen(false)}
                        />
                      )}
                       {objectType === 'workRoles' && (
                        <SingleWorkRoleView
                          workRole={item}
                          //handleChange={handleChange}
                          //  detailNav={setOpen(false)}
                        />
                      )}
                    </Paper>
                  ))}
                </List>
                <Paper
                  variant='elevation3'
                  sx={{
                    p: 2,
                    height: 64,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                  }}
                ></Paper>
              </Grid>
            </Grid>
          )}
        </Box>
      </Drawer>
    </>
  );
};
