export default function GetChatColor(props) {
  // console.log("GetPriorityColor", props);

  let color = 'primary';
  // backgroundColor: "priorityCritical.dark",
  // backgroundColor: "priorityHigh.dark",
  // backgroundColor: "priorityNormal.dark",
  // backgroundColor: "priorityLow.dark",
  // backgroundColor: "priorityNone.dark",
  switch (props) {
    case 0:
      color = 'warning';
      break;
    case 1:
      color = 'success';
      break;
    case 2:
      color = 'third';
      break;
    case 3:
      color = 'info';
      break;
    case 4:
      color = 'secondary';
      break;
    case 5:
      color = 'error';
      break;

    default:
      color = 'primary';
      break;
  }
  // console.log("GetPriorityColor color - " , color);

  return color;
}
