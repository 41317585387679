import { Grid, Paper } from '@mui/material';
import { SearchFilter } from 'components/ui/SearchFilter';
import React from 'react';
import StorageIcon from '@mui/icons-material/Storage';



export const Storage = () => {
// INITIALIZATION

// API

 // HANDLES

 // EFFECTS
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
      }}
    >
      <Grid item xs={4} sx={{ p: 0, m: 0 }}>
      
          <SearchFilter Icon={StorageIcon} Header='Storage Hardware' />
 

        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            mr:1,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        ></Paper>
      </Grid>

      <Grid item xs={4} sx={{ p: 0, m: 0 }}>
    
          <SearchFilter Icon={StorageIcon} Header='Storage Software' />
       

        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            mr:1,
            ml:1,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        ></Paper>
      </Grid>

      <Grid item xs={4} sx={{ p: 0, m: 0 }}>
   
          <SearchFilter Icon={StorageIcon} Header='Storage Other' />
     

        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            ml:1,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        ></Paper>
      </Grid>


    </Grid>
  );
};
