import React, { useEffect, useState } from 'react'; // Import statements

import CircularProgress from '@mui/material/CircularProgress';
import { IFeedbackViewModel } from './FeedbackConfig';
import { useGet } from 'http/useInnovit';
import { rq } from 'http/apiRoutes';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import { SearchFilter } from 'components/ui/SearchFilter';
import { ThumbUpRounded } from '@mui/icons-material';
import StarRating from 'components/ui/StarRating';
import {
  FormControl,
  InputLabel,
  List,
  MenuItem,
  Select,
  Button,
  Typography,
  Paper,
  Grid,
} from '@mui/material';
import GetChartColor from 'functions/GetChartColor';
import useWindowsDimension from 'functions/useWindowsDimension';

export const Feedbacks = () => {
  const { width, height } = useWindowsDimension();

  // Constants
  const quantity = 1000;
  const numColumns = 2;
  const feedbacksPerColumn = 5;
  const itemsPerPageOptions = [1, 2, 5, 10];

  // States
  const [currentPage, setCurrentPage] = useState(1);
  const [feedback, setFeedback] = useState<IFeedbackViewModel[]>([]);
  const [loading, setLoading] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  // API
  const { data: feedbackAdminData } = useGet(
    rq.feedbackAdmin(1, quantity, true)
  );

  // Use Effects
  useEffect(() => {
    if (feedbackAdminData) {
      setFeedback(feedbackAdminData.feedbacks);
      setLoading(false);
    }
  }, [feedbackAdminData]);

  // Handlers
  const handlePageClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (event: any) => {
    const selectedValue = parseInt(event.target.value, 10);
    if (itemsPerPageOptions.includes(selectedValue)) {
      setItemsPerPage(selectedValue);
      setCurrentPage(1);
      console.log(`Changed to ${selectedValue}`);
    }
  };

  // Calculations
  const totalPages = Math.ceil(feedback.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, feedback.length);
  const currentFeedbacks = feedback.slice(startIndex, endIndex);
  const feedbackItemClass = 5 > feedbacksPerColumn ? 6 : 12;

  // Generate Feedback Columns
  const feedbackColumns = [];
  for (let i = 0; i < numColumns; i++) {
    const startIdx = i * feedbacksPerColumn;
    const endIdx = Math.min(
      startIdx + feedbacksPerColumn,
      currentFeedbacks.length
    );
    feedbackColumns.push(currentFeedbacks.slice(startIdx, endIdx));
  }

  // JSX Return
  return (
    <Grid container display={'flex'} flexDirection={'column'} flexGrow={1}>
     
        {/* Header */}
        <Grid item xs={12}>
          <SearchFilter Icon={ThumbUpRounded} Header='Feedback' />
        </Grid>
     
        {/* Middle Section */}
        <List
          sx={{
            height: Math.ceil(height - 148),
            width: '100%',
            mb: 0,
            p: 2,
            backgroundColor: 'primary.back',
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            feedbackColumns.map((column, columnIndex) => (
              <Grid item xs={feedbackItemClass} key={columnIndex}>
                {column.map((currentFeedback) => (
                  <Paper
                    key={currentFeedback.id}
                    elevation={10}
                    sx={{
                      backgroundColor: 'primary.main',
                      color: 'primary.text',
                      mb: 2,
                      p: 2,
                      overflow: 'auto',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      leftBorder: 8,
                      borderRadius: 3,
                      borderLeft: 8,
                      borderRight: 3,
                      borderColor: `${GetChartColor(
                        currentFeedback.rating
                      )}.light`,
                    }}
                  >
                    <ReceiptRoundedIcon sx={{ fontSize: 40 }} />
                    <Grid item sx={{ flexGrow: 1, pl: 1 }}>
                      <Grid container alignItems='center'>
                        <Grid
                          item
                          sx={{
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            color: 'text.primary',
                          }}
                        >
                          <Typography>{`${currentFeedback.employee.name}`}</Typography>
                          <Typography
                            sx={{ pr: 1, pl: 1, color: 'text.secondary' }}
                          >
                            |
                          </Typography>
                          <Typography
                            sx={{ color: 'text.secondary' }}
                          >{` ${currentFeedback.company.name}`}</Typography>
                        </Grid>
                        <Grid item>
                          <StarRating rating={currentFeedback.rating} />
                        </Grid>
                      </Grid>
                      <Typography
                        sx={{
                          wordWrap: 'break-word',
                          color: 'main.text',
                          maxWidth: '100%',
                        }}
                      >
                        {currentFeedback.description}
                      </Typography>
                    </Grid>
                  </Paper>
                ))}
              </Grid>
            ))
          )}
        </List>
        {/* Footer */}
      
        <Paper
      variant='elevation3'
      sx={{
        height: 64,
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
        m:0,
        p:0,
        pt:1.5,
      }}
    >
            <Grid 
              container
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'left'}
              >
              <Grid item  sx={{ pr: 2,pl: 2, pt:1, }}> 
                <Typography 
                 sx={{
                  m: 0,
                  letterSpacing: 1,
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 'normal',
                  textTransform: 'capitalize',
                  fontSize: 16,
                }}
                >
                  Items per page
                </Typography>
              </Grid>
              <Grid item  sx={{ width:100, pr: 2,pl: 2, }}> 
                  <Select
                    sx={{ width:70, }}
                    variant = 'standard' 
                    labelId='simple-select-label'
                    id='simple-select'
                    label='Items per page'
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                  >
                    {itemsPerPageOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
              </Grid>
              
              
                <Button
                  sx={{ width:120, }}
                  variant='contained'
                  color='primary'
                  onClick={() => handlePageClick(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </Button>
              <Grid item sx={{ pr: 2,pl: 2, pt:1, }}> 
              <Typography 
                 sx={{
                  m: 0,
                  letterSpacing: 1,
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 'normal',
                  textTransform: 'capitalize',
                  fontSize: 16,
                }}
                >
                  Page {currentPage} of {totalPages}
                </Typography>
              </Grid>
                <Button
                  sx={{ width:120, }}
                  variant='contained'
                  color='primary'
                  disabled={currentPage === totalPages}
                  onClick={() => handlePageClick(currentPage + 1)}
                >
                  Next
                </Button>
            </Grid>
          </Paper> 
    </Grid>
  );
};
