import React, { useEffect, useState, useReducer } from 'react';
import { useDel, useGet, usePost, usePut } from 'http/useInnovit';
import { rqDel, rqPost, rq } from 'http/apiRoutes';
import { Grid, List, Paper, Button, TextField } from '@mui/material';
import EngineeringIcon from '@mui/icons-material/Engineering';
import useWindowsDimension from 'functions/useWindowsDimension';
import ownerAtom from 'data/atoms/ownerAtom';
import { useAtom } from 'jotai';
import { FormBox } from 'components/form/FormBox';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import LocalAtmRoundedIcon from '@mui/icons-material/LocalAtmRounded';
import ratesAtom from 'data/atoms/ratesAtom';
import { IRateViewModel, IWorkRoleViewModel } from 'data/atoms/atomConfig';
import { SingleWorkRoleView } from 'components/ui/SingleWorkRoleView';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { SearchFilter } from 'components/ui/SearchFilter';
import { useForm } from 'react-hook-form';

const formReducer = (state: any, action: any) => {
  switch (action.type.toUpperCase()) {
    case 'SET_RATES':
      return {
        ...state,
        rates: action.payload._rates
      };
      case 'UPDATE_RATE':
        const newRates = [...state.rates];
        newRates[action.payload.index][action.payload.field] = +action.payload.value;
        return { ...state, rates: newRates };
  
      case 'UPDATE_END_AND_START_HOUR':
        const updatedRates = [...state.rates];
        updatedRates[action.payload.index].endHour = +action.payload.value;
  
        if (action.payload.index + 1 < 4) { // Ensure we are not at the last rate
          updatedRates[action.payload.index + 1].startHour = +action.payload.value;
        }  
        return { ...state, rates: updatedRates };
    case 'POST_WORKROLE':
      // console.log("WORK ROLE PAYLOAD", action.payload);
      return {
        ...action.payload,
      };
    case 'SET_WORK_ROLE':
      return {
        ...state,
        workRole: action.payload,
      };
    case 'UPDATE_WORK_ROLE':
      return {
        ...state,
        id: action.payload.id,
        title: action.payload.title,
        inHouseHourlyRate: action.payload.inHouseHourlyRate,
        onSiteHourlyRate: action.payload.onSiteHourlyRate,
      };
    case 'CHANGE_NAME':
      // console.log("CHANGE TITLE PAYLOAD", action.payload);
      return { ...state, title: action.payload };
    case 'CHANGE_IN_HOUSE_HOURLY_RATE':
      return { ...state, inHouseHourlyRate: +action.payload };
    case 'CHANGE_ON_SITE_HOURLY_RATE':
      return { ...state, onSiteHourlyRate: +action.payload };
    case 'RESET_FORM':
      return initialState;
    case 'RESET_WORK_ROLE':
      return {
        ...state,
        workRole: {} as IWorkRoleViewModel,
      };
    default:
      return state;
  }
};

const initialState = {
  DispatchFee: 0,
  WeekendFee: 0,
  HolidayFee: 0,
  rates: [] as IRateViewModel[],
  workRole: {} as IWorkRoleViewModel,
  rateName: ['Night time', 'Work time', 'Early evening', 'Late evening'],
};

export const WorkRoles = () => {
  // initializations
  const { height } = useWindowsDimension();

  // states
  const [owner, setOwner] = useAtom(ownerAtom);
  const [rates, setRates] = useAtom(ratesAtom);
  const [workRoles, setWorkRoles] = useState<IWorkRoleViewModel[]>([]);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [state, dispatch] = useReducer(formReducer, initialState);
  useState<IWorkRoleViewModel | null>(null);

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { isDirty },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: state.workRole,
    /*  resolver: yupResolver(schema), */
  });

  // api
  let isEmployee = true;
  let isEnabled = owner.id ? true : false;
  const putWorkRole = usePut(rqPost.workRole(owner.id, true), true);
  const postWorkRole = usePost(rqPost.workRole(owner.id, true), true);
  let isWorkRoleEnabled = state.workRole.id ? true : false;
  const delWorkRole = useDel(
    rqDel.workRole(owner.id, state.workRole.id, isWorkRoleEnabled),
    isWorkRoleEnabled
  );
  const { data: workRoleDataGet } = useGet(rq.workRoles(owner.id, isEnabled),true);
  const { data: ratesData } = useGet(rq.rates(isEnabled),true);
  const putRates = usePut(rqPost.rates(true), true);
  // HANDLES

  const handleConfirm = async (e: any) => {
    e.preventDefault();
    let _workRole = { ...state.workRole };

    console.log('HandleConfirm', _workRole);
    //let id = state.workRole.id.subString(0,3);

    if (_workRole.id !== 'new') {
      delWorkRole.mutate(state.workRole, {
        onSuccess: dispatch({
          type: 'RESET_WORK_ROLE',
        }),
      });
    }
  };
  // form
  const onSubmit = (data: any, e: any) => {
    e.preventDefault();

    if (state.workRole.id === 'new') {
      delete state.workRole.id;

      postWorkRole.mutate(state.workRole, {
        onSuccess: dispatch({
          type: 'RESET_WORK_ROLE',
        }),
      });
    } else {
      console.log('onsubmit putWorkRole ', state.workRole);
      putWorkRole.mutate(state.workRole, {
        onSuccess: dispatch({
          type: 'RESET_WORK_ROLE',
        }),
      });
    }
  };
  const handleChangeWorkRole = (workRole: IWorkRoleViewModel) => {
    console.log('HandleChangeWorkRole', workRole);
    let _workRole: IWorkRoleViewModel = {
      title: workRole.title,
      id: workRole.id,
      inHouseHourlyRateCost: +workRole.inHouseHourlyRateCost,
      inHouseHourlyRate: +workRole.inHouseHourlyRate,
      onSiteHourlyRateCost: +workRole.onSiteHourlyRateCost,
      onSiteHourlyRate: +workRole.onSiteHourlyRate,
    };

    dispatch({
      type: 'SET_WORK_ROLE',
      payload: _workRole,
    });
  };
  const handleChangeName = (e: any) => {
    dispatch({
      type: 'SET_WORK_ROLE',
      payload: {
        ...state.workRole,
        title: e.target.value, // Update the title property
      },
    });
  };
  const handleChangeInHouseHourlyRate = (e: any) => {
    dispatch({
      type: 'SET_WORK_ROLE',
      payload: {
        ...state.workRole,
        inHouseHourlyRate: +e.target.value, // Update the inHouseHourlyRate property
      },
    });
  };
  const handleChangeInHouseHourlyRateCost = (e: any) => {
    dispatch({
      type: 'SET_WORK_ROLE',
      payload: {
        ...state.workRole,
        inHouseHourlyRateCost: +e.target.value, // Update the inHouseHourlyRate property
      },
    });
  };
  const handleChangeOnSiteHourlyRate = (e: any) => {
    dispatch({
      type: 'SET_WORK_ROLE',
      payload: {
        ...state.workRole,
        onSiteHourlyRate: +e.target.value, // Update the onSiteHourlyRate property
      },
    });
  };
  const handleChangeOnSiteHourlyRateCost = (e: any) => {
    console.log('handleChangeOnSiteHourlyRateCost', e.target.value);
    dispatch({
      type: 'SET_WORK_ROLE',
      payload: {
        ...state.workRole,
        onSiteHourlyRateCost: +e.target.value, // Update the onSiteHourlyRate property
      },
    });
  };
  const handleNewWorkRole = async (e: React.MouseEvent) => {
    e.preventDefault();
    const newWorkRoleData: IWorkRoleViewModel = {
      id: 'new',
      title: '',
      inHouseHourlyRateCost: 0,
      inHouseHourlyRate: 0,
      onSiteHourlyRateCost: 0,
      onSiteHourlyRate: 0,
    };
    dispatch({
      type: 'SET_WORK_ROLE',
      payload: newWorkRoleData,
    });
  };
  const handleChangeRate = (index: number, field: string, value: any) => {
    if (field === 'endHour' && index < 3) { // Check it's an 'endHour' and not for the last rate
      dispatch({
        type: 'UPDATE_END_AND_START_HOUR',
        payload: { index, value },
      });
    } else {
      dispatch({
        type: 'UPDATE_RATE',
        payload: { index, field, value },
      });
    }
  }
  
  const handleSubmitRoles = async (e: React.MouseEvent) => {
    e.preventDefault();
    console.log('handleSubmitRoles', state.rates);
   let rates =  {
      rates: state.rates.map((rate: IRateViewModel) => ({
        id: rate.id,
        startHour: rate.startHour,
        endHour: rate.endHour,
        percentageIncrease: rate.percentageIncrease,
      })),
    }
    putRates.mutate(rates,);
  };
  //UseEffect
  useEffect(() => {
    if (workRoleDataGet) {
      const sortedWorkRoles = [...workRoleDataGet.workRoles].sort((a, b) => {
        if (a.title < b.title) return -1;
        if (a.title > b.title) return 1;
        return 0;
      });

      setWorkRoles(sortedWorkRoles as IWorkRoleViewModel[]);
      
    }
  }, [workRoleDataGet]);

  useEffect(() => {
    if (ratesData) {
      let _rates = ratesData.rates.map((rate: IRateViewModel) => ({
        id: rate.id,
        startHour: rate.startHour,
        endHour: rate.endHour,
        percentageIncrease: rate.percentageIncrease,
      }));
     // console.log('_rates', _rates);
  
      dispatch({
        type: 'SET_RATES',
        payload: {
          ...state.rates, _rates
        },
      });
    }
  }, [ratesData]);


  

  return (
    <>
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
        }}
      >
      <Grid item xs={6} sx={{ p: 1, m: 0 }}>
        <Paper variant='elevation3'>
          <SearchFilter
            Icon={EngineeringIcon}
            Header='Work Roles'
            handleNew={handleNewWorkRole}
          />

          {/* Display relevant work roles on the left */}
          <List
            sx={{
              height: Math.ceil(height / 2 - 20 - 64 - 64), // Adjust the height as needed
              overflowY: 'auto', // Add this to make the list scrollable
              mb: 0,
              p: 2,
              backgroundColor: 'primary.back',
            }}
          >
            {workRoles?.map((workRole: IWorkRoleViewModel) => (
              <Grid
                key={workRole.id}
                onClick={() => {
                  handleChangeWorkRole(workRole as IWorkRoleViewModel);
                }}
              >
                <SingleWorkRoleView workRole={workRole} />
              </Grid>
            ))}
          </List>
        </Paper>
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        ></Paper>
      </Grid>
      <Grid item xs={6} sx={{p: 1, m: 0 }}>
        <form onSubmit={handleSubmit(onSubmit)} name='ratesForm'>
          <SearchFilter
            Icon={EngineeringIcon}
            Header={
              state.workRole.id === 'new'
                ? 'Create work role'
                : 'Edit work role'
            }
            hideAll={state.workRole.id === 'new' ? false : false}
          />
          <Paper
            variant='elevation3'
            sx={{
              p: 2,
              borderRadius: 0,
              height: Math.ceil(height / 2 - 20 - 64 - 64), // Adjust the height as needed
            }}
          >
            {/* Right side (work role form) */}
            {state.workRole.id != null ? (
              <>
                <TextField
                  onChange={handleChangeName}
                  sx={{ width: '100%', p: 0, mb: 2 }}
                  id='WorkRoleTitle'
                  label='Work Role Title'
                  variant='standard'
                  value={state.workRole.title}
                  InputLabelProps={{
                    shrink: state.workRole.title ? true : undefined,
                  }}
                />
                <TextField
                  onChange={handleChangeInHouseHourlyRateCost}
                  sx={{ width: '100%', p: 0, mb: 2 }}
                  id='InHouseHourlyRateCost'
                  label='In house hourly rate Cost'
                  variant='standard'
                  value={state.workRole.inHouseHourlyRateCost}
                  InputLabelProps={{
                    shrink: state.workRole.inHouseHourlyRateCost
                      ? true
                      : undefined,
                  }}
                />
                <TextField
                  onChange={handleChangeInHouseHourlyRate}
                  sx={{ width: '100%', p: 0, mb: 2 }}
                  id='InHouseHourlyRate'
                  label='In house hourly rate'
                  variant='standard'
                  value={state.workRole.inHouseHourlyRate}
                  InputLabelProps={{
                    shrink: state.workRole.inHouseHourlyRate
                      ? true
                      : undefined,
                  }}
                />
                <TextField
                  onChange={handleChangeOnSiteHourlyRateCost}
                  sx={{ width: '100%', p: 0, mb: 2 }}
                  id='OnSiteHourlyRateCost'
                  label='On site hourly rate cost'
                  variant='standard'
                  value={state.workRole.onSiteHourlyRateCost}
                  InputLabelProps={{
                    shrink: state.workRole.onSiteHourlyRateCost
                      ? true
                      : undefined,
                  }}
                />
                <TextField
                  onChange={handleChangeOnSiteHourlyRate}
                  sx={{ width: '100%', p: 0, mb: 2 }}
                  id='OnSiteHourlyRate'
                  label='On site hourly rate'
                  variant='standard'
                  value={state.workRole.onSiteHourlyRate}
                  InputLabelProps={{
                    shrink: state.workRole.onSiteHourlyRate
                      ? true
                      : undefined,
                  }}
                />
              </>
            ) : (
              // Render an empty paper when not editing
              <Paper />
            )}
            {/* Button to trigger the fetchWorkRoles function and update the work role */}
          </Paper>
          <Paper
            variant='elevation3'
            sx={{
              p: 2,
              height: 64,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            {/*   Submit Button */}

            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
              <Button
                variant='contained'
                onClick={() =>
                  setDeleteConfirm(!deleteConfirm ? true : false)
                }
              >
                {postWorkRole.isLoading
                  ? 'Deleting Workrole...'
                  : 'Delete Workrole'}
              </Button>
              {!!deleteConfirm === true && (
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={handleConfirm}
                >
                  Confirm Delete
                </Button>
              )}
              <Grid sx={{ flexGrow: 1 }}></Grid>{' '}
              {state.workRole.id !== 'new' && (
                <Button type='submit' variant='contained'>
                  {putWorkRole.isLoading
                    ? 'Updating Workrole...'
                    : 'Update Workrole'}
                </Button>
              )}
              {state.workRole.id === 'new' && (
                <Button type='submit' variant='contained'>
                  {postWorkRole.isLoading
                    ? 'Creating Workrole...'
                    : 'Create Workrole'}
                </Button>
              )}
            </Grid>
          </Paper>
        </form>
      </Grid>
      <Grid item xs={6} sx={{ p: 1, m: 0 }}>
        <SearchFilter
          Icon={LocalAtmRoundedIcon}
          Header='Extra billing'
        />
        <Grid
          sx={{
            p: 2,
            height: Math.ceil(height / 2 - 26 - 64 - 64), // Adjust the height as needed
            backgroundColor: 'primary.main',
            borderRadius: 0,  
          }}
        >
          <TextField
            disabled={true}
            sx={{ width: '100%', p: 0, mb: 2 }}
            id='DispatchFee'
            label='Dispatch Fee'
            variant='standard'
            value={state.DispatchFee}
            InputLabelProps={{
              shrink: state.DispatchFee ? true : undefined,
            }}
          />
          <TextField
          disabled={true}
            sx={{ width: '100%', p: 0, mb: 2 }}
            id='WeekendFee'
            label='Weekend Fee'
            variant='standard'
            value={state.WeekendFee}
            InputLabelProps={{
              shrink: state.WeekendFee ? true : undefined,
            }}
          />
          <TextField
          disabled={true}
            sx={{ width: '100%', p: 0, mb: 2 }}
            id='HolidayFee'
            label='Holiday Fee'
            variant='standard'
            value={state.HolidayFee}
            InputLabelProps={{
              shrink: state.HolidayFee ? true : undefined,
            }}
          />
        </Grid>
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        ></Paper>
      </Grid>
      <Grid item xs={6} sx={{ p: 1, m: 0}}>
        <SearchFilter
          Icon={PendingActionsIcon}
          Header='24 hour rates'
        />
        <Grid
          sx={{
            p: 2,
            height: Math.ceil(height / 2 - 26 - 64 - 64), // Adjust the height as needed
            backgroundColor: 'primary.main',
            borderRadius: 0,  
          }}
        >
{state.rates.length > 0 && state.rates.map((rate: IRateViewModel, index: number) => (
  <Grid
    item
    key={index}
    xs={12}
    sx={{
      mb: 1,
      display: 'flex',
      flexDirection: 'row',
      fontSize: 12,
    }}
  >
    <Grid item xs={2} sx={{ mb: 1 }}>
      <TextField                   
        sx={{ width: '100%', p: 0, mb: 2, pr: 2 }}
        disabled={true}
        id={`StartHour${index}`}
        label='Start Hour'
        variant='standard'
        value={rate.startHour}
        InputLabelProps={{
          shrink: rate.startHour ? true : undefined,
        }}
        onChange={(e) => handleChangeRate(index, 'startHour', e.target.value)}
      />
    </Grid>
    <Grid item xs={6} sx={{ mb: 1 }}>
      <TextField
        sx={{ width: '100%', p: 0, mb: 2, pr: 2 }}
        disabled={true}
        id={`RateName${index}`}
        label='Rate Name'
        variant='standard'
        value={state.rateName[index]}
        InputLabelProps={{
          shrink: state.HolidayFee ? true : undefined,
        }}
        // Assuming you also want to handle change for rateName.
       //onChange={(e) => handleRateNameChange(index, e.target.value)}
      />
    </Grid>
    <Grid item xs={2} sx={{ mb: 1 }}>
      <TextField
        sx={{ width: '100%', p: 0, mb: 2, pr: 2 }}
        id={`endHour${index}`}
        label='End Hour'
        variant='standard'
        disabled={index === state.rates.length - 1}
        value={rate.endHour}
        InputLabelProps={{
          shrink: rate.endHour ? true : undefined,
        }}
        onChange={(e) => handleChangeRate(index, 'endHour', e.target.value)}
      />
    </Grid>
    <Grid item xs={2} sx={{ mb: 1 }}>
      <TextField
        sx={{ width: '100%', p: 0, mb: 2, pr: 2 }}
        id={`percentageIncrease${index}`}
        label='% Increase'
        variant='standard'
        value={rate.percentageIncrease}
        InputLabelProps={{
          shrink: rate.percentageIncrease ? true : undefined,
        }}
        onChange={(e) => handleChangeRate(index, 'percentageIncrease', e.target.value)}
      />
    </Grid>
  </Grid>
))}
        </Grid>
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
             
              <Grid sx={{ flexGrow: 1 }}></Grid>{' '}
                <Button 
                  type='submit' 
                  variant='contained'
                  onClick={(e) => {handleSubmitRoles(e)}}
                >
                  Update Workroles
                </Button>
            </Grid>
        </Paper>
      </Grid>
      </Grid>
    </>
  );
};
