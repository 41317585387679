import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGet } from 'http/useInnovit';
import { rq } from 'http/apiRoutes';
import { SingleCompanyView } from 'components/ui/SingleCompanyView';
import CompanyView from 'components/ui/CompanyView';
import { SearchFilter } from 'components/ui/SearchFilter';
import { emptyCompany } from 'data/templates';
import { companyTypes } from 'data/enum';
import { getFilteredList } from 'functions/misc';
import useWindowsDimension from 'functions/useWindowsDimension';

import { Grid, List, Paper } from '@mui/material';
import Business from '@mui/icons-material/Business';
import { CompanyList } from 'components/ui/CompanyList';

let _ = require('lodash');

export const CompaniesList = () => {
  /* INITIALIZATIONS */
  let navigate = useNavigate();
  const { height } = useWindowsDimension();
  const ref = useRef(2);

  // api
  const { data } = useGet(rq.companies(ref.current));

  // states
  const [company, setCompany] = useState(emptyCompany);
  const [companies, setCompanies] = useState([]);
  const [filterType, setFilterType] = useState(2);

  /* FUNCTIONS */

  // handles
  const handleNew = (e) => {
    navigate('/companies/new');
  };

  const handleSearch = (e) => {
    setCompanies(getFilteredList(data?.companies, e.target.value));
  };
  const handleFilterChange = (e) => {
    console.log('filter value', e);
    setFilterType(e);
    ref.current = e;
  };

  useEffect(() => {
    let tmp = _.cloneDeep(data?.companies);
    setCompanies(tmp);
  }, [data]);

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
      }}
    >
      <Grid item xs={6} sx={{ pr: 1, m: 0 }}>
 
          <SearchFilter
            Icon={Business}
            Header='Companies'
            filterValue={filterType}
            handleFilterChange={handleFilterChange}
            handleSearch={handleSearch}
            handleNew={handleNew}
            selectList={companyTypes}
          />
        <List
          sx={{
            height: Math.ceil(height - 148),
            width: '100%',
            mb: 0,
            p: 2,
            backgroundColor: 'primary.back',
            overflow: 'auto',
          }}
        >
          {companies?.length === 0 && <div>No data</div>}
          {companies?.map((company, index) => (
            <SingleCompanyView
              key={index}
              index={index}
              company={company}
              setCompany={setCompany}
              detailNav={true}
            />
          ))}
        </List>
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        ></Paper>
      </Grid>

      <Grid item lg={6} sx={{ pl: 1 }}>
        <Paper
          variant='elevation3'
          sx={{
            height: Math.ceil(height - 23),
          }}
        >
          <List
            sx={{
              pl: 2,
              pr: 2,
              height: Math.ceil(height - 24),
              backgroundColor: 'primary.main',
              overflow: 'auto',
              borderRadius: 3,
            }}
          >
            {!!company.id ? (
              <CompanyView company={company} />
            ) : (
              <CompanyView company={emptyCompany} />
            )}
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
};
