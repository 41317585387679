import { memo, useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import { useGet, usePost, usePut } from "http/useInnovit";
import { useForm } from "react-hook-form";
import { rq, rqPost } from "http/apiRoutes";
import { FormText, FormSelect, FormSelectProduct, FormBox } from "components/form";
import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Box,
} from "@mui/material";
import FeedRoundedIcon from "@mui/icons-material/FeedRounded";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import ImageUploader from "components/ui/ImageUploader";
import ConvertBase64StringToFileModel from "functions/ConvertBase64StringToFileModel";
import useWindowsDimension from 'functions/useWindowsDimension';
import { IEnumCycleAlignment } from "pages/Setup/setupConfig";

let _ = require("lodash");

const initialState = { category: null, categoryId: null, type: null };


const FeatureList = memo((props: any) => {
  const { features, control } = props;
  console.log("features ", features);
  return (
    <>
      {features?.map((f:any) => (
        <Grid item xs={3} key={f.id}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                m: 0,
                pr: 1,
                pt: 3,
                pb: -1,
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <AddBoxRoundedIcon sx={{ fontSize: 18 }} />
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <FormSelect
                name={`feat.${f.name}`}
                control={control}
                label={f.name}
                list={f.productFeatureData}
              />
            </Box>
          </Box>
        </Grid>
      ))}
    </>
  );
});

const fields = [
  "productType",
  "serialNumber",
  "brand",
  "name",
  "comment",
  "security",
  "unitPrice",
  "retailPrice",
  "ean",
  "paymentCycle",
  "bindingPeriod",
];

export const ProductForm = (props: any) => {
  /* INITIALIZATIONS */
  const { productId } = useParams();
  const { categories, product } = props;

  const cycleAlignmentOptions: { id: IEnumCycleAlignment; name: string }[] = [
    { id: IEnumCycleAlignment.None, name: "None" },
    { id: IEnumCycleAlignment.Product, name: "Product" },
    { id: IEnumCycleAlignment.ProductType, name: "Product Type" },
    { id: IEnumCycleAlignment.Category, name: "Category" },
  ];
  
  
  //console.log("product", product);
  // states
  const reducer = (state:any, action:any) => {
    switch (action.type) {
      case "SET_CATEGORY":
        let cat = categories.productCategories?.find(
          (c:any) => c.id === action.payload
        );
        return { ...state, category: cat, type: "" };
      case "SET_TYPE":
        return { ...state, type: action.payload };
      case "SET_FORM":
        return {
          ...state,
          category: action.payload.category,
          type: action.payload.type,
        };
      case "RESET_FORM":
        return { ...initialState };
      default:
    }
  };
  const [state, action] = useReducer(reducer, initialState);
  const [image, setImage] = useState<File | null>(null);
  const [base64String, setBase64String] = useState<string | null>(null);
  const { height } = useWindowsDimension(); 
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { isDirty },
  } = useForm({
    mode: "onSubmit",
    resolver: undefined,
    defaultValues: { ...product },
  });
  // api
  let isEnabled = !state.type ? false : true;

  const { data: features } = useGet(
    rq.productTypeFeatures(state.type, isEnabled),
    isEnabled
  );
  const validProductId = productId || ''; // Use an empty string as a default if productId is undefined
  const putProduct = usePut(rqPost.product(validProductId, true), true);
  const postProduct = usePost(rqPost.product(validProductId, true), true);
  


 const [allBrands, setAllBrands] = useState([]);

 const { data: brandData } = useGet(rq.getAllBrands(1, isEnabled), isEnabled);

 useEffect(() => {
  if (brandData) {
    console.log("Brands", brandData);
    setAllBrands(brandData.map((item: any) => ({
      id: item.id,
      brandName: item.brandName,
      isActive: item.isActive,
    })));
  }
  
}, [brandData]);

  /* FUNCTIONS */

  // handles
  const handleSelectType = (id: any) => {
    action({ type: "SET_TYPE", payload: id });
  };

  const setFormDirty = () => {
    setValue("image", image, { shouldDirty: true });
  };

  // onsubmit
  const onSubmit = (data: any, e: any) => {
    e.preventDefault();
    let _product = _.cloneDeep(data);
    let arr = [];
    let tmp;

    console.log("onSubmit product", _product);

    // get feature data and store in respective property
    if (productId === "new") {
      for (let feature in _product.feat) {
        if (_product.feat[feature] !== "") {
          _product.productFeatureDataIds.push(_product.feat[feature]);
        }
      }
    } else {
      for (let i in _product.feat) {
        for (let j in features.productFeatures) {
          tmp = features.productFeatures[j].productFeatureData.find(
            (f: any) => f.id === _product.feat[i]
          );
          if (tmp) {
            arr.push(tmp);
            break;
          }
        }
      }
      _product.productFeatures = arr;
    }
    // delete feat and features from product
    delete _product.feat;
    delete _product.features;

    // remove productType and add productTypeId
    _product.productTypeId = _product.productType;
    delete _product.productType;

    // XXX add fileModel and remove url
    //product.fileModel = null;
    delete _product.url;
    if (image) {
      _product.fileModel = ConvertBase64StringToFileModel(
        base64String,
        image.name
      );
    }

    // convert strings to int
    _product.quantity = +_product.quantity;
    _product.unitPrice = +String(_product.unitPrice).replace(',', '.');
    _product.retailPrice = +String(_product.retailPrice).replace(',', '.');
    _product.security = +_product.security;
    _product.paymentCycle = +_product.paymentCycle;
    _product.bindingPeriod = +_product.bindingPeriod;
    _product.ean = _product.ean ? _product.ean : null;

    if (isDirty) {
      if (productId === "new") {
        console.log("product new", _product);
       postProduct.mutate(_product, {
          onSuccess: (res :any) => {
            reset(res);
            setFeatureValues(res);
            // set features values
            res.features?.map((f:any) => {
              setValue(`feat.${f.featureName}`, f.id);
            });
          },
        }); 
      } else {
        _product.id = productId;
        putProduct.mutate(_product, {
          onSuccess: (res:any) => {
            console.log("onSuccess", res);
            //setValue(res); set form values
          },
        });
      }
    }
  };

  const Typesjsx = memo(() => {
    return (
      <FormSelectProduct
        name="productType"
        disabled={state.category?.id ? false : true}
        control={control}
        label="Product Types"
        handleSelectType={handleSelectType}
        list={state.category?.productTypes ? state.category.productTypes : []}
      />
    );
  });

  const setFeatureValues = (product:any) => {
    let cat:any = null;
    // XXX this is a wrong approach
    fields.map((f) => {
      if (f === "productType") {
        for (let c in categories?.productCategories) {
          if (cat) break;
          let tmp = categories?.productCategories[c];
          tmp.productTypes.find((element:any) => {
            if (element.id === product[f].id) {
              cat = tmp;
              return;
            }
          });
        }

        if (product[f] !== null || product[f] !== undefined) {
          action({
            type: "SET_FORM",
            payload: { category: cat, type: product[f].id },
          });
          setValue(f, product[f].id);
        }
      } else {
        // set basic information
        setValue(f, product[f]);
      }
    });
  };

  useEffect(() => {
    if (productId === "new") {
      reset(product);
      action({
        type: "SET_FORM",
        payload: { category: null, type: null },
      });
    } else {
      // set category, product type based on product type
      setFeatureValues(product);
    }

    // set features values
    product.features?.map((f:any) => {
      setValue(`feat.${f.featureName}`, f.id);
    });
  }, [productId, product, reset, setValue, action]);

  return (
    <Grid
      container
      direction="row"
      spacing={0}
      sx={{
        p: 2,
      }}
    >
      <Box sx={{ width: "100%" }}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        name={`form-${productId}`}
      >
        <Grid item xs={12} sx={{ pt: 2, pb: 2 }}>
          <Box
            sx={{
              alignSelf: "center",
              display: "flex",
              flexGrow: 1,
            }}
          >
            <FeedRoundedIcon sx={{ fontSize: 40, ml: 0 }} />
            <Typography
              noWrap
              sx={{
                textTransform: "capitalize",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: "bold",
                fontSize: 18,
                letterSpacing: 4,
                ml: 2,
                pt: 1,
                flexGrow: 1,
              }}
            >
              Select Category
            </Typography>
            <Box sx={{ alignSelf: "right", mr: 2 }}>
            
            </Box>
            <Box sx={{ alignSelf: "right" }}>
            <FormControl sx={{ minWidth: 200 }}>
                <InputLabel id="label-category">Category</InputLabel>
                <Select
                  value={!!state.category?.id ? state.category?.id : ""}
                  size="medium"
                  labelId="label-category"
                  onChange={(e) => {
                    action({ type: "SET_CATEGORY", payload: e.target.value });
                  }}
                >
                  {categories?.productCategories?.map((option:any) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sx={{ pt: 2, pb: 2 }}>
          <Box
            sx={{
              alignSelf: "center",
              display: "flex",
              flexGrow: 1,
            }}
          >
            <FeedRoundedIcon sx={{ fontSize: 40, ml: 0 }} />
            <Typography
              noWrap
              sx={{
                textTransform: "capitalize",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: "bold",
                fontSize: 18,
                letterSpacing: 4,
                ml: 2,
                pt: 1,
                flexGrow: 1,
              }}
            >
              Select Producttype
            </Typography>
            <Box sx={{ alignSelf: "right", mr: 2 }}>              
            </Box>
            <Box sx={{ alignSelf: "right" }}>{<Typesjsx />}</Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ mt: 2, mb: 2 }} variant="inset" />
        </Grid>

        {/* BASIC PRODUCT INFORMATION*/}
        <Grid item xs={12} sx={{ pb: 2 }}>
          <FormBox Icon={FeedRoundedIcon} title=" Basic Product Information" />
        </Grid>

        <Box
          sx={{
            display: "flex",
            alignContent: "flex-start",
          }}
        >
          <Box
            sx={{
              p: 3,
            }}
          >
            <ImageUploader
              width={192}
              height={192}
              setImage={setImage}
              setBase64String={(str) => {
                if (typeof str === 'string' || str === null) {
                  setBase64String(str);
                }
              }}
              setFormDirty={setFormDirty}
              base64String={base64String}
              previewImage={product.url}
            />


          </Box>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={3}>
              <FormText
                name="serialNumber"
                control={control}
                label="Serial Number"
              />
            </Grid>
            <Grid item xs={4}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row",
                }}
              >
                <Box
                  sx={{
                    m: 0,
                    pr: 1,
                    pt: 3,
                    pb: -1,
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <AddBoxRoundedIcon sx={{ fontSize: 18 }} />
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  {/* <FormAutocompleteFeature
                    name="brand"
                    control={control}
                    label="Brand"
                    list={brand}
                  /> */}
                  <FormSelect
                    //name={tmp.f.featureName}
                    name={`brand`}
                    control={control}
                    label={"Brand"}
                    list={allBrands}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={5}>
              <FormText name="name" control={control} label="Name" />
            </Grid>
            <Grid item xs={12}>
              <FormText name="comment" control={control} label="Comment" />
            </Grid>

            <Grid item md={4} lg={3}>
              <FormText
                adornmentText="%"
                name="security"
                control={control}
                label="Security Factor"
              />
            </Grid>

            <Grid item md={4} lg={3}>
              <FormText
                adornmentText="kr"
                name="unitPrice"
                control={control}
                label="Unit Cost"
              />
            </Grid>
            <Grid item md={4} lg={3}>
              <FormText
                adornmentText="kr"
                name="retailPrice"
                control={control}
                label="Retail Price"
              />
            </Grid>

            <Grid item md={4} lg={3}>
              <FormText
                adornmentText="ean"
                name="ean"
                control={control}
                label="EAN Number"
              />
            </Grid>
            <Grid item md={4} lg={3}>
            <FormSelect
                    //name={tmp.f.featureName}
                    name={`paymentCycle`}
                    control={control}
                    label={"Payment Cycle"}
                    list={[
                      { id: "0", name: "None" },
                      { id: "1", name: "Month" },
                      { id: "3", name: "Quarter" },
                      { id: "12", name: "Year" },

                    ]}
                  />
            </Grid>
            <Grid item md={4} lg={3}>
            <FormSelect
                    //name={tmp.f.featureName}
                    name={`cycleAlignment`}
                    control={control}
                    label={"Cycle alignment"}
                    list={cycleAlignmentOptions}
                  />
               
            </Grid>
            <Grid item md={4} lg={3}>
            <FormSelect
                    //name={tmp.f.featureName}
                    name={`bindingPeriod`}
                    control={control}
                    label={"Binding Period"}
                    list={[
                      { id: "0", name: "None" },
                      { id: "1", name: "Month" },
                      { id: "3", name: "Quarter" },
                      { id: "12", name: "Year" },
                      { id: "36", name: "ThreeYears" },
                    ]}
                  />
               
            </Grid>

            <Grid item md={4} lg={3}>
              <FormText
                adornmentText="Qty"
                name="quantity"
                control={control}
                label="Quantity"
              />
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ mt: 2, mb: 2 }} variant="inset" />
            </Grid>
          </Grid>
        </Box>

        {/* FEATURES */}
        <Grid item xs={12} sx={{ pb: 2 }}>
          <FormBox Icon={FeedRoundedIcon} title=" Product Features" />
        </Grid>
        <Grid container direction="row" spacing={2}>
          {!!features && (
            <FeatureList
              product={product}
              features={features?.productFeatures}
              control={control}
            />
          )}
        </Grid>
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <Button
            sx={{}}
            type="submit"
            variant="contained"
            disabled={!isDirty || putProduct.isLoading || postProduct.isLoading}
          >
            {putProduct.isLoading || postProduct.isLoading
              ? "Submitting..."
              : "Submit"}
          </Button>
        </Box>
      </form>
      </Box>
    </Grid>
  );
};
