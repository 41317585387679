import { memo, useEffect, useReducer, useState } from 'react';
import { useGet, usePost, usePut } from 'http/useInnovit';
import { rq, rqPost } from 'http/apiRoutes';
import { useParams } from 'react-router';
import { FormBox } from 'components/form';
import useWindowsDimension from 'functions/useWindowsDimension';
// @ts-ignore
import { ProductCardPackage } from 'components/ui/ProductCardPackage';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import {
  Box,
  Button,
  Avatar,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  List,
  ListItem,
  Select,
  TextField,
  Typography,
  Switch,
  Stack,
  FormControlLabel,
} from '@mui/material';

import BallotRoundedIcon from '@mui/icons-material/BallotRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { IProductViewModel } from 'pages/Billing/BillingConfig';
import { ObjectDrawer } from './ObjectDrawer';
import _ from 'lodash';
import {
  IPackageAddViewModel,
  IPackageEditViewModel,
  IPackageViewModel,
  IProductPackageProductAddViewModel,
  IProductPackageProductViewModel,
} from './IPackageConfig';
import GenerateGuid from 'functions/GenerateGuid';

const variables = {
  PHOTO_URL: process.env.PUBLIC_URL + '/img/',
};
let teller = -1;
const img = [
  {
    index: teller++,
    imageUrl: `${variables.PHOTO_URL}BG ${teller++}.png`,
  },
  {
    index: teller++,
    imageUrl: `${variables.PHOTO_URL}BG ${teller++}.png`,
  },
  {
    index: teller++,
    imageUrl: `${variables.PHOTO_URL}BG ${teller++}.png`,
  },
  {
    index: teller++,
    imageUrl: `${variables.PHOTO_URL}BG ${teller++}.png`,
  },
  {
    index: teller++,
    imageUrl: `${variables.PHOTO_URL}BG ${teller++}.png`,
  },
  {
    index: teller++,
    imageUrl: `${variables.PHOTO_URL}BG ${teller++}.png`,
  },
  {
    index: teller++,
    imageUrl: `${variables.PHOTO_URL}BG ${teller++}.png`,
  },
];

const initialState: IPackageEditViewModel = {
  id: '',
  name: '',
  description: '',
  isDefault: false,
  defaultDepartmentId: '',
  active: false,
  productPackageProducts: [] as IProductPackageProductViewModel[],
  investment: 0,
  monthly: 0,
  quarterly: 0,
  yearly: 0,
};

const reducer = (state: any, action: any) => {
  const findPaymentCycle = (cycle: any) => {
    let _paymentCycle;
    switch (cycle) {
      case 1:
        _paymentCycle = 1;
        break;
      case 3:
        _paymentCycle = 3;
        break;
      case 12:
        _paymentCycle = 12;
        break;
      default:
        _paymentCycle = 0;
    }
    return _paymentCycle;
  };
  switch (action.type) {
    case 'ADD_PRODUCT': {
      const newProduct: IProductViewModel = action.payload;

      // Check if the product already exists in the package
      const existingProductIndex = state.productPackageProducts.findIndex(
        (p: IProductPackageProductViewModel) => p.product.id === newProduct.id
      );
      const paymentCycle = findPaymentCycle(newProduct.paymentCycle);
      const retailPrice = newProduct.retailPrice;
      if (existingProductIndex !== -1) {
        // Product already exists, handle accordingly (e.g., increase quantity)
        return state; // or another appropriate action
      }

      // Convert IProductViewModel to IProductPackageProductViewModel
      const newProductPackageProduct: IProductPackageProductViewModel = {
        id: GenerateGuid(), // Generate or assign an appropriate ID
        productPackageId: state.id, // Assuming this is the ID of the package
        productId: newProduct.id,
        product: newProduct,
        quantity: 1, // Starting quantity
      };

      return {
        ...state,
        productPackageProducts: [
          ...state.productPackageProducts,
          newProductPackageProduct,
        ],
        monthly:
          paymentCycle === 1 ? state.monthly + retailPrice : state.monthly,
        quarterly:
          paymentCycle === 3 ? state.quarterly + retailPrice : state.quarterly,
        yearly: paymentCycle === 12 ? state.yearly + retailPrice : state.yearly,
        investment:
          paymentCycle === 0
            ? state.investment + retailPrice
            : state.investment,
      };
    }
    case 'DELETE_PRODUCT': {
      const productIdToDelete = action.payload.id;

      // Filter out the product to delete
      const updatedProductPackageProducts = state.productPackageProducts.filter(
        (p: IProductPackageProductViewModel) =>
          p.product.id !== productIdToDelete
      );

      return {
        ...state,
        productPackageProducts: updatedProductPackageProducts,
        // Update other state properties if necessary
      };
    }
    case 'REMOVE_PRODUCT':
      return {
        ...state,
        productPackageProducts: state.productPackageProducts.filter(
          (product: IProductViewModel) => product.id !== action.payload.id
        ),
      };
    case 'INCREASE_PRODUCT_QUANTITY': {
      const productIndex = state.productPackageProducts.findIndex(
        (prod: IProductPackageProductViewModel) =>
          prod.product.id === action.payload
      );

      if (productIndex === -1) {
        // Product not found
        return state;
      }

      const product = state.productPackageProducts[productIndex];
      let newProductPackageProducts = [...state.productPackageProducts];

      // Increment the product quantity
      const newQuantity = product.quantity + 1;

      if (newQuantity > 0) {
        // Update the product with the new quantity
        newProductPackageProducts[productIndex] = {
          ...product,
          quantity: newQuantity,
        };
      } else {
        // Remove the product if the new quantity is 0
        newProductPackageProducts = newProductPackageProducts.filter(
          (_, index) => index !== productIndex
        );
      }

      const paymentCycle = findPaymentCycle(product.product.paymentCycle);
      const retailPrice = product.product.retailPrice;

      // Update the financial metrics accordingly
      return {
        ...state,
        productPackageProducts: newProductPackageProducts,
        monthly:
          paymentCycle === 1 ? state.monthly + retailPrice : state.monthly,
        quarterly:
          paymentCycle === 3 ? state.quarterly + retailPrice : state.quarterly,
        yearly: paymentCycle === 12 ? state.yearly + retailPrice : state.yearly,
        investment:
          paymentCycle === 0
            ? state.investment + retailPrice
            : state.investment,
      };
    }
    case 'DECREASE_PRODUCT_QUANTITY': {
      const productIndex = state.productPackageProducts.findIndex(
        (prod: IProductPackageProductViewModel) =>
          prod.product.id === action.payload
      );

      if (productIndex === -1) {
        // Product not found, return state as is.
        return state;
      }

      const product = state.productPackageProducts[productIndex];
      const newProductPackageProducts = [...state.productPackageProducts];

      if (product.quantity === 1) {
        newProductPackageProducts.splice(productIndex, 1);
      } else {
        newProductPackageProducts[productIndex] = {
          ...product,
          quantity: product.quantity - 1,
        };
      }

      const paymentCycle = findPaymentCycle(product.product.paymentCycle);
      const retailPrice = product.product.retailPrice;

      return {
        ...state,
        productPackageProducts: newProductPackageProducts,
        monthly:
          paymentCycle === 1 ? state.monthly - retailPrice : state.monthly,
        quarterly:
          paymentCycle === 3 ? state.quarterly - retailPrice : state.quarterly,
        yearly: paymentCycle === 12 ? state.yearly - retailPrice : state.yearly,
        investment:
          paymentCycle === 0
            ? state.investment - retailPrice
            : state.investment,
      };
    }
    case 'CHANGE_DEPARTMENT':
      return {
        ...state,
        defaultDepartmentId: action.defaultDepartmentId,
      };
    case 'CHANGE_NAME':
      return {
        ...state,
        name: action.name,
      };
    case 'CHANGE_DESCRIPTION':
      return {
        ...state,
        description: action.description,
      };
    case 'CHANGE_DEFAULT_PACKAGE':
      return {
        ...state,
        isDefault: action.isDefault,
      };
    case 'REPLACE_STATE':
      const packageViewModel = action.payload as IPackageViewModel; // Assuming this is of type IPackageViewModel
      let monthly = 0;
      let quarterly = 0;
      let yearly = 0;
      let investment = 0;
      packageViewModel.productPackageProducts &&
        packageViewModel.productPackageProducts.forEach(
          (productPackageProduct: IProductPackageProductViewModel) => {
            const paymentCycle = findPaymentCycle(
              productPackageProduct.product.paymentCycle
            );
            const retailPrice = productPackageProduct.product.retailPrice;
            const quantity = productPackageProduct.quantity;

            switch (paymentCycle) {
              case 1:
                monthly += retailPrice * quantity;
                break;
              case 3:
                quarterly += retailPrice * quantity;
                break;
              case 12:
                yearly += retailPrice * quantity;
                break;
              default:
                investment += retailPrice * quantity;
            }
          }
        );

      return {
        ...state,
        ...packageViewModel,
        monthly: monthly,
        quarterly: quarterly,
        yearly: yearly,
        investment: investment,
      };

    default:
      return state;
  }
};

type Props = {
  packageData: IPackageViewModel;
};

const convertToPackageAddViewModel = (
  state: IPackageViewModel,
  companyId: string
): IPackageAddViewModel => {
  // Map productPackageProducts from IProductPackageProductViewModel[] to IProductPackageProductAddViewModel[]
  const productPackageProducts: IProductPackageProductAddViewModel[] =
    state.productPackageProducts.map((productPackageProduct) => ({
      productId: productPackageProduct.productId,
      productPackageId: GenerateGuid(),
      quantity: productPackageProduct.quantity,
    }));

  const _state: IPackageAddViewModel = {
    name: state.name,
    description: state.description,
    isDefault: state.isDefault,
    defaultDepartmentId: state.defaultDepartmentId,
    companyId: companyId,
    active: state.active,
    productPackageProducts: productPackageProducts,
  };

  return _state;
};

export const PackageForm = (packageData: Props) => {
  console.log('PackageForm packageData', packageData);
  /* INITIALIZATIONS */
  const { height } = useWindowsDimension();
  const { companyId, packageId } = useParams();
  const [isProducts, setIsProducts] = useState(true);
  const [products, setProducts] = useState<IProductViewModel[]>([]);
  const [isDepartments, setIsDepartments] = useState(true);
  const [departments, setDepartments] = useState<any[]>([]);
  const [state, dispatch] = useReducer(reducer, initialState);

  // api
  let _companyId = companyId ? companyId : '';
  const postProductPackage = usePost(rqPost.package(_companyId, false));
  const putProductPackage = usePut(rqPost.package(_companyId, false));
  const { data: dData } = useGet(rq.departments(_companyId, isDepartments));
  // api
  const { data: productsSata } = useGet(rq.products(isProducts), isProducts);
  /* FUNCTIONS */

  // handles
  const handleChangeDepartment = (e: any) => {
    dispatch({
      type: 'CHANGE_DEPARTMENT',
      defaultDepartmentId: e.target.value,
    });
    const _department = departments.find((dept) => dept.id === e.target.value);
    let name = _department.name + ' package';
    dispatch({ type: 'CHANGE_NAME', name: name });
  };
  const handleChangeName = (e: any) => {
    dispatch({ type: 'CHANGE_NAME', name: e.target.value });
  };
  const handleChangeDescription = (e: any) => {
    dispatch({ type: 'CHANGE_DESCRIPTION', description: e.target.value });
  };
  const handleDecProduct = (product: IProductViewModel) => {
    dispatch({ type: 'DECREASE_PRODUCT_QUANTITY', payload: product.id });
  };

  const handleIncProduct = (product: IProductViewModel) => {
    dispatch({ type: 'INCREASE_PRODUCT_QUANTITY', payload: product.id });
  };

  /* HANDLSES */

  const handleChangeObjectDrawer = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    selectedObject: any,
    objectType: string
  ) => {
    switch (objectType) {
      case 'products':
        let object = selectedObject as IProductViewModel;
        dispatch({ type: 'ADD_PRODUCT', payload: object });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!!productsSata) {
      let tmp = _.cloneDeep(productsSata?.products) as IProductViewModel[];
      setIsProducts(false);
      setProducts(tmp);
    }
  }, [productsSata]);

  useEffect(() => {
    if (!!dData) {
      let tmp = _.cloneDeep(dData?.departments) as any[];
      setIsDepartments(false);
      setDepartments(tmp);
    }
  }, [dData]);

  // form submit
  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (packageId === 'new') {
      let newPackage = convertToPackageAddViewModel(state, companyId || '');
      console.log('submit newPackage POST :* ', newPackage);
      postProductPackage.mutate(newPackage);
    } else {
      const _state: IPackageViewModel = {
        id: state.id,
        name: state.name,
        description: state.description,
        isDefault: state.isDefault,
        defaultDepartmentId: state.defaultDepartmentId,
        active: state.active,
        productPackageProducts: state.productPackageProducts,
      };

      console.log('submit _state PUT :* ', _state);
      putProductPackage.mutate(_state);
    }
  };
  useEffect(() => {
    if (packageData) {
      let data = packageData.packageData as IPackageViewModel;
      dispatch({ type: 'REPLACE_STATE', payload: data });
    }
  }, [packageId, packageData]);

  const handleDefaultPackageChange = () => {
    const newDefaultPackage = !state.defaultPackage;
    dispatch({
      type: 'CHANGE_DEFAULT_PACKAGE',
      defaultPackage: newDefaultPackage,
    });
  };
  const ActiveJSX = memo(() => {
    return (
      <>
        default package
        <Switch
          color='secondary'
          checked={state.defaultPackage}
          onChange={handleDefaultPackageChange}
        />
      </>
    );
  });

  return (
    <>
      <Grid item xs={12} sx={{ p: 2, height: 64 }}>
        <FormBox Icon={BallotRoundedIcon} title='Package' FormJSX={ActiveJSX} />
      </Grid>

      <Grid container direction={'row'} sx={{}}>
        <Grid item xs={4} sx={{ p: 2, pr: 1 }}>
          <FormControl fullWidth sx={{ pb: 2 }}>
            <InputLabel id='selected-department-label'>
              Select Department
            </InputLabel>
            <Select
              variant='standard'
              labelId='selected-department-label'
              id='selected-department'
              value={state.defaultDepartmentId}
              //defaultValue={state.defaultDepartmentId}
              label='Select Department'
              onChange={(e: any) => handleChangeDepartment(e)}
            >
              {!!departments &&
                departments.map((dep: any) => (
                  <MenuItem value={dep.id} key={dep.id}>
                    {dep.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {state.id ? (
            <Avatar
              variant='rounded'
              alt={state.name}
              // src={img[selectedPackage.imageNr].imageUrl}
              sx={{
                mx: 'auto',
                width: '100%',
                height: 128,
                borderRadius: 2,
                pt: 1,
              }}
            />
          ) : (
            <Avatar
              variant='rounded'
              alt={'unnamed'}
              src={img[0].imageUrl}
              sx={{
                mx: 'auto',
                width: '100%',
                height: 128,
                borderRadius: 2,
                pt: 1,
              }}
            />
          )}
        </Grid>

        <Grid item xs={4} sx={{ p: 2, pl: 1 }}>
          <FormControl fullWidth sx={{ pb: 2 }}>
            <TextField
              id='name'
              label='Name'
              variant='standard'
              value={state.name}
              onChange={(e: any) => handleChangeName(e)}
            />
          </FormControl>
          <FormControl fullWidth sx={{}}>
            <TextField
              id='description'
              label='Description'
              variant='standard'
              multiline
              maxRows={3}
              rows={3.5}
              value={state.description}
              onChange={(e: any) => handleChangeDescription(e)}
            />
          </FormControl>
        </Grid>

        <Grid
          item
          xs={4}
          sx={{
            pr: 1,
          }}
        >
          <Paper
            variant='elevation3'
            sx={{ backgroundColor: 'primary.back', p: 1, height: 200 }}
          >
            <Typography
              sx={{
                width: '100%',
                textAlign: 'Left',
                letterSpacing: 2,
                fontSize: 16,
                fontWeight: 'bold',
              }}
            >
              Investment
            </Typography>
            <Stack direction={'row'} sx={{ width: '100%' }}>
              <Typography sx={{ textAlign: 'end', width: '50%' }}>
                total
              </Typography>
              <Typography
                sx={{
                  width: '50%',
                  textAlign: 'end',
                  color: 'secondary.text',
                  fontWeight: 'bold',
                }}
              >
                {state.investment.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Typography>
            </Stack>
            <Typography
              sx={{
                width: '100%',
                pt: 2,
                textAlign: 'Left',
                letterSpacing: 2,
                fontSize: 16,
                fontWeight: 'bold',
              }}
            >
              Agreement increases
            </Typography>
            <Stack direction={'row'} sx={{ width: '100%' }}>
              <Typography sx={{ textAlign: 'end', width: '50%' }}>
                Monthly
              </Typography>
              <Typography
                sx={{
                  width: '50%',
                  textAlign: 'end',
                  color: 'secondary.text',
                  fontWeight: 'bold',
                }}
              >
                {state.monthly.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Typography>
            </Stack>
            <Stack direction={'row'} sx={{ width: '100%' }}>
              <Typography sx={{ textAlign: 'end', width: '50%' }}>
                Quarterly
              </Typography>
              <Typography
                sx={{
                  width: '50%',
                  textAlign: 'end',
                  color: 'secondary.text',
                  fontWeight: 'bold',
                }}
              >
                {state.quarterly.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Typography>
            </Stack>
            <Stack direction={'row'} sx={{ width: '100%' }}>
              <Typography sx={{ textAlign: 'end', width: '50%' }}>
                Yearly
              </Typography>
              <Typography
                sx={{
                  width: '50%',
                  textAlign: 'end',
                  color: 'secondary.text',
                  fontWeight: 'bold',
                }}
              >
                {state.yearly.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Typography>
            </Stack>
          </Paper>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            pl: 1,
            pr: 2,
            pb: 1,
            display: 'flex',
            flexDirection: 'row-reverse',
          }}
        >
          <ObjectDrawer
            buttonContext={'Change Product'}
            buttonIcon={<ChangeCircleIcon sx={{ fontSize: 40 }} />}
            objectList={!!products ? products : []}
            objectType={'products'}
            handleChange={handleChangeObjectDrawer}
            buttonHeight={40}
            buttonWidth={60}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <List
            sx={{
              p: 2,
              width: '100%',
              height: Math.ceil(height - 566),
              backgroundColor: 'primary.back',
              overflow: 'auto',
            }}
          >
            {state.productPackageProducts.length > 0 &&
              state.productPackageProducts.map((prod: any) => {
                return !!prod ? (
                  <ListItem sx={{ mb: 1, p: 0 }} button key={prod.id}>
                    <ProductCardPackage
                      key={prod.id}
                      companyProduct={prod}
                      handleIncProduct={handleIncProduct}
                      handleDecProduct={handleDecProduct}
                    />
                  </ListItem>
                ) : null;
              })}
          </List>
        </Grid>
      </Grid>

      <Box
        sx={{
          m: 0,
          p: 2,
          width: '100%',
          display: 'flex',
          flexDirection: 'row-reverse',
        }}
      >
        <Button
          variant='contained'
          onClick={(e: any) => handleSubmit(e)}
          disabled={
            postProductPackage.isLoading ||
            state.productPackageProducts.length === 0
          }
        >
          {packageId === 'new'
            ? 'Create Product Package'
            : 'Update Product Package'}
        </Button>
      </Box>
    </>
  );
};
