import { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFilteredList } from 'functions/misc';
import { SearchFilter } from './SearchFilter';
import { SingleProductView } from 'components/ui/SingleProductView';
import useWindowsDimension from 'functions/useWindowsDimension';
import _ from 'lodash';

import { Grid, List, Paper } from '@mui/material';
import ImportantDevicesRoundedIcon from '@mui/icons-material/ImportantDevicesRounded';

export const ProductList = memo((props) => {
  /* INITIALIZATION */
  const { categories, products, handleClick } = props;
  const list = _.cloneDeep(products);
  list?.map((product) => {
    product.typeId = product.productType.id;
    return <></>;
  });

  const { height } = useWindowsDimension();
  let navigate = useNavigate();

  // states
  const [search, setSearch] = useState('');
  const [filterType, setFilterType] = useState('all');
  const [filteredProducts, setFilteredProducts] = useState(products);
  const [productTypes, setProductTypes] = useState([]);

  /* FUNCTIONS */

  // handles
  const handleNew = (e) => {
    navigate('/products/new');
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    let filterValue = filterType === 'all' ? '' : filterType;
    let filteredRows = getFilteredList(list, filterValue);
    let searchRows = getFilteredList(filteredRows, e.target.value);

    setFilteredProducts(searchRows);
  };

  const handleFilterChange = (e) => {
    setFilterType(e);
    let filterValue = e === 'all' ? '' : e;
    let filteredRows = getFilteredList(list, filterValue);
    let searchRows = getFilteredList(filteredRows, search);

    setFilteredProducts(searchRows);
  };

  useEffect(() => {
    setFilteredProducts(products);
  }, [products]);

  useEffect(() => {
    let tmp = [];
    categories?.productCategories.forEach((category) => {
      category.productTypes.forEach((type) => {
        tmp.push(type);
      });
    });
    setProductTypes(tmp.sort((a, b) => a.name.localeCompare(b.name)));
  }, [categories]);
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
      }}
    >
      <Grid item xs={12} sx={{ pr: 1, m: 0 }}>
        
          <SearchFilter
            Icon={ImportantDevicesRoundedIcon}
            Header='Products'
            filterValue={filterType}
            handleFilterChange={handleFilterChange}
            handleSearch={handleSearch}
            handleNew={handleNew}
            selectList={productTypes}
          />
       

        <List
          sx={{
            height: Math.ceil(height - 148),
            width: '100%',
            mb: 0,
            p: 2,
            backgroundColor: 'primary.dark',
            overflow: 'auto',
          }}
        >
          {filteredProducts?.length === 0 && <div>No products found</div>}
          {filteredProducts?.map((product, index) => (
            <Paper
              variant='elevation3'
              key={index}
              onClick={(e) => {
                handleClick(e, product);
              }}
            >
              {/* XXX */}
              {filteredProducts.productType !== null && (
                <SingleProductView
                  key={index}
                  index={index}
                  product={product}
                />
              )}
            </Paper>
          ))}
        </List>
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        ></Paper>
      </Grid>
    </Grid>
  );
});
