import { memo } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";

export const FormSelectProduct = memo((props:any) => {
  const { name, control, disabled, label, list, handleSelectType } = props;
  const generateSingleOptions = () => {
    return list.map((option:any) => {
      return (
        <MenuItem key={option.id} value={option.id}>
          {name === "productCategory" ? option.category : option.name}
        </MenuItem>
      );
    });
  };

  return (
    <Box sx={{ minWidth: 200 }}>
      <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <Controller
          render={({ field: { onChange, value } }) => (
            <Select
              value={value}
              disabled={disabled}
              onChange={(e) => {
                onChange(e.target.value);
                handleSelectType(e.target.value);
              }}
            >
              {generateSingleOptions()}
            </Select>
          )}
          control={control}
          name={name}
          defaultValue=""
        />
      </FormControl>
    </Box>
  );
});
