import React, { useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  Stack,
  Avatar,
  Paper,
  Button,
} from '@mui/material';
import FeedRoundedIcon from '@mui/icons-material/FeedRounded';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import GetColorByName from 'functions/GetColorByName';
import GetInitials from 'functions/GetInitials';
import { ObjectDrawer } from 'pages/Companies/ObjectDrawer';
import {
  IOrderCompanyViewModel,
  IOrderContactViewModel,
} from 'pages/Orders/OrdersConfig';
import { MissingPhoto } from 'data/globalTypeConfig';

type Props = {
  vendor?: IOrderCompanyViewModel | null;
  vendorContact?: IOrderContactViewModel | null;
  objectList: IOrderCompanyViewModel[];
  handleChange: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    selectedObject: any,
    objectType: string
  ) => void;
};

export const VendorBlock = ({
  vendor,
  vendorContact,
  objectList,
  handleChange,
}: Props) => {
  console.log('VendorBlock', objectList);

  return (
    <Grid item xs={12} sx={{ p: 0 }}>
      <Paper
        variant='elevation3'
        sx={{
          backgroundColor:
            vendor?.onHold === true ? 'warning.light' : 'primary.back',
          minHeight: 150,
          mb: 2,
        }}
      >
        {vendor?.id ? (
          <Grid
            container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexGrow: 1,
              p: 1,
            }}
          >
            <Grid item xs={12} sx={{ p: 0 }}>
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Box sx={{ p: 0 }}>
                  <FeedRoundedIcon sx={{ fontSize: 32 }} />
                </Box>
                <Box sx={{ pl: 2, pt: 1 }}>
                  <Typography
                    sx={{
                      textTransform: 'capitalize',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontWeight: 'bold',
                      fontSize: 16,
                      letterSpacing: 4,
                    }}
                  >
                    {'Vendor Info'}
                  </Typography>
                </Box>
                <Box sx={{ pt: 1, flexGrow: 1 }}>
                  <Typography
                    sx={{
                      width: '100%',
                      textTransform: 'uppercase',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontWeight: 'bold',
                      fontSize: 16,
                      letterSpacing: 4,
                      textAlign: 'center',
                      color: 'warning.dark',
                    }}
                  >
                    {vendor?.onHold === true ? 'ON HOLD' : ''}
                  </Typography>
                </Box>
                <Box sx={{ pt: 1 }}>
                  <Typography
                    sx={{
                      textTransform: 'capitalize',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontWeight: 'bold',
                      fontSize: 16,
                      letterSpacing: 2,
                    }}
                  >
                    ( {vendor?.organizationNumber} )
                  </Typography>
                </Box>
                <Box sx={{ pl: 2 }}>
                  <ObjectDrawer
                    buttonIcon={<ChangeCircleIcon sx={{ fontSize: 40 }} />}
                    buttonContext={'Change vendor'}
                    objectList={!!objectList ? objectList : []}
                    objectType={'vendors'}
                    handleChange={handleChange}
                    buttonHeight={40}
                    buttonWidth={'100%'}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={6} sx={{ p: 0 }}>
              <Stack direction='row'>
                <Grid item sx={{ ml: 0, p: 0 }}>
                  <img
                    src={vendor?.url || MissingPhoto.COMPANY_URL}
                    style={{
                      width: 158,
                      height: 80,
                      borderRadius: 3,
                      objectFit: 'scale-down',
                      boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
                    }}
                    alt={'previewUrl'}
                  />
                </Grid>
                <Stack direction={'column'} sx={{ ml: 2 }}>
                  <Typography
                    sx={{
                      width: '100%',
                      textTransform: 'capitalize',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontWeight: 'bold',
                      fontSize: 14,
                      letterSpacing: 2,
                    }}
                  >
                    {vendor?.name}
                  </Typography>
                  <Typography
                    sx={{
                      width: '100%',
                      textTransform: 'capitalize',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontWeight: 'bold',
                      fontSize: 14,
                      letterSpacing: 1,
                      textAlign: 'left',
                    }}
                  >
                    {vendor?.email}
                  </Typography>
                  <Typography
                    sx={{
                      width: '100%',
                      textTransform: 'capitalize',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontWeight: 'bold',
                      fontSize: 14,
                      letterSpacing: 1,
                      textAlign: 'left',
                    }}
                  >
                    {vendor?.phone}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            {vendorContact?.name ? (
              <Grid item xs={6} sx={{ p: 0 }}>
                <Stack direction='row'>
                  <Grid item sx={{ ml: 0, p: 0 }}>
                    {vendorContact?.url !== null ? (
                      <Avatar
                        variant='rounded'
                        alt={'contact name'}
                        src={vendorContact?.url}
                        sx={{
                          mx: 'auto',
                          width: 128,
                          height: 64,
                          borderRadius: 3,
                        }}
                      />
                    ) : (
                      <Avatar
                        sx={{
                          borderRadius: 3,
                          width: 128,
                          height: 64,
                          backgroundColor: GetColorByName(
                            vendorContact?.name || 'Unknown'
                          ),
                          color: 'white',
                          textTransform: 'uppercase',
                        }}
                        children={GetInitials(
                          vendorContact?.name || 'Unknown',
                          4
                        )}
                      />
                    )}
                  </Grid>
                  <Stack direction={'column'} sx={{ ml: 2 }}>
                    <Typography
                      sx={{
                        width: '100%',
                        textTransform: 'capitalize',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontWeight: 'bold',
                        fontSize: 14,
                        letterSpacing: 2,
                      }}
                    >
                      {vendorContact?.name}
                    </Typography>
                    <Typography
                      sx={{
                        width: '100%',
                        textTransform: 'capitalize',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontWeight: 'bold',
                        fontSize: 14,
                        letterSpacing: 1,
                        textAlign: 'left',
                      }}
                    >
                      {vendorContact?.email}
                    </Typography>
                    <Typography
                      sx={{
                        width: '100%',
                        textTransform: 'capitalize',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontWeight: 'bold',
                        fontSize: 14,
                        letterSpacing: 1,
                        textAlign: 'left',
                      }}
                    >
                      {vendorContact?.phone}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            ) : (
              <Grid
                item
                xs={6}
                sx={{
                  p: 0,
                  border: 1,
                  borderColor: 'primary.main',
                  borderRadius: 3,
                }}
              >
                <Typography
                  sx={{
                    width: '100%',
                    textTransform: 'capitalize',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontWeight: 'bold',
                    fontSize: 14,
                    letterSpacing: 2,
                    textAlign: 'center',
                    pt: 3,
                  }}
                >
                  No contact
                </Typography>
              </Grid>
            )}

            {vendor?.onHold === true ? (
              <Grid item xs={12} sx={{ p: 0 }}>
                <Typography
                  sx={{
                    p: 1,
                    fontSize: 16,
                    textTransform: 'capitalize',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontWeight: 'bold',
                    letterSpacing: 4,
                  }}
                >
                  On hold note
                </Typography>
                <Typography
                  sx={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                  }}
                >
                  {vendor?.onHoldNote || 'No comment'}
                </Typography>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        ) : (
          <ObjectDrawer
            buttonContext={'Select vendor'}
            buttonIcon={null}
            objectList={!!objectList ? objectList : []}
            objectType={'vendors'}
            handleChange={handleChange}
            buttonHeight={150}
            buttonWidth={'100%'}
          />
        )}
      </Paper>
    </Grid>
  );
};
