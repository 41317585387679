import _ from 'lodash';

export const getRates = (owner:any, post:any, fixedHourlyRate:any) => {
  // const owner = JSON.parse(window.localStorage.getItem('OWNER'));
  // check what rate to use
  console.log('getRates - post', post);
  // 1. use owners fixed rate
  let rateValue = post.author.workRole.rate;
  // 2. if the contact company has a rate, use that
  if (post.receiver.companyFixedPrice > 0) {
    rateValue = post.receiver.companyFixedPrice;
  }
  // 3. if the post has fixed rate, use that
  if (fixedHourlyRate > 0) {
    rateValue = +fixedHourlyRate;
  }

  // set the rate based on ratevalue
  const _rates = _.cloneDeep(owner.rates);
  _rates.forEach((rate:any, index:number) => {
    switch (index) {
      case 0:
        rate.rate = rateValue * 2; // xxx need to include night rate and if this is a free day
        break;
      case 1:
        rate.rate = rateValue; // Normal work time // check if its a free day
        break;
      case 2:
        rate.rate = rateValue * 1.5; // xxx need to include 50% overtime rate // and check if its a free day
        break;
      case 3:
        rate.rate = rateValue * 2; // xxx need to include 100% overtime rate  // and check if its a free day
        break;
      default:
        break;
    }
  });

  return _rates;
};
