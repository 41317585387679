import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { styled, alpha } from '@mui/material/styles';
import {
  MenuItem,
  Select,
  InputBase,
  Typography,
  Button,
  Paper,
} from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import SearchIcon from '@mui/icons-material/Search';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  marginTop: 3,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

export const SearchFilter = (props: any) => {
  const {
    Icon,
    Header,
    filterValue,
    handleFilterChange,
    handleSearch,
    handleNew,
    selectList,
    gfx,
    hideAll,
  } = props;

  return (
    <Paper
      variant='elevation3'
      sx={{
        height: 64,
        borderRadius: 2,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      }}
    >
      {!hideAll && (
        <Toolbar
          sx={{
            backgroundColor: 'transparent',
          }}
        >
          {!!Icon && <Icon sx={{ fontSize: 40, mr: 2 }} />}

          <Box sx={{ flexGrow: 1 }}>
            <Typography
              sx={{
                textTransform: 'capitalize',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: 'bold',
                fontSize: 18,
                letterSpacing: 4,
              }}
            >
              {Header}
            </Typography>
          </Box>
          {!!selectList && handleSearch && (
            <Search sx={{ mr: 2, display: 'flex', width: 100 }}>
              <Box>
                <SearchIcon sx={{ ml: 1, mt: 1.2 }} />
              </Box>
              <Box>
                <InputBase
                  sx={{ ml: 1, mt: 1 }}
                  placeholder='Search…'
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={handleSearch}
                />
              </Box>
            </Search>
          )}
          {!!selectList && (
            <Select
              variant='standard'
              sx={{mr: 2, minWidth: 150, maxWidth: 200 }}
              labelId='select-type-label'
              id='select-type'
              value={filterValue}
              onChange={(e) => {
                handleFilterChange(e.target.value);
              }}
            >
            {/*   {Header !== 'Companies' && (
                <MenuItem key={'all'} value={'all'}>
                  All
                </MenuItem>
              )} */}
              {selectList.map((item: any) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          )}
          <Box>
            {!!handleNew && (
              <Button
                // component='button'
                sx={{ mt: 0.5, mx: 'auto' }}
                // type='add'
                variant='contained'
                onClick={(e) => {
                  handleNew(e);
                }}
              >
                <AddRoundedIcon sx={{ fontSize: 32 }} />
              </Button>
            )}
            {!!gfx && gfx}
          </Box>
        </Toolbar>
      )}
    </Paper>
  );
};
