import { Box, Grid, List, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';

import { Dashboard as DashboardIcon } from '@mui/icons-material';

import { ChartSingle } from 'components/ui/ChartSingle';
import { SingleTicketView } from 'components/ui/tickets/SingleTicketView';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useState } from 'react';
import useWindowsDimension from 'functions/useWindowsDimension';
import { SingleSaleView } from 'components/ui/SingleSaleView';

import SpeakerNotesOffRoundedIcon from '@mui/icons-material/SpeakerNotesOffRounded';
import ThreePRoundedIcon from '@mui/icons-material/ThreePRounded';
import MarkChatReadRoundedIcon from '@mui/icons-material/MarkChatReadRounded';
import FactCheckRoundedIcon from '@mui/icons-material/FactCheckRounded';
import { ChartTriple } from 'components/ui/ChartTriple';

const Dashboard = () => {
  const { height } = useWindowsDimension();
  let SolvedTicketData = true;
  const originalTicketLists = [
    {
      id: 0,
      heading: 'To Do',
      tickets: [],
    },
  ];

  // CHART TRIPLE DATA
  const tripleData = [
    {
      subHeader: 'Unassigned',
      value: 150,
      oldValue: 100,
      colorType: 0,
      trend: true,
    },
    {
      subHeader: 'In Progress',
      value: 80,
      oldValue: 100,
      colorType: 0,
      trend: true,
    },
    {
      subHeader: 'Solved',
      value: 80,
      oldValue: 80,
      colorType: 0,
      trend: true,
    },
    {
      subHeader: 'Total',
      value: 310,
      oldValue: 300,
      colorType: 0,
      trend: true,
    },
  ];

  const [ticketLists, updateTicketList] = useState(originalTicketLists);

  const ListView = (props) => {
    const heading = props.heading;
    const tickets = props.tickets;

    // console.log("ListView", tickets);

    return (
      <Grid item xs={12} sx={{}}>
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: Math.ceil(height - 356),
          }}
        >
          {/* HEADER */}
          <Grid
            sx={{
              height: 45,
            }}
          >
            <Typography
              sx={{
                textTransform: 'capitalize',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: 'bold',
                fontSize: 18,
                letterSpacing: 4,
              }}
              noWrap
            >
              {heading}
            </Typography>
          </Grid>
          {/* LIST */}

          <Droppable droppableId={heading}>
            {(provided, snapshot) => (
              <List
                sx={{
                  height: Math.ceil(height - 448),
                  p: 2,
                  backgroundColor: `${
                    snapshot.isDraggingOver ? 'primary.dark' : 'primary.back'
                  }`,
                  overflow: 'auto',
                  borderRight: 1,
                  borderRightColor: 'primary.text',
                  borderBottom: 1,
                  borderBottomColor: 'primary.text',
                  borderRadius: 3,
                }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {tickets?.map((_ticket, index) => (
                  <SingleTicketView
                    key={index}
                    index={index}
                    ticket={_ticket}
                  />
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </Paper>
      </Grid>
    );
  };

  const SalesListView = (props) => {
    const heading = props.heading;
    const tickets = props.tickets;

    //console.log("SalesListView", tickets);

    return (
      <Grid item xs={12} sx={{}}>
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: Math.ceil(height - 356),
          }}
        >
          {/* HEADER */}
          <Grid
            sx={{
              height: 45,
            }}
          >
            <Typography
              sx={{
                textTransform: 'capitalize',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: 'bold',
                fontSize: 18,
                letterSpacing: 4,
              }}
              noWrap
            >
              {heading}
            </Typography>
          </Grid>
          {/* LIST */}

          <Droppable droppableId={heading}>
            {(provided, snapshot) => (
              <List
                sx={{
                  height: Math.ceil(height - 448),
                  p: 2,
                  backgroundColor: `${
                    snapshot.isDraggingOver ? 'primary.dark' : 'primary.back'
                  }`,
                  overflow: 'auto',
                  borderRight: 1,
                  borderRightColor: 'primary.text',
                  borderBottom: 1,
                  borderBottomColor: 'primary.text',
                  borderRadius: 3,
                }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {tickets?.map((_ticket, index) => (
                  <SingleSaleView key={index} index={index} ticket={_ticket} />
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </Paper>
      </Grid>
    );
  };

  const handleOnDragEnd = (result) => {
    const { destination, source } = result;

    /* Check if drop outside of droppable zone */
    if (!destination) {
      return;
    }

    /* Check if drop is the same place as it was */
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    /* find list where you picket up the ticket */
    const start = ticketLists.find((obj) => {
      return obj.heading === source.droppableId;
    });

    /* find list where you dropped the ticket */
    const finish = ticketLists.find((obj) => {
      return obj.heading === destination.droppableId;
    });

    /* check if you dropped it in the same list you picked it */
    if (start.id === finish.id) {
      const newTickets = Array.from(start.tickets);

      /* reorganize the ticket list */
      const ticketToMove = start.tickets[source.index];
      newTickets.splice(source.index, 1);
      newTickets.splice(destination.index, 0, ticketToMove);
      const newList = {
        ...start,
        tickets: newTickets,
      };

      /* get the original list and change the tickets */
      const newTicketList = Array.from(ticketLists);
      newTicketList.map((list) => {
        if (source.droppableId === list.heading) {
          list.tickets = newList.tickets;
        }
      });

      /* update the lists */
      updateTicketList(newTicketList);

      /* update the lists */

      return;
    }

    /* get all tickets where you picked up and where you dropped it  */
    const newStartTickets = Array.from(start.tickets);
    const newDroppedTickets = Array.from(finish.tickets);

    /* reorganize the ticket list */
    const ticketToMove = start.tickets[source.index];
    newStartTickets.splice(source.index, 1);
    newDroppedTickets.splice(destination.index, 0, ticketToMove);

    const newStartList = {
      ...start,
      tickets: newStartTickets,
    };

    const newDroppedList = {
      ...finish,
      tickets: newDroppedTickets,
    };

    /* reorganize the ticket list */
    const newTicketList = Array.from(ticketLists);
    newTicketList.map((list) => {
      if (source.droppableId === list.heading) {
        list.tickets = newStartList.tickets;
      }
      if (destination.droppableId === list.heading) {
        list.tickets = newDroppedList.tickets;
      }
    });
    updateTicketList(newTicketList);
    /* XXX Update ticket with technician */
  };

  return (
    <Grid container direction={'row'}>
      <Grid item xs={12} sx={{}}>
        <Paper
          variant='elevation3'
          sx={{
            flexGrow: 1,
            mb: 2,
            p: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Box sx={{ pt: 1 }}>
              <DashboardIcon sx={{ fontSize: 40 }} />
            </Box>
            <Box sx={{ pl: 2, pt: 2, flexGrow: 1 }}>
              <Typography
                noWrap
                sx={{
                  textTransform: 'capitalize',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontWeight: 'bold',
                  fontSize: 18,
                  letterSpacing: 4,
                }}
              >
                Dashboard
              </Typography>
            </Box>
            <Box sx={{}}></Box>
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={2} sx={{}}>
        {SolvedTicketData && (
          <ChartSingle
            version={0}
            header={'Tickets'}
            subHeader={'unassigned'}
            icon={SpeakerNotesOffRoundedIcon}
            value={17}
            oldValue={12}
            trend={true}
          />
        )}
      </Grid>

      <Grid item xs={2} sx={{}}>
        {SolvedTicketData && (
          <ChartSingle
            version={4}
            header={'Tickets'}
            subHeader={'in progress'}
            icon={ThreePRoundedIcon}
            value={38}
            oldValue={230}
            trend={true}
          />
        )}
      </Grid>

      <Grid item xs={2} sx={{}}>
        {SolvedTicketData && (
          <ChartSingle
            version={2}
            header={'Tickets'}
            subHeader={'solved'}
            icon={MarkChatReadRoundedIcon}
            value={999}
            oldValue={80}
            trend={true}
          />
        )}
      </Grid>

      <Grid item xs={6} sx={{}}>
        {SolvedTicketData && (
          <ChartTriple
            header={'Tickets'}
            icon={FactCheckRoundedIcon}
            tripleData={tripleData}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Dashboard;
