import React, { Reducer, useEffect, useReducer, useState } from 'react';
import { Box, Button, Grid, List, Paper } from '@mui/material';
import { SearchFilter } from 'components/ui/SearchFilter';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { useGet, usePost } from 'http/useInnovit';
import { useNavigate } from 'react-router';
import {
  IDndOrdersArrays,
  IOrderCompanyViewModel,
  IOrderContactViewModel,
  IOrderDeliveryAddressViewModel,
  IOrderProductFeaturesViewModel,
  IOrderProductViewModel,
  IOrderTicketViewModel,
  IOrderViewModel,
  IUnmanagedOrderState,
} from './OrdersConfig';
import SingleUnmanagedObjectView from 'components/ui/Orders/UnManaged/SingleUnmanagedObjectView';
import { DroppableTicketList } from '../../components/ui/DroppableTicketList';
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
  closestCorners,
  DragStartEvent,
  DragEndEvent,
  DragOverlay,
} from '@dnd-kit/core';
import { VendorBlock } from 'components/ui/Orders/UnManaged/VendorBlock';
import { DeliveryAddressBlock } from 'components/ui/Orders/UnManaged/DeliveryAddressBlock';
import { IAddressViewModel } from 'data/globalTypeConfig';
import { IOwnerViewModel } from 'data/atoms/atomConfig';
import { useAtom } from 'jotai';
import ownerAtom from 'data/atoms/ownerAtom';
import useWindowsDimension from 'functions/useWindowsDimension';
import { rq, rqPost } from 'http/apiRoutes';
import { DroppableOrdersList } from './DroppableOrdersList';
import { createPortal } from 'react-dom';
import _ from 'lodash';

type Props = {};

const CreateDeliveryAddresses = (
  company: IOrderCompanyViewModel | null,
  contact: IOrderContactViewModel | null,
  owner: IOwnerViewModel
) => {
  let deliveryAddressList: IOrderDeliveryAddressViewModel[] = [];

  // OWNER ADDRESS
  if (owner) {
    //  console.log('CreateDeliveryAddresses owner', owner);
    let address: IOrderDeliveryAddressViewModel = {
      id: owner.deliveryAddress.id ? owner.deliveryAddress.id : null,
      name: owner.name,
      url: owner.url || null,
      address: {
        id: owner.deliveryAddress.id,
        street: owner.deliveryAddress.street,
        postalCode: owner.deliveryAddress.postalCode,
        area: owner.deliveryAddress.area,
        city: owner.deliveryAddress.city,
        country: owner.deliveryAddress.country,
        postBox: owner.deliveryAddress.postBox || null,
      },
    };
    deliveryAddressList.push(address);
  }
  // COMPANY ADDRESS
  if (company !== null && company.deliveryAddress) {
    // console.log('CreateDeliveryAddresses company', company);
    let address: IOrderDeliveryAddressViewModel = {
      id: company.deliveryAddress.id,
      name: company.name,
      url: company.url || null,
      address: {
        id: company.deliveryAddress.id,
        street: company.deliveryAddress.street,
        postalCode: company.deliveryAddress.postalCode,
        area: company.deliveryAddress.area,
        city: company.deliveryAddress.city,
        country: company.deliveryAddress.country,
        postBox: company.deliveryAddress.postBox || null,
      },
    };
    deliveryAddressList.push(address);
  }

  // CONTACT ADDRESS
  if (contact !== null && contact.departmentAddress) {
    // console.log('CreateDeliveryAddresses contact', contact);
    let address: IOrderDeliveryAddressViewModel = {
      id: contact.departmentAddress.id,
      name: contact.name,
      url: contact.url || null,
      address: {
        id: contact.departmentAddress.id,
        street: contact.departmentAddress.street,
        postalCode: contact.departmentAddress.postalCode,
        area: contact.departmentAddress.area,
        city: contact.departmentAddress.city,
        country: contact.departmentAddress.country,
        postBox: contact.departmentAddress.postBox || null,
      },
    };
    deliveryAddressList.push(address);
  }

  // CONTACT ADDRESS
  if (contact != null && contact.homeAddress) {
    // console.log('CreateDeliveryAddresses contact', contact);
    let address: IOrderDeliveryAddressViewModel = {
      id: contact.homeAddress.id,
      name: contact.name,
      url: contact.url || null,
      address: {
        id: contact.homeAddress.id,
        street: contact.homeAddress.street,
        postalCode: contact.homeAddress.postalCode,
        area: contact.homeAddress.area,
        city: contact.homeAddress.city,
        country: contact.homeAddress.country,
        postBox: contact.homeAddress.postBox || null,
      },
    };
    deliveryAddressList.push(address);
  }

  // console.log('CreateDeliveryAddresses all ', deliveryAddressList);
  return deliveryAddressList;
};

const reducer = (state: IUnmanagedOrderState, action: any) => {
  //console.log("reducer", state, action);
  switch (action.type) {
    case 'RESET':
      return initialState;
    case 'COMPANY_CHANGE_BOOLEAN':
      return { ...state, companyChanged: action.payload };
    case 'CHANGE_ORDER':
      return { ...state, selectedOrder: action.payload };
    case 'CHANGE_TICKET':
      return { ...state, selectedTicket: action.payload };
    case 'CHANGE_COMPANY':
      //console.log('CHANGE_COMPANY', action.payload);
      return {
        ...state,
        selectedTicket: {
          ...state.selectedTicket,
          company: action.payload,
        },
        selectedCompany: action.payload,
      };
    case 'CHANGE_ATTENTION':
      return { ...state, attention: action.payload };
    case 'CHANGE_PRODUCT':
      return { ...state, selectedProduct: action.payload };
    case 'CHANGE_VENDOR':
      return { ...state, selectedVendor: action.payload };
    case 'CHANGE_DELIVERY_ADDRESS':
      return { ...state, selectedDeliveryAddress: action.payload };
    case 'CHANGE_DELIVERY_ADDRESS_LIST':
      // console.log('CHANGE_DELIVERY_ADDRESS_LIST', action.payload);
      return { ...state, deliveryAddressList: action.payload };
    case 'CHANGE_CONTACT_LIST':
      // console.log('CHANGE_CONTACT_LIST', action.payload);
      return { ...state, employeeList: action.payload };
    case 'CHANGE_COMPANY_CONTACT':
      //console.log('CHANGE_CONTACT_LIST', action.payload);
      return {
        ...state,
        selectedTicket: {
          ...state.selectedTicket,
          contact: action.payload,
        },
        selectedCompanyContact: action.payload,
      };
    default:
      return state;
  }
};

const initialState: IUnmanagedOrderState = {
  selectedOrder: {} as IOrderViewModel,
  selectedTicket: {} as IOrderTicketViewModel,
  selectedCompany: {} as IOrderCompanyViewModel,
  selectedCompanyContact: {} as IOrderContactViewModel,
  selectedVendor: {} as IOrderCompanyViewModel,
  selectedVendorContact: {} as IOrderContactViewModel,
  selectedProduct: {} as IOrderProductViewModel,
  selectedDeliveryAddress: {} as IOrderDeliveryAddressViewModel,
  deliveryAddressList: [],
  employeeList: [],
  companyChanged: false,
};

export const UnmanagedOrders = (props: Props) => {
  // INITIALIZATION
  const { height } = useWindowsDimension();
  const [owner] = useAtom(ownerAtom);
  const [selectedOrder, setSelectedOrder] = useState<IOrderViewModel>(
    {} as IOrderViewModel
  );
  const [activeOrder, setActiveOrder] = useState<IOrderViewModel | null>(null);
  const [vendorList, setVendorList] = useState<IOrderCompanyViewModel[]>([]);
  const [dndArrays, setDndArrays] = useState<IDndOrdersArrays>({
    unmanagedArray: [],
    managedArray: [],
  });
  let navigate = useNavigate();
  const [state, actions] = useReducer<Reducer<IUnmanagedOrderState, any>>(
    reducer,
    initialState
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  // API
  // vendors
  let isEnabled: boolean = true;
  const { data: vendorData } = useGet(rq.companies('4', isEnabled), isEnabled);

  // all unmanaged orders
  const { data: unmanagedData } = useGet(rq.orders('0', isEnabled), isEnabled);
  const postTicket = usePost(rqPost.order('0', isEnabled), isEnabled);

  // HANDLES
  const handleClick = (e: any, obj: IOrderViewModel) => {
    console.log('handleClick Product', obj);
    setSelectedOrder(obj);
  };
  // new
  const handleNew = (e: any) => {
    navigate('/companies/new');
  };

  const updateAddress = (newAddress: IAddressViewModel) => {
    console.log('updateAddress', newAddress);
    // let updateAddress: IOrderDeliveryAddressViewModel = {
    //   id: state.selectedDeliveryAddress.id,
    //   name: state.selectedDeliveryAddress.name,
    //   url: state.selectedDeliveryAddress.url || null,
    //   address: newAddress,
    // };

    // actions({ type: 'CHANGE_DELIVERY_ADDRESS', payload: updateAddress });
  };

  const updateAttention = (attention: string) => {
    console.log('updateAttention', attention);
    actions({ type: 'CHANGE_ATTENTION', payload: attention });
  };

  const handleChange = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    selectedObject: any,
    objectType: string
  ) => {
    const commonAction = (type: string, payload: any) =>
      actions({ type, payload });

    switch (objectType) {
      case 'companies':
        const company = selectedObject as IOrderCompanyViewModel;
        commonAction('CHANGE_COMPANY', company);
        commonAction('COMPANY_CHANGE_BOOLEAN', true);

        const deliveryAddress = CreateDeliveryAddresses(company, null, owner);
        commonAction('CHANGE_DELIVERY_ADDRESS_LIST', deliveryAddress);
        break;

      case 'vendors':
        commonAction('CHANGE_VENDOR', selectedObject as IOrderCompanyViewModel);
        break;

      case 'products':
        commonAction(
          'CHANGE_PRODUCT',
          selectedObject as IOrderProductViewModel
        );
        break;

      case 'deliveryAddress':
        commonAction(
          'CHANGE_DELIVERY_ADDRESS',
          selectedObject as IOrderDeliveryAddressViewModel
        );
        break;

      case 'employees':
        const contact = selectedObject as IOrderContactViewModel;
        commonAction('CHANGE_COMPANY_CONTACT', contact);

        // const deliveryAddressC = CreateDeliveryAddresses(
        //   state.selectedTicket.company as IOrderCompanyViewModel,
        //   contact,
        //   owner
        // );
        // commonAction('CHANGE_DELIVERY_ADDRESS_LIST', deliveryAddressC);
        break;

      default:
        break;
    }
  };

  const handleDragStart = (e: DragStartEvent) => {
    console.log('handleDragStart', e.active);
    setActiveOrder(e.active.data.current?.unmanagedObject);
  };

  const handleDragEnd = (e: DragEndEvent) => {
    const { active, over } = e;

    const getColumnNameForId = (id: string | number) => {
      if (dndArrays.unmanagedArray.find((order) => order.id === id)) {
        return 'unmanagedArray';
      } else if (dndArrays.managedArray.find((order) => order.id === id)) {
        return 'managedArray';
      }
      return null;
    };

    const activeColumn = getColumnNameForId(active.id);
    const overColumn = over ? over.id : null;

    if (overColumn && activeColumn !== overColumn) {
      if (activeColumn) {
        const activeOrder = dndArrays[activeColumn].find(
          (order) => order.id === active.id
        );
        if (activeColumn === 'unmanagedArray') {
          // do something when order is moved to the right column
        } else if (activeColumn === 'managedArray') {
          // do something when order is moved to the left column
        }
      } else {
        return;
      }
    }

    if (activeColumn === 'unmanagedArray' && overColumn === 'managedArray') {
      const orderToMove = dndArrays.unmanagedArray.find(
        (order) => order.id === active.id
      );
      if (orderToMove) {
        setDndArrays((prevState) => ({
          ...prevState,
          unmanagedArray: prevState.unmanagedArray.filter(
            (order) => order.id !== active.id
          ),
          managedArray: [orderToMove, ...prevState.managedArray],
        }));
      }
    } else if (
      activeColumn === 'managedArray' &&
      overColumn === 'unmanagedArray'
    ) {
      const orderToMove = dndArrays.managedArray.find(
        (order) => order.id === active.id
      );
      if (orderToMove) {
        setDndArrays((prevState) => ({
          ...prevState,
          managedArray: prevState.managedArray.filter(
            (order) => order.id !== active.id
          ),
          unmanagedArray: [orderToMove, ...prevState.unmanagedArray],
        }));
      }
    }
  };

  const onSubmit = () => {
    let _order = _.cloneDeep(state);

    // delete _order.companyChanged;
    // delete _order.employeeList;
    console.log('onSubmit', _order);

    postTicket.mutate(_order, {
      onSuccess: (state: any) => {
        // navigate(`/tickets/${data.id}`);
      },
    });
  };

  // USE EFFECTS
  // orders
  useEffect(() => {
    if (unmanagedData) {
      console.log('unmanaged data', unmanagedData);
      const orders: IOrderViewModel[] = unmanagedData.orderPosts.map(
        (obj: any) => ({
          id: obj.id,
          orderNo: obj.orderNo,
          orderStatus: obj.orderStatus,
          orderDate: obj.orderDate,
          trackingNo: obj.trackingNo || null,
          ticket: obj.ticket as IOrderTicketViewModel | null,
          company: obj.company as IOrderCompanyViewModel | null,
          companyContact: obj.companyContact as IOrderContactViewModel | null,
          product: obj.product as IOrderProductViewModel,
          productQty: obj.productQty,
          vendor: obj.vendor as IOrderCompanyViewModel,
          vendorContact: obj.vendorContact as IOrderContactViewModel | null,
          deliveryAddress:
            obj.deliveryAddress as IOrderDeliveryAddressViewModel,
          deliveryStatus: obj.deliveryStatus,
          deliveryDate: obj.deliveryDate,
          estimatedDeliveryDate: obj.estimatedDeliveryDate,
          attention: obj.attention || null,
        })
      );

      setDndArrays({
        unmanagedArray: orders,
        managedArray: [],
      });
      console.log('useEffect orders ', orders);
    }
  }, [unmanagedData]);

  // vendors
  useEffect(() => {
    if (vendorData) {
      const vendors: IOrderCompanyViewModel[] = vendorData.companies.map(
        (obj: any) => ({
          id: obj.id,
          name: obj.name,
          organizationNumber: obj.organizationNumber,
          phone: obj.phone,
          email: obj.email,
          title: obj.title,
          url: obj.url || null,
          priority: obj.priority,
          onHold: obj.onHold,
          onHoldNote: obj.onHoldNote || null,
        })
      );

      setVendorList(vendors);
    }
  }, [vendorData]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          height: Math.ceil(height - 135),
          overflow: 'hidden',
        }}
      >
        <Grid item xs={6} sx={{ pr: 1, m: 0, height: '100%' }}>
          <SearchFilter
            Icon={CreditCardIcon}
            Header='Unmanaged Orders'
            handleNew={handleNew}
          />
          <DroppableOrdersList
            id={Object.keys(dndArrays)[0]}
            orders={dndArrays.unmanagedArray}
            setActiveOrder={setActiveOrder}
            handleClick={handleClick}
          />
          <Paper
            variant='elevation3'
            sx={{
              p: 2,
              height: 64,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          />
        </Grid>
        <Grid item lg={6} sx={{ pl: 1, m: 0 }}>
          <Paper
            variant='elevation3'
            sx={{
              position: 'relative',
              height: '100%',
              backgroundColor: 'primary.back',
              borderRadius: 2,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            <SearchFilter Icon={CreditCardIcon} Header='Info' />
            <VendorBlock
              vendor={state.selectedVendor}
              vendorContact={selectedOrder?.vendorContact}
              objectList={vendorList}
              handleChange={handleChange}
            />
            <DroppableOrdersList
              id={Object.keys(dndArrays)[1]}
              orders={dndArrays.managedArray}
              setActiveOrder={setActiveOrder}
              handleClick={handleClick}
            />
            <Paper
              variant='elevation3'
              sx={{
                p: 2,
                position: 'absolute',
                bottom: 0,
                // height: 64,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
            >
              {createPortal(
                <>
                  {console.log("Here's the active order:", activeOrder)}
                  <DragOverlay>
                    {activeOrder && (
                      <SingleUnmanagedObjectView
                        unmanagedObject={activeOrder}
                        handleClick={() => {}}
                      />
                    )}
                  </DragOverlay>
                </>,
                document.body
              )}
              <DeliveryAddressBlock
                selectedDeliveryAddress={state.selectedDeliveryAddress}
                attention={'state.attention'}
                updateAddress={updateAddress}
                updateAttention={updateAttention}
                objectList={state.deliveryAddressList}
                handleChange={handleChange}
              />
              <Box
                sx={{
                  m: 0,
                  pt: 2,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row-reverse',
                }}
              >
                <Button
                  variant='contained'
                  //type='submit'
                  onClick={onSubmit}
                  // disabled={postTicket.isLoading}
                >
                  Save
                </Button>
              </Box>
            </Paper>
          </Paper>
        </Grid>
      </Grid>
    </DndContext>
  );
};
