import { useEffect } from 'react';
import { AppRoutes } from 'routes';
import { useIsAuthenticated } from '@azure/msal-react';
import { useLocation } from 'react-router-dom';
import { Sidebar } from 'components/ui';
import BluuTheme from 'assets/BluuTheme.ts';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import { ThemeProvider } from '@mui/material/styles';
import useWindowsDimension from 'functions/useWindowsDimension';
import GlobalSpinnerLoader from 'components/ui/GlobalSpinnerLoader';

const drawerWidth = 276;

const PageLayout = () => {
  const { pathname } = useLocation();
  const { height, width } = useWindowsDimension();

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <>
      <ThemeProvider theme={BluuTheme}>
        <CssBaseline />
        <Grid container direction='row' spacing={0} sx={{ m: 0, p: 0 }}>
          <Grid item xs={12} sx={{ height: '10px', m: 0, p: 0 }}>
            <GlobalSpinnerLoader />
          </Grid>
          <Grid item width={drawerWidth} height={height - 10}>
            <Sidebar />
          </Grid>
          {!!isAuthenticated && (
            <Grid
              item
              width={width - drawerWidth - 50}
              sx={{
                ml: 2,
              }}
            >
              <AppRoutes />
            </Grid>
          )}
        </Grid>
      </ThemeProvider>
    </>
  );
};

export default PageLayout;
