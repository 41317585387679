import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";

export const FormAddressCity = (props:{ name:string, control:any, label:string, handleBlur:any }) => {
  return  (
    <Controller
    
      name={props.name}
      control={props.control}
      render={({
        field: { onChange, ref, value },
        fieldState: { error },
        //formState,
      }) => (
        <TextField
          sx = {{'& .Mui-focused':{
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
          }, }}
          helperText={error ? error.message : null}
          inputRef={ref}
          error={!!error}
          onChange={onChange}
          value={value || ""}
          fullWidth
          label={props.label}
          className="subvariant-hovered"
          onBlur={props.handleBlur}
          name={props.name}
        />
      )}
    />
  );
};
