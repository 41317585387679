import { Typography, Box } from '@mui/material';

export const FormBox = (props:any) => {
  const { Icon, title, FormJSX } = props;

  return (
    <Box sx={{ alignSelf: "center", display: "flex", }}>
    {!!Icon && <Icon sx={{ fontSize: 32, m: 1.5 }} />}
    <Typography
      sx={{
        m: 2,
        ml: -1,
        pt: 0.5,
        flexGrow: 1,
        textTransform: "uppercase",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontWeight: "bold",
        fontSize: 14,
        letterSpacing: 2,
      }}
    >
      {title}
    </Typography>
      
    {!!FormJSX && <FormJSX />}
  </Box>
  );
};
