import Typography from '@mui/material/Typography';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
import { Grid, Paper, Stack, IconButton } from '@mui/material';

import GetPriorityColor from 'functions/GetPriorityColor';
import { useNavigate } from 'react-router';
import { ticketStatus } from 'data/enum';
import { MissingPhoto } from 'data/globalTypeConfig';

function getFormattedTimeDifference(date1: Date, date2: Date): string {
  const diffInMilliseconds: number = Math.abs(
    date2.getTime() - date1.getTime()
  ); // Absolute difference in milliseconds

  // Convert to minutes
  const diffInMinutes: number = Math.floor(diffInMilliseconds / 1000 / 60);

  // Convert to hours and minutes if the difference is at least 60 minutes
  const hours: number = Math.floor(diffInMinutes / 60);
  const minutes: number = diffInMinutes % 60;

  // Format time
  const formattedHours: string = String(hours).padStart(2, '0');
  const formattedMinutes: string = String(minutes).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
}

export const SingleTicketView = (props: any) => {
  let navigate = useNavigate();
  if (!props.ticket) {
    return null; // You can return a placeholder or null here
  }
  const id = `${props.ticket.id}`;
  const index = props.index;
  const ticket = props?.ticket;

  const handleMouseEnter = () => {
    if (props.setIsDragDisabled) {
      props.setIsDragDisabled(true);
    }
  };

  const handleMouseLeave = () => {
    if (props.setIsDragDisabled) {
      props.setIsDragDisabled(false);
    }
  };

  // calculating date difference
  const date1 = new Date(ticket.updated) as Date;
  const date2 = new Date() as Date;
  // console.log('date1', date1);
  // console.log('date2', date2);
  // console.log('ticket', ticket);

  const formattedTimeDifference: string = getFormattedTimeDifference(
    date1,
    date2
  );

  if (!ticket) {
    return (
      <Paper
        // ref={setNodeRef}
        variant='elevation3'
        sx={{
          mb: 1,
          borderLeft: 8,
          borderRight: 1,
          borderColor: GetPriorityColor(ticket.priority),
        }}
        // style={draggableStyle}
      >
        <Stack direction={'row'}>
          <Grid container direction={'row'}></Grid>
        </Stack>
      </Paper>
    );
  }

  return (
    <Paper
      // ref={setNodeRef}
      variant='elevation3'
      sx={{
        p: 0,
        mb: 1,
        borderLeft: 8,
        borderRight: 3,
        borderColor: GetPriorityColor(ticket.priority),
        ':hover': {
          cursor: 'pointer',
          filter: 'brightness(120%)',
          transition: 'all 0.2s ease-in-out',
        },
      }}
      // style={draggableStyle}
    >
      <Stack direction={'row'}>
        <Grid container direction={'row'} sx={{ pl: 1 }}>
          <Grid item sx={{ mr: 1, width: 82 }}>
            <Stack direction={'column'}>
              <Typography
                sx={{
                  m: 0,
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 'normal',
                  fontSize: 14,
                }}
              >
                {ticket.ticketNo}
              </Typography>
              <Typography
                sx={{
                  m: 0,
                  p: 0,
                  letterSpacing: 1,
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 'normal',
                  fontSize: 12,
                }}
              >
                {ticketStatus[ticket.status].name}
              </Typography>
              <Typography
                sx={{
                  m: 0,
                  p: 0,
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 'bold',
                  fontSize: 14,
                }}
              >
                Age {formattedTimeDifference}
              </Typography>
            </Stack>
          </Grid>
          <Grid item sx={{ mr: 0.5, width: 128 }}>
            <Paper
              sx={{
                mx: 'auto',
                display: 'flex',
                alignSelf: 'flex-center',
                backgroundColor: 'primary.light',
              }}
            >
              <img
                src={ticket.company?.url || MissingPhoto.COMPANY_URL}
                style={{
                  padding: 1,
                  width: 128,
                  height: 64,
                  borderRadius: 3,
                  objectFit: 'scale-down',
                }}
                alt={'previewUrl'}
              />
            </Paper>
          </Grid>

          <Grid item sx={{ mr: 1, width: 150 }}>
            <Stack direction={'column'}>
              <Typography
                sx={{
                  m: 0,
                  p: 0,
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 'normal',
                  fontSize: 14,
                }}
              >
                {ticket.areaSelection}
              </Typography>
              <Typography
                sx={{
                  m: 0,
                  p: 0,
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 'normal',
                  fontSize: 14,
                }}
              >
                {ticket.companyProductSelection}
              </Typography>
              <Typography
                sx={{
                  m: 0,
                  p: 0,
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 'normal',
                  fontSize: 14,
                }}
              >
                {ticket.autoAnswerSelection}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs zeroMinWidth sx={{ mr: 1, flexGrow: 1 }}>
            <Typography
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '3',
                WebkitBoxOrient: 'vertical',
                textAlign: 'left',
                whiteSpace: 'wrap',
                fontWeight: 'normal',
                fontSize: 14,
              }}
            >
              {ticket.comment ? ticket.comment : 'No comment'}
            </Typography>
          </Grid>
          <Grid item sx={{}}>
            <img
              src={ticket.contact?.url || MissingPhoto.EMPLOYEE_URL}
              style={{
                width: 64,
                height: 64,
                borderRadius: 100,
                objectFit: 'scale-down',
                boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
              }}
              alt={'previewUrl'}
            />
          </Grid>
        </Grid>
        <Grid sx={{}}>
          <IconButton
            sx={{ mr: -1, mt: -1 }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={(event) => {
              event.stopPropagation();
              console.log('event.stopPropagation', event);
              navigate(`/tickets/${ticket.id}/communications`);
            }}
          >
            <LaunchRoundedIcon sx={{ fontSize: 26 }} />
          </IconButton>
        </Grid>
      </Stack>
    </Paper>
  );
};
