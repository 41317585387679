import { useGet } from "http/useInnovit";
import { useParams } from "react-router";
import { ProductForm } from "./ProductForm";
import { newProduct } from "data/templates";
import { rq } from "http/apiRoutes";

export const Product = () => {
  const { productId } = useParams();

  // api

  let isEnabled = productId !== "new" ? true : false;
  const { data: product } = useGet(rq.product(productId, isEnabled), isEnabled);
  const { data: categories } = useGet(rq.categories);

  /*https://www.reddit.com/r/reactjs/comments/xqiuea/what_do_you_do_in_a_situation_when_you_want_to/ */

  if (productId === "new") {
    return <ProductForm categories={categories} product={newProduct} />;
  }
  return (
    !!product &&
    !!categories && <ProductForm categories={categories} product={product} />
  );

  // return null
};
