import { DepartmentForm } from 'pages/Companies/DepartmentForm';
import { useParams } from 'react-router';
import { useGet } from 'http/useInnovit';
import { rq } from 'http/apiRoutes';
import { emptyDepartment } from 'data/templates';

export const Department = () => {
  const { departmentId, companyId } = useParams();
  let department;

  // api
  let isEnabled = departmentId !== 'new' ? true : false;

  //console.log("Department", departmentId, companyId);
  const { data } = useGet(
    rq.department(companyId, departmentId, isEnabled),
    isEnabled
  );
  data ? (department = data) : (department = emptyDepartment);

  if (departmentId === 'new')
    return <DepartmentForm department={emptyDepartment} />;

  if (departmentId !== 'new') return <DepartmentForm department={department} />;
};
