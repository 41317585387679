import { Box } from "@mui/material";
import { TextField, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";

export function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

export function QuickSearchToolbar(props) {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{
          width: {
            xs: 1,
            sm: "auto",
          },
          m: (theme) => theme.spacing(1, 0.5, 1.5),
          "& .MuiSvgIcon-root": {
            mr: 0.5,
          },
          "& .MuiInput-underline:before": {
            borderBottom: 1,
            borderColor: "divider",
          },
        }}
      />
    </Box>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

/* @list an array of objects */
export const getName = (id, list) => {
  for (let i = 0; i < list.length; i++) {
    if (id === list[i].id) {
      return list[i].name;
    }
  }
};

/* image handling */
export const convert2base64 = (file) => {
  const reader = new FileReader();
  reader.onloadend = () => {
    //setImage usestate
  };
  return reader.readAsDataURL(file);
};

/* open google map */
export const openInNewTab = (e, url) => {
  e.preventDefault();
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

/* get filter list */
export const getFilteredList = (list, filterValue) => {
  let filterRegex = new RegExp(escapeRegExp(filterValue), "i");
  const filteredRows = list.filter((row) => {
    return Object.keys(row).some((field) => {
      if (row[field] === null || row[field] === undefined) {
        row[field] = "";
      }
      return filterRegex.test(row[field].toString());
    });
  });
  return filteredRows;
};
