import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Stack,
  Paper,
  TextField,
  Button,
} from '@mui/material';
import FeedRoundedIcon from '@mui/icons-material/FeedRounded';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { IOrderDeliveryAddressViewModel } from 'pages/Orders/OrdersConfig';
import { ObjectDrawer } from 'pages/Companies/ObjectDrawer';
import postalCodes from 'norway-postal-codes';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { add } from 'lodash';
import { IAddressViewModel } from 'data/globalTypeConfig';

interface ChildComponentProps {
  selectedDeliveryAddress: IOrderDeliveryAddressViewModel;
  attention: string;
  updateAddress: (newAddress: IAddressViewModel) => void;
  updateAttention: (newAttention: string) => void;
  objectList: IOrderDeliveryAddressViewModel[];
  handleChange: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    selectedObject: any,
    objectType: string
  ) => void;
}

export const DeliveryAddressBlock: React.FC<ChildComponentProps> = ({
  selectedDeliveryAddress,
  attention,
  updateAddress,
  updateAttention,
  objectList,
  handleChange,
}) => {
  const [edit, setEdit] = useState(false);
  const [localAddress, setLocalAddress] = useState<IAddressViewModel>(
    selectedDeliveryAddress.address
  );
  const [localAttention, setLocalAttention] = useState<string>(attention);

  console.log('localAddress', localAddress);

  useEffect(() => {
    setLocalAddress(selectedDeliveryAddress.address);
  }, [selectedDeliveryAddress]);

  const handleAddressFieldChange =
    (field: keyof IOrderDeliveryAddressViewModel['address']) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setLocalAddress((prevState) => ({
        ...prevState,
        [field]: e.target.value,
      }));
    };

  const handleAttentionFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLocalAttention(e.target.value);
  };

  const handleSave = () => {
    updateAddress(localAddress);
    setEdit(false);
  };

  return (
    <Grid
      container
      direction={'row'}
      display={'flex'}
      flexDirection={'row'}
      sx={{ backgroundColor: 'primary.main', p: 1, borderRadius: 2 }}
    >
      <Grid item xs={12} sx={{ p: 0, mb: 1 }}>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Box sx={{ p: 0 }}>
            <FeedRoundedIcon sx={{ fontSize: 32 }} />
          </Box>
          <Box sx={{ pl: 2, pt: 1 }}>
            <Typography
              sx={{
                textTransform: 'capitalize',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: 'bold',
                fontSize: 16,
                letterSpacing: 4,
              }}
            >
              Delivery Address
            </Typography>
          </Box>
          <Box sx={{ pt: 1, flexGrow: 1 }}>
            <Typography
              sx={{
                width: '100%',
                textTransform: 'capitalize',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: 'normal',
                fontSize: 14,
                letterSpacing: 1,
                textAlign: 'center',
                color: 'success.text',
              }}
            >
              {selectedDeliveryAddress?.name}
            </Typography>
          </Box>
          <Box sx={{ pl: 2 }}>
            <Stack direction='row'>
              {edit && (
                <Button variant='contained' onClick={handleSave}>
                  Save
                </Button>
              )}
              {!edit && (
                <Button
                  sx={{
                    p: 0,
                    m: 0,
                    mr: 2,
                  }}
                  variant='contained'
                  onClick={() => setEdit(!edit)}
                >
                  <EditRoundedIcon sx={{ fontSize: 26 }} />
                </Button>
              )}
              <ObjectDrawer
                buttonContext={'Select DeliveryAddress'}
                buttonIcon={<ChangeCircleIcon sx={{ fontSize: 40 }} />}
                objectList={!!objectList ? objectList : []}
                objectType={'deliveryAddress'}
                handleChange={handleChange}
                buttonHeight={40}
                buttonWidth={'100%'}
              />
            </Stack>
          </Box>
        </Box>
      </Grid>

      {['street', 'postalCode', 'area', 'city', 'country', 'postBox'].map(
        (field, index) => (
          <Grid item xs={3} key={index} sx={{ p: 1 }}>
            <TextField
              size='small'
              id={`Address.${field}`}
              label={field.charAt(0).toUpperCase() + field.slice(1)}
              type='text'
              variant={edit ? 'filled' : 'standard'}
              InputProps={{ readOnly: !edit }}
              value={localAddress?.[field as keyof IAddressViewModel] ?? ''}
              onChange={handleAddressFieldChange(
                field as keyof IAddressViewModel
              )}
              sx={{ mt: 0 }}
              fullWidth
            />
          </Grid>
        )
      )}
      <Grid item xs={6} sx={{ p: 1 }}>
        <TextField
          size='small'
          id={`attention`}
          label={'Attention'}
          type='text'
          variant={'filled'}
          value={localAttention}
          onChange={handleAttentionFieldChange}
          onBlur={(e) => {
            updateAttention(e.target.value);
          }}
          sx={{ mt: 0 }}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};
