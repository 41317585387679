function SortNameArray(props) {
  let data = props;
  //console.log(data);
  let _sortedArray = [];
  if (data) {
    _sortedArray = data.sort(function (a, b) {
      var nameA = a.name.toUpperCase(); // ignore upper and lowercase
      var nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1; //nameA comes first
      }
      if (nameA > nameB) {
        return 1; // nameB comes first
      }
      return 0; // names must be equal
    });
  }

  return _sortedArray;
}

export default SortNameArray;
