import GetColorByName from "./GetColorByName";

function GetAvatarInitials(name, width, height, borderRadius) {
  //npm start console.log("GetAvatarInitials", name);
  if (!name) return '';
  let names = "Jon Doe";
  name && (names = name.split(" "));
  let _Initials = "";

  let _sliceSize = 0;
  if (names.length > 1) {
    _sliceSize = 1;
  } else if (width > 255) {
    _sliceSize = 8;
  } else if (width > 127) {
    _sliceSize = 6;
  } else if (width > 95) {
    _sliceSize = 4;
  } else if (width > 63) {
    _sliceSize = 3;
  } else if (width > 0) {
    _sliceSize = 2;
  }

  name &&
    names.forEach((n) => {
      _Initials = _Initials + n.slice(0, _sliceSize);
    });

  return {
    sx: {
      borderRadius: borderRadius,
      width: width,
      height: height,
      backgroundColor: GetColorByName(name),
      color: "white",
      textTransform: "uppercase",
    },
    children: `${_Initials}`,
  };
}
export default GetAvatarInitials;
