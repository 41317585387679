import React from "react";
import { Paper, Stack, Typography } from "@mui/material";
import { ChartDataView } from "./ChartDataView";


export const ChartSingle = (props) => {
  const { header, subHeader, icon: IH, value, oldValue, trend } = props;

  let colorType = 0;
  if (value > oldValue) {
    colorType = 0;
  } else if (value < oldValue) {
    colorType = 2;
  } else {
    colorType = 1;
  }

  return (
    <Paper
      variant="elevation3"
      sx={{
        p: 1,
        mr: 2,
        mb: 2,
        borderRadius: 3,
        backgroundColor: "primary.main",
      }}
    >
      <Stack direction={"row"}>
        <IH sx={{ fontSize: 32 }} />
        <Typography
          sx={{
            m: 1,
            fontSize: 18,
            letterSpacing: 2,
            fontWeight: "bold",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {header}
        </Typography>
      </Stack>

      <ChartDataView
        subHeader={subHeader}
        value={value}
        oldValue={oldValue}
        colorType={colorType}
        trend={trend}
      />
    </Paper>
  );
};
