import useWindowsDimension from 'functions/useWindowsDimension';

import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  Button,
  Box,
} from '@mui/material';
import WatchLaterRoundedIcon from '@mui/icons-material/WatchLaterRounded';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import { useParams } from 'react-router';
import { useState, useEffect, useReducer } from 'react';
import { TotalBillableAmount } from 'functions/TotalBillableAmount';
import { Fragment, memo } from 'react';
import GetChartColor from 'functions/GetChartColor';
import { rq, rqPost } from 'http/apiRoutes';
import { useGet, usePut } from 'http/useInnovit';
import { BillableTime } from 'functions/BillableTime';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { getTimeDifference } from 'functions/GetTimeDifference';
import moment from 'moment';
import { FormText, FormBoxElevated } from 'components/form';
import { useForm } from 'react-hook-form';
import PaymentIcon from '@mui/icons-material/Payment';
import _ from 'lodash';

const initialState = {
  fixedHourlyRate: 0,
  fixedPrice: 0,
  maxPrice: 0,
};

const reducer = (state, action) => {
  switch (action.type.toUpperCase()) {
    case 'CHANGE_HOURLY_RATE':
      return { ...state, fixedHourlyRate: action.payload };
    case 'CHANGE_FIXED_PRICE':
      return { ...state, fixedPrice: action.payload };
    case 'CHANGE_MAX_PRICE':
      return { ...state, maxPrice: action.payload };
    case 'SET_ECONOMY_TICKET':
      console.log('set economy ticket', action.payload.fixedPrice);
      return {
        ...state,
        fixedHourlyRate: +action.payload.fixedHourlyRate,
        fixedPrice: +action.payload.fixedPrice,
        maxPrice: +action.payload.maxPrice,
      };

    // return { ...state, responsibleFor: [...state.responsibleFor,action.responsibleFor] };
    default:
      return initialState;
  }
};

export const TicketEconomy = () => {
  /* INITIALIZATIONS */

  const { ticketId } = useParams();
  const [ticket, setTicket] = useState([]);
  const [posts, setPosts] = useState([]);
  const [state, dispatch] = useReducer(reducer, initialState);

  let isEnabled = ticketId !== 'new' ? true : false;
  const { data: ticketData } = useGet(
    rq.ticket(ticketId, isEnabled),
    isEnabled
  );
  const { data } = useGet(rq.ticketPosts(ticketId, isEnabled), isEnabled);
  const putTicket = usePut(rqPost.ticketPut(ticketId));

  const FixedHourlyRateJSX = memo(() => {
    return (
      <Box sx={{ alignSelf: 'center', pr: 2 }}>
        <FormText name='fixedHourlyRate' control={control} adornmentText='kr' />
      </Box>
    );
  });

  const FixedPriceJSX = memo(() => {
    return (
      <Box sx={{ alignSelf: 'center', pr: 2 }}>
        <FormText name='fixedPrice' control={control} adornmentText='kr' />
      </Box>
    );
  });

  const MaxPriceJSX = memo(() => {
    return (
      <Box sx={{ alignSelf: 'center', pr: 2 }}>
        <FormText name='maxPrice' control={control} adornmentText='kr' />
      </Box>
    );
  });

  const {
    handleSubmit,
    control,
    getValues,
    reset,
    watch,
    formState: { isDirty },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: ticketData,
    /*  resolver: yupResolver(schema), */
  });

  console.log('get values', getValues());

  const handleChangeFixedHourlyRate = (e) => {
    console.log('handleChangeFixedHourlyRate: ', e);
    dispatch({
      type: 'CHANGE_HOURLY_RATE',
      payload: e.target.value,
    });
  };

  const handleChangeFixedPrice = (e) => {
    console.log('handleChangeFixedHourlyRate: ', e.target.value);
    dispatch({
      type: 'CHANGE_FIXED_PRICE',
      payload: e.target.value,
    });
  };

  const handleChangeMaxPrice = (e) => {
    console.log('handleChange: ', e);
    dispatch({
      type: 'CHANGE_MAX_PRICE',
      payload: e.target.value,
    });
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    console.log('onSubmit: ', data, ticket);
    const _ticket = _.cloneDeep(ticket);

    _ticket.fixedHourlyRate = data.fixedHourlyRate || 0;
    _ticket.fixedPrice = data.fixedPrice || 0;
    _ticket.maxPrice = data.maxPrice || 0;

    putTicket.mutate(_ticket);
  };

  useEffect(() => {
    if (ticketData) {
      // ticketData.fixedHourlyRate = ticketData.fixedHourlyRate;
      setTicket(ticketData);
      console.log('ticketData: ', ticketData);
      dispatch({ type: 'SET_ECONOMY_TICKET', payload: ticketData });
    }
  }, [ticketData]);

  useEffect(() => {
    if (data) {
      setPosts(data.ticketPosts);
    }
  }, [data]);

  const { height } = useWindowsDimension();
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
      }}
    >
      <Grid item xs={6} sx={{ pr: 1, m: 0 }}>
        <form onSubmit={handleSubmit(onSubmit)} name='ticketEconomyForm'>
          <Paper
            variant='elevation3'
            sx={{
              p: 2,
              height: Math.ceil(height - 128),
              borderRadius: 2,
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                mb: 2,
              }}
            >
              <FormBoxElevated
                Icon={WatchLaterRoundedIcon}
                title='Fixed Hourly Rate'
                FormJSX={FixedHourlyRateJSX}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                mb: 2,
              }}
            >
              <FormBoxElevated
                Icon={MonetizationOnRoundedIcon}
                title='Fixed Price'
                FormJSX={FixedPriceJSX}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                mb: 2,
              }}
            >
              <FormBoxElevated
                Icon={PaymentIcon}
                title='Max Price'
                FormJSX={MaxPriceJSX}
              />
            </Grid>
            <Box
              sx={{
                ml: 2,
                width: '100%',
                display: 'flex',
                // flexDirection: 'row-reverse',
              }}
            >
              <Button
                variant='contained'
                type='submit'
                //onClick={onSubmit}
                // disabled={postTicket.isLoading}
              >
                Update Ticket
              </Button>
            </Box>
          </Paper>
        </form>
      </Grid>

      <Grid item xs={6} sx={{ pl: 1, m: 0 }}>
        <Paper
          variant='elevation3'
          sx={{
            height: 64,
            borderRadius: 2,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          {posts.length > 0 && (
            <Grid
              container
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexGrow: 1,
              }}
            >
              <Typography
                sx={{
                  p: 2,
                  fontSize: 24,
                  letterSpacing: 3,
                  textAlign: 'right',
                  color: 'text.secondary',
                  fontWeight: 'bold',
                  flexGrow: 1,
                }}
              >
                Total
              </Typography>
              <Grid
                sx={{
                  p: 2,
                }}
              >
                {ticket.fixedPrice > 0 ? (
                  <Typography
                    sx={{
                      p: 0,
                      m: 0,
                      width: 150,
                      fontSize: 24,
                      letterSpacing: 3,
                      textAlign: 'right',
                      color: 'secondary.text',
                      fontWeight: 'bold',
                    }}
                  >
                    {ticket.fixedPrice?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                ) : (
                  <TotalBillableAmount
                    posts={posts}
                    size={24}
                    color={'secondary.text'}
                    fixedHourlyRate={ticket.fixedHourlyRate}
                    maxPrice={ticket.maxPrice}
                  />
                )}
              </Grid>
            </Grid>
          )}
        </Paper>
        <List
          sx={{
            pl: 1,
            pr: 1,
            height: Math.ceil(height - 258),
            backgroundColor: 'primary.back',
            overflow: 'auto',
          }}
        >
          {posts.length > 0 &&
            posts?.map(
              (post, index) =>
                post.billable === true &&
                post.direction === 1 &&
                post.internal !== true && (
                  <ListItem
                    key={index}
                    alignItems='flex-start'
                    sx={{
                      m: 0,
                      p: 0,
                      borderRadius: 3,
                    }}
                  >
                    <ListItemText
                      primary={
                        <Grid
                          container
                          sx={{
                            m: 0,
                            p: 0,
                            borderRadius: 2,
                            width: '100%',
                            backgroundColor: GetChartColor(6) + '.dark',
                          }}
                        >
                          <Typography
                            sx={{
                              pl: 1,
                              display: 'inline',
                              fontSize: 18,
                              color: 'primary.text',
                            }}
                          >
                            {moment(new Date(post.startTime)).format(
                              'dd/MM-yyyy HH:mm'
                            )}
                          </Typography>
                          <Typography
                            sx={{
                              pl: 3,
                              width: 75,
                              display: 'inline',
                              fontSize: 18,
                              color: 'warning.light',
                            }}
                          >
                            -
                            {getTimeDifference(
                              post.startDeduction,
                              post.endDeduction
                            )}
                          </Typography>
                          <Typography
                            sx={{
                              pl: 3,
                              display: 'inline',
                              fontSize: 18,
                              color: 'primary.text',
                            }}
                          >
                            {moment(new Date(post.endTime)).format('HH:mm')}
                          </Typography>
                          <Typography sx={{ flexGrow: 1 }}></Typography>

                          <HistoryToggleOffIcon />

                          <BillableTime
                            startTime={post.startTime}
                            startDeduction={post.startDeduction}
                            endDeduction={post.endDeduction}
                            endTime={post.endTime}
                            size={16}
                            color={'secondary.text'}
                          />

                          <AttachMoneyIcon />

                          <TotalBillableAmount
                            posts={[post]}
                            size={16}
                            color={'secondary.text'}
                            fixedHourlyRate={ticket.fixedHourlyRate}
                            maxPrice={ticket.maxPrice}
                          />
                        </Grid>
                      }
                      secondary={
                        <Fragment>
                          <Typography
                            sx={{ display: 'inline', fontWeight: 'bold' }}
                            component='span'
                            variant='body1'
                            color='text.primary'
                          >
                            {post?.author?.name + ' '}
                          </Typography>
                          <Typography
                            sx={{ display: 'inline' }}
                            component='span'
                            variant='body2'
                            color='text.primary'
                          >
                            {post.message}
                          </Typography>
                        </Fragment>
                      }
                    />
                  </ListItem>
                )
            )}
        </List>
        <Paper
          variant='elevation3'
          sx={{
            height: 64,
            borderRadius: 2,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        ></Paper>
      </Grid>
    </Grid>
  );
};
