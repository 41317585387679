import { SingleTicketHistory } from "components/ui/SingleTicketHistory";
import useWindowsDimension from "functions/useWindowsDimension";
import { SubHeading } from "components/ui";
import { Box, Grid, List, Paper, Typography, Stack } from "@mui/material";
import DevicesOtherRoundedIcon from "@mui/icons-material/DevicesOtherRounded";
import RestorePageRoundedIcon from "@mui/icons-material/RestorePageRounded";
import { SingleProductView } from "components/ui/SingleProductView";

const Header = (props) => {
  const { icon: IH, action: AH, headerText: HT } = props;
  return (
    <Box
      sx={{
        display: "flex",
        p: 1,
      }}
    >
      <Box sx={{ pt: 1 }}>
        <IH sx={{ fontSize: 40 }} />
      </Box>
      <Box sx={{ pl: 2, pt: 2, flexGrow: 1 }}>
        <Typography noWrap variant="subHeader">
          {HT} {props.children}
        </Typography>
      </Box>
      <Box sx={{}}>{AH && <AH sx={{ fontSize: 40 }} />}</Box>
    </Box>
  );
};

export const TicketProductsHistory = () => {
  const { height } = useWindowsDimension();
  let ticket = [];
  //console.log(ticket);
  return (
    <div>
      <Grid sx={{ ml: 2, mb: 2 }}>
        <SubHeading icon={DevicesOtherRoundedIcon} title="Product history" />
      </Grid>
      <Stack direction={"row"}>
        <Paper
          variant="elevation3"
          sx={{
            width: "50%",
            height: Math.ceil(height - 122),
            ml: 2,
            backgroundColor: "primary.main",
          }}
        >
          <Header icon={DevicesOtherRoundedIcon} headerText="product" />
          <Grid sx={{ p: 2 }}>
            <List
              dense
              sx={{
                p: 1,
                overflow: "auto",
                height: Math.ceil(height - 222),
                backgroundColor: "primary.back",
                borderRight: 1,
                borderRightColor: "primary.text",
                borderBottom: 1,
                borderBottomColor: "primary.text",
                borderRadius: 3,
              }}
            >
              {ticket?.products &&
                ticket.products?.map((product, index) => (
                  <SingleProductView
                    key={index}
                    index={index}
                    product={product}
                  />
                ))}
            </List>
          </Grid>
        </Paper>

        <Paper
          variant="elevation3"
          sx={{
            width: "50%",
            height: Math.ceil(height - 122),
            ml: 2,
            backgroundColor: "primary.main",
          }}
        >
          <Header icon={RestorePageRoundedIcon} headerText="product history" />
          <Grid sx={{ p: 2 }}>
            <List
              dense
              sx={{
                p: 1,
                overflow: "auto",
                height: Math.ceil(height - 222),
                backgroundColor: "primary.back",
                borderRight: 1,
                borderRightColor: "primary.text",
                borderBottom: 1,
                borderBottomColor: "primary.text",
                borderRadius: 3,
              }}
            >
              {ticket.length > 0 &&
                ticket.map((_ticket, index) => (
                  <SingleTicketHistory
                    key={index}
                    index={index}
                    ticket={_ticket}
                  />
                ))}
            </List>
          </Grid>
        </Paper>
      </Stack>
    </div>
  );
};
