import { Link, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import useWindowsDimension from 'functions/useWindowsDimension';
import { SearchFilter } from 'components/ui/SearchFilter';
import {
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import RequestQuoteRoundedIcon from '@mui/icons-material/RequestQuoteRounded';
import { rq, rqPost } from 'http/apiRoutes';
import { useGet, usePost, usePut } from 'http/useInnovit';
import React, { useEffect, useReducer, useState } from 'react';
import SingleBillingPostView from 'components/ui/SingleBillingPostView';
import {
  IBillingDetailViewModel,
  IBillingPostViewModel,
  IInvoicePostViewModel,
  IInvoiceViewModel,
} from './BillingConfig';
import { CompanyInfoView } from 'components/ui/CompanyInfoView';
import { ICompanyViewModel } from 'pages/Companies/CompanyConfig';
import { SingleBillingDetailView } from 'components/ui/SingleBillingDetailView';
import { PDFFile } from 'components/ui/Billing/PDFFile';
import { PDFDownloadLink } from '@react-pdf/renderer';
import dayjs from 'dayjs';
let _ = require('lodash');

const billingHeaderLinks = [
  {
    name: 'Unmanaged',
    component: { Link },
    to: `/billing`,
  },
  {
    name: 'Billed',
    component: { Link },
    to: `/billing/ordersList`,
  },
  {
    name: 'Payed',
    component: { Link },
    to: `/billing/ordersList`,
  },
];

const billingPostReducer = (state: any, action: any) => {
  switch (action.type.toUpperCase()) {
    case 'SELECT_BILLING_POST':
      return {
        ...state,
        ...action.payload,
      };
    case 'RESET_BILLING_POST':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
const initialState: IBillingPostViewModel = {
  id: null,
  BillingNo: 'No2023-00001',
  agreementInvoiceValue: 0,
  agreementCost: 0,
  billingViewModels: [] as IBillingDetailViewModel[],
  company: {} as ICompanyViewModel,
  cost: 0,
  investmentInvoiceValue: 0,
  investmentCost: 0,
  isBilled: false,
  otherInvoiceValue: 0,
  otherCost: 0,
  supportCost: 0,
  supportInvoiceValue: 0,
  invoiceNo: null,
};

const hasNestedProperties = (obj: any, pathArray: string[]): boolean => {
  return pathArray.every((key) => {
    if (typeof obj !== 'object' || obj === null || !(key in obj)) {
      return false;
    }
    obj = obj[key];
    return true;
  });
};

const ConvertBillingViewModel = (
  billingPostViewModel: IBillingPostViewModel
) => {
  const _invoiceViewModel = {} as IInvoiceViewModel;

  _invoiceViewModel.invoicePosts = [] as IInvoicePostViewModel[];
  _invoiceViewModel.supportCost = 0;
  _invoiceViewModel.supportInvoiceValue = 0;
  _invoiceViewModel.agreementCost = 0;
  _invoiceViewModel.agreementInvoiceValue = 0;
  _invoiceViewModel.investmentCost = 0;
  _invoiceViewModel.investmentInvoiceValue = 0;
  _invoiceViewModel.otherCost = 0;
  _invoiceViewModel.otherInvoiceValue = 0;

  console.log('billingPostViewModel ', billingPostViewModel);

  billingPostViewModel.billingViewModels.forEach((item) => {
    let _invoicePost: IInvoicePostViewModel = {} as IInvoicePostViewModel;

    _invoicePost.agreementProductId = item.agreementProduct?.id || null;
    _invoicePost.companyProductId = item.companyProduct?.id || null;
    _invoicePost.ticketPostId = item.ticketPost?.id || null;

    if (item.support && item.ticketPost) {
      _invoicePost.quantity = 1;
      _invoicePost.unitPrice =
        item.support.nightCost +
        item.support.workCost +
        item.support.midDayCost +
        item.support.eveningCost;
      _invoicePost.retailPrice =
        item.support.nightBillableValue +
        item.support.workBillableValue +
        item.support.midDayBillableValue +
        item.support.eveningBillableValue;
      _invoiceViewModel.supportCost += item.support?.nightCost || 0;
      _invoiceViewModel.supportCost += item.support?.workCost || 0;
      _invoiceViewModel.supportCost += item.support?.midDayCost || 0;
      _invoiceViewModel.supportCost += item.support?.eveningCost || 0;

      _invoiceViewModel.supportInvoiceValue +=
        item.support?.nightBillableValue || 0;
      _invoiceViewModel.supportInvoiceValue +=
        item.support?.workBillableValue || 0;
      _invoiceViewModel.supportInvoiceValue +=
        item.support?.midDayBillableValue || 0;
      _invoiceViewModel.supportInvoiceValue +=
        item.support?.eveningBillableValue || 0;
    } else {
      _invoicePost.quantity = item.agreementProduct?.quantity || 1;
      _invoicePost.unitPrice = item.agreementProduct?.unitPrice || 0;
      _invoicePost.retailPrice = item.agreementProduct?.retailPrice || 0;
      _invoiceViewModel.agreementCost += item.agreementProduct?.unitPrice || 0;
      _invoiceViewModel.agreementInvoiceValue +=
        item.agreementProduct?.retailPrice || 0;
      _invoiceViewModel.investmentCost += item.companyProduct?.unitPrice || 0;
      _invoiceViewModel.investmentInvoiceValue +=
        item.companyProduct?.retailPrice || 0;
      _invoiceViewModel.otherCost += 0;
      _invoiceViewModel.otherInvoiceValue += 0;
    }
    _invoiceViewModel.isPayed = false;
    _invoicePost.InvoiceNo = 'new';
    _invoicePost.companyId = billingPostViewModel.company.id;
    _invoiceViewModel.InvoiceNo = 'new';
    _invoiceViewModel.invoicePosts.push(_invoicePost);
  });
  return _invoiceViewModel;
};

export const BillingOverView = () => {
  // INITIALIZATION
  const { height, width } = useWindowsDimension();
  let navigate = useNavigate();

  // States
  const [state, dispatch] = useReducer(billingPostReducer, initialState);
  const [billingPosts, setBillingPosts] = useState<IBillingPostViewModel[]>([]);
  const [isInvoiceOk, setIsInvoiceOk] = useState<boolean>(false);
  // API
  const isEnabled = true;
  const { data: billingPostData } = useGet(rq.billing(isEnabled), isEnabled);
  const postBillingData = usePost(rqPost.billing(isEnabled), isEnabled);
  const putBillingData = usePut(rqPost.billing(isEnabled), isEnabled);
  const postInvoiceData = usePost(rqPost.Invoice(isEnabled), isEnabled);
  const putInvoiceData = usePut(rqPost.Invoice(isEnabled), isEnabled);

  useEffect(() => {
    if (isInvoiceOk) {
      console.log('state ', state);
      isInvoiceOk && (
        <PDFDownloadLink
          document={<PDFFile data={state} />}
          fileName={`${state.InvoiceNo}.pdf`}
        >
          {({ blob, url, loading, error }) =>
            loading ? 'Loading document...' : 'Download PDF'
          }
        </PDFDownloadLink>
      );
      dispatch({
        type: 'RESET_BILLING_POST',
        payload: { ...initialState },
      });
      setIsInvoiceOk(false);
    }
  }, [isInvoiceOk]);

  useEffect(() => {
    if (billingPostData) {
      //console.log('billingPostData ' , billingPostData);
      setBillingPosts(billingPostData.billingPost);
    }
  }, [billingPostData]);

  useEffect(() => {
    if (billingPosts && billingPosts.length > 0) {
      billingPosts.sort((a: any, b: any) => {
        if (!a.agreementProduct || !b.agreementProduct) return 0;

        const dateA = a.agreementProduct?.lastBilled
          ? dayjs(a.agreementProduct?.lastBilled).toDate()
          : dayjs().toDate();
        const dateB = b.agreementProduct?.lastBilled
          ? dayjs(b.agreementProduct?.lastBilled).toDate()
          : dayjs().toDate();

        if (dateA < dateB) return -1;
        if (dateA > dateB) return 1;

        // If dates are equal, compare product names
        if (a.agreementProduct.product.name < b.agreementProduct.product.name)
          return -1;
        if (a.agreementProduct.product.name > b.agreementProduct.product.name)
          return 1;

        // If product names are equal, compare by agreementProduct (assuming it's a string or number)
        if (a.agreementProduct < b.agreementProduct) return -1;
        if (a.agreementProduct > b.agreementProduct) return 1;

        return 0;
      });
    }
  }, [billingPosts]);

  const handlePostInvoice = (e: any) => {
    e.preventDefault();
    let _invoiceViewModel = { ...state };
    console.log('_invoiceViewModel ', _invoiceViewModel);

    const _billingViewPostModel = { ...state };
    if (
      String(_billingViewPostModel.id) ===
        '00000000-0000-0000-0000-000000000000' ||
      _billingViewPostModel.id === null ||
      _billingViewPostModel.id === undefined
    ) {
      const invoice = ConvertBillingViewModel(_billingViewPostModel);
      invoice.companyId = state.company.id;
      console.log('Invoice ', invoice);
      // console.log('Invoice ' , _invoice);
      postInvoiceData.mutate(invoice, {
        onSuccess: (res: any) => {
          console.log('POST INVOICE SUCCESS', res);
          dispatch({
            type: 'SELECT_BILLING_POST',
            payload: res,
          });
          setIsInvoiceOk(true);
        },
      });
    } else {
      /* putBillingData.mutate(_invoice, {
        onSuccess: () => {
          console.log('POST INVOICE SUCCESS');
        },
      }); */
    }
  };

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
      }}
    >
      <Paper
        variant='elevation3'
        sx={{
          p: 0,
          mb: 2,
        }}
      >
        <ListItem>
          <ListItemAvatar>
            <RequestQuoteRoundedIcon sx={{ fontSize: 40 }} />
          </ListItemAvatar>
          <ListItemText
            sx={{ ml: 2 }}
            primary={
              <Typography
                sx={{
                  textTransform: 'capitalize',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontWeight: 'bold',
                  fontSize: 18,
                  letterSpacing: 4,
                }}
              >
                Billing
              </Typography>
            }
          />
        </ListItem>

        <Grid item xs={12} sx={{ p: 0.5 }}>
          <ToggleButtonGroup
            sx={{
              width: Math.ceil(width - 350),
              ml: 0.5,
              mb: 0.2,
              p: 0,
            }}
            color='secondary'
            size='small'
            orientation='horizontal'
            // value={currentView}
            exclusive
            aria-label='text alignment'
          >
            {billingHeaderLinks.map((obj, index) => {
              return (
                <ToggleButton
                  key={index}
                  name={obj.name}
                  onClick={() => navigate(obj.to)}
                  sx={{
                    m: 0,
                    p: 0,
                    width: 200,
                    borderRadius: 2,
                    borderLeft: 2,
                    borderRight: 2,
                    borderLeftColor: 'primary.light',
                    borderRightColor: 'primary.light',
                  }}
                  value={index}
                  aria-label='left aligned'
                >
                  {obj.name}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </Grid>
      </Paper>

      {/* Left side */}
      <Grid
        item
        xs={6}
        sx={{
          pr: 2,
          m: 0,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <SearchFilter
          Icon={RequestQuoteRoundedIcon}
          Header='Unmanaged billing'
        />
        <List
          sx={{
            height: Math.ceil(height - 264),
            backgroundColor: 'primary.back',
          }}
        >
          {billingPosts.length > 0 ? (
            billingPosts.map((billingPost, index) => (
              <Grid
                sx={{ m: 1 }}
                key={index}
                onClick={() => {
                  dispatch({
                    type: 'SELECT_BILLING_POST',
                    payload: billingPost,
                  });
                }}
                style={{ cursor: 'pointer' }}
              >
                <SingleBillingPostView billingPost={billingPost} />
              </Grid>
            ))
          ) : (
            <p>No billing data available.</p>
          )}
        </List>
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            backgroundColor: 'primary.main',
          }}
        >
          {/*   <PDFViewer style={{ width: '100%', height: '100%' }}>
             <PDFFile data={state} />
          </PDFViewer> */}
        </Paper>
      </Grid>

      {/* Right side */}

      <Grid
        item
        xs={6}
        sx={{
          p: 0,
          m: 0,
          pr: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <SearchFilter
          Icon={RequestQuoteRoundedIcon}
          Header='Selected Billing Post'
        />
        <CompanyInfoView company={state.company ? state.company : null} />
        <List
          sx={{
            height: Math.ceil(height - 344),
            backgroundColor: 'primary.back',
            p: 1,
            overflow: 'auto',
          }}
        >
          {state.billingViewModels.length > 0 ? (
            state.billingViewModels.map(
              (billingDetail: IBillingDetailViewModel, index: number) => (
                <div
                  key={index}
                  onClick={() => {
                    dispatch({
                      type: 'SELECT_BILLING_POST',
                      payload: billingDetail,
                    });
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <SingleBillingDetailView billingDetail={billingDetail} />
                </div>
              )
            )
          ) : (
            <p>No billing data available.</p>
          )}
        </List>
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          <Button
            variant='contained'
            disabled={state.billingViewModels.length === 0}
            onClick={(e) => handlePostInvoice(e)}
          >
            Create invoice
          </Button>

          {state.invoiceNo === null && (
            <PDFDownloadLink document={<PDFFile data={state} />}>
              <Button variant='contained' sx={{ color: 'White' }}>
                Download invoice
              </Button>
            </PDFDownloadLink>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};
