import { Typography } from '@mui/material';
import { getRates } from 'functions/GetRates';
import { useAtom } from 'jotai';
import ownerAtom from 'data/atoms/ownerAtom';


const calculateAmount = (rates:any, startHour:any, startMin:any, endHour:any, endMin:any) => {
  let totalAmount = 0;
  let currentHour = startHour;
  let currentMin = startMin;

  while (
    currentHour < endHour ||
    (currentHour === endHour && currentMin < endMin)
  ) {
    let rate;
    for (let i = 0; i < rates.length; i++) {
      const {
        startHour: rateStartHour,
        endHour: rateEndHour,
        rate: rateValue,
      } = rates[i];

      if (currentHour >= rateStartHour && currentHour < rateEndHour) {
        rate = rateValue;
        break;
      }
    }

    const duration = (currentHour === endHour ? endMin : 60) - currentMin;
    totalAmount += (duration / 60) * rate;

    currentMin = 0;
    currentHour++;
  }

  return totalAmount;
};

export const TotalBillableAmount = (props:any) => {
  const { posts, size, color, fixedHourlyRate, maxPrice } = props;
  const [owner, setOwner] = useAtom(ownerAtom);
  // const user = JSON.parse(window.localStorage.getItem('USER'));
  let totalBillableAmount = 0;
  let totalDeductionAmount = 0;

  // filter all billable posts
  let billablePosts = posts.filter(
    (post:any) =>
      post.billable === true && post.direction === 1 && post.internal !== true
  );

  // calculate total billable amount
  billablePosts.map((post:any) => {
    // get rates for this post
    
    let rates = getRates(owner,post, fixedHourlyRate);
    // set start hour and min and end hour and min
    let startHour = new Date(post.startTime).getHours();
    let startMin = new Date(post.startTime).getMinutes();
    let endHour = new Date(post.endTime).getHours();
    let endMin = new Date(post.endTime).getMinutes();

    // use the times and rates to calculate the total amount
    totalBillableAmount += calculateAmount(
      rates,
      startHour,
      startMin,
      endHour,
      endMin
    );

    let startDeductHour = new Date(post.startDeduction).getHours();
    let startDeductMin = new Date(post.startDeduction).getMinutes();
    let endDeductHour = new Date(post.endDeduction).getHours();
    let endDeductMin = new Date(post.endDeduction).getMinutes();

    // use the times and rates to calculate the deduction value
    totalDeductionAmount += calculateAmount(
      rates,
      startDeductHour,
      startDeductMin,
      endDeductHour,
      endDeductMin
    );
    totalBillableAmount -= totalDeductionAmount;
    if (maxPrice > 0)
      totalBillableAmount = Math.min(totalBillableAmount, maxPrice);

    return totalBillableAmount;
  });

  return (
    <Typography
      sx={{
        p: 0,
        m: 0,
        width: 150,
        fontSize: size,
        letterSpacing: 3,
        textAlign: 'right',
        color: color,
        fontWeight: 'bold',
      }}
    >
      {totalBillableAmount?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}
    </Typography>
  );
};
