export interface IEmailModel {
  contactName: string;
  contactEmail: string;
}

export interface IAddressViewModel {
  id?: string | null;
  street: string;
  postalCode: number;
  area:string;
  city: string;
  country: string;
  postBox?: string | null;
}

export interface IMobileAnswersViewModel {
    id?: string | null;
    productTypeId:string;
    local: string;
    english: string;
  }

  interface IImg {
    COMPANY_URL: string;
    EMPLOYEE_URL: string;
    PRODUCT_URL: string;
    IMAGE_URL: string;
    PACKAGE_URL: string;
    BILLING_SUPPORT_URL: string;
    BILLING_PRODUCT_URL: string;
    BILLING_AGREEMENT_URL: string;
    BILLING_OTHER_URL: string;
  } 
export const MissingPhoto: IImg = {
    COMPANY_URL: process.env.PUBLIC_URL + '/img/noCompany.png',
    EMPLOYEE_URL: process.env.PUBLIC_URL + '/img/noEmployee.png',
    PRODUCT_URL: process.env.PUBLIC_URL + '/img/noImage.png',
    IMAGE_URL: process.env.PUBLIC_URL + '/img/noImage.png',
    PACKAGE_URL: process.env.PUBLIC_URL + '/img/noPackage.png', 
    BILLING_SUPPORT_URL: process.env.PUBLIC_URL + '/img/BillingSupport.png',
    BILLING_PRODUCT_URL: process.env.PUBLIC_URL + '/img/BillingHardware.png',
    BILLING_AGREEMENT_URL: process.env.PUBLIC_URL + '/img/BillingAgreement.png',
    BILLING_OTHER_URL: process.env.PUBLIC_URL + '/img/BillingOther.png',
  };

  export enum IEnumBoardViewModel {
    Sale = 0,
    Support = 1,
  }

  export enum IEnumPriorityViewModel {
    NONE = 0,
    LOW = 1,
    NORMAL = 2,
    HIGH = 3,
    CRITICAL= 4
  }
  
  export enum IEnumBillingViewModel {
    SystemPeriod = 0,
    CompanyPeriod = 1,
    Solved = 2,
  }

  export enum IEnumStatusViewModel {
    New = 0,
    Scheduled = 1,
    InProgress = 2,
    OnHold = 3,
    AwaitingResponses = 4,
    AwaitingParts = 5,
    Resolved = 6,
    Reopened = 7,
    Invoiced = 8,
  }

export enum IEnumCycleAlignment
{
    None = 0,
    Category = 1,
    ProductType =2,
    Product = 3,
}

  