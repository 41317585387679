import { useState, useRef } from 'react';
import {
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import useWindowsDimension from 'functions/useWindowsDimension';
import React from 'react';
import { Link, useNavigate, Outlet } from 'react-router-dom';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import SingleUnmanagedView from 'components/ui/Orders/UnManaged/SingleUnmanagedSelectedView';

type Props = {};
const ordersHeaderLinks = [
  {
    name: 'Unmanaged Orders',
    component: { Link },
    to: `/orders`,
  },

  {
    name: 'All Orders',
    component: { Link },
    to: `/orders/ordersList`,
  },

  {
    name: 'Storage',
    component: { Link },
    to: `/orders/storage`,
  },
  {
    name: 'Products',
    component: { Link },
    to: `/orders/products`,
  },

  {
    name: 'Finance',
    component: { Link },
    to: `/orders/finance`,
  },
];
export const Orders = (props: Props) => {
  // INITIALIZATION
  let navigate = useNavigate();
  const { height, width } = useWindowsDimension();

  // API

  // HANDLES

  // EFFECTS



  return (
    <Grid>
      <Paper
        variant='elevation3'
        sx={{
          p: 0,
          mb: 2,
        }}
      >
        <ListItem>
          <ListItemAvatar>
            <ReceiptRoundedIcon sx={{ fontSize: 40 }} />
          </ListItemAvatar>
          <ListItemText
            sx={{ ml: 2 }}
            primary={
              <Typography
                sx={{
                  textTransform: 'capitalize',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontWeight: 'bold',
                  fontSize: 18,
                  letterSpacing: 4,
                }}
              >
                Orders
              </Typography>
            }
          />
        </ListItem>

        <Grid item xs={12} sx={{ p: 0.5 }}>
          <ToggleButtonGroup
            sx={{
              width: Math.ceil(width - 350),
              ml: 0.5,
              mb: 0.2,
              p: 0,
            }}
            color='secondary'
            size='small'
            orientation='horizontal'
            // value={currentView}
            exclusive
            aria-label='text alignment'
          >
            {ordersHeaderLinks.map((obj, index) => {
              return (
                <ToggleButton
                  key={index}
                  name={obj.name}
                  onClick={() => navigate(obj.to)}
                  sx={{
                    m: 0,
                    p: 0,
                    width: 200,
                    borderRadius: 2,
                    borderLeft: 2,
                    borderRight: 2,
                    borderLeftColor: 'primary.light',
                    borderRightColor: 'primary.light',
                  }}
                  value={index}
                  aria-label='left aligned'
                >
                  {obj.name}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </Grid>
      </Paper>

      <Grid item xs={12} sx={{}}>
        <Outlet />
      </Grid>
    </Grid>
  );
};
